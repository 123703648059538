import AlertTemplate from './template/AlertTemplate.vue'

const AlertModule = {
  install(Vue) {
    const AlertConstructor = Vue.extend(AlertTemplate)
    let alertInstance = new AlertConstructor({
      el: document.createElement('div')
    })

    document.body.appendChild(alertInstance.$el)

    const alert = {
      alert: function (message, title) {
        return new Promise((resolve) => {
          if ( title ) {
            alertInstance.title = title
          }

          alertInstance.message = message
          alertInstance.isConfirm = false
          alertInstance.isShow = true
          alertInstance.callback = function () {
            resolve()
          }
        })
      },
      confirm: function (message, title) {
        return new Promise((resolve) => {
          if ( title ) {
            alertInstance.title = title
          }

          alertInstance.message = message
          alertInstance.isConfirm = true         
          alertInstance.isShow = true
          alertInstance.callback = function (result) {
            if (result === true) {
              resolve(true)
            } else {
              resolve(false)
            }
          }
        })
      },
      close: function () {
        if (alertInstance) {
          alertInstance.isShow = false
        }
      }
    }

    window.$alert = alert
    Vue.alert = alert
    Vue.prototype.$alert = alert
  }
}

export default AlertModule    