<template>
  <div style="width: 100%; height: 100%">
    <ag-grid-vue
      style="width: 100%; height: 100%"
      class="ag-theme-wts"
      :columnDefs="grid.columnDefs"
      :rowData="grid.rowData"
      :headerHeight="rowHeight"
      :rowHeight="rowHeight"
      :pagination="true"
      :suppressPaginationPanel="true"
      :suppressScrollOnNewData="true"
      :columnHoverHighlight="true"
      @grid-ready="onGridReady"
    />
    <PaginationComp :paging="grid.paging" @onPage="search" />
  </div>
</template>

<script>
// import { ref } from 'vue';
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
// import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme
import { AgGridVue } from "ag-grid-vue"; // Vue Grid Logic
// eslint-disable-next-line no-unused-vars
// import {currencyFormatter, cellClassRulesMinus} from '@/agGridUtils.js';
import PaginationComp from '@/components/agTables/PaginationComp.vue';
import Utils from '@/modules/utils'
import gridUtils from '@/modules/grid-utils';
import { stringCount } from '@/modules/helper';
import {buySellType, cellClassRulesMinus} from '@/modules/grid-utils.js';

export default {
  name: "AgTransactionTable",
  components: {
    AgGridVue,
    PaginationComp,
  },
  props:{
    account:{
      type: Object
    },
    setGridSearch:{
      type: Object
    }
  },
  computed:{
    getSymbolList: function(){
      let symbolList = window.$store.getters['Symbol/getSymbolList'];
      return symbolList;
    },
    getLoginUserInfo: function(){
      let loginInfo = window.$store.getters['Login/getLoginUserInfo'];
      return loginInfo;
    },
  },
  watch:{
    getSymbolList(symbolList){
      this.symbolList = symbolList;
    },
    setGridSearch:{
      handler(newVal){
        console.log("setGridSearch ", newVal)
        this.gridSearch = newVal;
        this.search(1);
      },
      deep:true
    },
  },
  data() {
    return {
      showColumn : false,
      gridSearch: {
        accId: 0,
        accPass: '',
        fromDate: Utils.getToday(), 
        toDate: Utils.getToday(), 
      },
      grid: {
        columnDefs: [],
        rowData: [],
        autoSizeStrategy: null,
        paginationPageSize: null,
        paging: {
          page: 1,
          size: 20,
          total: 0,
          pageSize : 10
        } 
      },
      gridApi: null,
      currentPage: '',
      totalPages: '',
			rowHeight: '',
      symbolList: [],
    };
  },
	created() {
    this.grid.rowData = [];
    this.grid.paging.page = 1;
		this.isMobile();
  },
  mounted(){
    this.showColumn = this.getLoginUserInfo.lvTpCd == "Y" ? true : false;
    this.symbolList = window.$store.getters['Symbol/getSymbolList'];

    this.grid.columnDefs = this.showColumn 
      ? [{ 
            headerName: "주문시간", 
            field: "ordTm",
            minWidth: 80,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
          },
          { 
            headerName: "주문번호",
            field: "ordId",
            minWidth: 80,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
          },
          { 
            headerName: "원주문번호",
            field: "orgHstOrdNo",
            minWidth: 80,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
          },
          { 
            headerName: "종목코드",
            field: "symCd",
            minWidth: 80,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
          },
          { 
            headerName: "종목명",
            field: "symEngNm",
            minWidth: 80,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
          },
          { 
            headerName: "주문구분",
            field: "ordSdNm",
            minWidth: 80,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
            cellClassRules: buySellType,
          },
          { 
            headerName: "주문상태",
            field: "plcTpNm",
            minWidth: 80,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
          },
          { 
            headerName: "주문유형",
            field: "ordTpNm",
            minWidth: 80,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
          },
          { 
            headerName: "레버리지",
            field: "lv",
            minWidth: 80,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
          },
          { 
            headerName: "주문수량",
            field: "ordQty",
            minWidth: 80,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
            valueFormatter: gridUtils.numberComma,
          },
          { 
            headerName: "체결수량",
            field: "execQty",
            minWidth: 80,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
            valueFormatter: gridUtils.numberComma,
          },
          { 
            headerName: "주문가격",
            field: "ordPrc",
            minWidth: 80,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
            valueFormatter: (d) =>{
              if (d.value == undefined || d.value == null || d.value == 0){
                return 0;
              }else{
                let symbol = null;
                this.symbolList.forEach(item => {
                  if (item.name == d.data.symCd){
                    symbol = item;
                    return;
                  }
                });
                return Number(d.value).toFixed(this.tikDotSz(symbol));
              }
            }
          },
          { 
            headerName: "체결단가",
            field: "execPrc",
            minWidth: 80,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
            valueFormatter: (d) =>{
              if (d.value == undefined || d.value == null || d.value == 0){
                return 0;
              }else{
                let symbol = null;
                this.symbolList.forEach(item => {
                  if (item.name == d.data.symCd){
                    symbol = item;
                    return;
                  }
                });
                return Number(d.value).toFixed(this.tikDotSz(symbol));
              }
            }
          },
          { 
            headerName: "이전평단가",
            field: "bfAvgPrc",
            minWidth: 80,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
            valueFormatter: (d) =>{
              if (d.value == undefined || d.value == null || d.value == 0){
                return 0;
              }else{
                let symbol = null;
                this.symbolList.forEach(item => {
                  if (item.name == d.data.symCd){
                    symbol = item;
                    return;
                  }
                });
                return Number(d.value).toFixed(this.tikDotSz(symbol));
              }
            }
          },
          { 
            headerName: "이후평단가",
            field: "afAvgPrc",
            minWidth: 80,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
            valueFormatter: (d) =>{
              if (d.value == undefined || d.value == null || d.value == 0){
                return 0;
              }else{
                let symbol = null;
                this.symbolList.forEach(item => {
                  if (item.name == d.data.symCd){
                    symbol = item;
                    return;
                  }
                });
                return Number(d.value).toFixed(this.tikDotSz(symbol));
              }
            }
          },
          { 
            headerName: "체결매매손익",
            field: "ordPlMn",
            minWidth: 100,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
            cellClassRules: cellClassRulesMinus,
            valueFormatter: gridUtils.numberComma,
          },
          { 
            headerName: "체결수수료",
            field: "cms",
            minWidth: 80,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
            cellClassRules: cellClassRulesMinus,
            valueFormatter: gridUtils.numberComma,
          },
          { 
            headerName: "체결실현손익",
            field: "exePlMn",
            minWidth: 100,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
            cellClassRules: cellClassRulesMinus,
            valueFormatter: gridUtils.numberComma,
          }] 
          
          : 
          
          [{ 
            headerName: "주문시간", 
            field: "ordTm",
            minWidth: 80,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
            sortable: true,
          },
          { 
            headerName: "주문번호",
            field: "ordId",
            minWidth: 80,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
          },
          { 
            headerName: "원주문번호",
            field: "orgHstOrdNo",
            minWidth: 80,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
          },
          { 
            headerName: "종목코드",
            field: "symCd",
            minWidth: 80,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
          },
          { 
            headerName: "종목명",
            field: "symEngNm",
            minWidth: 80,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
          },
          { 
            headerName: "주문구분",
            field: "ordSdNm",
            minWidth: 80,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
            cellClassRules: buySellType,
          },
          { 
            headerName: "주문상태",
            field: "plcTpNm",
            minWidth: 80,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
          },
          { 
            headerName: "주문유형",
            field: "ordTpNm",
            minWidth: 80,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
          },
          { 
            headerName: "주문수량",
            field: "ordQty",
            minWidth: 80,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
            valueFormatter: gridUtils.numberComma,
          },
          { 
            headerName: "체결수량",
            field: "execQty",
            minWidth: 80,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
            valueFormatter: gridUtils.numberComma,
          },
          { 
            headerName: "주문가격",
            field: "ordPrc",
            minWidth: 80,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
            valueFormatter: (d) =>{
              if (d.value == undefined || d.value == null || d.value == 0){
                return 0;
              }else{
                let symbol = null;
                this.symbolList.forEach(item => {
                  if (item.name == d.data.symCd){
                    symbol = item;
                    return;
                  }
                });
                return Number(d.value).toFixed(this.tikDotSz(symbol));
              }
            }
          },
          { 
            headerName: "체결단가",
            field: "execPrc",
            minWidth: 80,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
            valueFormatter: (d) =>{
              if (d.value == undefined || d.value == null || d.value == 0){
                return 0;
              }else{
                let symbol = null;
                this.symbolList.forEach(item => {
                  if (item.name == d.data.symCd){
                    symbol = item;
                    return;
                  }
                });
                return Number(d.value).toFixed(this.tikDotSz(symbol));
              }
            }
          },
          { 
            headerName: "이전평단가",
            field: "bfAvgPrc",
            minWidth: 80,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
            valueFormatter: (d) =>{
              if (d.value == undefined || d.value == null || d.value == 0){
                return 0;
              }else{
                let symbol = null;
                this.symbolList.forEach(item => {
                  if (item.name == d.data.symCd){
                    symbol = item;
                    return;
                  }
                });
                return Number(d.value).toFixed(this.tikDotSz(symbol));
              }
            }
          },
          { 
            headerName: "이후평단가",
            field: "afAvgPrc",
            minWidth: 80,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
            valueFormatter: (d) =>{
              if (d.value == undefined || d.value == null || d.value == 0){
                return 0;
              }else{
                let symbol = null;
                this.symbolList.forEach(item => {
                  if (item.name == d.data.symCd){
                    symbol = item;
                    return;
                  }
                });
                return Number(d.value).toFixed(this.tikDotSz(symbol));
              }
            }
          },
          { 
            headerName: "체결매매손익",
            field: "ordPlMn",
            minWidth: 100,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
            cellClassRules: cellClassRulesMinus,
            valueFormatter: gridUtils.numberComma,
          },
          { 
            headerName: "체결수수료",
            field: "cms",
            minWidth: 80,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
            cellClassRules: cellClassRulesMinus,
            valueFormatter: gridUtils.numberComma,
          },
          { 
            headerName: "체결실현손익",
            field: "exePlMn",
            minWidth: 100,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
            cellClassRules: cellClassRulesMinus,
            valueFormatter: gridUtils.numberComma,
          }];
  },
  methods: {
    async search(page) {
      if (page) {
        this.grid.paging.page = page
      }

      // 조회 조건
      this.gridSearch.accId   = this.account.accId
      //this.gridSearch.accPass = this.account.accPass
      this.gridSearch.page    = this.grid.paging.page
      this.gridSearch.size    = this.grid.paging.size

      const response = await this.$lhttp.get('/api/account/trd/list/page', { params: this.gridSearch })

      if ( response && response.data ) {
        this.grid.paging.total = response.data.total
        this.grid.rowData = response.data.content

        if ( response.data.content.length <= 0 ) {
          this.$alert.alert('조회된 거래내역이 없습니다.', '알림')
        }else{
          this.grid.rowData.forEach(d=>{
            d.cms = Math.ceil(d.cms * -1);
          })
        }
      }         
    },
    onGridReady(params) {
      this.gridApi = params.api;
    },
		isMobile() {
      if (window.innerWidth <= 1200) {
        return this.rowHeight = 40;
      } else {
        return this.rowHeight = 30;
      }
    },
    tikDotSz(symbol){
      if (symbol == null){
        return 0;
      }
      return stringCount(symbol.pricescale);
    },
  },
};

</script>

<style lang="scss" scoped>
@import '@/assets/scss/agGridStyles.scss';
</style>