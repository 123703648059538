<template>
  <div style="width: 100%; height: 100%">
    <ag-grid-vue
     style="width: 100%; height: 100%"
     class="ag-theme-wts"
     :columnDefs="grid.columnDefs"
     :rowData="grid.rowData"
     :headerHeight="rowHeight"
     :rowHeight="rowHeight"
     @grid-ready="onGridReady"
     />
  </div>
</template>

<script>
// import { ref } from 'vue';
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
// import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme
import { AgGridVue } from "ag-grid-vue"; // Vue Grid Logic
// eslint-disable-next-line no-unused-vars
import Utils from '@/modules/utils'

export default {
  name: "agProfitLossTable",
  components: {
    AgGridVue,
  },
  props:{
    account:{
      type: Object
    },
    setGridSearch:{
      type: Object
    }
  },
  watch:{
    setGridSearch:{
      handler(newVal){
        console.log("setGridSearch ", newVal)
        this.gridSearch = newVal;
        this.search();
      },
      deep:true
    }
  },
  data() {
    return {
      gridSearch: {
        accId: 0,
        accPass: '',
        fromDate: Utils.getDatePrevMonth(1), 
        toDate: Utils.getToday(), 
      },              
			grid: {
        columnDefs: [
          { 
            headerName: "거래일자", 
            field: "trdDt",
            minWidth: 80,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
            sortable : true ,
          },
          { 
            headerName: "신청시간",
            field: "dpsWdReqTm",
            minWidth: 80,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
            sortable : true ,
          },
          { 
            headerName: "구분",
            field: "dpsWdTpNm",
            minWidth: 80,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
            sortable : true ,
          },
          { 
            headerName: "금액",
            field: "amount",
            minWidth: 80,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
            sortable : true ,
            //alueFormatter: currencyFormatter
          },
          { 
            headerName: "처리시간",
            field: "dpsWdProcTm",
            minWidth: 80,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
            sortable : true ,
          },
          { 
            headerName: "상태",
            field: "dpsWdProcNm",
            minWidth: 80,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
            sortable : true ,
          },
          { 
            headerName: "비고",
            field: "cmt",
            minWidth: 200,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
            sortable : true ,
          },
        ],
        rowData: [],
        gridApi: null,
        autoSizeStrategy: null,
      },
			rowHeight: ''	
    };
  },
	created() {
		this.isMobile();
  },
  mounted(){
    this.grid.rowData = [];
  },
  methods: {
    onPaginationChanged() {
      if (this.gridApi) {
        this.currentPage = this.gridApi.paginationGetCurrentPage() + 1;
        this.totalPages = this.gridApi.paginationGetTotalPages();
      }
    },
		toFirstPage() {
			console.log('current page: ' + this.currentPage)
			this.gridApi.paginationGoToFirstPage();
		},
		toPrevPage() {
      this.gridApi.paginationGoToPreviousPage();
    },
    toNextPage() {
      this.gridApi.paginationGoToNextPage();
    },
		toLastPage() {
			this.gridApi.paginationGoToLastPage();
		},
    onGridReady(params) {
      this.gridApi = params.api;
    },
		isMobile() {
      if (window.innerWidth <= 1200) {
        return this.rowHeight = 40;
      } else {
        return this.rowHeight = 30;
      }
    },
    async search() {
      this.grid.rowData = [];
      //url 변경필요
      if (this.gridSearch.usrId == ""){return}
      const response = await this.$http.post('/api/partner/usrwithdrawlist', { usrId: this.gridSearch.setusrId,usrSeq : this.gridSearch.setusrSeq, stdt : this.gridSearch.fromDate, eddt : this.gridSearch.toDate, tp : this.gridSearch.inoutType, cpnId : this.gridSearch.cpnId})

      if ( response && response.data ) {
         this.grid.rowData = response.data
      }         
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/agGridStyles.scss';
</style>