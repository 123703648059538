<template>
  <div>
    <button class="round-btn" @touchend="onMenuToutch($event, params)">{{this.params.data.actionName}}</button>
  </div>
</template>

<script>
export default {
  name: 'WhiteLabelAgBtn',	
  data() {
    return {
    };
  },
  mounted() {
  },
  methods: {
    onMenuToutch(e, params){
		console.log("터치???????", params)
      if (params.enableMenuTouch != undefined && params.enableMenuTouch == true){
        //grid.columnDefs.headerComponentParams 에 추가한 touched 로 리턴
        params.touched(params);
      }
    }
  },
};
</script>
