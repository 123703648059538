<template>
  <div class="login2">
    <div class="login2-bg">
      <h1>Welcome!</h1>

      <h2>
        to keep connected with us please
        <br />
        login with your personal info
      </h2>
    </div>
    <div class="login2-form">
      <div class="signup-form">
        <div class="signup-form-header">
          <img class="company-logo" src="@/assets/images/toss-logo.png" alt="" />
          <h1 class="signup-title">회원가입</h1>
          <button class="close-btn"><img src="@/assets/icons/close-black.svg" alt="" /></button>
        </div>
        <div class="signup-form-body">
          <section>
            <h2 class="section-title">기본정보</h2>
            <div class="section-form">
              <div class="input-row">
                <label for="" class="input-label">사용구분</label>
                <div class="base-dropdown">
                  <div class="dropdown">
                    <div class="selected">1. 일반</div>
                    <img src="@/assets/icons/dropdown-triangle.svg" alt="" />
                  </div>
                  <ul class="dropdown-list">
                    <li>1. 일반</li>
                    <li>2. 모의투자</li>
                  </ul>
                </div>
              </div>
              <div class="input-row">
                <label for="" class="input-label">이름</label>
                <input type="text" />
              </div>
              <div class="input-row">
                <label for="" class="input-label">아이디</label>
                <input type="text" />
              </div>
              <div class="input-row">
                <label for="" class="input-label">비밀번호</label>
                <input type="password" />
              </div>
              <div class="input-row">
                <label for="" class="input-label">비밀번호확인</label>
                <input type="password" />
              </div>
              <div class="input-row">
                <label for="" class="input-label">휴대번호</label>
                <div class="mobile-form">
                  <div class="base-dropdown">
                    <div class="dropdown">
                      <div class="selected">010</div>
                      <img src="@/assets/icons/dropdown-triangle.svg" alt="" />
                    </div>
                    <ul class="dropdown-list">
                      <li>010</li>
                      <li>019</li>
                    </ul>
                  </div>
                  <input type="text" />
                  <input type="text" />
                </div>
              </div>
              <div class="input-row">
                <label for="" class="input-label">이메일</label>
                <input type="text" />
              </div>
              <div class="input-row">
                <label for="" class="input-label">추천인</label>
                <input type="text" />
              </div>
            </div>
          </section>
          <section>
            <h2 class="section-title">계좌정보</h2>
            <div class="section-form">
              <div class="input-row">
                <label for="" class="input-label">은행명</label>
                <div class="base-dropdown">
                  <div class="dropdown">
                    <div class="selected">은행명</div>
                    <img src="@/assets/icons/dropdown-triangle.svg" alt="" />
                  </div>
                  <ul class="dropdown-list">
                    <li>카카오뱅크</li>
                    <li>우리은행</li>
                  </ul>
                </div>
              </div>
              <div class="input-row">
                <label for="" class="input-label">예금주</label>
                <input type="text" />
              </div>
              <div class="input-row">
                <label for="" class="input-label">계좌번호</label>
                <input type="text" />
              </div>
            </div>
          </section>
          <button class="btn-primary blue">회원가입</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WhiteLabelSignup2Page',

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>
<style lang="scss" scoped>
.btn-primary {
  width: 100%;
  height: 40px;
  flex: 1;
  margin-top: 40px;
}
.mobile-form {
  display: grid;
	grid-template-columns: 1fr 1fr 1fr;
  gap: 0 5px;
}
.base-dropdown {
  .dropdown {
    width: 100%;
    flex: 1;
  }
  .dropdown-list {
		display: none;	
    width: 100%;
    flex: 1;
  }
}
</style>
