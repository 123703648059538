<template>
  <div class="custom-pagination">
    <button class="first-page-btn" @click="prevPage">
      <IconPageLast left />
    </button>
    <button naked class="prev-page-btn" @click="prev">
      <IconChevronDown left />
    </button>
    <div class="pagination-info">
      <!-- <h4>{{ currentPage }} / {{ totalPages }}</h4> -->
      <button 
      v-for="(page, index) in pages"
        :key="index"
        :class="{active: page === paging.page}"
        @click="select(page)" >
        <h4>{{page}}</h4>
      </button>
    </div>
    <button class="next-page-btn" @click="next">
      <IconChevronDown right />
    </button>
    <button class="last-page-btn" @click="nextPage">
      <IconPageLast right />
    </button>
  </div>
</template>

<script>
import IconChevronDown from '@/components/icons/IconChevronDown.vue';
import IconPageLast from '@/components/icons/IconPageLast.vue';
export default {
  name: 'PaginationComp',
  components: {
    IconPageLast,
    IconChevronDown
  },
  props: {
    paging: {
      type: Object,
      default: function () {
        return { 
          page: 1,
          size: 20,
          total: 0,
          pageSize : 10          
        }
      }      
    }
  },
  computed: {
    startPage: function() {
      return (Math.floor((this.paging.page-1) / this.paging.pageSize) * this.paging.pageSize) + 1
    },
    totalPage: function() {
      return Math.floor(this.paging.total / this.paging.size) + ((this.paging.total % this.paging.size) == 0 ? 0 : 1)
    },
    pages: function() {
      const pages = [];
      let startPage = this.startPage
      let totalPage = this.totalPage

      for ( let i = this.startPage; i <= totalPage && i < (startPage + this.paging.pageSize); i++ ) { 
        pages.push(i) 
      }

      //console.log('startPage ===> '+ this.startPage)
      //console.log('startPage ===> '+ this.totalPage)

      return pages
    }    
  },  
  data() {
    return {
      active: false,
      activeItem: 0,
    };
  },

  mounted() {
    //console.log(this.activeItem + '   ' + this.currentPage)
  },

  methods: {
    prev: function() {
      if (this.paging.page == 1) {
        this.select(this.paging.page)
      } else {
        this.select(this.paging.page - 1)
      }
    },
    next: function() {
      if (this.paging.page == this.totalPage) {
        this.select(this.paging.page)
      } else {
        this.select(this.paging.page + 1)
      }
    },
    prevPage: function() {    
      if (this.startPage == 1) {
        this.select(1)
      } else {
        this.select(this.startPage - this.paging.pageSize)
      }
    },
    nextPage: function() {    
      if (this.totalPage < (this.startPage + this.paging.pageSize)) {
        this.select(this.totalPage)
      } else {
        this.select(this.startPage + this.paging.pageSize)
      }
    },
    select: function(page) {
      this.$emit("onPage", page)
    }
  },
};
</script>

<style lang="scss" scoped>
.custom-pagination {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 20px;
	gap: 10px;
  /* @media (max-width: 500px) {
    margin-top: 10px;
    padding-bottom: 20px;
  } */
	.pagination-info {
		display: flex;
		align-items: center;
		gap: 20px;
    padding-bottom: 2px;
    .active {
      color: var(--btn-primary);
      font-weight: bold;
    }
	}
}
</style>
