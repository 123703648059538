import Vue from 'vue'
import Vuex from 'vuex'
import Layout from './modules/Layout'
import Login from './modules/Login'
import Symbol from './modules/Symbol'
import SocketOms from './modules/SocketOms'
import SocketPrice from './modules/SocketPrice'
import SocketMsg from './modules/SocketMsg'
import SocketCoinOms from './modules/SocketCoinOms'
import SocketCoinPrice from './modules/SocketCoinPrice'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    Layout,
    Login,
    Symbol,
    SocketOms,
    SocketPrice,
    SocketMsg,
    SocketCoinOms,
    SocketCoinPrice
  }
})