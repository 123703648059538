import { stringCount } from '@/modules/helper';

// object 초기화
const initObject = function (object, deep) {
    let self = this;
    self.obj = object;
  
    for( var key in object ) {
      switch ( typeof object[key] ) {
        case 'number':          // 정수
          self.obj[key] = 0;   
          break;
        case 'boolean':         // boolean
          self.obj[key] = false;   
          break;
        case 'array':           // array
          self.obj[key] = [];   
          break;
        case 'object':          // object
          if (deep) {
            initObject(self.obj[key]);
          } else {
            self.obj[key] = {};
          }
          break;                
        default:                // 문자
          self.obj[key] = '';
      }    
    }
  }
  
  // 숫자 3자리 콤마
  const numberComma = function( value ) {
    var len, point, str; 
         
    if ( value ) {
      var isMinus = (value < 0) ? true : false;
      value = Math.abs(value) + ""; 
      var pointValue = '';
  
      if ( value.indexOf('.') > -1 ) {
        var arrValue = value.split('.');
        value = arrValue[0];
        pointValue = '.'+ arrValue[1];
      }
  
      point = value.length % 3 ;
      len = value.length; 
     
      str = value.substring(0, point); 
      while (point < len) { 
          if (str != "") str += ","; 
          str += value.substring(point, point + 3); 
          point += 3; 
      } 
  
      if (pointValue != '') {
        str += pointValue;
      }
  
      if (isMinus) {
        str = '-'+ str;
      }
  
    } else {
      str = value;
    }
     
    return str;
  }

// 오늘 날짜 yyyy-MM-dd
const getToday = function( ) {
  var d = new Date()

  return getDateStr(d)
}

// ?일전 날짜
const getDatePrevDay = function(day) {
  var d = new Date()
  var dayOfMonth = d.getDate()
  d.setDate(dayOfMonth - day)

  return getDateStr(d)
}

// ?달전 날짜
const getDatePrevMonth = function(month) {
  var d = new Date()
  var monthOfYear = d.getMonth()
  d.setMonth(monthOfYear - month)

  return getDateStr(d)
}

// date yyyy-MM-dd
const getDateStr = function( date ) {
  var month = '' + (date.getMonth() + 1), 
  day = '' + date.getDate(), 
  year = date.getFullYear(); 
  
  if (month.length < 2) month = '0' + month; 
  if (day.length < 2) day = '0' + day; 
  
  return [year, month, day].join('-');
}

const getUuidv4 = function() {
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  )
}  

/**
 * NVL: expr1 값이 null(undefined) 또는 공백인 경우 expr2 값을 반환, 그렇지 않으면 expr1 값 반환
 * @return expr1
 */
const nvl = function(expr1, expr2) {
	if (expr1 === undefined || expr1 == null || expr1 == "") {
		expr1 = expr2;
	}
	return expr1;
}

// 배열에 값 추가
const addValueInArray = function(array, value) {
  if ( array !== null && Array.isArray(array) ) {
    if ( array.indexOf(value) < 0 )  {
      array.push(value)
    }
  }
}

// 배열에 값 삭제
const delValueInArray = function(array, value) {
  if ( array !== null && Array.isArray(array) ) {
    for ( let i = 0; i < array.length; i++ ) {
      if(array[i] === value)  {
        array.splice(i, 1)
        i--;
      }
    }
  }
}

/**
 * 우측문자열채우기
 * @params
 *  - str : 원 문자열
 *  - padLen : 최대 채우고자 하는 길이
 *  - padStr : 채우고자하는 문자(char)
 */
const rpad = function(str, padLen, padStr) {
  if (padStr.length > padLen) {
      console.log("오류 : 채우고자 하는 문자열이 요청 길이보다 큽니다");
      return str + "";
  }
  str += ""; // 문자로
  padStr += ""; // 문자로
  while (str.length < padLen)
      str += padStr;
  str = str.length >= padLen ? str.substring(0, padLen) : str;
  return str;
}

//현재시간
const getNowTime = function(){
  const curr = new Date();
  // UTC 시간 계산
  const utc = curr.getTime() + (curr.getTimezoneOffset() * 60 * 1000);

  // UTC to KST (UTC + 9시간)
  const KR_TIME_DIFF = 9 * 60 * 60 * 1000;
  const kr_curr = new Date(utc + (KR_TIME_DIFF));

  const month = String(kr_curr.getMonth()+1).padStart(2,"0");
  const date = String(kr_curr.getDate()).padStart(2,"0");
  const h = String(kr_curr.getHours()).padStart(2,"0");
  const m = String(kr_curr.getMinutes()).padStart(2,"0");
  const s = String(kr_curr.getSeconds()).padStart(2,"0");
  // const weekday = ["일", "월", "화", "수", "목", "금", "토"];
  // const day = weekday[kr_curr.getDay()];
  //var korGmt = `GMT+09:00 ${month}-${date}`

  //this.serverGmt = korGmt;
  const serverTime = `${h}${m}${s}`;
  return serverTime;
}

//종목 소수점 자리수
const tikDotSz = function(symbol){
  if (symbol == null){
    return 0;
  }
  return stringCount(symbol.pricescale);
}

//종목별 틱
const tikVal = function(symbol){
  if (symbol == null){
    return 0;
  }
  return (symbol.minmov / symbol.pricescale);
}

const symbolCalPrc = function(data, ovcData, symbolList, usdExr, usdExHkd, hkdExR, lvTpCd, currency){
  let resData = {data:data, valProLoss: 0};
  let symbolData = null;
  let pricescale = 1;
    symbolList.forEach(item => {
      if (item.name == data.symCd){
        symbolData = item;
        if (item.pricescale > 0){
          pricescale = item.pricescale;
        }
        return;
      }
    });

  if (ovcData != undefined){
    //현재가
    data.nowPrice = Number(ovcData.curPr).toFixed(tikDotSz(symbolData));

    //평가손익 계산
    let val1 = 0;
    let val2 = 0;
    let valProLoss = 0;
    let trdUnt = data.trdUnt;
    if (lvTpCd != undefined && lvTpCd == "Y"){ //레버리지 여부
      trdUnt = data.lvTrdUnt; //레버리지 금액 / 배율 한 금액
      usdExr = 1;
      usdExHkd = 1;
      hkdExR = 1;
    } 
    if (ovcData.curPr == 0 || data.ordSdCd == 0){
      valProLoss = 0;
    }else{
      if (data.ordSdCd == "1"){ //매도
        val1 = ((data.execPrc.toFixed(tikDotSz(symbolData)) * data.balQty) *  pricescale);
        val2 = ((ovcData.curPr * data.balQty) * pricescale);
      }else if (data.ordSdCd == "2"){ //매수
        val1 = ((ovcData.curPr * data.balQty) * pricescale) ;
        val2 = (((data.execPrc.toFixed(tikDotSz(symbolData)) * data.balQty) * pricescale));
      }

      //소수점 계산에서 오차가 발생 ex> 0.0001 + 0.0009 = 0.000999 같은경우
      //1. execPrc의 평균가를 toFixed로 종목별 자릿수 만큼 반올림
      //2. pricescale 곱하여 소수점없이 계산
      //3. val1,val2 계산된값에 trunc을 하여 소수점을 버림
      //4. 최종 가격에서 pricescale로 나눠서 정상 가격을 표시

      //달러로 계산
      if (data.symNo == "2"){ //홍콩달러
        valProLoss = ((val1 - val2) * trdUnt); //홍콩달러계산
      }else{
        valProLoss = ((val1 - val2) / tikVal(symbolData)) * trdUnt; //달러로 계산
      }

      
      //console.log("valProLoss ", data)
      
      if (isNaN(valProLoss)) valProLoss = 0;

      //원화로 표시
      if (currency == "WON"){
        if (data.symNo == "2"){
          valProLoss = Math.round((valProLoss * hkdExR) / pricescale);
          data.valPl = valProLoss;
        }else if (data.symNo == "10"){
          //국내선물
          valProLoss = Math.round(valProLoss) / pricescale;
          data.valPl = valProLoss;
        }else{
          //원화는 통화를 원화로 변경
          valProLoss = Math.round((valProLoss * usdExr) / pricescale);
          data.valPl = valProLoss;              
        }                  
      }else{ //달러
        if (data.symNo == "2"){
          valProLoss = (valProLoss * usdExHkd);
          data.valPl = valProLoss.toFixed(2);
        }else if (data.symNo == "10"){
          valProLoss = (valProLoss / usdExr);
          data.valPl = valProLoss.toFixed(2);
        }else{
          data.valPl = valProLoss.toFixed(2);
        }
      }

      resData.data = data;
      resData.valProLoss = valProLoss;
    }
  }

  return resData;
}

export default { 
  initObject,
  numberComma,  
  getToday,
  getDatePrevDay,
  getDatePrevMonth,
  getUuidv4,
  nvl,
  addValueInArray,
  delValueInArray,
  rpad,
  getNowTime,
  tikDotSz,
  tikVal,
  symbolCalPrc
}