import Utils from '@/modules/utils'

const state = {
  channelToPriceOvc: new Map(), //구독중 OVC(체결) 종목
  channelToPriceOvh: new Map(), //구독중 OVH(호가) 종목
  socketPrice : null,
  isConnectPrice: false,
  stockPrice: null,
  sessionStorage: null,
  ovcData: null,
  ovhData: null,
  cocData: null,
  cohData: null,
  cotData: null,
  comData: null,
  priceInterval: null,
  connectPriceTimeout: null,
  selectSymbol: null, //선택한 종목
  //---폴링시세 관련---
  isPolingPrice: false,
  pollingInterVal: null,
  ovcDataList: null,
  ovhDataList: null,
  cocDataList: null,
  cohDataList: null,
  cotDataList: null,
  comDataList: null,
  pollingSymCd: null,
  pollingSymList: null,
  pollingDelayTime: 300, //폴링간격 0.1초
  //---폴링시세 관련---
  priceReqType: "SCKTP02", //시세 받는방식 socket(SCKTP01), polling(SCKTP02/SCKTP03)
}
  
const getters = {
  getIsConnectPrice(state){
      return state.isConnectPrice;
  },
  //ovc 시세
  getOvcData(state){
    return state.ovcData;
  },
  //ovh 호가
  getOvhData(state){
    return state.ovhData;
  },
  getStockPrice(state){
    return state.stockPrice;
  },
  getSelectSymbol(state){
    return state.selectSymbol;
  },
  //---폴링시세 관련---
  getIsPolingPrice(state){
    return state.isPolingPrice;
  },
  getOvcDataList(state){
    return state.ovcDataList;
  },
  getOvhDataList(state){
    return state.ovhDataList;
  },
  //---폴링시세 관련---
  getPriceReqType(state){
    return state.priceReqType;
  }
}
  
const mutations = {
  setSessionStorage (state, {sessionStorage}){
    state.sessionStorage = sessionStorage
  },
  setConectPrice (state, {socketPrice}){
    state.socketPrice = socketPrice
  },
  setIsConnectPrice(state, {isConnect}){
    // console.log("setIsConnectPrice >>> ", isConnect)
    state.isConnectPrice = isConnect
  },
  setStockPrice(state, {stockPrice}){
      state.stockPrice = stockPrice
  },
  sendPriceMessage(state, {apiCode, apiType, symbolList, symbol}){
    //console.log("sendPriceMessage >>>>> ", apiCode, apiType, symbolList, symbol)

    if (!symbolList && !symbol) return;

    // if (symbol != null && symbol.length > 2 && symbol.substring(0,2) == '10'){
    //   if (apiType == 'OVC'){
    //     apiFc0Type = 'FC0';
    //   }else if (apiType == 'OVH'){
    //     apiFh0Type = 'FH0';
    //   }
    // }
    // if (symbolList != undefined && symbolList.length > 0){
    //   for (const sym of symbolList){
    //     if (sym != null && sym.length > 2 && sym.substring(0,2) == '10'){
    //       if (apiType == 'OVC'){
    //         apiFc0Type = 'FC0';
    //         fc0Symbol = sym;
    //       }else if (apiType == 'OVH'){
    //         apiFh0Type = 'FH0';
    //         fh0Symbol = sym;
    //       }

    //     }
    //   }
    // }

    let ovcReqData = {
      header: {
        apiCode : apiCode,
        token : state.sessionStorage
      },
      body : {
        apiType : apiType,
        apiTypeData: [apiType],
        symbol : symbol,
        symbolData : symbolList
      }
    }

    let ovhReqData = {
      header: {
        apiCode : apiCode,
        token : state.sessionStorage
      },
      body : {
        apiType : apiType,
        apiTypeData: [apiType],
        symbol : symbol,
        symbolData : symbolList      
      }
    }

    if (apiCode == "DSA0004"){
      ovcReqData.body.symbolData = [symbol];
      ovhReqData.body.symbolData = [symbol];
    }

    // let fc0ReqData = {
    //   header: {
    //     apiCode : apiCode,
    //     token : state.sessionStorage
    //   },
    //   body : {
    //     apiType : 'FC0',
    //     apiTypeData: ['FC0'],
    //     symbolData : [symbol]
    //   }
    // }

    // if (apiCode == "DSA0004"){
    //   ovcReqData.body = {
    //     apiType : 'OVC',
    //     symbol : symbol,
    //     apiTypeData: ['OVC'],
    //     symbolData : [symbol]
    //   }

    //   fc0ReqData.body = {
    //     apiType : 'FC0',
    //     symbol : symbol,
    //     apiTypeData: ['FC0'],
    //     symbolData : [symbol] 
    //   }
    // }
    // //호가는 선택한 종목 단건만 요청
    // let ovhReqData = {
    //   header: {
    //     apiCode : apiCode,
    //     token : state.sessionStorage
    //   },
    //   body : {
    //     apiType : 'OVH',
    //     apiTypeData: ['OVH'],
    //     //symbol : symbol,
    //     symbolData : symbolList      
    //   }
    // }

    // if (symbol){
    //   ovhReqData.body.symbolData = [symbol];
    // }

    // const fh0ReqData = {
    //   header: {
    //     apiCode : apiCode,
    //     token : state.sessionStorage
    //   },
    //   body : {
    //     apiType : 'FH0',
    //     apiTypeData: ['FH0'],
    //     symbol : fh0Symbol,
    //     symbolData : [fh0Symbol]
    //   }
    // }
    // console.log("ovcReqData", ovcReqData)
    // console.log("ovhReqData", ovhReqData)
    try{
      if (state.socketPrice.readyState == 1){
        if (apiType == "OVC"){
          if (ovcReqData.body.symbolData[0]){
            state.socketPrice.send(JSON.stringify(ovcReqData))
          }
        }
        if (apiType == "OVH"){
          if (ovhReqData.body.symbolData[0]){
            state.socketPrice.send(JSON.stringify(ovhReqData))
          }          
        }
        if (apiType == "FC0"){
          if (ovcReqData.body.symbolData[0]){
            state.socketPrice.send(JSON.stringify(ovcReqData))
          }
        }
        if (apiType == "FH0"){
          if (ovhReqData.body.symbolData[0]){
            state.socketPrice.send(JSON.stringify(ovhReqData))
          }
        }
      }
    }catch(e){
      console.log("e >>> ", e, ovcReqData, ovhReqData)
    }  
  },
  setChannelToPriceOvc(state,  {subscribeItem}){
    state.channelToPriceOvc.set(subscribeItem.symbol, subscribeItem)
  },
  delChannelToPriceOvc(state,  {symbol}){
    state.channelToPriceOvc.delete(symbol)
  },
  setChannelToPriceOvh(state,  {subscribeItem}){
    state.channelToPriceOvh.set(subscribeItem.symbol, subscribeItem)
  },
  delChannelToPriceOvh(state,  {symbol}){
    state.channelToPriceOvh.delete(symbol)
  },
  sendPriceHeartBeat(state) {
    // console.log("sendPriceHeartBeat ", state.sessionStorage)
      const reqData = {
        header: {
        apiCode : 'DSA0000',
        token : state.sessionStorage
        },
        body : {
        }
      }
      if (state.socketPrice.readyState == 1){
        state.socketPrice.send(JSON.stringify(reqData))
      }
  },
  setOvcData(state,  {ovcData}){
    state.ovcData = ovcData
  },
  setOvhData(state,  {ovhData}){
    state.ovhData = ovhData
  },
  setSelectSymbol(state, symbol){
    state.selectSymbol = symbol
  },
  setDisconnect(state){
    if (state.isConnectPrice){
      state.socketPrice.onclose = function () {}
      state.socketPrice.close();
      state.isConnectPrice = false
    }
    clearInterval(state.priceInterval)
  },
  //---폴링시세 관련---
  setIsPolingPrice(state, {symCd:symCd, symbolList:symbolList}){
    state.isPolingPrice = true;
    //시세,호가 받을 목록 저장
    state.pollingSymCd = symCd;
    state.pollingSymList = symbolList;
  },
  setClearPolling(state){
    if (state.isPolingPrice){
      state.isPolingPrice = false
    }
    clearInterval(state.pollingInterVal)
  },
  setPollingData(state, {data:data}){
    //시세, 호가 데이터 초기화
    state.ovcDataList = [];
    state.ovhDataList = [];
    if (data != undefined && data.ovh != null){
      const ovhData = {
        bidHo1: data.ovh.bidHo1,
        bidHo2: data.ovh.bidHo2,
        bidHo3: data.ovh.bidHo3,
        bidHo4: data.ovh.bidHo4,
        bidHo5: data.ovh.bidHo5,
        bidNo1: data.ovh.bidNo1,
        bidNo2: data.ovh.bidNo2,
        bidNo3: data.ovh.bidNo3,
        bidNo4: data.ovh.bidNo4,
        bidNo5: data.ovh.bidNo5,
        bidRem1: data.ovh.bidRem1,
        bidRem2: data.ovh.bidRem2,
        bidRem3: data.ovh.bidRem3,
        bidRem4: data.ovh.bidRem4,
        bidRem5: data.ovh.bidRem5,
        offerHo1: data.ovh.offerHo1,
        offerHo2: data.ovh.offerHo2,
        offerHo3: data.ovh.offerHo3,
        offerHo4: data.ovh.offerHo4,
        offerHo5: data.ovh.offerHo5,
        offerNo1: data.ovh.offerNo1,
        offerNo2: data.ovh.offerNo2,
        offerNo3: data.ovh.offerNo3,
        offerNo4: data.ovh.offerNo4,
        offerNo5: data.ovh.offerNo5,
        offerRem1: data.ovh.offerRem1,
        offerRem2: data.ovh.offerRem2,
        offerRem3: data.ovh.offerRem3,
        offerRem4: data.ovh.offerRem4,
        offerRem5: data.ovh.offerRem5,
        symbol: data.ovh.symbol,
        totBidCnt: data.ovh.totBidCnt,
        totBidRem: data.ovh.totBidRem,
        totOfferCnt: data.ovh.totOfferCnt,
        totOfferRem: data.ovh.totOfferRem
      };

      state.ovhDataList.push(ovhData);
    }

    if (data != undefined && data.ovcDataList != undefined) {
      for (const key in data.ovcDataList){
        const item = data.ovcDataList[key];
        if (item.symbol == null) return;
        const ovcData = {          
          symbol: item.symbol,
          ovsDate: item.ovsDate,
          korDate: item.korDate,
          trdTm: item.trdTm,
          korTm: item.korTm,
          curPr: item.curPr,
          ydiffPr: item.ydiffPr,
          ydiffSign: item.ydiffSign,
          open: item.open,
          high: item.high,
          low: item.low,
          chGrate: item.chGrate,
          trDq: item.trDq,
          totQ: item.totQ,
          cgubun: item.cgubun,
          mdVolume: item.mdVolume,
          msVolume: item.msVolume,
          ovsMkEnd: item.ovsMkEnd,
        };
        state.ovcDataList.push(ovcData);
      }
    }
  },
  setDelayTime(state, {time:time}){
    if (time == null && isNaN(time)){
      return;
    }else{
      state.pollingDelayTime = time;
      //console.log("setDelayTime ", state.pollingDelayTime)
    }
  },
  //---폴링시세 관련---
  //소켓 폴링 설정
  setPriceReqType(state, {sckTpCd:sckTpCd}){
    //console.log("setPriceReqType >>>> ", sckTpCd)
    state.priceReqType = sckTpCd;
  },
  resetSelectSymbol(state){
    state.selectSymbol = null;
  },
}
  
const actions = {
  //소켓 커넥션 생성
  setConectPrice({commit, state, dispatch}, sessionStorage){
    commit('setSessionStorage', {sessionStorage:sessionStorage.sessionStorage})
    const websocketPath = window.$store.VUE_APP_QUOTE_SOCKET_BASE_URL

    if(state.socketPrice && state.socketPrice.readyState == WebSocket.OPEN){     
        state.socketPrice.close()
    }
    let socketPrice = new WebSocket(websocketPath);

    commit('setConectPrice', {socketPrice: socketPrice})   

    state.socketPrice.onopen = () => {
      commit('setIsConnectPrice', {isConnect:true})
      //state.isConnectPrice = true
      //commit('sendPriceHeartBeat', {})
      clearInterval(state.priceInterval);
      state.priceInterval = setInterval(() => {
        commit('sendPriceHeartBeat', {})
      }, 50000)
    }

    state.socketPrice.onmessage = (e) => {
      //console.log("onMessage?", e.data)
      const returnData = JSON.parse(e.data)
      const data = returnData.body

      //state.stockPrice = data;
      commit('setStockPrice', {stockPrice:data})

      if ( data.apiType == 'OVC' ) {         
        //console.log("ovc data >>>> ", data.symbol, data.curPr, data);
        commit('setOvcData', {ovcData:data})
      }else if (data.apiType == 'OVH'){
        //console.log("ovh data >>>> ", data.symbol, data.hotTime, data);
        commit('setOvhData', {ovhData:data})
      }else if (data.apiType == 'FC0'){
        commit('setOvcData', {ovcData:data})
      }else if (data.apiType == 'FH0'){
        commit('setOvhData', {ovhData:data})
      }
    }

    state.socketPrice.onerror = (e) => {
      commit('setIsConnectPrice', {isConnect:false})
      console.error("[Error] socket Error")
    }

    //socket.onclose = () => {
    state.socketPrice.onclose = (e) => {
      commit('setIsConnectPrice', {isConnect:false})

      if (e.wasClean) {
        console.log('[price] WS Closed')
        state.connectMsgTimeout = setTimeout(() => {
          dispatch('setConectPrice', {sessionStorage:state.sessionStorage});
        }, 5000);
      } else {
        console.log('[price] WS Connecting...')
        dispatch('setConectPrice', {sessionStorage:state.sessionStorage})
      }

      // console.error("[OnClose] socket close", e.code, e.wasClean);
      // state.connectPriceTimeout = setTimeout(() => {
      //   dispatch('setConectPrice', {sessionStorage:state.sessionStorage})
      // }, 1000)
    }
  },
  setSubscribeOnPriceList({commit, state}, symbolList) {
    if (!symbolList || symbolList.length == 0) return

    let subscriptionUID = sessionStorage.getItem('subscriptionUID')

    if ( subscriptionUID == null ) {
      subscriptionUID = Utils.getUuidv4()
      sessionStorage.setItem('subscriptionUID', subscriptionUID)
    }

    if (symbolList && symbolList.length > 0){
      symbolList.forEach(symbol=>{
        // 소켓 구독
        const subscribeItem = {
          symbol: symbol,
          subscriptionUID: sessionStorage.getItem('subscriptionUID'),
          data: new Array()
        }

        commit('setChannelToPriceOvc', {subscribeItem:subscribeItem})
      })
    }



    //this.channelToPrice.set(symbol, subscribeItem)
    //commit('setChannelToPrice', {subscribeItem:subscribeItem})
    commit('sendPriceMessage', {apiCode:'DSA0003', apiType:'OVC', symbolList})
    //commit('setConnectSymbol', {symbol:symbol})
  },
  setUpdateSubscribeOnPriceList({commit, state}, {symbolList, popupSelSymbolList}) {
    // 소켓 구독 수정

    if ((symbolList == undefined && popupSelSymbolList == undefined) || (symbolList.length == 0 && popupSelSymbolList.length == 0)){
      return;
    }   
    
    let ovcConnectList = [];
    let fc0ConnectList = [];
    if (symbolList && symbolList.length > 0){
      
      
      for (let symbol of state.channelToPriceOvc.keys()) {
        if (symbolList.indexOf(symbol) == -1){ //symbolList 접속중인 symbol 없으면 구독 해지
          //구독 해지
          
          commit('delChannelToPriceOvc', {symbol:symbol});
          let apiType = 'OVC';
          let apiType2 = 'OVH';
          if (symbol != null && symbol.length > 2 && symbol.substring(0,2) == '10'){
            apiType = 'FC0';
            apiType2 = 'FH0';
          }
          commit('sendPriceMessage', {apiCode:'DSA0004', apiType:apiType, symbolList:null, symbol:symbol})
          commit('sendPriceMessage', {apiCode:'DSA0004', apiType:apiType2, symbolList:null, symbol:symbol})
        }
      }
      
      symbolList.forEach(symbol=>{
        if (state.channelToPriceOvc.get(symbol) == undefined){ //접속중인 목록이 없는 목록만 추가
          // 소켓 구독
          const subscribeItem = {
            symbol: symbol,
            subscriptionUID: sessionStorage.getItem('subscriptionUID'),
            data: new Array()
          }

          commit('setChannelToPriceOvc', {subscribeItem:subscribeItem})
          //국내선물은 따로 설정
          if (symbol != null && symbol.length > 2 && symbol.substring(0,2) == '10'){
            fc0ConnectList.push(symbol);
          }else{
            ovcConnectList.push(symbol);
          }

        }else { //channelToPriceOvc 등록 되어있으면 목록에만 추가
          //국내선물은 따로 설정
          if (symbol != null && symbol.length > 2 && symbol.substring(0,2) == '10'){
            fc0ConnectList.push(symbol);
          }else{
            ovcConnectList.push(symbol);
          }
        }
      })
    }

    //팝업창 호가 업데이트
    let ovhConnectList = [];
    let fh0ConnectList = [];

    //선택중인 심볼 추가
    if (state.selectSymbol != undefined) {
      const selectSymbol = state.selectSymbol.name;

      //팝업 구독해지
      for (let symbol of state.channelToPriceOvc.keys()) {
        if (popupSelSymbolList != undefined && popupSelSymbolList.indexOf(symbol) == -1){ //popupSelSymbolList 접속중인 symbol 없으면 구독 해지
          //구독 해지
          commit('delChannelToPriceOvc', {symbol:symbol});
          commit('delChannelToPriceOvh', {symbol:symbol});
          let apiType = 'OVC';
          let apiType2 = 'OVH';
          if (symbol != null && symbol.length > 2 && symbol.substring(0,2) == '10'){
            apiType = 'FC0';
            apiType2 = 'FH0';
          }
          
          commit('sendPriceMessage', {apiCode:'DSA0004', apiType:apiType, symbolList:null, symbol:symbol})
          commit('sendPriceMessage', {apiCode:'DSA0004', apiType:apiType2, symbolList:null, symbol:symbol})
        }
      }

      //ovh구독해지
      for (let symbol of state.channelToPriceOvh.keys()) {
        //console.log("구독 해지 종목? ", state.channelToPriceOvh.get(symbol), symbol)
        if (state.channelToPriceOvh.get(selectSymbol) != undefined){ //현재 종목과 다르면
          let apiType = 'OVH';
          if (symbol != null && symbol.length > 2 && symbol.substring(0,2) == '10'){
            apiType = 'FH0';
          }
          commit('sendPriceMessage', {apiCode:'DSA0004', apiType:apiType, symbolList:null, symbol:symbol})
        }
      }

      if (selectSymbol != null && selectSymbol.length > 2 && selectSymbol.substring(0,2) == '10'){
        if (fc0ConnectList.indexOf(selectSymbol) == -1){
          // 소켓 구독
          const subscribeItem = {
            symbol: selectSymbol,
            subscriptionUID: sessionStorage.getItem('subscriptionUID'),
            data: new Array()
          }

          commit('setChannelToPriceOvc', {subscribeItem:subscribeItem})

          fc0ConnectList.push(selectSymbol);
        }

        // 소켓 구독
        const subscribeItem = {
          symbol: selectSymbol,
          subscriptionUID: sessionStorage.getItem('subscriptionUID'),
          data: new Array()
        }

        commit('setChannelToPriceOvh', {subscribeItem:subscribeItem})
        fh0ConnectList = [selectSymbol];
        
      }else{
        if (ovcConnectList.indexOf(selectSymbol) == -1){
          // 소켓 구독
          const subscribeItem = {
            symbol: selectSymbol,
            subscriptionUID: sessionStorage.getItem('subscriptionUID'),
            data: new Array()
          }

          commit('setChannelToPriceOvc', {subscribeItem:subscribeItem})

          ovcConnectList.push(selectSymbol);
        }

        // 소켓 구독
        const subscribeItem = {
          symbol: selectSymbol,
          subscriptionUID: sessionStorage.getItem('subscriptionUID'),
          data: new Array()
        }

        commit('setChannelToPriceOvh', {subscribeItem:subscribeItem})
        ovhConnectList = [selectSymbol];
      }

    }   
   
    if (popupSelSymbolList != undefined && popupSelSymbolList && popupSelSymbolList.length > 0){     
      //OVC 추가
      popupSelSymbolList.forEach(symbol=>{
        if (state.channelToPriceOvc.get(symbol) == undefined){ //접속중인 목록이 없는 목록만 추가
          // 소켓 구독
          const subscribeItem = {
            symbol: symbol,
            subscriptionUID: sessionStorage.getItem('subscriptionUID'),
            data: new Array()
          }

          commit('setChannelToPriceOvc', {subscribeItem:subscribeItem})
          //국내선물은 따로 설정
          if (symbol != null && symbol.length > 2 && symbol.substring(0,2) == '10'){
            fc0ConnectList.push(symbol);
          }else{
            ovcConnectList.push(symbol);
          }
        }
      })

      //OVH 추가
      popupSelSymbolList.forEach(symbol=>{
        if (state.channelToPriceOvh.get(symbol) == undefined){ //접속중인 목록이 없는 목록만 추가
          // 소켓 구독
          const subscribeItem = {
            symbol: symbol,
            subscriptionUID: sessionStorage.getItem('subscriptionUID'),
            data: new Array()
          }

          commit('setChannelToPriceOvh', {subscribeItem:subscribeItem})
          //국내선물은 따로 설정
          if (symbol != null && symbol.length > 2 && symbol.substring(0,2) == '10'){
            fh0ConnectList.push(symbol);
          }else{
            ovhConnectList.push(symbol);
          }
        }else{ // 호가 시세를 받기 위해 리스트에 없는 목록 추가
          //국내선물은 따로 설정
          if (symbol != null && symbol.length > 2 && symbol.substring(0,2) == '10'){
            fh0ConnectList.push(symbol);
          }else{
            ovhConnectList.push(symbol);
          }
        }
      })
    }

    commit('sendPriceMessage', {apiCode:'DSA0003', apiType:'OVC', symbolList:ovcConnectList});
    commit('sendPriceMessage', {apiCode:'DSA0003', apiType:'OVH', symbolList:ovhConnectList});
    commit('sendPriceMessage', {apiCode:'DSA0003', apiType:'FC0', symbolList:fc0ConnectList});
    commit('sendPriceMessage', {apiCode:'DSA0003', apiType:'FH0', symbolList:fh0ConnectList});
    
  },
  setSubscribeOnPrice({commit, state}, symbol) {
    let subscriptionUID = sessionStorage.getItem('subscriptionUID')

    if ( subscriptionUID == null ) {
      subscriptionUID = Utils.getUuidv4()
      sessionStorage.setItem('subscriptionUID', subscriptionUID)
    }

    // 소켓 구독
    const subscribeItem = {
      symbol,
      subscriptionUID: sessionStorage.getItem('subscriptionUID'),
      data: new Array()
    }

    commit('setChannelToPriceOvc', {subscribeItem:subscribeItem})

    //this.channelToPrice.set(symbol, subscribeItem)
    //commit('setChannelToPrice', {subscribeItem:subscribeItem})
    //commit('sendPriceMessage', {apiCode:'DSA0003', apiType:'', symbol:symbol})
    commit('setConnectSymbol', {symbol:symbol})
    
  },
  setUpdateSubscribeOnPrice({commit, state}, symbol) {
    // 소켓 구독 수정
    const subscribeItem = {
      //symbol,
      subscriptionUID: sessionStorage.getItem('subscriptionUID'),
      data: new Array()
    }

    commit('setChannelToPriceOvc', {subscribeItem:subscribeItem})

    //commit('sendPriceMessage', {apiCode:'DSA0003', apiType:'', symbol:symbol})
    
  },
  setUnSubscribeOnPrice({commit, state}, symbol){
    // 소켓 구독 종료
    const data = state.channelToPriceOvc.get(symbol)
    if(data != null){
      if(state.isConnectPrice){
        commit('sendPriceMessage', {apiCode:'DSA0004', apiType:'', symbol:symbol})
        commit('delChannelToPriceOvc', {symbol:symbol})
      }
    }    
  },
  setSelectSymbol({commit, state}, symbol){
    //시세 변경시 호가 구독 종료 호출
    if (window.name == undefined || window.name == null || window.name == ""){
      // let apiType = 'OVH';
      // if (state.isConnectPrice && state.selectSymbol){
      //   apiType = 'OVH';
      //   if (state.selectSymbol.symbolno == 10){ //국내선물
      //     apiType = 'FH0';
      //   }
      //   //ovh 접속 목록 삭제
      //   commit('delChannelToPriceOvh', {symbol:state.selectSymbol.name});
      //   commit('sendPriceMessage', {apiCode:'DSA0004', apiType:apiType, symbolList:null, symbol:state.selectSymbol.name});
      // }       
      
      // if (symbol.symbolno == 10){ //국내선물
      //   apiType = 'FH0';
      // }else{
      //   apiType = 'OVH';
      // }
      // //ovh 접속 목록 추가
      // const subscribeItem = {
      //   symbol: symbol,
      //   subscriptionUID: sessionStorage.getItem('subscriptionUID'),
      //   data: new Array()
      // }
      // commit('setChannelToPriceOvh', {subscribeItem:subscribeItem});
      // commit('sendPriceMessage', {apiCode:'DSA0003', apiType:apiType, symbolList:null, symbol:symbol.name});
    }else{
      //팝업창일때
      if (window.name == "orderPop1"){
        window.opener.postMessage({ type: 'addSymbol', popupId:window.name, symbol:symbol}, window.location.origin);
      }else if (window.name == "orderPop2"){
        window.opener.postMessage({ type: 'addSymbol', popupId:window.name, symbol:symbol }, window.location.origin);
      }
    }

    commit('setSelectSymbol', symbol);
  },
  //구독중인 종목 모두 삭제
  resetSymbol({commit, state}){
    for (let symbol of state.channelToPriceOvc.keys()) {
        //구독 해지
        commit('delChannelToPriceOvc', {symbol:symbol});
        let apiType = 'OVC';
        if (symbol != null && symbol.length > 2 && symbol.substring(0,2) == '10'){
          apiType = 'FC0';
        }
        commit('sendPriceMessage', {apiCode:'DSA0004', apiType:apiType, symbolList:null, symbol:symbol})
    }
    for (let symbol of state.channelToPriceOvh.keys()) {
        //구독 해지
        commit('delChannelToPriceOvh', {symbol:symbol});
        let apiType = 'OVH';
        if (symbol != null && symbol.length > 2 && symbol.substring(0,2) == '10'){
          apiType = 'FH0';
        }
        commit('sendPriceMessage', {apiCode:'DSA0004', apiType:apiType, symbolList:null, symbol:symbol})
    }

    commit("resetSelectSymbol");
  },
  //---폴링시세 관련---
  setPollingPrice({commit, state, dispatch}, {symCd:symCd, symbolList:symbolList}){
    commit('setIsPolingPrice', {symCd:symCd, symbolList:symbolList});
    if (state.priceInterval == null){
      dispatch('getPollingData');
    }
  },
  getPollingData({commit, state, dispatch}){
    state.priceInterval = setInterval(() => {
      return new Promise((resolve, reject) => {
        window.$http
          .get('apisise/getCurPrice', { params: { symCd:state.pollingSymCd, symbolList: state.pollingSymList } })
          .then(response => {
            if ( response ) {
              if ( response.data !== null ) {
                commit('setPollingData', {data:response.data});
              }
            }
            resolve()
          })
          .catch(() => { resolve(); })
      }) 
    }, state.pollingDelayTime)
  },
  setPollingType({commit, state, dispatch}, {sckTpCd:sckTpCd, sckTpDsc:sckTpDsc}){
    let type = sckTpCd;
    let time = 300;

    if (type == undefined || type == null || type.trim() == ""){
      type = "SCKTP02"; //디폴트 폴링
    }
    commit('setPriceReqType', {sckTpCd:type});

    if (type == "SCKTP02"){
      time = 100;
    }else if (type == "SCKTP03"){
      time = 300;
    }

    commit('setDelayTime', {time:time});
  },
  //---폴링시세 관련---
  //---팝업시세 관련---
  setChannelData({ commit, dispatch }, {type, data}) {
    if ( type == 'OVC' ) {         
       commit('setOvcData', {ovcData:data})
    }else if (type == 'OVH'){
      commit('setOvhData', {ovhData:data})
    }else if (type == 'FC0'){
      commit('setOvcData', {ovcData:data})
    }else if (type == 'FH0'){
      commit('setOvhData', {ovhData:data})
    }else if (type == 'COC') {
      commit('setOvcData', {ovcData:data})
    }else if (type == 'COH'){
      commit('setovhData', {ovhData:data})
    }
  },
  //---팝업시세 관련---

  // getOVCReqData({commit, state}, {apiCode:apiCode, symbol:symbol}) {
  //   let apiTypeData = ['OVC'];
  //   let apiType = 'OVC';

  //   const reqData = {
  //     header: {
  //       apiCode : apiCode,
  //       token : state.sessionStorage,
  //     },
  //     body : {
  //       apiType : apiType,
  //       symbol : symbol,
  //       apiTypeData: apiTypeData,
  //       symbolData : [symbol]
  //     }
  //   }
  //   return reqData

  // },    
  // getOVHReqData({commit, state}, {apiCode:apiCode, symbol:symbol}) {
  //     let apiTypeData = ['OVH'];
  //     let apiType = 'OVH';
      
  //     const reqData = {
  //         header: {
  //         apiCode : apiCode,
  //         token : state.sessionStorage,
  //         },
  //         body : {
  //         apiType : apiType,
  //         symbol : symbol,
  //         apiTypeData: apiTypeData,
  //         symbolData : [symbol]
          
  //         }
  //     }
  //     return reqData
  // },
  //커넥션 종료
  setDisconnect({commit, state}){
    commit('setDisconnect', {})
  },
  //폴링 종료
  setClearPolling({commit, state}){
    commit("setClearPolling")
  }
}
  
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}