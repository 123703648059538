import LoadingTemplate from './template/LoadingTemplate.vue'

const LoadingModule = {
  install (Vue) {
    const LoadingConstructor = Vue.extend(LoadingTemplate)
    let loader = new LoadingConstructor({
        el: document.createElement('div'),
        isShow: false
    })

    document.body.appendChild(loader.$el)

    const loading = {
      show: function () {
        loader.isShow = true
      },
      hide: function () {
        loader.isShow = false
      }
    }

    window.$loading = loading
    Vue.loading = loading
    Vue.prototype.$loading = loading

  }
}

export default LoadingModule  