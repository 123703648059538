<template>
  <notifications group="orderFilledNotification" :duration="-3000" :width="notificationWidth" position="bottom right">
    <template slot="body" slot-scope="props">
      <div class="notification-container">
        <div class="notification-header">
          <h1>알림</h1>
          <button @click="props.close" class="close-btn">
            <img src="@/assets/icons/icon-close-modal.svg" alt="" />
          </button>
        </div>

        <div class="notification-body" v-html="props.item.text"></div>

        <div class="notification-footer">
          <button class="btn-primary blue" @click="props.close">확인</button>
        </div>
      </div>
    </template>
  </notifications>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'WhiteLabelNotification',
  components: {
  },
  data() {
    return {
      notificationWidth: '',
    };
  },

  created() {
    this.isMobile();
  },

  mounted() {},

  methods: {
    isMobile() {
      if (window.name == undefined || window.name == null || window.name == ""){
        if (window.innerWidth <= 1200) {
          return (this.notificationWidth = '100%');
        } else {
          return (this.notificationWidth = 300);
        }
      }else{
        return (this.notificationWidth = 300);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_variables.scss';

.notification-container {
  background-color: white;
  border: 1px solid #ddd;
}
.notification-header {
  /* height: 60px; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
  position: relative;
  .close-btn {
    position: absolute;
    top: 15px;
    right: 20px;
  }
  @media (max-width: $mobile) {
    padding: 5px 20px;
    h1 {
      /* font-size: 14px; */
      display: none;
    }
  }
}
.notification-body {
  padding: 0 20px;
  text-align: center;
  line-height: 28px;
  font-size: 14px;
  @media (max-width: $mobile) {
    text-align: left;
  }
}
.notification-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 15px 20px;
  @media (max-width: $mobile) {
    padding: 5px 20px;
    button {
      display: none;
    }
  }
}
</style>
