import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VModal from 'vue-js-modal'

import store from './store'
import HttpModule from './modules/http'
import HttpLodingModule from './modules/http-loading'
import AlertModule from './modules/alert'
import LoadingModule from './modules/loading'
import Notifications from 'vue-notification'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/locale/ko'
import './modules/vee-validation'
import vueCookies from "vue-cookies"
import Storage from 'vue-web-storage'

Vue.config.productionTip = false

/* vuex */
window.$store = store

/* axios */
Vue.use(HttpModule)
Vue.use(HttpLodingModule)

/* alert */
Vue.use(AlertModule)

/* loading */
Vue.use(LoadingModule)

/* vue-js-modal */
Vue.use(VModal, { dynamic: true })

/* vue-notification */
Vue.use(Notifications)

/* lodash */
Vue.use(VueLodash, { name: 'custom' , lodash: lodash })

/* vue2-datepicker */
Vue.component('DatePicker', DatePicker)

/* vue cookies */
Vue.use(vueCookies)

/* storage */
Vue.use(Storage, {
  prefix: 'stock_trader_',
  drivers: ['session','local']
});

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')

