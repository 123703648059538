import Utils from '@/modules/utils'
import Vue from 'vue'

const numberComma = function( param ) {
  return Utils.numberComma(param.value)
}

const addRow = function( rowData, addData ) {
  addData.mode = 'added'
  rowData.splice(0, 0, addData)
}

const editRow = function( event, gridApi, validation) {
  if ( event.oldValue != event.newValue ) {
    let validates = []
    let isValide = true

    if ( validation ) {
      validates = validation.split('|')
    }

    for ( let i in validates ) {
      if ( validates[i] == 'required' ) {
        if ( event.newValue == null || event.newValue.trim() == '' ) {
          Vue.notify({
            group: 'notification',
            title: '입력항목오류',
            text: '입력하신 항목은 필수 입력 항목입니다.'
          })

          event.data[event.colDef.field] = event.oldValue
          gridApi.refreshCells({rowNodes: [event.node]})

          isValide = false

          break
        }
      } else if ( validates[i] == 'integer' ) {
        if ( event.newValue != null && event.newValue.trim() != '' 
          && ( isNaN(event.newValue) || !Utils.isInteger(event.newValue) ) ) {
          Vue.notify({
            group: 'notification',
            title: '입력항목오류',
            text: '입력하신 항목은 정수만 입력하실 수 있습니다.'
          })

          event.data[event.colDef.field] = event.oldValue
          gridApi.refreshCells({rowNodes: [event.node]})

          isValide = false

          break
        }
      }
    }

    if ( isValide ) {
      if ( event.data.mode != 'added' ) {
        event.data.mode = 'edited'
      }
    }
  }

}

const delRow = function( rowData, params ) {
  rowData.splice(params.node.rowIndex, 1)
}

const getSaveData = function( gridApi ) {
  let data = {addedList: [], editedList: []}

  gridApi.forEachNode(function(rowNode) {
    if ( rowNode.data.mode == 'added' ) {
      data.addedList.push(rowNode.data)
    } else if ( ( rowNode.data.mode == 'edited' ) ) {
      data.editedList.push(rowNode.data)
    }
  })

  return data
}

export function currencyFormatter( params ) {
  return params.value.toLocaleString('en-US', { minimumFractionDigits: 0 });
}

export function volumnFormatter( params ) {
  return params.value.toLocaleString('en-US', { minimumFractionDigits: 0 });
}

export const buySellType = {
  blue: (params) => params.value === '매도',
  red: (params) => params.value === '매수',
};

export const cellClassRulesMinus = {
	red: params => params.value > 0,
	blue: params => params.value < 0,
}

export const cellClassRulesExePrc = {
	red: params => params.data.ydiffSign == "2",
	blue: params => params.data.ydiffSign == "5",
}

export default { 
  numberComma,  
  addRow,
  editRow,
  delRow,
  getSaveData
}
