const state = {
  symbolList: [],
  curSymbol: {},
  secMtList: [],
  coinSymbolList: [],
}

const getters = {
  getSymbolList (state) {
    return state.symbolList
  },
  getCurSymbol (state) {
    return state.curSymbol
  },
  getCoinSymbolList (state) {
    return state.coinSymbolList
  },
}

const mutations = {
  setSymbolList (state, { symbolList }) {
    if (state.secMtList != undefined && state.secMtList.length > 0){
      for (let symbol of symbolList){
        for (const secMt of state.secMtList){
          if (symbol.name == secMt.symCd){
            symbol.secMt = secMt.secYy + '.' + secMt.secMt
          }
        }
      }
    }

    state.symbolList = symbolList
  },
  setCoinSymbolList (state, { symbolList }) {
    state.coinSymbolList = symbolList
  },
  setCurSymbol (state, { curSymbol }) {
    state.curSymbol = curSymbol
  },
  setSecMtList (state, { secMtList }) {
    if (state.symbolList != undefined && state.symbolList.length > 0){
      for (let symbol of state.symbolList){
        for (const secMt of secMtList){
          if (symbol.name == secMt.symCd){
            symbol.secMt = secMt.secYy + '.' + secMt.secMt
          }
        }
      }
    }

    state.secMtList = secMtList
  },
}

const actions = {
  async load ({ commit, dispatch }) {
    return await new Promise((resolve, reject) => {
      window.$http
        .get('chart/datafeed/symbols')
        .then(response => {
          //console.log(response.data)
          if (response.data) {
            commit('setSymbolList', {
              symbolList: response.data
            })

            dispatch('setCurSymbol', response.data[0])
          }
          resolve()
        })
        .catch(e => reject(e))
    })    
  },
  async coinLoad ({ commit, dispatch }) {
    return await new Promise((resolve, reject) => {
      window.$http
        .get('chart/datafeed/coinsymbols')
        .then(response => {
          //console.log('coinSymbols', response.data)
          if (response.data) {
            commit('setCoinSymbolList', {
              symbolList: response.data
            })

            //dispatch('setCurSymbol', response.data[0])
          }
          resolve()
        })
        .catch(e => reject(e))
    })    
  },
  setCurSymbol({ commit, state }, symbolName) {
    // 초기화
    commit('setCurSymbol', {curSymbol: {}})

    if ( state.symbolList != null && state.symbolList.length > 0 ) {
      const findSymbol = state.symbolList.find(symbol =>  symbol.name === symbolName)
      if(findSymbol){
        commit('setCurSymbol', {curMenu: findSymbol})
      }
    }   
  },
  async getSecMt ({ commit, dispatch }) {
    
      //await window.$http.get('apisise/getLastPrice', { params: { symCd: newSymbol.name} } )
      await window.$http
        .get('api/symbol/getSecMt')
        .then(response => {
          //console.log(response.data)
          if (response.data) {
            commit('setSecMtList', {
              secMtList: response.data
            })
          }
        })

  },
}    

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}