import Utils from '@/modules/utils';

const state = {
  //channelToMsg: new Map(),
  socketMsg : null,
  isConnectMsg: false,
  sessionStorage: null,
  msgInterval: null,
  connectMsgTimeout: null,
  resultMsg: null,
  intervalCnt: 0,
}
const MAX_INTERVAL = 10;

const getters = {
  //message 접속
  getIsConnectMsg(state){
    return state.isConnectMsg;
  },
  //MESSAGE 리턴 조회
  getMessage(state){
    const resultMsg = Object.assign({}, state.resultMsg)
    return resultMsg;
  },
}

const mutations = {
  setSessionStorage (state, {sessionStorage}){
    state.sessionStorage = sessionStorage;
  },
  setConectMsg (state, {socketMsg}){
    state.socketMsg = socketMsg;
    state.intervalCnt++;
  },
  setIsConnectMsg(state, {isConnect}){
    state.isConnectMsg= isConnect;
  },
  sendMsgMessage(state, {apiCode, body}){      
    if (!state.isConnectMsg ) {
      console.log("Msg Connect Fail.");
      return;
    }

    const reqData = {
      header: {
        apiCode : apiCode,
        token : state.sessionStorage,
      },
      body : body
    } 

    try{
      state.socketMsg.send(JSON.stringify(reqData));
    }catch(e){
      // console.log("e >>> ", e, body)
    }
  },
  // setChannelToMsg(state,  {subscribeItem}){
  //   state.channelToMsg.set(subscribeItem.subscriptionUID, subscribeItem);
  // },
  // delChannelToMsg(state,  {subscriptionUID}){
  //   state.channelToMsg.delete(subscriptionUID);
  // },
  sendMsgHeartBeat(state) {
    const reqData = {
      header: {
      apiCode : 'MSG0000',
      token : state.sessionStorage
      },
      body : {
      }
    }
    if (state.socketMsg.readyState == 1){
      state.socketMsg.send(JSON.stringify(reqData));
      state.intervalCnt = 0;
    }
  },
  setDisconnect(state){
    if (state.isConnectMsg){
      state.socketMsg.onclose = function () {};
      state.socketMsg.close();
      state.isConnectMsg = false;
    }
    clearInterval(state.msgInterval)
  },
  setMsg(state,  {apiCode, body, notify}){
    let resultMsg = {apiCode:apiCode, body:body, notify:notify};
    state.resultMsg = resultMsg;
  },
}

const actions = {
  //소켓 커넥션 생성
  setConectMsg({commit, dispatch, state}, sessionStorage){
    commit('setSessionStorage', {sessionStorage:sessionStorage.sessionStorage})
    const websocketPath = window.$store.VUE_APP_MESSAGE_SOCKET_BASE_URL

    if(state.socketMsg && state.socketMsg.readyState == WebSocket.OPEN){       
        state.socketMsg.close()
    }
    commit('setConectMsg', {socketMsg: new WebSocket(websocketPath)})
    state.socketMsg.onopen = () => {
      commit('setIsConnectMsg', {isConnect:true});

      clearInterval(state.msgInterval);
            
      if (state.socketMsg.readyState != 1){
        console.log("state.socketMsg.readyState ", state.socketMsg.readyState)
        return;
      }

      let subscriptionUID = sessionStorage.subscriptionUID;

      if ( subscriptionUID == null ) {
        subscriptionUID = Utils.getUuidv4();
        sessionStorage.subscriptionUID = subscriptionUID;
      }
  
      // 소켓 구독
      const subscribeItem = {
        subscriptionUID: subscriptionUID,
        data: new Array()
      }
  
      //commit('setChannelToMsg', {subscribeItem:subscribeItem})
      commit('sendMsgMessage', {apiCode:'MSG9999'})

      state.msgInterval = setInterval(() => {
          commit('sendMsgHeartBeat', {});
      }, 50000)
    }

    state.socketMsg.onmessage = (e) => {
      const returnData = JSON.parse(e.data);
      dispatch('completeMsg', {apiCode:returnData.header.apiCode, body:returnData.body});
    }

    state.socketMsg.onerror = (e) => {
      commit('setIsConnectMsg', {isConnect:false});
      console.error("[Error] socket Error", e);
    }

    state.socketMsg.onclose = (e) => {
      commit('setIsConnectMsg', {isConnect:false});
      console.error("[OnClose] socket close", e.code, e.wasClean);
      

      if (e.wasClean) {
        console.log('[MSG] WS Closed', state.intervalCnt)
        //gateway 서버가 내려갈 경우 close되고 끝나서 별도 커넥션을 다시 시도
        clearInterval(state.msgInterval);
        state.connectMsgTimeout = setTimeout(() => {
          dispatch('setConectMsg', {sessionStorage:state.sessionStorage});
        }, 5000);
      } else {
        console.log('[MSG] WS Connecting...', state.intervalCnt)
        clearInterval(state.msgInterval);
        dispatch('setConectMsg', {sessionStorage:state.sessionStorage});
      }

      // 재시도 MAX_INTERVAL 이상이면 리로드
      if (Number(state.intervalCnt) > MAX_INTERVAL){
        window.location.reload();
      }
      

      // if (e.code === NORMAL_CLOSURE) {
      //   console.log('[Chat] WS Closed')
      //   return
      // }
    
      // if (e.code === ABNORMAL_CLOSURE) {
      //   console.log('[Chat] WS Connecting...')
      //   // 다시 연결하는 로직을 작성하자
      //   commit("setDisconnect");
      //   //dispatch('setConectMsg', {sessionStorage:state.sessionStorage})
        
      //   clearTimeout(state.connectMsgTimeout);
      //   state.connectMsgTimeout = setTimeout(() => {
      //     console.log("connect>>>> ?")
      //     dispatch('setConectMsg', {sessionStorage:state.sessionStorage})
      //   }, 1000);
      // }
    }
  },
  // setUnSubscribeOnMsg({commit, state}){
  //     // 소켓 구독 종료
  //   const subscriptionUID = sessionStorage.getItem('subscriptionUID');
  //   const data = state.channelToMsg.get(subscriptionUID);
  //   if(data != null){
  //     if(state.isConnectMsg){
  //       commit('sendMsgMessage', {apiCode:'MSG9998'});
  //     }
  //     commit('delChannelToMsg', {subscriptionUID:subscriptionUID});
  //   }
  // },
  sendMsgMessage({commit, state}, {apiCode, body}){
    commit('sendMsgMessage', {apiCode:apiCode, body:body});
  },
  completeMsg({commit, state}, {apiCode, body}){

    commit('setMsg', {apiCode:apiCode, body:body, notify:null})
  },
  //커넥션 종료
  setDisconnect({commit, state}){
    commit('sendMsgMessage', {apiCode:'MSG9998'}) //구독해지
    commit("setDisconnect")
  }
}
  
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}