<template>
  <div>
    <modal name="modalBasic" classes="main-modal" :adaptive="true" :width="width" :height="height"
    @before-open="beforeOpen"
    @opened="opened">
      <div class="modal-header">
        <h2 class="modal-header-title">공지사항</h2>
        <button @click="closeModal()"><img src="@/assets/icons/icon-close-modal.svg" alt="" /></button>
      </div>

      <div class="modal-body">
        <div class="logout-container">
          <div class="text-container">
            <h1>{{ post.postSj }}</h1>
						<p v-html="post.cont"></p>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <button class="btn-primary blue" @click="closeModal()">확인</button>
      </div>
    </modal>
  </div>
</template>

<script>
export default {
  name: 'WhiteLabelModaBasic',

  data() {
    return {
      brdCd : 'NOTICE',
      postNo : 0,
      post: {},
    }
  },

  created() {
    if (window.innerWidth > 1200) {
      this.width = 1000;
      this.height = 600;
    } else {
      this.width = '100%';
      this.height = '100%';
    }
  },

  mounted() {

  },
  methods: {
    closeModal() {
      this.$modal.hide('modalBasic');
    },
    opened(){
      //this.select(this.postNo);
    },
    beforeOpen(e){
      console.log("e.params >>> ", e)
      this.post = {};
      //this.postNo    = e.params.post.postNo;
      this.post = e.params.post;
    },
    async select(postNo) {
      // 조회 조건
      const params = {
        brdCd : this.brdCd,
        postNo : postNo
      };

      const response = await this.$lhttp.get('/api/board/post', { params: params });

      if ( response && response.data ) {
        this.post = response.data;
      }
    },
  },
};
</script>
