<template>
  <div class="temp">
    <div class="desktop-main" :class="selectedTheme">
      <main>
        <div class="left" v-if="getLoginUserInfo != undefined && getLoginUserInfo.authId != undefined">
          <button class="panel-btn" @click="resizeShowPanel(true)" v-if="!showPanel">
            확장
            <img src="@/assets/icons/chevron-left.svg" alt="" />
          </button>
          <div class="upper">
            <div class="instrument-row">
              <button class="btn-line" value="korea" :class="{ active: showInstruments === 'korea' }" @click="checkInstruments">국내선물</button>
              <button class="btn-line" value="global" :class="{ active: showInstruments === 'global' }" @click="checkInstruments">해외선물</button>
              <input type="text" v-model="symbol.name" class="symbol" readonly />
              <input type="text" :value="(symbol.description != undefined ? symbol.description:'') + (symbol.secMt != undefined ? ' '+symbol.secMt:'')" class="symbol-name" readonly />
              <button class="btn-primary blue" @click="resizeOrder"><span v-if="!orderResize">주문창확장</span><span v-if="orderResize">주문창축소</span></button>
            </div>
            <div class="white-label-table-container">
              <table class="white-label-table">
                <tbody>
                  <tr>
                    <th>평가손익</th>
                    <td><span :class="{ 'color-red' : dailyPlMn.totalValPl > 0, 'color-blue' : dailyPlMn.totalValPl < 0 }">{{ thousand(dailyPlMn.totalValPl) }}</span></td>
                    <th>실현손익</th>
                    <td><span :class="{ 'color-red' : dailyPlMn.totNPlMn > 0, 'color-blue' : dailyPlMn.totNPlMn < 0 }">{{ thousand(dailyPlMn.totNPlMn) }}</span></td>
                    <th>평가담보</th>
                    <td><span :class="{ 'color-red' : dailyPlMn.balMn > 0, 'color-blue' : dailyPlMn.balMn < 0 }">{{ thousand(dailyPlMn.balMn + dailyPlMn.totalValPl) }}</span></td>
                    <th>로스컷</th>
                    <td>{{ thousand(dailyPlMn.totalLossCut) }}</td>
                  </tr>
                  <tr>
                    <th>잔고</th>
                    <td>{{ thousand(symbolPlMn.balQty) }}</td>
                    <th>평균단가</th>
                    <td>{{ thousand(symbolPlMn.execPrc) }}</td>
                    <th>종목평가</th>
                    <td><span :class="{ 'color-red' : symbolPlMn.valPl > 0, 'color-blue' : symbolPlMn.valPl < 0 }">{{ thousand(symbolPlMn.valPl) }}</span></td>
                    <th>종목실현</th>
                    <td><span :class="{ 'color-red' : symbolPlMn.plMn > 0, 'color-blue' : symbolPlMn.plMn < 0 }">{{ thousand(symbolPlMn.plMn) }}</span></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="stop-profit-row">
              <div class="white-label-table-container">
                <table class="white-label-table">
                  <tbody>
                    <tr>
                      <th><span class="color-blue">매도</span></th>
                      <td><span class="color-blue">{{ thousand(sellAbleQty) }}</span></td>
                      <th><span class="color-red">매수</span></th>
                      <td><span class="color-red">{{ thousand(buyAbleQty) }}</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="inner">
                <div class="checkbox-default">
                  <input type="checkbox" id="stopProfit" name="stopProfit" v-bind:checked="slStockVal2 == true" @click="sellSlStock" />
                  <label for="stopProfit">손절</label>
                </div>
                <CounterComp :disabled="slStockVal2" :maxCnt="200" :maxLength=3 @childEvent="setSellSlTick" :value="sellSlTick" />
              </div>
              <div class="inner">
                <div class="checkbox-default">
                  <input type="checkbox" id="takeProfit" name="takeProfit" v-bind:checked="slStockVal1 == true" @click="buySlStock" />
                  <label for="takeProfit">익절</label>
                </div>
                <CounterComp :disabled="slStockVal1" :maxCnt="200" :maxLength=3 @childEvent="setBuySlTick" :value="buySlTick" />
              </div>
              <div class="inner-leverage" v-if="getLoginUserInfo.lvTpCd != undefined && getLoginUserInfo.lvTpCd == 'Y'">
                <div class="white-label-table-leverage">
                  <table class="white-label-table">
                    <tbody>
                      <tr>
                        <th>레버리지</th>
                        <td>
                          <!-- <CodeDropDown width="63" cdHeight="26" grpCd="LVTP" v-model="leverage" height="200" popup @input="selectLeverage" /> -->
                          <BaseDropdown id="leverageCd" cdHeight="26" :options="leverageList" v-model="leverage" @leverageCd="selectLeverage" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="dom-top">
              <button class="btn-line blue" @click="sellMarket">시장가 매도</button>
              <div class="inner">
                <button class="btn-line" @click="clearOrderData('all')">전종목 청산</button>
                <button class="btn-line" @click="clearOrderData('symbol')">현종목 청산</button>
              </div>
              <button class="btn-line red" @click="buyMarket">시장가 매수</button>
            </div>
          </div>

          <div class="dom-container" v-if="orderType === 'click'">
            <DomGridClick
              :account="account"
              :selectOrderData="selectOrderData"
              :ordClick="ordClick"
              :avgPrice="avgPrice"
              :rStock="rStock"
              :slStockVal1="slStockVal1"
              :slStockVal2="slStockVal2"
              :buySlTick="buySlTick"
              :sellSlTick="sellSlTick"
              :ovcDataList="ovcDataList"
            />
          </div>
          <div class="lower">
            <div class="ag-container">
              <AgPositionTable
              :account="account"
              @updateTotalValPl="updateTotalValPl"
              @updateSubscribeOnPrice="updateRtPosSymbol"
              />
            </div>
          </div>
        </div>

        <div class="right" v-if="showPanel && getLoginUserInfo != undefined && getLoginUserInfo.authId != undefined">
          <button class="panel-btn" @click="resizeShowPanel(false)">
            <img src="@/assets/icons/chevron-right.svg" alt="" />
            축소
          </button>
          <div class="order-row">
            <div class="label">주문수량</div>
            <div class="inner">
              <CounterComp :maxCnt="200" :maxLength=3 @childEvent="setClickOrd" v-bind:value="ordClick" />
              <button class="btn-line" @click="setOrdClick(clickSetList[0])">{{ clickSetList[0] }}</button>
              <button class="btn-line" @click="setOrdClick(clickSetList[1])">{{ clickSetList[1] }}</button>
              <button class="btn-line" @click="setOrdClick(clickSetList[2])">{{ clickSetList[2] }}</button>
              <button class="btn-line" @click="setOrdClick(clickSetList[3])">{{ clickSetList[3] }}</button>
              <button class="btn-line" @click="setOrdClick(clickSetList[4])">{{ clickSetList[4] }}</button>
            </div>
          </div>
          <div class="white-label-table-container right-panel">
            <table class="white-label-table">
              <tbody>
                <tr>
                  <th>현재가</th>
                  <td><span :class="{ 'color-red' : nowPrice.ydiffSign != undefined && nowPrice.ydiffSign == '2' , 'color-blue' : nowPrice.ydiffSign != undefined && nowPrice.ydiffSign == '5' }">{{ nowPrice.curPr }}</span></td>
                  <th>대비</th>
                  <td><span :class="{ 'color-red' : nowPrice.ydiffSign != undefined && nowPrice.ydiffSign == '2' , 'color-blue' : nowPrice.ydiffSign != undefined && nowPrice.ydiffSign == '5' }">{{ nowPrice.ydiffPr }}</span></td>
                </tr>
                <tr>
                  <th>시가</th>
                  <td>{{ nowPrice.open }}</td>
                  <th>%</th>
                  <td><span :class="{ 'color-red' : nowPrice.ydiffSign != undefined && nowPrice.ydiffSign == '2' , 'color-blue' : nowPrice.ydiffSign != undefined && nowPrice.ydiffSign == '5' }">{{ nowPrice.chGrate }}</span></td>
                </tr>
                <tr>
                  <th>고가</th>
                  <td>{{ nowPrice.high }}</td>
                  <th>환율</th>
                  <td>{{ symbol.symbolno == 2 ? thousand(dailyPlMn.hkdExR):thousand(dailyPlMn.usdExR)  }}</td>
                </tr>
                <tr>
                  <th>저가</th>
                  <td>{{ nowPrice.low }}</td>
                  <th>틱가치</th>
                  <td>{{ thousand(symbolTikPrice) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="order-standard-container">
            <div class="order-standard short">
              <div class="row">
                <h3 class="label">종목명</h3>
                <h3 class="value">{{ symbol.description }}</h3>
              </div>
              <div class="row">
                <h3 class="label">유형</h3>
                <div class="order-type">
                  <div class="radio-default">
                    <input type="radio" id="market" name="orderType" value="1" v-model="ordStdType" />
                    <label for="market">시장가</label>
                  </div>
                  <div class="radio-default">
                    <input type="radio" id="limit" name="orderType" value="2" v-model="ordStdType" />
                    <label for="limit">지정가</label>
                  </div>
                  <div class="radio-default">
                    <input type="radio" id="mit" name="orderType" value="3" v-model="ordStdType" />
                    <label for="mit">MIT</label>
                  </div>
                </div>
              </div>

              <div class="row">
                <h3 class="label">수량</h3>
                <CounterComp full :maxCnt="200" :maxLength=3 @childEvent="setStdOrdQty" :value="ordStdQty" />
              </div>

              <div class="row">
                <h3 class="label">가격</h3>
                <OrderInput modal :symbol="symbol" :nowPrice="Number(nowPrice.curPr)" :value="Number(ordStdPrc)" @updateOrdPrc="setOrdStdPrc" />
              </div>
              <div class="row end">
                <button class="btn-line" @click="stdReset()">초기화</button>
                <button class="btn-line blue" @click="stdSellBuy('sell')">매도</button>
                <button class="btn-line red" @click="stdSellBuy('buy')">매수</button>
              </div>
            </div>
          </div>

          <div class="instruments-table-container" v-if="showInstruments === 'korea'">
            <div class="ag-container">
              <!-- <AgOptionsTable/> -->
              <AgFuturesTable/>
            </div>
          </div>
          <div class="instruments-table-container" v-if="showInstruments === 'global'">
            <div class="ag-container">
              <AgFuturesTable/>
            </div>
          </div>
          <div class="filled-table-container">
            <div class="ag-container">
              <AgFilledTable></AgFilledTable>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import CounterComp from '@/components/CounterComp.vue';
import OrderInput from '@/components/OrderInput.vue';
import DomGridClick from '@/components/DomGridClick.vue';

import AgFilledTable from '@/components/agTables/AgFilledTable.vue';
import AgPositionTable from '@/components/agTables/AgPositionTable.vue';
import AgFuturesTable from '@/components/agTables/AgFuturesTable.vue';
import AgOptionsTable from '@/components/agTables/AgOptionsTable.vue';
import EventBus from '@/modules/event-bus';
import { stringCount } from '@/modules/helper';
import Utils from '@/modules/utils';
import Const from '@/const';
import CodeDropDown from '@/components/CodeDropDown.vue'
import BaseDropdown from '@/components/BaseDropdown.vue'

export default {
  name: 'WhiteLabelDesktopPage',
  components: {
    CounterComp,
    AgFilledTable,
    AgPositionTable,
    AgFuturesTable,
    AgOptionsTable,
    DomGridClick,
    OrderInput,
    CodeDropDown,
    BaseDropdown,
  },
  
  computed: {
    // 로그인 여부
    isLogin () {
      return opener.$store.getters['Login/isLogin'];
    },
    //소켓시세
    changeOvcData: function(){
      let ovcData = window.$store.getters['SocketPrice/getOvcData'];
      return ovcData;
    },
    //폴링시세
    changeOvcDataList: function(){
      let ovcData = window.$store.getters['SocketPrice/getOvcDataList'];
      return ovcData;
    },
    changeFvSymbol: function(){
      let custom = window.$store.getters['Layout/getFavoriteGridCustom'];
      if (custom == undefined){
        return null;
      }
      return {socketSymbol: custom.socketSymbol, socketSymbolCnt: this.socketSymbolCnt+1};
    },
    getSelectSymbol: function(){
      return window.$store.getters['SocketPrice/getSelectSymbol'];
    },
    tikDotSz(){
      return stringCount(this.symbol.pricescale);
    },
    resultOms: function(){
      return window.$store.getters['SocketOms/getCompleteOrder'];
    },
    getAbleQty: function(){
      return window.$store.getters['SocketOms/getAbleQty'];
    },
    resultMsg: function(){
      return window.$store.getters['SocketMsg/getMessage'];
    },
    getDailyPlMn: function(){
      return window.$store.getters['SocketOms/getDailyPlMn'];
    },
    getPosOrdStList: function(){
      return window.$store.getters['SocketOms/getPosOrdStList'];
    },
    getLoginUserInfo: function(){
      let loginInfo = window.$store.getters['Login/getLoginUserInfo'];
      return loginInfo;
    },
  },
  watch: {
    //소켓시세
    changeOvcData(newPrice){
      if (this.symbol && newPrice.symbol == this.symbol.name) {
        this.nowPrice = newPrice;
        this.setSymbolPrice();

        //일반주문 가격 시세 첫번째 값으로 저장
        if (this.ordStdPrc == 0){
          this.ordStdPrc = Number(newPrice.curPr).toFixed(this.tikDotSz);
        }
      }
    },
    //폴링시세
    changeOvcDataList(newPrice){
      if (newPrice != null && newPrice.length > 0) {
        newPrice.forEach(data=>{
          if (this.symbol && data.symbol == this.symbol.name){
            //
          }
        })
      }
    },
    changeFvSymbol(val){
      this.updateSubscribeOnPrice()
    },
    getSelectSymbol(newVal){
      if (newVal == undefined) return;
      
      this.rStock = Object.assign({}, null);

      //종목이 변경될경우 소켓데이터 연결 갱신
      this.symbol = Object.assign({}, newVal);

      this.nowPrice = {curPr:0, ydiffPr:0, open:0, chGrate:0, high:0, low:0, ydiffSign:0};

      this.tikSize = newVal.minmov / newVal.pricescale;

      this.ordStdPrc = 0; //일반주문 가격

      this.setStockInfo(newVal, null);

      //this.symbolDropdown(newVal);     

      this.selectOrder(this.symbol.name);

      this.updateSubscribeOnPrice();
      
      //accId가 있으면
      if (this.account.accId && this.account.accId > 0){
        this.selectUsrData();
      }else{
        //accId가 로딩중이면 기다렸다 실행
        window.$store.watch(() => this.account.accId, isAccount => {
          if (isAccount) {
            this.selectUsrData();
          }
        })
      }

      //국내선물일때 해외선물 종목 선택시 해외선물 표시로 변경
      if (this.showInstruments === 'korea' && newVal.symbolno > 0 && newVal.symbolno < 10 ){
        this.showInstruments = 'global';
      }
    },
    resultOms(res){
      if (res.result == "S"){
        this.completeOrder(res);
      }else{
        //this.procFailMessage(res);
        //실패시 리스트목록 주문 목록 재조회
        if (res.body.code != "OMSFL007" && res.body.code != "OMSFL011" && res.body.code != "OMSFL002" && res.body.code != "OMSFL012"
          && res.body.code != "OMSFL014" && res.body.code != "OMSFL016" && res.body.code != "OMSFL017"){
          this.selectOrder(this.symbol.name);
        }
      }
    },
    getAbleQty:{
      handler(res){
        this.sellAbleQty = res.ordAbleSellQty;
        this.buyAbleQty = res.ordAbleBuyQty;
      },
      deep:true
    },
    resultMsg:{
      handler(res){
        if (res.apiCode == Const.MSG_API_TYPE.BANK){
          //유저 데이터 재조회
          opener.$store.dispatch('SocketOms/selectUsrData', {accId:this.account.accId, symCd:this.symbol.name, symNo:this.symbol.symbolno, lv:this.leverage});
        } else if ( res.apiCode == Const.MSG_API_TYPE.LOGOUT ) {

          opener.$store.dispatch('Login/logout');

          this.$alert.alert('다른 브라우저에서 로그인하여 <span style="color:red;">로그아웃</span> 되었습니다.<br/><br/>로그인을 다시 시도하시거나 사용을 종료 해 주십시오.', '안내')
          .then(() => {
            this.$router.push('/login');
          });

          return;
        }

        // 알림 재조회
        //EventBus.$emit('Alarm:event:refresh', {});

        //공지 팝업 호출
        if (res.body.popupFlg != undefined && res.body.popupFlg == "Y"){
          this.$modal.show("modalBasic", res.body);
        }else{
          this.$notify({
            group : 'notification',
            text : '<span style="color: var(--main-red)">알림</span> '+ res.body.msg
          });
        }

      },
      deep:true
    },
    getDailyPlMn:{
      handler(res){
        this.dailyPlMn.fxPlMn = res.fxPlMn; // 해외손익금액
        this.dailyPlMn.fxCms = res.fxCms; // 해외수수료
        this.dailyPlMn.nFxPlMn = res.nFxPlMn; // 해외순손익
        this.dailyPlMn.futPlMn = res.futPlMn; // 국내손익금액
        this.dailyPlMn.futCms = res.futCms; // 국내수수료
        this.dailyPlMn.nFutPlMn = res.nFutPlMn; // 국내순손익
        this.dailyPlMn.totPlMn = res.totPlMn; // 총손익금액
        this.dailyPlMn.totNPlMn = res.totNPlMn; // 총순손익
        this.dailyPlMn.totCms = res.totCms; // 총수수료
        this.dailyPlMn.niPlMn = res.niPlMn;
        this.dailyPlMn.balMn = res.balMn;
        this.dailyPlMn.hkdExR = res.hkdExR;
        this.dailyPlMn.usdExR = res.usdExR;
        this.dailyPlMn.usdExHkd = res.usdExHkd;
      },        
      deep:true
    },
    getPosOrdStList(newList){
      if (newList.length > 0){
        let balQty = 0;
        let execPrc = 0;
        let ordSdCd = "0";
        let plMn = 0;
        let valPl = 0;
        let plData = {};
        newList.forEach(data=>{
          if (data.ordType == "POS" && data.symCd == this.symbol.name){
            balQty = data.balQty;
            ordSdCd = data.ordSdCd;
            if (balQty > 0) execPrc = data.execPrc;
            plMn = data.plMn;
            valPl = data.valPl;
            plData = data;
          }
        })

        this.symbolPlMn.balQty = balQty;
        this.symbolPlMn.execPrc = execPrc;
        this.symbolPlMn.plMn = plMn;
        this.symbolPlMn.data = plData;

        this.avgPrice = Object.assign({}, {avgPrice:execPrc, ordSdCd:ordSdCd, balQty:balQty})
        

        this.setSymbolPrice();
        
        //symbolPlMn:{balQty:0, execPrc:0, fxPlMn:0, valPl:0},
      }
    },
    getLoginUserInfo:{
      handler(userInfo){
        this.loginUserInfo = userInfo;
      }
    },
  },
  data() {
    return {
      ovcMap: new Map(),
      ovcDataList: null,
      // selectedTable: 'held',
      showPanel: true,
      orderResize: false,
      showInstruments: 'global',
      setIntervalId: null,
      selectedTheme: 'black',
      symbol:{name:null, base_name:null},
      oldSymbol:null,
      symbolList:[],
      accId: null,
      account: {
        accNm: '',
        accPass: '',
        accCnt: 0,
        accId: 0,
      },
      coinAccount: {
        accNm: '',
        accPass: '',
        accCnt: 0,
        accId: 0,
      },
      accountList:[],
      chgSymbol: null,
      priceReqType: "SCKTP02", //시세 받는방식 socket(SCKTP01), polling(SCKTP02/SCKTP03)
      symbolCdList:[],
      rtPosSymbolList:[],
      sellAbleQty:0,
      buyAbleQty:0,
      orderSetting: null,
      btnSellMarketFlag : true,
      btnBuyMarketFlag : true,
      btnCancelSellStAllFlag : true,
      btnCancelBuyStAllFlag : true,
      btnCancelSellAllFlag : true,
      btnCancelBuyAllFlag : true,
      btnCancelAllFlag : true,
      sellSlTick:1,
      buySlTick:1,
      clickSetList: ['1','2','3','4','5'],
      ordClick: 1,
      slStockVal1:false,
      slStockVal2:false,
      leverage:1,
      leverageList: [{cd:'1', name:'1:1'}],
      selectOrderData:null,
      nowPrice:{curPr:0, ydiffPr:0, open:0, chGrate:0, high:0, low:0, ydiffSign:0},
      dailyPlMn:{fxPlMn:"0", fxCms:"0", nFxPlMn:"0", futPlMn:"0", futCms:"0", nFutPlMn:"0", totPlMn:"0", totNPlMn:"0", totCms:"0", niPlMn:"0", balMn:"0", hkdExR:"0", usdExR:"0", usdExHkd:"0", totalValPl:"0", totalLossCut:"0", data:null},
      symbolPlMn:{balQty:"0", execPrc:"0", plMn:"0", valPl:"0", data:null},
      totalValPl: null,
      orderType: 'click',
      tikSize: 0,
      avgPrice:null,      
      popupId:null,
      width: 757,
      height: 950,
      currencyInfo:{currency:"WON"},
      ordStdType:"1",
      ordStdQty:1,
      ordStdPrc:0,
      btnStdFlag:true,
      layout : {},
      noticePop: null,
      rStock: null,
      symbolTikPrice:0,
      windowPollingChk: null,
      windowSize: {width:1150, height:900},
      winWidth: 1150,
    }
  },
  filters: {},
  created(){
    if (!window.opener){
      return;
    }
    
    // this.windowSize.width = window.innerWidth;
    // this.windowSize.height = window.innerHeight;

    //this.window.resizeTo(1150, 900);
    //console.log("this.windowSize.width", this.windowSize.width, this.windowSize.height)
 

    this.selectSymbolList();
    this.loadCustom();
    this.selectAccountList();

    EventBus.$on('Layout:event:loadLayout', (layout) => {
      this.setLayout(layout)
    })

    //팝업 선택 종목 초기화
    if (window.name == "orderPop1"){
      this.$sessionStorage.remove('popup1SelSymbol');
    }else if (window.name == "orderPop2"){
      this.$sessionStorage.remove('popup2SelSymbol');     
    }

    //포지션, DailyPlMn 정보
    this.selectInitData();
  },
  mounted() {

    //무보창 폴링 체크
    this.checkOpenerWindow();
    window.addEventListener('beforeunload', this.handleBeforeUnload);

    // 폴링 시세 채널 생성
    const dataChannel = new BroadcastChannel('dataChannel');

    //시세 수신
    dataChannel.onmessage = (event) => {
      const data = event.data.data;
      const type = event.data.type;

      if (type == "S_OVC"){ //소켓시세
        window.$store.dispatch('SocketPrice/setChannelData', {type:'OVC', data:data});
      }else if (type == "S_OVH"){ //폴링시세
        window.$store.dispatch('SocketPrice/setChannelData', {type:'OVH', data:data});
      }else if (type == "orderSetting"){ //환경설정 재조회
        EventBus.$emit('Layout:event:loadLayout', this.layout);
      }else if (type == "OMS"){ //oms result
        window.$store.dispatch('SocketOms/setChannelData', {type:type, data:data});
      }else if (type == "POS_ORD"){ //잔고 포지션
        window.$store.dispatch('SocketOms/setChannelData', {type:type, data:data});
      }else if (type == "DAILY"){
        window.$store.dispatch('SocketOms/setChannelData', {type:type, data:data});
      }else if (type == "CLOSE"){
        window.close();
      }else if (type == "selectLeverageList"){ //레버리지 목록 갱신
        this.selectLeverageList();
      }else if (type == "resizeTo"){ //팝업창 리사이즈 실패
        if (data.popupId == window.name){
          this.$alert.alert('팝업주문창을 찾을 수 없습니다. 팝업창을 닫고 다시 시도 해주세요.', '안내');
        }
      }
    };

    window.onresize = (_=>{
      setTimeout(() => { //2초 후 사이즈 조정하도록 수정
        this.popupResize();
      }, 1000)
    });

    window.addEventListener('resize', function(){

      // setTimeout(() => { //2초 후 사이즈 조정하도록 수정
      //   console.log("사이즈 ", this.getWinsize())
      //   this.window.resizeTo(1150, 900);
      //   //this.window.location.reload();
      // }, 2000)
    });  
  },

  methods: {
    getWinsize(){
      return this.windowSize;
    },
    resizeOrder(){ //팝업창 높이 확장/축소
      this.orderResize = !this.orderResize;
      this.popupResize();
    },
    resizeShowPanel(flag){ //축소/확장 버튼
      this.showPanel = flag;
      if (flag == true){
        window.resizeBy(+450, 0);
        this.sendOmsLogMessage("팝업("+window.name + ") 확장 클릭");
      }else{
        window.resizeBy(-450, 0);
        this.sendOmsLogMessage("팝업("+window.name + ") 축소 클릭");
      }
    },
    popupResize(){
      let width = this.windowSize.width;
        let height = this.windowSize.height;

        if (!this.showPanel){
          width += -450;
        }

        if (this.orderResize){
          //height += 
          height = (window.screen.height)
        }

        //console.log("resize? ", window.innerWidth, this.windowSize.width, window.innerHeight, this.windowSize.height)
        
        if (window.innerWidth != this.windowSize.width || window.innerHeight != this.windowSize.height){
          //window.resizeTo(width, height);
          //this.window.location.reload();
          //console.log("resize? ", width, height, window.innerWidth, window.innerHeight)

          window.opener.postMessage({ type: 'resizeTo', popupId:window.name, width:width, height:height});
        
        }
    },
    checkOpenerWindow(){ //부모창 체크 폴링
      this.windowPollingChk = setInterval(() => {
        try{
          if (!window.opener){
            window.close();
          }else if (window.opener.location.origin != window.location.origin){ //호출 url다를경우
            window.close();
          }else if (window.opener.location.origin == window.location.origin && window.opener.location.href != window.opener.location.origin+"/desktop"){ //호출 url다를경우
            window.close();
          }
        }catch(e){
          //에러가 발생하면 종료
          window.close();
        }

      }, 1000)
    },
    //창이 닫힐때 호출
    handleBeforeUnload(event) {
      window.opener.postMessage({ type: 'closePopup', popupId:window.name }, window.location.origin);
    },
    calledFromOpener(){
      alert("test")
    },
    async loginChk() {
      //await window.$http.post('/auth/login/refresh', {params : {}});
      const response = await window.$http.post('/auth/login/checkLogin', {params : {}});
      if (response.data.loginUserInfo == null){ // 로그인 정보가 없으면
        await window.$http.post('/auth/login/refresh', {params : {}});
      }
    },
    thousand(value, digits) {
      if (value == undefined){
        return 0;
      }else{
        return value.toLocaleString('en-US', { minimumFractionDigits: digits });
      }
    },
    checkInstruments(e) {
      if(e.target.value === this.showInstruments) {
        //this.showInstruments = '';
      } else {
        if (e.target.value == "korea"){
          //국내선물
          this.sendOmsLogMessage("팝업("+window.name + ") 국내선물 클릭");
          for (let item of this.symbolList) {
            if (item.symbolno == 10){ //국내선물
              this.symbol = item;

              window.$store.dispatch('SocketPrice/setSelectSymbol', item);
              break;
            }
          }
        }else if (e.target.value == "global"){
          //해외선물
          this.sendOmsLogMessage("팝업("+window.name + ") 해외선물 클릭");
          for (let item of this.symbolList) {
            if (item.symbolno == 1){ //나스닥
              this.symbol = item;

              window.$store.dispatch('SocketPrice/setSelectSymbol', item);
              break;
            }
          }
        }
        this.showInstruments = e.target.value;
      }
    },
    loadLayout() {
      opener.$store
        .dispatch('Layout/load')
        .then(() => {
          this.layout = opener.$store.getters['Layout/getLayout']

          EventBus.$emit('Layout:event:loadLayout', this.layout)

          //this.isCompleteLoadLayout = true
        })         
    },
    setLayout(layout) {
      //this.orderSetting = layout.orderSetting;
      this.loadCustom();
    },
    loadCustom() {
      if (!window.opener){
        return;
      }
      this.orderSetting = window.opener.$store.getters['Layout/getOrderSetting'];
      window.$store.dispatch('Layout/setPopupOrderSetting', {data:this.orderSetting});
      //this.orderSetting = Object.assign({}, window.$store.getters['Layout/getOrderSetting']);

      if (this.orderSetting != null){
        //this.selectLoadCustom = this.selectLoadCustom +1;

        //손절
        if (this.slStockVal2 == false){
          this.setSellSlTick(this.orderSetting.slTikVal1.tikVal);
        }

        //익절
        if (this.slStockVal1 == false){
          this.setBuySlTick(this.orderSetting.slTikVal2.tikVal);
        }

        if (this.orderSetting != null){
          //주문수량 설정
          this.clickSetList[0] = this.orderSetting.clickVal1.val.toString();
          this.clickSetList[1] = this.orderSetting.clickVal2.val.toString();
          this.clickSetList[2] = this.orderSetting.clickVal3.val.toString();
          this.clickSetList[3] = this.orderSetting.clickVal4.val.toString();
          this.clickSetList[4] = this.orderSetting.clickVal5.val.toString();

          this.ordClick = Number(this.clickSetList[0]);

          //원클릭, 더블클릭 설정
          // if (this.orderSetting.oneClick == true){
          //   this.itemColumnDoubleClickTime = 0;
          // }else if (this.orderSetting.dbClick == true){
          //   this.itemColumnDoubleClickTime = 200;
          // }
        }

        //레버리지
        if (this.orderSetting.leverage){
            this.leverage = this.orderSetting.leverage;
        }
      }
    },
    async selectLeverageList(){
      const list = await window.$http.get('/api/order/lv/limitlist', { params: { accNo:this.account.accNo } } );

      let lvList = [];
      if (list.data != undefined && list.data.length > 0){
        list.data.forEach((item)=>{
          lvList.push({cd:item.cd, name:item.cdNm});
        });
        this.leverageList = lvList;

        if (Number(this.leverage) < Number(lvList[0].cd)){
          this.selectLeverage(lvList[0]);
        }
      }

    },
    //종목 조회
    async selectSymbolList() {
      await window.$store.dispatch('Symbol/getSecMt'); //월물 조회
      await window.$store.dispatch('Symbol/load'); //종목 조회
      this.symbolList = window.$store.getters['Symbol/getSymbolList'];
      this.symbolDropdown(this.symbolList[0]);    
    },
    updateRtPosSymbol(dataList){
      this.rtPosSymbolList = [];
      dataList.forEach(data=>{
        if (data.ordType == "POS"){
          this.rtPosSymbolList.push(data);
        }
      })
      
      this.updateSubscribeOnPrice();
    },
    updateSubscribeOnPrice(){
      //소켓 구독 종목 업데이트
      this.symbolCdList = [];
      //관심종목이 없고 잔고 목록만 있을경우
      this.rtPosSymbolList.forEach(data =>{
        this.symbolCdList.push(data.symCd);
      });

      //선택 종목
      const selSymbol = window.$store.getters['SocketPrice/getSelectSymbol'];

      if (selSymbol != undefined && selSymbol.name){
        let chkYn = "N";
        this.symbolCdList.forEach(item =>{
          if (selSymbol.name == item){
            chkYn = "Y";
            return;
          }
        })
        if (chkYn == "N"){
          this.symbolCdList.push(selSymbol.name);
        }
      }
      

      //console.log("소켓 구독 수정", this.symbolCdList)
      
      //this.subscribeOnPriceModify(this.symbolCdList);
    },
    subscribeOnPriceModify(symbolList) {
      if (this.priceReqType == "SCKTP01"){
        window.$store.dispatch('SocketPrice/setUpdateSubscribeOnPriceList', symbolList);
      }else if (this.priceReqType == "SCKTP02" || this.priceReqType == "SCKTP03"){
        window.$store.dispatch('SocketPrice/setPollingPrice', {symCd:this.symbol.name, symbolList:symbolList});
      }
    },
    async setStockInfo(newSymbol, oldSymbol) {

      //const rStock = await window.$http.get('chart/datafeed/stockdata', { params: {  } } )
      //console.log("rStock search >>> ", newSymbol)
      const rStock = await window.$http.get('apisise/getLastPrice', { params: { symCd: newSymbol.name} } )
      
      if ( rStock != null ) {
        //console.log("rStock >>>>> ", rStock)
        if (rStock.data.ovc != null){
          this.nowPrice = rStock.data.ovc;
        }else{
          this.nowPrice = {curPr:0, ydiffPr:0, open:0, chGrate:0, high:0, low:0, ydiffSign:0};
        }
        
        this.rStock = Object.assign({}, rStock.data);
        
        // let ovc = this.ovcData.ovcData;

        // //시세 db값으로 초기 설정
        // rStock.data.forEach(item => {
        //   ovc.set(item.symbol, item);
        // });
        
        // let cnt = this.ovcData.cnt; //map으로 전달하면 PanelComp2 watch에서 감지를 못하여 cnt값을 계속 증가시켜 감지
        // const param = {cnt:cnt+1, ovcData:ovc};         
        // this.ovcData = param;
      }
    },
    // oms socket 연결
    async connectOms() {
      await window.$store.dispatch('SocketOms/setConectOms', {sessionStorage:this.$sessionStorage.get('xDkToken'), subscriptionUID:sessionStorage.getItem('subscriptionUID')})
    },
    async connectMsg() {
      await window.$store.dispatch('SocketMsg/setConectMsg', {sessionStorage:this.$sessionStorage.get('xDkToken'), subscriptionUID:sessionStorage.getItem('subscriptionUID')})
    },
    //종목셀렉트 선택
    symbolDropdown(value) {
      window.$store.dispatch('SocketPrice/setSelectSymbol', value)      
    },
    selectAccountList(){
      if (this.$sessionStorage.get('account') != undefined){
        this.account = JSON.parse(this.$sessionStorage.get('account'));

        this.coinAccount = JSON.parse(this.$sessionStorage.get('coinAccount'));

        //레버리지 유저별 목록
        this.selectLeverageList();
      }

    },
    async selectOrder(symbol) {
      if (this.account.accId == undefined || this.account.accId == 0){
        return;
      }
      const response = await window.$http.get('api/order/list', { params: { accId: this.account.accId, symCd: symbol } } );
      //console.log("response", response);



      //STOP LOSS 초기화
      // this.slStockVal1 = false;
      // this.slStockVal2 = false;
      // this.sellSlTick = 1; //db저장된 디폴트 값을 셋팅
      // this.buySlTick = 1; //db저장된 디폴트 값을 셋팅


      this.slStockVal2 = false;
      this.slStockVal1 = false;

      if (response.data) {
        this.selectOrderData = {selectOrderList: response.data};

        for (let order of response.data) {
          if (order.ordType == "SL" && order.symCd == this.symbol.name){
            if (order.ordSdCd == "1"){ //CHK_VAL 값 //1:익절 , 2:손절
              //ordPrc -> TICK_VAL 설정값
              if (order.ordPrc != null && order.ordPrc > 0){
                if (order.ordTpCd == 0){ // ORD_STAT_CD = 0 이면 체크
                  this.slStockVal1 = true;
                  this.setBuySlTick(order.ordPrc);
                }
              }
            }else if (order.ordSdCd == "2"){
              if (order.ordPrc != null && order.ordPrc > 0){
                if (order.ordTpCd == 0){ // ORD_STAT_CD = 0 이면 체크
                  this.slStockVal2 = true;
                  this.setSellSlTick(order.ordPrc);
                }
              }
            }
          }
        }

        //손절, 익절 포지션 설정값이 없으면 셋팅설정값으로 설정

        //손절
        if (this.slStockVal2 != undefined && this.slStockVal2 == false && this.orderSetting.slTikVal2.tikVal){
          this.setSellSlTick(this.orderSetting.slTikVal2.tikVal)
        }

        //익절
        if (this.slStockVal1 != undefined && this.slStockVal1 == false && this.orderSetting.slTikVal1.tikVal){
          this.setBuySlTick(this.orderSetting.slTikVal1.tikVal);
        }
      }else{
        this.selectOrderData = {selectOrderList: []};
      }
    },
    completeOrder(res){
      if (res != undefined && res.notify != undefined){
        //this.notifyAlert(res.notify)
      }
      const apiCode = res.apiCode;
      const body = res.body;
      if ( apiCode == 'OMS0001' && body.ordTpCd == "2" && body.execYn == "Y"){ //시장가 버튼 활성화
        if (body.ordSdCd == "1"){ //매도
          this.btnSellMarketFlag = true;
        }else if (body.ordSdCd == "2"){ //매수
          this.btnBuyMarketFlag = true;
        }  
      }else if ( apiCode == 'OMS0007') {
        if ( body.chkVal == '1' && body.accId == this.account.accId && this.symbol.name == body.symCd) {
            //this.$emit('updateSlStock', {chkType:'1', chkVal:!this.slStockVal1});
            this.updateSlStock({chkType:'1', chkVal:!this.slStockVal1, tickVal:res.body.tickVal});
        } else if ( body.chkVal == '2' && body.accId == this.account.accId && this.symbol.name == body.symCd) {
            //this.$emit('updateSlStock', {chkType:'2', chkVal:!this.slStockVal2});
            this.updateSlStock({chkType:'2', chkVal:!this.slStockVal2, tickVal:res.body.tickVal});
        }
      // STOP LOSS 취소 
      } else if ( apiCode == 'OMS0008') {
        if ( body.chkVal == '1' && body.accId == this.account.accId && this.symbol.name == body.symCd) {
          if ( body.execYn == 'Y' ) {
            this.updateSlStock({chkType:'1', chkVal:!this.slStockVal1, tickVal:res.body.tickVal});
          }
        } else if ( body.chkVal == '2' && body.accId == this.account.accId && this.symbol.name == body.symCd) {
          if ( body.execYn == 'Y' ) {
            this.updateSlStock({chkType:'2', chkVal:!this.slStockVal2, tickVal:res.body.tickVal});
          }
        }
       // 전/현 종목 청산
      } else if ( apiCode == 'OMS0013') {
        if (body.delList != undefined){
          body.delList.forEach(del => {
              //같은 종목만 삭제 처리
              if (del.symCd == this.symbol.name){
                if ( del.chkVal == '1' ) {
                  if ( del.execYn == 'Y' ) {
                    this.updateSlStock({chkType:'1', chkVal:false});
                  }
                } else if ( del.chkVal == '2' ) {
                  if ( del.execYn == 'Y' ) {        
                    this.updateSlStock({chkType:'2', chkVal:false});
                  }
                }
              }           
            });
        }
      //RMS 청산
      }else if (apiCode == 'OMS0017'){
        this.updateSlStock({chkType:'1', chkVal:false});
        this.updateSlStock({chkType:'2', chkVal:false});
      //배치 청산
      } else if ( apiCode == 'OMS9995'){ 
        if (body.execYn == "Y"){
          this.updateSlStock({chkType:'1', chkVal:false});
          this.updateSlStock({chkType:'2', chkVal:false});
        }
      }
      

      //포지션 조회
      if (this.account.accId && this.account.accId > 0){
        //this.search(this.symbol.name);
        this.searchOrdAbleQty();
        //this.$emit("PanelComp4Select", this.symbol.name);
      }
    },
    procFailMessage(res) {
      let body = res.body;

      //기존 알림창 닫기
      this.$notify({ group: 'notification', clean: true })

      this.$notify({
        group: 'notification',
        title: 'FAIL',
        text: body.message
      })
    },
    //평가손익/잔고/가능수량등 1번에 조회
    selectUsrData() {
      //window.$store.dispatch('SocketOms/selectAbleQty', {accId:this.account.accId, symcd:this.symbol.name, symNo:this.symbol.symbolno});
      window.$store.dispatch('SocketOms/selectUsrData', {accId:this.account.accId, symCd:this.symbol.name, symNo:this.symbol.symbolno, lv:this.leverage});
    },
    //주문가능수량 조회
    searchOrdAbleQty() {
      window.$store.dispatch('SocketOms/selectAbleQty', {accId:this.account.accId, symCd:this.symbol.name, symNo:this.symbol.symbolno, lv:this.leverage});
      //window.$store.dispatch('SocketOms/selectUsrData', {accId:this.account.accId, symCd:this.symbol.name, symNo:this.symbol.symbolno});
    },
    tikVal(symbol){
      return (symbol.minmov / symbol.pricescale);
    },
    notifyAlert(msgInfo){ //주문 알림 메시지
      //console.log("msgInfo", msgInfo)
      
      if (msgInfo != null){
        if (this.orderSetting != null && this.orderSetting.soundAlert){
           // 사운드 실행
           if (msgInfo.apiCode == 'OMS0001'){ //주문완료
            if (msgInfo.body.ordSdCd == '1'){
              this.soundOn('/SELL_COMP.WAV')
            }else{
              this.soundOn('/BUY_COMP.WAV')
            }
          }else if (msgInfo.apiCode == 'OMS0002'){  //취소주문
            this.soundOn('/CANCEL_COMP.WAV')
          }else if (msgInfo.apiCode == 'OMS0003'){  //정정주문  
            this.soundOn('/MODIFY_COMP.WAV')
          }else if (msgInfo.apiCode == 'OMS0005'){  //MIT취소완료
            this.soundOn('/CANCEL_COMP.WAV')
          }else if (msgInfo.apiCode == 'OMS0006'){  //MIT정정완료
            this.soundOn('/MODIFY_COMP.WAV')
          }else if (msgInfo.apiCode == 'OMS0010'){ //종목선택취소 
            this.soundOn('/CANCEL_COMP.WAV')
          }else if (msgInfo.apiCode == 'OMS0011'){ //MIT종목선택취소
            this.soundOn('/CANCEL_COMP.WAV')
          }else if (msgInfo.apiCode == 'OMS0012'){ //일괄취소
            this.soundOn('/CANCEL_COMP.WAV')
          }
        }

        if (this.orderSetting != null && this.orderSetting.messageAlert){
        
          
          this.$notify({ group: msgInfo.group, clean: true })

          this.$notify(msgInfo)
        }        
      }
    },
    soundOn(file){
      //if (this.orderSetting != null && this.orderSetting.soundAlert){
        new Audio("/wav"+file).play();
      //}
    },

    sellMarket(){
      //주문내역이 있으면 리턴 결과 오기전 재주문 불가
      if (!this.btnSellMarketFlag){
        return;
      } 

      this.btnSellMarketFlag = false

      const order = {
        usrKey: Utils.getUuidv4(),
        accId : this.account.accId,
        symCd : this.symbol.name,
        symNo : this.symbol.symbolno,
        trdDt : '',
        ordId : 0,
        orgOrdNo: 0,
        ordSdCd : '1',
        ordTpCd : '2',
        ordPrc : 0,
        ordQty : this.ordClick,
        repReqQty : 0,
        cxlReqQty : 0,
        lv : this.leverage,
      }

      let message = "팝업("+window.name + ") 시장가 매도 클릭";
      this.sendOmsLogMessage(message);

      if (this.orderSetting != undefined && this.orderSetting.ordMessageAlert){
        // this.$alert.confirm(
        //   '<b style="font-weight:600;">종목명</b> : ' + this.symbol.description + '</br>' +
        //   '<b style="font-weight:600;">구분</b> : 매도(시장가)' + '</br>' +
        //   '<b style="font-weight:600;">수량</b> : ' + this.ordClick + '</br>' +
        //   '<b style="font-weight:600;">가격</b> : 시장가' + '</br>' +
        //   '<b style="font-weight:600;">매도 주문을 하시겠습니까?</b>', '주문 확인')
        // .then((response) => {
        //   if (!response) {
        //     this.btnSellMarketFlag = true
        //     return;
        //   }else{
        //     this.sendOmsMessage('OMS0001', order)
        //   }
        // })
        const orderData = {apiCode:"OMS0001", order: order, symbol:this.symbol ,qty:this.ordClick, price: 0, message:message};
        this.$modal.show("modalConfirmOrder", orderData);
      }else{
        this.sendOmsMessage('OMS0001', order)
      }

      //1.5초후 버튼 누를 수 있게 설정
      if (!this.btnSellMarketFlag){
        setTimeout(() => {
          this.btnSellMarketFlag = true
        }, 1500)
      }
    },
    buyMarket(){
      //주문내역이 있으면 리턴 결과 오기전 재주문 불가
      if (!this.btnBuyMarketFlag){
        return;
      } 

      this.btnBuyMarketFlag = false

      const order = {
        usrKey: Utils.getUuidv4(),
        accId : this.account.accId,
        symCd : this.symbol.name,
        symNo : this.symbol.symbolno,
        trdDt : '',
        ordId : 0,
        orgOrdNo: 0,
        ordSdCd : '2',
        ordTpCd : '2',
        ordPrc : 0,
        ordQty : this.ordClick,
        repReqQty : 0,
        cxlReqQty : 0,
        lv : this.leverage,
      }

      let message = "팝업("+window.name + ") 시장가 매수 클릭";
      this.sendOmsLogMessage(message);

      if (this.orderSetting != undefined && this.orderSetting.ordMessageAlert){
        // this.$alert.confirm(
        //   '<b style="font-weight:600;">종목명</b> : ' + this.symbol.description + '</br>' +
        //   '<b style="font-weight:600;">구분</b> : 매수(시장가)' + '</br>' +
        //   '<b style="font-weight:600;">수량</b> : ' + this.ordClick + '</br>' +
        //   '<b style="font-weight:600;">가격</b> : 시장가' + '</br>' +
        //   '<b style="font-weight:600;">매수 주문을 하시겠습니까?</b>', '주문 확인')
        // .then((response) => {
        //   if (!response) {
        //     this.btnBuyMarketFlag = true
        //     return;
        //   }else{
        //     this.sendOmsMessage('OMS0001', order)
        //   }
        // })
        const orderData = {apiCode:"OMS0001", order: order, symbol:this.symbol ,qty:this.ordClick, price: 0, message:message};
        this.$modal.show("modalConfirmOrder", orderData);
      }else{
        this.sendOmsMessage('OMS0001', order)
      }

      //1.5초후 버튼 누를 수 있게 설정
      if (!this.btnBuyMarketFlag){
        setTimeout(() => {
          this.btnBuyMarketFlag = true;
        }, 1500)
      }
    },
    cancelSellAll(){ //SELL 전체 취소
      if (this.account == undefined || this.account.accId == undefined ||this.account.accId == 0){
        window.$alert.alert('선택된 계좌가 없습니다.', '알림')
        return;
      }

      // if (!this.getUsrAccPass){
      //   window.$alert.alert('계좌 비밀번호를 확인하세요.', '알림')
      //   return;
      // }

      //주문내역이 있으면 리턴 결과 오기전 재주문 불가
      if (!this.btnCancelSellAllFlag){
        return;
      }

      this.btnCancelSellAllFlag = false;

      const order = {
        usrKey: Utils.getUuidv4(),
        accId : this.account.accId,
        symCd : this.symbol.name,
        symNo : this.symbol.symbolno,
        trdDt : '',
        ordSdCd : '1',
        //accPass : this.getUsrAccPass
      };

      if (this.orderSetting != undefined && this.orderSetting.clearMessageAlert){
        // this.$alert.confirm('매도주문을 취소 하시겠습니까?', '주문 확인')
        // .then((response) => {
        //   if (!response) {
        //     this.btnCancelSellAllFlag = true;
        //     return;
        //   }else{
        //     this.sendOmsMessage("OMS0010", order);
        //   }
        // });
        const orderData = {apiCode:"OMS0010", order: order, symbol:this.symbol, qty:0, price: 0};
        this.$modal.show("modalConfirmOrder", orderData);
      }else{
        this.sendOmsMessage("OMS0010", order);
      }

      //1.5초후 버튼 누를 수 있게 설정
      if (!this.btnCancelSellAllFlag){
        setTimeout(() => {
          this.btnCancelSellAllFlag = true;
        }, 1500);
      }
    },
    cancelBuyAll(){ //BUY 전체 취소
      if (this.account == undefined || this.account.accId == undefined ||this.account.accId == 0){
        window.$alert.alert('선택된 계좌가 없습니다.', '알림');
        return;
      }

      // if (!this.getUsrAccPass){
      //   window.$alert.alert('계좌 비밀번호를 확인하세요.', '알림');
      //   return;
      // }

      //주문내역이 있으면 리턴 결과 오기전 재주문 불가
      if (!this.btnCancelBuyAllFlag){
        return;
      }

      this.btnCancelBuyAllFlag = false;

      const order = {
        usrKey: Utils.getUuidv4(),
        accId : this.account.accId,
        symCd : this.symbol.name,
        symNo : this.symbol.symbolno,
        trdDt : '',
        ordSdCd : '2',
        //accPass : this.getUsrAccPass
      };

      if (this.orderSetting != undefined && this.orderSetting.clearMessageAlert){
        // this.$alert.confirm('매수주문을 취소 하시겠습니까?', '주문 확인')
        // .then((response) => {
        //   if (!response) {
        //     this.btnCancelBuyAllFlag = true;
        //     return;
        //   }else{
        //     this.sendOmsMessage("OMS0010", order);
        //   }
        // });
        const orderData = {apiCode:"OMS0010", order: order, symbol:this.symbol, qty:0, price: 0};
        this.$modal.show("modalConfirmOrder", orderData);
      }else{
        this.sendOmsMessage("OMS0010", order);
      }

      //1.5초후 버튼 누를 수 있게 설정
      if (!this.btnCancelBuyAllFlag){
        setTimeout(() => {
          this.btnCancelBuyAllFlag = true;
        }, 1500);
      }
    },
    cancelSellStAll(){ //SELL STOP 전체 취소
      if (this.account == undefined || this.account.accId == undefined ||this.account.accId == 0){
        window.$alert.alert('선택된 계좌가 없습니다.', '알림');
        return;
      }

      // if (!this.getUsrAccPass){
      //   window.$alert.alert('계좌 비밀번호를 확인하세요.', '알림');
      //   return;
      // }

      //주문내역이 있으면 리턴 결과 오기전 재주문 불가
      if (!this.btnCancelSellStAllFlag){
        return;
      }

      this.btnCancelSellStAllFlag = false;

      const order = {
        usrKey: Utils.getUuidv4(),
        accId : this.account.accId,
        symCd : this.symbol.name,
        symNo : this.symbol.symbolno,
        trdDt : '',
        ordSdCd : '1',
        //accPass : this.getUsrAccPass
      };

      if (this.orderSetting != undefined && this.orderSetting.clearMessageAlert){
        // this.$alert.confirm('ST매도주문을 취소 하시겠습니까?', '주문 확인')
        // .then((response) => {
        //   if (!response) {
        //     this.btnCancelSellStAllFlag = true;
        //     return;
        //   }else{
        //     this.sendOmsMessage("OMS0011", order)
        //   }
        // });
        const orderData = {apiCode:"OMS0011", order: order, symbol:this.symbol, qty:0, price: 0};
        this.$modal.show("modalConfirmOrder", orderData);
      }else{
        this.sendOmsMessage("OMS0011", order)
      }

      //1.5초후 버튼 누를 수 있게 설정
      if (!this.btnCancelSellStAllFlag){
        setTimeout(() => {
          this.btnCancelSellStAllFlag = true;
        }, 1500);
      }
    },
    cancelBuyStAll(){ //BUY STOP 전체 취소
      if (this.account == undefined || this.account.accId == undefined ||this.account.accId == 0){
        window.$alert.alert('선택된 계좌가 없습니다.', '알림');
        return;
      }

      // if (!this.getUsrAccPass){
      //   window.$alert.alert('계좌 비밀번호를 확인하세요.', '알림');
      //   return;
      // }
      
      //주문내역이 있으면 리턴 결과 오기전 재주문 불가
      if (!this.btnCancelBuyStAllFlag){
        return;
      }

      this.btnCancelBuyStAllFlag = false;

      const order = {
        usrKey: Utils.getUuidv4(),
        accId : this.account.accId,
        symCd : this.symbol.name,
        symNo : this.symbol.symbolno,
        trdDt : '',
        ordSdCd : '2',
        //accPass : this.getUsrAccPass
      };

      if (this.orderSetting != undefined && this.orderSetting.clearMessageAlert){
        // this.$alert.confirm('ST매수주문을 취소 하시겠습니까?', '주문 확인')
        // .then((response) => {
        //   if (!response) {
        //     this.btnCancelBuyStAllFlag = true;
        //     return;
        //   }else{
        //     this.sendOmsMessage("OMS0011", order);
        //   }
        // });
        const orderData = {apiCode:"OMS0011", order: order, symbol:this.symbol, qty:0, price: 0};
        this.$modal.show("modalConfirmOrder", orderData);
      }else{
        this.sendOmsMessage("OMS0011", order);
      }
      
      //1.5초후 버튼 누를 수 있게 설정
      if (!this.btnCancelBuyStAllFlag){
        setTimeout(() => {
          this.btnCancelBuyStAllFlag = true;
        }, 1500);
      }
    },

    sendOmsMessage(apiCode, body) {
      if ( !window.opener.$store.getters['SocketOms/getIsConnectOms'] ) {
        window.$alert.alert('주문서버 연결이 불안정합니다.\n새로고침 후 다시 시도해주세요.');
        return;
      }

      //비밀번호 추가
      //body.accPass = this.getUsrAccPass;

      window.opener.$store.dispatch('SocketOms/sendOmsMessage', {apiCode:apiCode, body:body});
    }, 
    //손절 틱설정값 저장
    setSellSlTick(tickVal){
      this.sellSlTick = Object.assign(Number(tickVal));
    },
    //익절 틱설정값 저장
    setBuySlTick(tickVal){
      this.buySlTick = Object.assign(Number(tickVal));
    },
    setClickOrd(val){
      this.ordClick = Object.assign(Number(val));
    },
    setOrdClick(val){
      this.ordClick = Object.assign(Number(val));
    },
    sellSlStock() {
      if (this.account == undefined || this.account.accId == undefined ||this.account.accId == 0){
        window.$alert.alert('선택된 계좌가 없습니다.', '알림');
        return;
      }

      // if (!this.getUsrAccPass){
      //   window.$alert.alert('계좌 비밀번호를 확인하세요.', '알림')
      //   return;
      // }

      const order = {
        usrKey: Utils.getUuidv4(),
        accId : this.account.accId,
        symCd : this.symbol.name,
        symNo : this.symbol.symbolno,
        trdDt : '',
        ordId : 0,
        orgOrdNo: 0,
        ordSdCd : '1',
        ordTpCd : '1',
        ordPrc : 0,
        tickVal : this.sellSlTick,
        chkVal : '2', // '1' 익절 '2' 손절
        //accPass : this.getUsrAccPass
        lv : this.leverage,
      };


      let message = "팝업("+window.name + ") 손절";
      
      let apiCode = "OMS0007";
      if (this.slStockVal2){ //체크값이 true이면 삭제처리
        apiCode = "OMS0008";
        message += "해제";
      }else{
        message += "체크";
      }
      message += ":"+this.sellSlTick;

      this.sendOmsLogMessage(message);
      this.sendOmsMessage(apiCode, order)
    },
    buySlStock() {
      if (this.account == undefined || this.account.accId == undefined ||this.account.accId == 0){
        window.$alert.alert('선택된 계좌가 없습니다.', '알림')
        return;
      }

      // if (!this.getUsrAccPass){
      //   window.$alert.alert('계좌 비밀번호를 확인하세요.', '알림')
      //   return;
      // }

      const order = {
        usrKey: Utils.getUuidv4(),
        accId : this.account.accId,
        symCd : this.symbol.name,
        symNo : this.symbol.symbolno,
        trdDt : '',
        ordId : 0,
        orgOrdNo: 0,
        ordSdCd : '1',
        ordTpCd : '1',
        ordPrc : 0,
        tickVal : this.buySlTick,
        chkVal : '1', // '1' 익절 '2' 손절 
        //accPass : this.getUsrAccPass
        lv : this.leverage,
      };

      
      let message = "팝업("+window.name + ") 익절";
      
      let apiCode = "OMS0007";
      if (this.slStockVal1){ //체크값이 true이면 삭제처리
        apiCode = "OMS0008";
        message += "해제";
      }else{
        message += "체크";
      }
      message += ":"+this.sellSlTick;

      this.sendOmsLogMessage(message);
      this.sendOmsMessage(apiCode, order);
    },
    updateSlStock(data){
      if (data.chkType == "2"){ //손절
        this.slStockVal2 = data.chkVal;
        if (this.slStockVal2) this.sellSlTick = data.tickVal;
      }else if (data.chkType == "1"){ //익절
        this.slStockVal1 = data.chkVal;
        if (this.slStockVal1) this.buySlTick = data.tickVal;
      }      
    },
    clearOrderData(type){ //전/현 종목 청산
      if (this.account == undefined || this.account.accId == undefined ||this.account.accId == 0){
        window.$alert.alert('선택된 계좌가 없습니다.', '알림')
        return;
      }

      // if (!this.getUsrAccPass){
      //   window.$alert.alert('계좌 비밀번호를 확인하세요.', '알림')
      //   return;
      // }

      //주문내역이 있으면 리턴 결과 오기전 재주문 불가
      if (!this.btnCancelAllFlag){
        return;
      }
      
      if (type == 'all' || type == 'symbol'){
        let msg = "현종목";
        let symCd = this.symbol.name;
        let symNo = this.symbol.symbolno;
        if (type == 'all'){
          msg = "전종목";
          symCd = "";
        }

        this.btnCancelAllFlag = false;

        const order = {
          usrKey: Utils.getUuidv4(),
          accId : this.account.accId,
          symCd : symCd,
          symNo : symNo,
          trdDt : '',
          clearType : type,
          accPass : this.getUsrAccPass,
        }

        let message = "팝업("+window.name + ") " + msg;
        message += " 청산 클릭";
        this.sendOmsLogMessage(message);

        if (this.orderSetting != undefined && this.orderSetting.clearMessageAlert){
          const orderData = {apiCode:"OMS0013", order: order, symbol:this.symbol, qty:0, price: 0, message:message};
          this.$modal.show("modalConfirmOrder", orderData);
        }else{
          this.sendOmsMessage("OMS0013", order);
        }
      }

      //1.5초후 버튼 누를 수 있게 설정
      if (!this.btnCancelAllFlag){
        setTimeout(() => {
          this.btnCancelAllFlag = true
        }, 1500)
      }
    },
    //잔고 내역 업데이트
    updateTotalValPl(newVal){
      this.totalValPl = Object.assign({}, newVal);
      this.dailyPlMn.totalLossCut = newVal.totalLossCut != 0 && Number(newVal.totalLossCut) > 0 ? newVal.totalLossCut / this.leverage : newVal.totalLossCut;
      this.dailyPlMn.totalValPl = newVal.totalValPl;
      //this.dailyPlMn.balMn = newVal.balMn;
    },
    //담보금 업데이트
    updateDailyPlMn(newVal){
      //console.log("updateDailyPlMn ", newVal)
    },
    async popupOrder(){
      //window.opener.closePopupOrder1();
      window.opener.postMessage({ action: 'executeFunction' }, window.location.origin);
    },
    //종목평가 업데이트
    setSymbolPrice(){
      const usdExr = this.dailyPlMn.usdExR;
      const hkdExR = this.dailyPlMn.hkdExR;
      const usdExHkd = this.dailyPlMn.usdExHkd;
      let data = this.symbolPlMn.data;

      // 계산 공통사용변경
      // data - 그리드 1개 row data
      // nowPrice OVC 데이터로 전달
      // symbolList
      // usdExr 환율
      // usdExHkd 홍콩달러환율
      // hkdExR 홍콩환율
      // lvTpCd 레버리지여부
      // currency 원/달러 구분 WON
      // return {data, }
      if (data != null){
        let res = Utils.symbolCalPrc(data, this.nowPrice, this.symbolList, usdExr, usdExHkd, hkdExR, data.lvTpCd, this.currencyInfo.currency);
        data = res.data; //데이터가 갱신 안되면 주석해제
        this.symbolPlMn.valPl = res.valProLoss;
      }
    },
    //일반주문 수량 설정
    setStdOrdQty(stdQty){
      this.ordStdQty = Number(stdQty);
    },
    setOrdStdPrc(stdPrc){
      this.ordStdPrc = stdPrc;
    },
    stdReset(){ //일반주문 초기화
      this.ordStdQty = 1;
      this.ordStdPrc = this.nowPrice.curPr;
      this.sendOmsLogMessage("팝업("+window.name + ") 일반 주문창 초기화 버튼 클릭");
    },
    stdSellBuy(type){
      if (!this.ordStdType){
        window.$alert.alert('유형을 선택하세요.', '알림');
        return;
      }else if (!this.ordStdQty || this.ordStdQty < 1){
        window.$alert.alert('수량을 입력 해주세요.', '알림');
        return;        
      }else if (!this.ordStdPrc || this.ordStdPrc <= 0){
        window.$alert.alert('가격을 입력 해주세요.', '알림');
        return;        
      }

      if (type == "buy" && this.buyAbleQty == 0){
        // this.$notify({ group: 'notification', clean: true })
        // this.$notify({
        //   group : 'notification',
        //   title : 'FAIL',
        //   text : '[주문거부] 주문가능 수량이 부족합니다.'
        // });

        window.$alert.alert('주문가능 수량이 부족합니다.', '알림');
        return;
      }

      //주문내역이 있으면 리턴 결과 오기전 재주문 불가
      if (!this.btnStdFlag){
        return;
      }

      let ordSdCd = "0";
      let message = "팝업("+window.name + ") 일반 주문창 주문";
      if (type == "sell"){
        ordSdCd = "1";
        message += " 매도";
      }else if (type == "buy"){
        ordSdCd = "2";
        message += " 매수";
      }

      message += " " + this.symbol.name;

      if (type == "sell"){
        ordSdCd = "1";
      }else if (type == "buy"){
        ordSdCd = "2";
      }

      let ordTpCd = "0";
      let apiCode = "";
      let stTpCd = "2";
      let ordPrc = 0;

      if (this.ordStdType == "1"){ //시장가
        ordTpCd = "2";
        apiCode = "OMS0001";
        message += "/시장가";
      }else if (this.ordStdType == "2"){ //지정가
        ordTpCd = "1";
        apiCode = "OMS0001";
        ordPrc = this.ordStdPrc;
        message += "/지정가";
      }else if (this.ordStdType == "3"){ //MIT
        ordTpCd = "2";
        apiCode = "OMS0004";
        ordPrc = this.ordStdPrc;
        message += "/MIT";
        
        //stTpCd : 스탑유형코드 // '1' 위방향 '2' 아래방향 (주문가격이 현재가보다 클경우 1 아닌경우 2)
        if (Number(this.nowPrice.curPr) == Number(this.ordStdPrc)){
          this.$notify({ group: 'notification', clean: true })
  
          this.$notify({
            group : 'notification',
            title : 'FAIL',
            text : '[주문거부] 현재가에 MIT주문이 불가능합니다.'
          });
          return;
        } else if (this.nowPrice.curPr < Number(this.ordStdPrc)){
          stTpCd = "1";
        }
      }

      message += "/수량 "+this.ordStdQty + "/가격 "+ordPrc;

      this.btnStdFlag = false;

      const order = {
        usrKey: Utils.getUuidv4(),
        accId : this.account.accId,
        symCd : this.symbol.name,
        symNo : this.symbol.symbolno,
        trdDt : '',
        ordId : 0,
        orgOrdNo: 0,
        ordSdCd : ordSdCd,
        ordTpCd : ordTpCd,
        ordPrc : ordPrc,
        ordQty : this.ordStdQty,
        repReqQty : 0,
        cxlReqQty : 0,
        stTpCd : stTpCd,
        accPass : this.getUsrAccPass,
        lv : this.leverage,
      }

      this.sendOmsLogMessage(message);

      if (this.orderSetting != undefined && this.orderSetting.ordMessageAlert){
        const orderData = {apiCode:apiCode, order: order, symbol:this.symbol ,qty:this.ordStdQty, price: Number(ordPrc), message:message};
        this.$modal.show("modalConfirmOrder", orderData);
      }else{
        this.sendOmsMessage(apiCode, order);
      }

      //0.3초후 버튼 누를 수 있게 설정
      if (!this.btnStdFlag){
        setTimeout(() => {
          this.btnStdFlag = true;
        }, 300);
      }
    },
    getServerStat(){
        // 청산 테스트용
        // let order = {
        //   usrKey: Utils.getUuidv4(),
        //   accId : this.account.accId,
        //   symCd : this.symbol.name,
        //   symNo : this.symbol.symbolno,
        //   trdDt : "20240712"
        // }
  
        // this.sendOmsMessage("OMS9995", order);


        // 서버구분  주문서버 ORDER, 체결서버 : EXEC, 체결처리: EXECU, 스탑 : STOP , 스탑로스 : STOPLS
        let order = {
        usrKey: Utils.getUuidv4(),
        accId : this.account.accId,
        symCd : this.symbol.name,
        symNo : this.symbol.symbolno,
        serverType : "STOP",
        }
  
        this.sendOmsMessage("OMS9997", order);
  
        order = {
        usrKey: Utils.getUuidv4(),
        accId : this.account.accId,
        symCd : this.symbol.name,
        symNo : this.symbol.symbolno,
        serverType : "EXEC",
        }
  
        this.sendOmsMessage("OMS9997", order);
  
        order = {
        usrKey: Utils.getUuidv4(),
        accId : this.account.accId,
        symCd : this.symbol.name,
        symNo : this.symbol.symbolno,
        serverType : "STOPLS",
        }
  
        this.sendOmsMessage("OMS9997", order);
  
        order = {
        usrKey: Utils.getUuidv4(),
        accId : this.account.accId,
        symCd : this.symbol.name,
        symNo : this.symbol.symbolno,
        serverType : "ORDER",
        }
  
        this.sendOmsMessage("OMS9997", order);
  
        order = {
        usrKey: Utils.getUuidv4(),
        accId : this.account.accId,
        symCd : this.symbol.name,
        symNo : this.symbol.symbolno,
        serverType : "EXECU",
        }
  
        this.sendOmsMessage("OMS9997", order);
  
        order = {
        usrKey: Utils.getUuidv4(),
        accId : this.account.accId,
        symCd : this.symbol.name,
        symNo : this.symbol.symbolno,
        serverType : "RMS",
        }
  
        this.sendOmsMessage("OMS9997", order);
    },
    selectInitData(){
      //잔고목록
      const data = window.opener.$store.getters['SocketOms/getPosOrdStList'];
      console.log("selectInitData", data)
      if (data != undefined && data.length > 0){
        window.$store.dispatch('SocketOms/setChannelData', {type:'POS_ORD', data:data});
      }

      const dailyPlMn = window.opener.$store.getters['SocketOms/getDailyPlMn'];
      if (dailyPlMn != undefined){
        window.$store.dispatch('SocketOms/setChannelData', {type:'DAILY', data:dailyPlMn});
      }
    },
    sendOmsLogMessage(message){
      window.opener.$store.dispatch('SocketOms/setLogMsg', {message:message});
    },
    async selectLeverage(leverage){
      //레버리지 선택 주문정보 확인
      
      const data = {accNo:this.account.accNo, symCd:this.symbol.name};

      await window.$http.get('/api/order/rtPosOrdChk', { params: data }).then((response) => {
        if (response.code == 200){
          if (response.data == null){
            this.leverage = this.orderSetting.leverage;
            this.$alert.alert('잔고/미체결 조회중 에러가 발생했습니다.', '안내');
            return;
          }else if (response.data.ordQty != undefined && response.data.ordQty > 0){
            this.$alert.alert('잔고/미체결 내역이 있으면 레버리지 변경 불가합니다.', '안내');
            this.leverage = this.orderSetting.leverage;
            return;
          }else{
            this.leverage = leverage;
            this.orderSetting.leverage = leverage;

            //가능 수량 조회
            window.$store.dispatch('SocketOms/selectAbleQty', {accId:this.account.accId, symCd:this.symbol.name, symNo:this.symbol.symbolno, lv:leverage});

            window.opener.postMessage({ type: 'selectLeverage', leverage:leverage});
          }
        }
      });
    },
  },
  destroyed() {
    clearInterval(this.windowPollingChk);
    //oms
    if (window.$store.getters['SocketOms/getIsConnectOms']) {
      //
      window.$store.dispatch('SocketOms/setDisconnect');
    }

    //소켓 시세
    if (window.$store.getters['SocketPrice/getIsConnectPrice']) {
      //setDisconnect
      window.$store.dispatch('SocketPrice/setDisconnect');
    }

    //폴링 시세
    if (window.$store.getters['SocketPrice/getIsPolingPrice']) {
      //clear polling
      window.$store.dispatch('SocketPrice/setClearPolling');
    }

    //메시지
    if (window.$store.getters['SocketMsg/getIsConnectMsg']) {
      //clear polling
      window.$store.dispatch('SocketMsg/setDisconnect');
    }    
  },
}
</script>
<style lang="scss" scoped>
// .btn-line {
//   display: inline-flex;
//   align-items: center;
//   justify-content: center;
//   font-size: 12px;
//   font-weight: bold;
//   color: white;
//   flex: 0 1 fit-content;
//   min-width: 60px;
//   height: 30px;
//   border: 1px solid var(--btn-line-border);
//   color: black;
//   background-color: var(--btn-line-bg);
//   padding: 0 17px 0;
//   white-space: nowrap;
//   &:hover {
//     background-color: #e5e5e5;
//   }
//   &.square {
//     aspect-ratio: 1/1;
//   }
//   &.options {
//     width: 100%;
//     flex: 1;
//     color: #666666;
//     border-color: #bbbbbb;
//     &.active {
//       background-color: var(--btn-primary);
//       border-color: var(--btn-primary);
//       color: white;
//     }
//   }
//   &.blue {
//     color: var(--table-blue);
//     border-color: var(--table-blue);
//   }
//   &.red {
//     color: var(--table-red);
//     border-color: var(--table-red);
//   }
//   &.active {
//     color: white;
//     background-color: var(--btn-primary);
//     border-color: var(--btn-primary);
//   }
// }

// input {
//   flex: 1;
//   width: 100%;
//   height: 30px;
//   font-size: 12px;
//   color: black;
//   border: 1px solid var(--input-border);
//   padding: 0 10px;
//   position: relative;
//   appearance: none;
//   border-radius: 0;
//   &::placeholder {
//     color: var(--input-placeholder);
//   }
//   &.error {
//     border-color: var(--table-red);
//   }
//   &.text-right {
//     text-align: right;
//   }
// }

// .white-label-table {
//   table-layout: fixed;
//   width: 100%;
//   font-size: 12px;
//   tr {
//     height: 30px;
//     &:not(:last-child) {
//       border-bottom: 1px solid #dddddd;
//     }
//   }
//   th {
//     background-color: #f4f5f7;
//     vertical-align: middle;
//     font-weight: bold;
//     padding: 0 10px;
//     text-align: left;
//     width: 80px;
//   }
//   td {
//     vertical-align: middle;
//     padding: 0 10px;
//     text-align: right;
//   }
// }

// .counter-container {
//   display: flex;
//   align-items: center;
//   position: relative;
//   width: 55px;
//   height: 30px;
//   &.full {
//     flex: 1;
//     width: 100%;
//     height: 30px;
//   }
//   &.alignRight {
//     input {
//       text-align: right;
//     }
//   }

//   input {
//     width: 100%;
//     height: 100%;
//     font-size: 12px;
//     padding: 2px 20px 0 10px;
//   }
//   .counter-input {
//     position: absolute;
//     top: 50%;
//     transform: translateY(-50%);
//     right: 10px;
//     display: inline-flex;
//     flex-direction: column;
//     gap: 4px;
//     .down {
//       transform: rotate(180deg);
//     }
//   }
// }

// .main left lower ag-container {
//   font-size: 12px !important;
//   border: 1px solid #bbbbbb;
//   min-height: 162px;
//   height: 100%;
//     margin-left: 0px;
//     margin-right: 0px;
//   &.modal-settings {
//     height: 180px;
//     margin-top: 20px;
//   }
// }

// .instruments-table-container {
//  &.ag-container{
//   font-size: 12px !important;
//   border: 1px solid #bbbbbb;
//   //min-height: 156px;
//   height: 100%;
//     margin-left: 0px;
//     margin-right: 0px;
//   &.modal-settings {
//     height: 180px;
//     margin-top: 20px;
//   }
//  } 
// }

// input[type='radio'] {
//     display: none;
//     & + label {
//       position: relative;
//       font-size: 12px;
//       padding-left: 21px;
//       cursor: pointer;
//       &::before {
//         content: '';
//         position: absolute;
//         top: 50%;
//         transform: translateY(-50%);
//         left: 0;
//         background-image: url('@/assets/icons/checkbox-off.svg');
//         background-repeat: no-repeat;
//         background-size: 16px 16px;
//         width: 16px;
//         height: 16px;
//       }
//     }
//     &:checked {
//       & + label {
//         &::before {
//           background-image: url('@/assets/icons/checkbox-on.svg');
//         }
//       }
//     }
//   }

// .checkbox-default {
//   display: flex;
//   align-items: center;
//   input[type='checkbox'] {
//     display: none;
//     & + label {
//       position: relative;
//       font-size: 12px;
//       padding-left: 21px;
//       cursor: pointer;
//       &::before {
//         content: '';
//         position: absolute;
//         top: 50%;
//         transform: translateY(-50%);
//         left: 0;
//         background-image: url('@/assets/icons/checkbox-off.svg');
//         background-repeat: no-repeat;
//         background-size: 16px 16px;
//         width: 16px;
//         height: 16px;
//       }
//     }
//     &:checked {
//       & + label {
//         &::before {
//           background-image: url('@/assets/icons/checkbox-on.svg');
//         }
//       }
//     }
//   }
// }
// .radio-default {
//   display: flex;
//   align-items: center;
//   &.variant {
//     input[type='radio'] {
//       & + label {
//         &::before {
//           background-image: url('@/assets/icons/radio-off-variant.svg');
//           background-repeat: no-repeat;
//           background-size: 16px 16px;
//         }
//       }
//       &:checked {
//         & + label {
//           &::before {
//             background-image: url('@/assets/icons/radio-on-variant.svg');
//             background-repeat: no-repeat;
//             background-size: 16px 16px;
//           }
//         }
//       }
//     }
//   }
//   input[type='radio'] {
//     display: none;
//     & + label {
//       position: relative;
//       font-size: 12px;
//       padding-left: 21px;
//       cursor: pointer;
//       &::before {
//         content: '';
//         position: absolute;
//         top: 50%;
//         transform: translateY(-50%);
//         left: 0;
//         background-image: url('@/assets/icons/checkbox-off.svg');
//         background-repeat: no-repeat;
//         background-size: 16px 16px;
//         width: 16px;
//         height: 16px;
//       }
//     }
//     &:checked {
//       & + label {
//         &::before {
//           background-image: url('@/assets/icons/checkbox-on.svg');
//         }
//       }
//     }
//   }
// }

// .btn-primary {
//   display: inline-flex;
//   align-items: center;
//   justify-content: center;
//   gap: 0 9px;
//   font-size: 12px;
//   font-weight: bold;
//   color: white;
//   padding: 0 5px;
//   width: 100px !important;
//   height: 30px !important;
//   &.blue {
//     background-color: var(--btn-primary);
//     &:hover {
//       background-color: var(--btn-primary-hover);
//       color: #e5e5e5;
//     }
//   }
//   &.darkgray {
//     background-color: var(--btn-darkgray);
//     &:hover {
//       background-color: var(--btn-darkgray-hover);
//       color: #e5e5e5;
//     }
//   }
//   &.medium {
//     font-size: 14px;
//   }
//   &.large {
//     font-size: 16px;
//   }
//   @media (max-width: $mobile) {
//     font-size: 12px;
//     flex: unset;
//     // padding: 12px 0 15px;
//     width: 100px;
//     height: 30px;
//   }
// }
</style>
