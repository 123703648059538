<template>
  <div>
    <div v-if="params.enableMenu" ref="menuButton" class="customHeaderMenuButton" @input="onMenuClicked($event, params)">
     <!-- <BtnCheckbox :id="params.checkBoxId" class="ag-custom-checkbox" text="" /> -->
     <div class="checkbox-default">
        <input type="checkbox" :id="params.checkBoxId" value="" />
        <label :for="params.checkBoxId"></label>
      </div>
    </div>
    <div class="customHeaderLabel">{{ params.displayName }}</div>
  </div>
</template>

<script>

export default {
  components: { 
  },
  name: 'CustomHeader',
  props: {
    id: String,
  },
  data() {
    return {
    }
  },
  mounted() {

  },

  methods: {
    onMenuClicked(e, params) {
      //grid.columnDefs.headerComponentParams 에 추가한 clicked 로 리턴
      params.clicked(e.target.checked);
    },
  },
};
</script>

<style lang="scss" scoped>
.ag-custom-checkbox {
	width: 16px;
  padding-top: 2px;
  label {
    padding-left: 0 !important;
  }
}
</style>
