<template>
  <div>
    <modal name="modalNoticeDetail" classes="main-modal" :adaptive="true" :width="width" :height="height"
    @before-open="beforeOpen"
    @opened="opened">
      <div class="modal-header">
        <h2 class="modal-header-title">공지사항</h2>
        <button @click="closeModal()"><img src="@/assets/icons/icon-close-modal.svg" alt="" /></button>
      </div>

      <div class="modal-body">
        <div class="notice-detail">
          <div class="notice-header">
            <div class="notice-title">{{ post.postSj }}</div>
            <div class="notice-date">{{ post.regDt }}</div>
          </div>
          <div class="notice-body">
						<!-- <div class="notice-image">
							image
						</div> -->
            <p v-html="post.cont"></p>
          </div>
        </div>
      </div>
			<div class="modal-footer notice"></div>
    </modal>
  </div>
</template>

<script>
export default {
  name: 'WhiteLabelModalNoticeDetail',

  data() {
    return {
      brdCd : 'NOTICE',
      postNo : 0,
      postDivNm : '',
      keyword : '',
      post : {}
    };
  },

  created() {
    if (window.innerWidth > 1200) {
      this.width = 1000;
      this.height = 600;
    } else {
      this.width = '100%';
      this.height = '100%';
    }
  },

  mounted() {},

  methods: {
    closeModal() {
      this.$modal.hide('modalNoticeDetail');
    },
    opened(){
      
    },
    beforeOpen(e){
      this.post = {};
      this.brdCd     = e.params.brdCd;
      this.postNo    = e.params.postNo;
      this.postDivNm = (e.params.postDivNm == undefined) ? '' : e.params.postDivNm;
      this.keyword   = (e.params.keyword == undefined) ? '' : e.params.keyword;

      this.select(this.postNo);
    },
    async select(postNo) {
      // 조회 조건
      const params = {
        brdCd : this.brdCd,
        postNo : postNo
      };

      const response = await this.$lhttp.get('/api/board/post', { params: params });

      if ( response && response.data ) {
        this.post = response.data;
      }
    },  
  },
};
</script>
