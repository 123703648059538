import Utils from '@/modules/utils'

const state = {
  channelToOms: new Map(),  
  socketOms : null,
  isConnectOms: false,
  sessionStorage: null,
  resultOrder: null,
  ordAble:{ordAbleBuyQty:0, ordAbleSellQty:0},
  dailyPlMn: {},
  posOrdStList:[],
  omsInterval: null,
  connectOmsTimeout: null,
}

const getters = {
  //oms접속
  getIsConnectOms(state){
    return state.isConnectOms
  },
  //oms 주문 리턴 조회
  getCompleteOrder(state){
    return state.resultOrder
  },
  //주문 가능 수량 조회
  getAbleQty(state){
    const ableQty = Object.assign({}, state.ordAble)
    return ableQty
  },
  //실현손익등 조회
  getDailyPlMn(state){
    const dailyPlMn = Object.assign({}, state.dailyPlMn)
    return dailyPlMn
  },
  getPosOrdStList(state){
    const posOrdStList = Object.assign([], state.posOrdStList)
    return posOrdStList
  },
}

const mutations = {
  setSessionStorage (state, {sessionStorage}){
    //console.log("oms setSessionStorage", sessionStorage)
    state.sessionStorage = sessionStorage
  },
  setConectOms (state, {socketOms}){
    state.socketOms = socketOms
  },
  setIsConnectOms(state, {isConnect}){
    //console.log("setIsConnectOms >>> ", isConnect)
    state.isConnectOms = isConnect
  },
  sendOmsMessage(state, {apiCode, body}){      
    // console.log("sendOmsMessage >>>", apiCode)
    // console.log("sendOmsMessage >>>", body)

    if (!state.isConnectOms ) {
      console.log("OMS Connect Fail.")
      //window.$alert.alert('OMS 서버 연결 종료.')
      //this.notifyAlert({ group: 'notification', title: 'SUCCESS', text: 'OMS 서버 연결 종료.' })
      return
    }

    const reqData = {
      header: {
        apiCode : apiCode,
        token : state.sessionStorage,
      },
      body : body
    } 

    try{
      state.socketOms.send(JSON.stringify(reqData))
    }catch(e){
      // console.log("e >>> ", e, body)
    }
  },
  setChannelToOms(state,  {subscribeItem}){
    //console.log("setChannelToOms >>>> subscribeItem", subscribeItem)
    state.channelToOms.set(subscribeItem.subscriptionUID, subscribeItem)
  },
  delChannelToOms(state,  {subscribeItem}){
    //console.log("delChannelToOms >>>> subscribeItem", subscribeItem)
    state.channelToOms.delete(subscribeItem.subscriptionUID)
  },
  sendOmsHeartBeat(state) {
    //console.log("sendOmsHeartBeat ", state.sessionStorage)
    const reqData = {
      header: {
      apiCode : 'OMS0000',
      token : state.sessionStorage
      },
      body : {
      }
    }
    if (state.socketOms.readyState == 1){
      state.socketOms.send(JSON.stringify(reqData))
    }
  },
  setDisconnect(state){
    if (state.isConnectOms){
      state.socketOms.onclose = function () {}
      state.socketOms.close()
      state.isConnectOms = false
    }
    clearInterval(state.omsInterval)
  },
  setCompleteOrder(state,  {apiCode, body, notify, result}){
    let resultOrder = {apiCode:apiCode, body:body, notify:notify, result:result}
    state.resultOrder = resultOrder
  },
  setAbleQty(state, {data}){
    let ordAbleSellQty = 0;
    if (data != undefined && data.ordAbleSellQty != undefined) ordAbleSellQty = data.ordAbleSellQty;

    let ordAbleBuyQty = 0;
    if (data != undefined && data.ordAbleBuyQty != undefined) ordAbleBuyQty = data.ordAbleBuyQty;

    state.ordAble = Object.assign({}, {ordAbleSellQty:ordAbleSellQty, ordAbleBuyQty:ordAbleBuyQty})
  },
  setDailyPlMn(state, {data}){
    state.dailyPlMn = Object.assign({}, data)
  },
  setPosOrdStList(state, {data}){
    state.posOrdStList = Object.assign([], data)
  },
  setPopupCompleteOrder(state,  {data}){
    //console.log("setPopupCompleteOrder >>>> ", data);
    state.resultOrder = data;
  },
}

const actions = {
  //소켓 커넥션 생성
  setConectOms({commit, dispatch, state}, sessionStorage){
    // console.log("setConectOms >>>> ", sessionStorage)
    commit('setSessionStorage', {sessionStorage:sessionStorage.sessionStorage})
    const websocketPath = window.$store.VUE_APP_OMS_SOCKET_BASE_URL

    if(state.socketOms && state.socketOms.readyState == WebSocket.OPEN){       
        state.socketOms.close()
    }
    commit('setConectOms', {socketOms: new WebSocket(websocketPath)})
    state.socketOms.onopen = () => {
      // console.log("socketOms onopen ??", sessionStorage)
      commit('setIsConnectOms', {isConnect:true})
      clearInterval(state.omsInterval)
      if (state.socketOms.readyState != 1){
        return;
      }

      let subscriptionUID = sessionStorage.subscriptionUID

      if ( subscriptionUID == null ) {
        subscriptionUID = Utils.getUuidv4()
        //sessionStorage.setItem('subscriptionUID', subscriptionUID)
        sessionStorage.subscriptionUID = subscriptionUID
      }
  
      // 소켓 구독
      const subscribeItem = {
        subscriptionUID: subscriptionUID,
        data: new Array()
      }
  
      commit('setChannelToOms', {subscribeItem:subscribeItem})
      commit('sendOmsMessage', {apiCode:'OMS9999'})

      state.omsInterval = setInterval(() => {
          commit('sendOmsHeartBeat', {})
      }, 50000)      
    }

    state.socketOms.onmessage = (e) => {
      //console.log("onMessage?", e.data)
      const returnData = JSON.parse(e.data)

      if ( returnData.header.result == 'S' ) {
        dispatch('completeOrder', {apiCode:returnData.header.apiCode, body:returnData.body, result:returnData.header.result}) 
      } else {
        dispatch('procFailMessage', {apiCode:returnData.header.apiCode, body:returnData.body, result:returnData.header.result}) 
      }
    }

    state.socketOms.onerror = (e) => {
      commit('setIsConnectOms', {isConnect:false})
      console.error("[Error] socket Error")
    }

    state.socketOms.onclose = (e) => {
      commit('setIsConnectOms', {isConnect:false});
      // console.error("[OnClose] socket close")

      if (e.wasClean) {
        console.log('[OMS] WS Closed')
        clearInterval(state.omsInterval);
        state.connectMsgTimeout = setTimeout(() => {
          dispatch('setConectOms', {sessionStorage:state.sessionStorage});
        }, 5000);
      } else {
        console.log('[OMS] WS Connecting...')
        dispatch('setConectOms', {sessionStorage:state.sessionStorage})
      }

      // clearTimeout(state.connectOmsTimeout)
      // state.connectOmsTimeout = setTimeout(() => {
      //   dispatch('setConectOms', {sessionStorage:state.sessionStorage})
      // }, 1000)
    }
  },
  setUnSubscribeOnOms({commit, state}){
      // 소켓 구독 종료
    const subscriptionUID = sessionStorage.getItem('subscriptionUID')
    const data = state.channelToOms.get(subscriptionUID)
    if(data != null){
      if(state.isConnectOms){
        commit('sendOmsMessage', {apiCode:'OMS9998'})
      }
      commit('delChannelToOms', {subscriptionUID:subscriptionUID})
    }
  },
  sendOmsMessage({commit, state}, {apiCode, body}){
    commit('sendOmsMessage', {apiCode:apiCode, body:body})
  },
  completeOrder({commit, state}, {apiCode, body, result}){
    // console.log("completeOrder apiCode >>> ", apiCode)
    // console.log("completeOrder body >>> ", body)

    let notify = null
    

    // 주문
    if ( apiCode == 'OMS0001' ) {
      if (body.execYn == 'Y'){
        notify = {group: 'notification', title: 'SUCCESS', text:'[주문완료] 주문이 처리되었습니다.', body : body , apiCode:apiCode}
      }
    // 취소  
    } else if ( apiCode == 'OMS0002' ) {
      if ( body.execYn == 'Y' ) {
        notify = {group: 'notification', title: 'SUCCESS', text:'[취소완료] 주문이 처리되었습니다.', body : body , apiCode:apiCode}
      }
    // 정정
    } else if ( apiCode == 'OMS0003' ) {

      if ( body.execYn == 'Y' ) {
        notify = {group: 'notification', title: 'SUCCESS', text:'[정정완료] 주문이 처리되었습니다.', body : body , apiCode:apiCode}
      }
    // STOP 주문
    } else if (apiCode == 'OMS0004') {
      if ( body.execYn == 'Y' ) {
        //notify = {group: 'notification', title: 'SUCCESS', text:'[ST주문완료] 주문이 처리되었습니다.', body : body , apiCode:apiCode}
      }
    // STOP 취소
    }  else if ( apiCode == 'OMS0005') {
      if ( body.execYn == 'Y' ) {
        notify = {group: 'notification', title: 'SUCCESS', text:'[MIT취소완료] 주문이 처리되었습니다.', body : body , apiCode:apiCode}
      }
    // STOP 정정
    } else if ( apiCode == 'OMS0006') {
      if ( body.execYn == 'Y' ) {
        notify = {group: 'notification', title: 'SUCCESS', text:'[MIT정정완료] 주문이 처리되었습니다.', body : body , apiCode:apiCode}
      }
    // STOP LOSS 주문
    } else if ( apiCode == 'OMS0007') {
      //
    // STOP LOSS 취소 
    } else if ( apiCode == 'OMS0008') {
      //
    // 종목선택 취소
    } else if ( apiCode == 'OMS0010') {
      if ( body.execYn == 'Y' ) {
        notify = {group: 'notification', title: 'SUCCESS', text:'[취소완료] 주문이 처리되었습니다.', body : body , apiCode:apiCode}
      }
      // STOP 종목선택 취소
    } else if ( apiCode == 'OMS0011') {
      if ( body.execYn == 'Y' ) {
        notify = {group: 'notification', title: 'SUCCESS', text:'[MIT취소완료] 주문이 처리되었습니다.', body : body , apiCode:apiCode}
      }
    // 상단 일괄 취소 버튼
    } else if ( apiCode == 'OMS0012') {
      if ( body.execYn == 'Y' ) {
        notify = {group: 'notification', title: 'SUCCESS', text:'[취소완료] 주문이 처리되었습니다.', body : body , apiCode:apiCode}
      }
    // 전/현 종목 청산
    } else if ( apiCode == 'OMS0013') {
      if ( body.execYn == 'Y' ) {
        notify = {group: 'notification', title: 'SUCCESS', text:'[청산완료] 주문이 처리되었습니다.', body : body , apiCode:apiCode}
      }
    // PanelComp2 청산버튼 - 선택 종목의 보유포지션 청산
    } else if ( apiCode == 'OMS0014') {
      if ( body.execYn == 'Y' ) {
        notify = {group: 'notification', title: 'SUCCESS', text:'[청산완료] 주문이 처리되었습니다.', body : body , apiCode:apiCode}
        // this.notifyAlert({ group: 'notification', title: 'SUCCESS', text: '[청산완료] 주문이 처리되었습니다.' })
      }
    } else if ( apiCode == 'OMS0015'){ // 원주문 이미 처리..
    } else if ( apiCode == 'OMS0017'){ // RMS 청산
      notify = {group: 'notification', title: 'SUCCESS', text:'[시스템청산] RMS 청산이 처리 되었습니다.', body : body , apiCode:apiCode}
      //body.ordApiType 정정, 취소 구분
    } else if ( apiCode == 'OMS0018'){ // 전/현 종목 취소
      notify = {group: 'notification', title: 'SUCCESS', text:'[취소완료] 주문이 처리되었습니다.', body : body , apiCode:apiCode}
    } else if ( apiCode == 'OMS9997'){ //서버 상태 조회
        //console.log("OMS9997 >>>> ", body);
    } else if ( apiCode == 'OMS9995'){
      notify = {group: 'notification', title: 'SUCCESS', text:'[장마감] 장마감 청산이 완료되었습니다.', body : body , apiCode:apiCode}
    } else if ( apiCode == 'OMS9994'){
      notify = {group: 'notification', title: 'SUCCESS', text:'[취소완료] 익절/손절 자동 취소 되었습니다.', body : body , apiCode:apiCode}
    }

    commit('setCompleteOrder', {apiCode:apiCode, body:body, notify:notify, result:result})
  },
  //실패 메시지 호출
  procFailMessage({commit, state}, {apiCode, body, result}){
    // console.log("procFailMessage >>>>>>>>>")
    commit('setCompleteOrder', {apiCode:apiCode, body:body, notify:null, result:result})
  },
  //가능수량 조회
  selectAbleQty({commit, state}, {accId, symCd, symNo, lv}){
    return new Promise((resolve, reject) => {
      window.$http
        .get('/api/order/ord-able-qty', { params: {accId: accId, symCd: symCd, symNo:symNo, lv:lv} })
        .then(response => {
          if ( response ) {
            console.log("response ", response.data)
            if ( response.data !== null ) {
              commit('setAbleQty', {data:response.data})
            }
          }
          resolve()
        })
        .catch(e => reject(e))
    })   
  },

  //평가손익/잔고/가능수량등 1번에 조회
  async selectUsrData({commit, state}, {accId, symCd, symNo, lv}){
    return await new Promise((resolve, reject) => {
      window.$http
        .get('/api/order/ord-usr-data', { params: {accId: accId, symCd: symCd, symNo:symNo, lv:lv} })
        .then(response => {
          if ( response ) {
            if ( response.data !== null ) {
              commit('setAbleQty', {data:response.data.ordAble})
              commit('setDailyPlMn', {data:response.data.dailyPlMn})
              commit('setPosOrdStList', {data:response.data.posOrdStList})
            }
          }
          resolve()
        })
        .catch(e => reject(e))
    })   
  },
  
  //커넥션 종료
  setDisconnect({commit, state}){
    let subscriptionUID = sessionStorage.subscriptionUID

    if ( subscriptionUID == null ) {
      subscriptionUID = Utils.getUuidv4()
      //sessionStorage.setItem('subscriptionUID', subscriptionUID)
      sessionStorage.subscriptionUID = subscriptionUID
    }

    // 소켓 구독
    const subscribeItem = {
      subscriptionUID: subscriptionUID,
      data: new Array()
    }

    commit('delChannelToOms', {subscribeItem:subscribeItem})
    //commit('sendOmsMessage', {apiCode:'OMS9998'}) //구독해지

    commit("setDisconnect")
  },

  //사용자 로그 전송
  setLogMsg({commit, state}, {message} ){ //사용자 로그 설정
    let logOrder = {
      usrKey: Utils.getUuidv4(),
      message : message,
      time : Utils.getNowTime(),
    }

    //console.log("logOrder >>>> ",logOrder)
      //this.sendOmsMessage("OMS9991", logOrder);
    commit('sendOmsMessage', {apiCode:'OMS9991', body:logOrder})
  },

  //---팝업주문창 관련---
  setChannelData({commit, state}, {type, data}){
    if (type == "OMS"){
      //state.resultOrder = data;
      commit('setPopupCompleteOrder', {data:data});
    }else if (type == "POS_ORD"){
      commit('setPosOrdStList', {data:data})
    }else if (type == "DAILY"){
      commit('setDailyPlMn', {data:data})
    }
  },
  //---팝업주문창 관련---

}
  
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}