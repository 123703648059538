<template>
  <div style="width: 100%; height: 100%">
    <ag-grid-vue
      style="width: 100%; height: 100%"
      class="ag-theme-wts"
      :columnDefs="grid.columnDefs"
      :rowData="grid.rowData"
      :headerHeight="rowHeight"
			:rowHeight="rowHeight"
      @grid-ready="onGridReady"
    />
</div>
</template>

<script>
// import { ref } from 'vue';
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
// import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme
import { AgGridVue } from "ag-grid-vue"; // Vue Grid Logic
// eslint-disable-next-line no-unused-vars
import gridUtils from '@/modules/grid-utils';
import { stringCount } from '@/modules/helper';
import {buySellType, cellClassRulesMinus} from '@/modules/grid-utils.js';

export default {
  name: "AgRealTimeTable",
  components: {
    AgGridVue,
    //PaginationComp,
  },
  props:{
    setGridSearch:{
      type: Object
    },
    symbolList:{
      type: Array
    },
    memberValPlList:{
      type: Object
    }
  },
  computed:{
    //소켓시세
    changeOvcData: function(){
      let ovcData = window.$store.getters['SocketPrice/getOvcData']
      return ovcData;
    },
    //폴링시세
    changeOvcDataList: function(){
      let ovcData = window.$store.getters['SocketPrice/getOvcDataList']
      return ovcData;
    },
  },
  watch:{
    //소켓시세
    changeOvcData(newPrice){
      this.ovcMap.set(newPrice.symbol, newPrice);
      //this.setGridPrice(); //현재가, 평가손익 새로고침
    },
    //폴링시세
    changeOvcDataList(newPrice){
      if (newPrice != null && newPrice.length > 0) {
        newPrice.forEach(data=>{
          this.ovcMap.set(data.symbol, data);
          //this.setGridPrice(); //현재가, 평가손익 새로고침
        })
      }
    },
    setGridSearch:{
      handler(newVal){
        this.gridSearch = newVal;
        this.gridSearch.usrNm = newVal.userInfoNm;
        this.search();
      },
      deep:true
    },
    memberValPlList:{
      handler(newVal){       
        if (this.grid.rowData != null && this.grid.rowData.length > 0){
          for (let member of newVal.list){
            for (let data of this.grid.rowData){
              if (member.usrId == data.usrId){
                data.valPl = member.valPl;
                data.totValPl = Number(data.valPl) + Number(data.balMn);
              }
            }
          }
          this.gridApi.refreshCells(); //셀 새로고침
        }
      },
      deep:true,
    }
  },
  data() {
    return {
      ovcMap: new Map(),
      currencyInfo:{currency:"WON"},
      grid:{
        columnDefs: [
          { 
            headerName: "", 
            field: "num",
            minWidth: 40,
            flex: 0.3,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
            sortable : true ,
          },
          { 
            headerName: "아이디",
            field: "usrId",
            minWidth: 80,
            flex: 0.6,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
            sortable : true ,
          },
          { 
            headerName: "이름",
            field: "usrNm",
            minWidth: 80,
            flex: 0.6,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
            sortable : true ,
          },
          { 
            headerName: "실시간손익",
            field: "valPl",
            minWidth: 80,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
            sortable : true ,
            cellClassRules: cellClassRulesMinus,
            valueFormatter: gridUtils.numberComma
          },
          { 
            headerName: "실현손익",
            field: "plMn",
            minWidth: 80,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
            sortable : true ,
            cellClassRules: cellClassRulesMinus,
            valueFormatter: gridUtils.numberComma
          },
          { 
            headerName: "수수료",
            field: "cms",
            minWidth: 80,
            flex: 0.7,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
            sortable : true ,
            valueFormatter: gridUtils.numberComma
          },
          { 
            headerName: "평가담보금",
            field: "totValPl",
            minWidth: 110,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
            sortable : true ,
            cellClassRules: cellClassRulesMinus,
            valueFormatter: gridUtils.numberComma
          },
          { 
            headerName: "증거금",
            field: "balMn",
            minWidth: 110,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
            sortable : true ,
            valueFormatter: gridUtils.numberComma
          },
          // { 
          //   headerName: "접속상태",
          //   field: "접속상태",
          //   minWidth: 70,
          //   flex: 0.7,
          //   cellStyle: {
          //     justifyContent: 'center'
          //   },
          //   headerClass: "center-align-header",
          //   suppressMovable: true,
          // },
          { 
            headerName: "RMS",
            field: "rmsTm",
            minWidth: 70,
            flex: 0.7,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
            sortable : true ,
          },
          
        ],
        rowData: [],
        autoSizeStrategy: null,
        paginationPageSize: null,
        rowHeight: '',
      },
      gridApi: null,
      gridSearch: null,
    };
  },
	created() {
    this.paginationPageSize = 12;
		this.isMobile();
  },
  mounted(){

  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
    },
		isMobile() {
      if (window.innerWidth <= 1200) {
        return this.rowHeight = 40;
      } else {
        return this.rowHeight = 30;
      }
    },
    async search() {
      this.grid.rowData = [];
      if (this.gridSearch.usrId == ""){return}
      const response = await this.$lhttp.post('/api/partner/usliveballist', this.gridSearch)

      if ( response && response.data ) {
        if (response.data.length > 0){
          this.grid.rowData = [];
          let totData = {totBalMn:0, totPlMn:0, totalCms:0};
          let totBalMn = 0;
          let totPlMn = 0;
          let totalCms = 0;
          response.data.forEach(data=>{
            //실시간 
            data.valPl = 0; //실시간손익
            data.totValPl = 0; //평가담보금
            data.nowPrice = 0; //현재시세

            totBalMn += Number(data.balMn);
            totalCms += Number(data.cms);
            totPlMn += Number(data.plMn);


            this.grid.rowData.push(data);
          })

          totData.totBalMn = totBalMn; //증거금
          totData.totCms  = totalCms; //수수료
          totData.totPlMn = totPlMn; //실현손익

          this.$emit("updateTotalValPl", totData);
        }else{
          this.grid.rowData = [];
        }

        

        //this.setGridPrice(); //실시간 계산
      }
    },
    setGridPrice(){
      
      if (this.grid.rowData != null && this.grid.rowData.length > 0){


        this.grid.rowData.forEach(data => {
          if (data.balQty > 0 && this.symbolList.length > 0){

            //
          }
        });
        this.gridApi.refreshCells(); //셀 새로고침


        //필요한정보 추가 후 상위페이지로 전달
      }



      
    },
    tikVal(symbol){
      if (symbol == null){
        return 0;
      }
      return (symbol.minmov / symbol.pricescale);
    },
    tikDotSz(symbol){
      if (symbol == null){
        return 0;
      }
      return stringCount(symbol.pricescale);
    },
  },
};


</script>

<style lang="scss" scoped>
@import '@/assets/scss/agGridStyles.scss';
</style>