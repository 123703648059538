<template>
  <div class="mobile-container">
    <div class="mobile-sidemenu-container" :class="{active: showSide}">
      <div class="bg" @click="showSide = false"></div>
      <div class="mobile-sidemenu" :class="{active: showSide}">
        <ul>
          <li @click="showSlideMenu('showAccountSheet')" v-if="getLoginUserInfo != undefined && getLoginUserInfo.authId != undefined">
            <div class="inner">
              <img src="@/assets/icons/account-black.svg" alt="">
              계좌
            </div>
            <img src="@/assets/icons/chevron-right-gray.svg" alt="">
          </li>
          <li @click="showSlideMenu('showDepositSheet')" v-if="getLoginUserInfo != undefined && getLoginUserInfo.authId != undefined">
            <div class="inner">
              <img src="@/assets/icons/monitor-black.svg" alt="">
              입출금
            </div>
            <img src="@/assets/icons/chevron-right-gray.svg" alt="">
          </li>
          <li @click="showSide = false; showModal('modalSettings')" v-if="getLoginUserInfo != undefined && getLoginUserInfo.authId != undefined">
            <div class="inner">
              <img src="@/assets/icons/settings-black.svg" alt="">
              환경설정
            </div>
          </li>
          <li @click="showSide = false; showModal('modalNotice')" v-if="getLoginUserInfo != undefined && getLoginUserInfo.authId != undefined">
            <div class="inner">
              <img src="@/assets/icons/notice-black.svg" alt="">
              공지사항
            </div>
          </li>
          <div class="division-line"></div>
          <li @click="showSide = false; showModal('modalLogout')" v-if="getLoginUserInfo != undefined && getLoginUserInfo.authId != undefined">
            <div class="inner">
              <img src="@/assets/icons/logout-black.svg" alt="">
              로그아웃
            </div>
          </li>
          <li @click="showSide = false; goLogin" v-if="getLoginUserInfo == undefined || getLoginUserInfo.authId == undefined">
            <router-link to="/" v-if="getLoginUserInfo == undefined || getLoginUserInfo.authId == undefined">
            <div class="inner">
              <img src="@/assets/icons/logout-black.svg" alt="">
              로그인
            </div>
          </router-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="mobile-header">
      <div class="left">
        <div class="avatar" @click="showSide = true">
          <!-- <AvatarComp :width="20" :height="20" :fontSize="12" /> -->
          <img src="@/assets/icons/menu-mobile.svg" alt=""  />
        </div>

        <div class="instrument" @click="showModal('modalInstruments')">
          <img src="@/assets/icons/search-black.svg" alt="">
          <h3>{{symbol.description}} {{ symbol.secMt != undefined ? ' ' + symbol.secMt : '' }}</h3>
        </div>
      </div>
      <div class="right">
        <div class="rgt_login">
          <router-link to="/" v-if="getLoginUserInfo == undefined || getLoginUserInfo.authId == undefined">
            <img src="@/assets/icons/logout-black.svg" alt="" class="rgt_img" />
            <h3 style="padding-top:3px;">로그인</h3>
          </router-link>
        </div>
        <div v-if="getLoginUserInfo != undefined && getLoginUserInfo.authId != undefined && this.orderFullSize">
          <button class="btn-primary blue" @click="setOrderSize">주문창축소</button>
        </div>
      </div>
    </div>
    <!-- <div class="mobile-chart-container" :style="{ height: chartHeight + 'px' }">chart here</div> -->
    <div class="mobile-inner-container">
        <div class="mobile-tab-container" v-show="!this.orderFullSize">
          <button v-show="tabName[0].useYn" class="mobile-tab" :class="{ active: mobileSelectedTab === 'clickOrder' }" @click="changeSelectedTab('clickOrder')" v-if="getLoginUserInfo != undefined && getLoginUserInfo.authId != undefined">
            {{ tabName[0].name }}
          </button>
          <button v-show="tabName[1].useYn" class="mobile-tab" :class="{ active: mobileSelectedTab === 'mit' }" @click="changeSelectedTab('mit')" v-if="getLoginUserInfo != undefined && getLoginUserInfo.authId != undefined">
            {{ tabName[1].name }}
          </button>
          <button v-show="tabName[2].useYn" class="mobile-tab" :class="{ active: mobileSelectedTab === 'standardOrder' }" @click="changeSelectedTab('standardOrder')" v-if="getLoginUserInfo != undefined && getLoginUserInfo.authId != undefined">
            {{ tabName[2].name }}
          </button>
          <button v-show="tabName[3].useYn" class="mobile-tab" :class="{ active: mobileSelectedTab === 'balance' }" @click="changeSelectedTab('balance')" v-if="getLoginUserInfo != undefined && getLoginUserInfo.authId != undefined">
            {{ tabName[3].name }}
            </button>
          <button v-show="tabName[4].useYn" class="mobile-tab" :class="{ active: mobileSelectedTab === 'filled' }" @click="changeSelectedTab('filled')" v-if="getLoginUserInfo != undefined && getLoginUserInfo.authId != undefined">
            {{ tabName[4].name }}
          </button>
          <button v-show="tabName[5].useYn" class="mobile-tab" :class="{ active: mobileSelectedTab === 'chart' }" @click="changeSelectedTab('chart')">
            {{ tabName[5].name }}
          </button>
        </div>
        <div class="mobile-click-order" v-if="mobileSelectedTab === 'clickOrder'" >
          <div class="mobile-order-row" v-show="!this.orderFullSize">
            <div class="inner">
              <label for="" >주문수량</label>
              <input type="number" v-model="ordClick" class="order-count">
              <button class="btn-line counter-btn" @click="minusSellOrd"><img src="@/assets/icons/minus-black.svg" alt=""></button>
              <button class="btn-line counter-btn" @click="addSellOrd"><img src="@/assets/icons/plus-black.svg" alt=""></button>
              <button class="btn-primary blue" @click="setOrderSize">주문창확대</button>
              <!-- <label for="" >가능</label>
              <button class="btn-line blue available">999</button>
              <button class="btn-line red available">999</button> -->
            </div>
          </div>

          <div class="mobile-order-row" v-show="!this.orderFullSize">
            <div class="inner">
              <button class="btn-line blue order-btn" @click="sellMarket(null)">매도</button>
              <input type="number" :value="sellAbleQty" class="order-count" readonly="readonly" />
              <button class="btn-line red order-btn" @click="buyMarket(null)">매수</button>
              <input type="number" :value="buyAbleQty" class="order-count" readonly="readonly" />
              <div v-if="getLoginUserInfo.lvTpCd != undefined && getLoginUserInfo.lvTpCd == 'Y'">
                <!-- <CodeDropDown width="63" cdHeight="40" grpCd="LVTP" v-model="leverage" height="200" popup @input="selectLeverage" /> -->
                <BaseDropdown id="leverageCd" cdHeight="40" :options="leverageList" v-model="leverage" @leverageCd="selectLeverage" />
              </div>
              
            </div>
          </div>

          <div class="mobile-sub-order-row" v-show="!this.orderFullSize">
            <button class="btn-line blue cancel" @click="cancelSellAll">취소</button>
            <button class="btn-line" @click="clearOrderData('all')">전종목 청산</button>
            <button class="btn-line" @click="clearOrderData('symbol')">현종목 청산</button>
            <button class="btn-line red cancel" @click="cancelBuyAll" >취소</button>
          </div>

          <div class="mobile-dom-container">
            <DomGridClick
              :account="account"
              :selectOrderData="selectOrderData"
              :ordClick="ordClick"
              :avgPrice="avgPrice"
              :mobileSet="mobileSet"
              :rStock="rStock"
              :orderFullSize="orderFullSize"
              :scrollMoveRowCount="1"
              :slStockVal1="slStockVal1"
              :slStockVal2="slStockVal2"
              :buySlTick="buySlTick"
              :sellSlTick="sellSlTick"
            />
          </div>
          <div class="mobile-ag-container moTap1" v-if="!orderFullSize && tap1AuthShow.posList">
            <div class="ag-container">
              <AgPositionTable
              :account="account"
              @updateTotalValPl="updateTotalValPl"
              @updateSubscribeOnPrice="updateRtPosSymbol"
              />
            </div>
          </div>
        </div>

        <div class="mobile-mit-order" v-if="mobileSelectedTab === 'mit'">
          <div class="mobile-order-row" v-show="!this.orderFullSize">
            <div class="inner">
              <label for="" >주문수량</label>
              <input type="number" v-model="ordClick" class="order-count">
              <button class="btn-line counter-btn" @click="minusSellOrd"><img src="@/assets/icons/minus-black.svg" alt=""></button>
              <button class="btn-line counter-btn" @click="addSellOrd"><img src="@/assets/icons/plus-black.svg" alt=""></button>
              <button class="btn-primary blue" @click="setOrderSize">주문창확대</button>
              <!-- <label for="" >가능</label>
              <button class="btn-line blue available">999</button>
              <button class="btn-line red available">999</button> -->
            </div>
          </div>

          <div class="mobile-order-row" v-show="!this.orderFullSize">
            <div class="inner">
              <button class="btn-line blue order-btn" @click="sellMarket(null)">매도</button>
              <input type="number" :value="sellAbleQty" class="order-count" readonly="readonly" />
              <button class="btn-line red order-btn" @click="buyMarket(null)">매수</button>
              <input type="number" :value="buyAbleQty" class="order-count" readonly="readonly" />
              <div v-if="getLoginUserInfo.lvTpCd != undefined && getLoginUserInfo.lvTpCd == 'Y'">
                <!-- <CodeDropDown width="63" cdHeight="40" grpCd="LVTP" v-model="leverage" height="200" popup @input="selectLeverage" /> -->
                <BaseDropdown id="leverageCd" cdHeight="40" :options="leverageList" v-model="leverage" @leverageCd="selectLeverage" />
              </div>
            </div>
          </div>

          <div class="mobile-sub-order-row" v-show="!this.orderFullSize">
            <button class="btn-line blue cancel" @click="cancelSellStAll">취소</button>
            <button class="btn-line" @click="clearOrderData('all')">전종목 청산</button>
            <button class="btn-line" @click="clearOrderData('symbol')">현종목 청산</button>
            <button class="btn-line red cancel" @click="cancelBuyStAll" >취소</button>
          </div>
          <div class="mobile-dom-container">
            <DomGridClick
              :account="account"
              :selectOrderData="selectOrderData"
              :ordClick="ordClick"
              :avgPrice="avgPrice"
              :mobileSet="mobileSet"
              :rStock="rStock"
              :orderFullSize="orderFullSize"
              :scrollMoveRowCount="1"
              :slStockVal1="slStockVal1"
              :slStockVal2="slStockVal2"
              :buySlTick="buySlTick"
              :sellSlTick="sellSlTick"
            />
          </div>
        </div>

        <div class="mobile-standard-order" v-if="mobileSelectedTab === 'standardOrder'">
          <div class="mini-dom">
            <!-- <div class="item header">
              <div class="checkbox-default">
                <input type="checkbox" id="fix" value="" />
                <label for="fix">고정</label>
              </div>
            </div> -->
            <div class="item header">가격</div>
            <div class="item header">잔량</div>
            <div class="item header"></div>
            <div class="item sell" @click="selectStdPrc(ovhData.offerHo5)" :class="{currentPrice: nowPrice.curPr == ovhData.offerHo5 && ovhData.offerHo5 > 0, '': nowPrice.curPr != ovhData.offerHo5}">{{ ovhData.offerHo5.toFixed(tikDotSz)}}</div>
            <div class="item sellCnt"> {{ Number(ovhData.offerRem5).toLocaleString() }}</div>
            <div class="item sl" :class="{stopBuyPrice: buyAvgPrice.avgPrice > 0 && Number(buyAvgPrice.avgPrice) == Number(ovhData.offerHo5) && buyAvgPrice.cssTopBottom == 'bottom'
              , stopSellPrice: sellAvgPrice.avgPrice > 0 && Number(sellAvgPrice.avgPrice) == Number(ovhData.offerHo5) && sellAvgPrice.cssTopBottom == 'top'
              , '' : buyAvgPrice.avgPrice != ovhData.offerHo5 && sellAvgPrice.avgPrice != ovhData.offerHo5}"></div>

            <div class="item sell" @click="selectStdPrc(ovhData.offerHo4)" :class="{currentPrice: nowPrice.curPr == ovhData.offerHo4 && ovhData.offerHo4 > 0, '': nowPrice.curPr != ovhData.offerHo4}">{{ ovhData.offerHo4.toFixed(tikDotSz)}}</div>
            <div class="item sellCnt">{{ Number(ovhData.offerRem4).toLocaleString() }}</div>
            <div class="item sl" :class="{stopBuyPrice: buyAvgPrice.avgPrice > 0 && Number(buyAvgPrice.avgPrice) == Number(ovhData.offerHo4) && buyAvgPrice.cssTopBottom == 'bottom'
              , stopSellPrice: sellAvgPrice.avgPrice > 0 && Number(sellAvgPrice.avgPrice) == Number(ovhData.offerHo4) && sellAvgPrice.cssTopBottom == 'top'
              , '' : buyAvgPrice.avgPrice != ovhData.offerHo4 && sellAvgPrice.avgPrice != ovhData.offerHo4}"></div>

            <div class="item sell" @click="selectStdPrc(ovhData.offerHo3)" :class="{currentPrice: nowPrice.curPr == ovhData.offerHo3 && ovhData.offerHo3 > 0, '': nowPrice.curPr != ovhData.offerHo3}">{{ ovhData.offerHo3.toFixed(tikDotSz)}}</div>
            <div class="item sellCnt">{{ Number(ovhData.offerRem3).toLocaleString() }}</div>
            <div class="item sl" :class="{stopBuyPrice: buyAvgPrice.avgPrice > 0 && Number(buyAvgPrice.avgPrice) == Number(ovhData.offerHo3) && buyAvgPrice.cssTopBottom == 'bottom'
              , stopSellPrice: sellAvgPrice.avgPrice > 0 && Number(sellAvgPrice.avgPrice) == Number(ovhData.offerHo3) && sellAvgPrice.cssTopBottom == 'top'
              , '' : buyAvgPrice.avgPrice != ovhData.offerHo3 && sellAvgPrice.avgPrice != ovhData.offerHo3}"></div>

            <div class="item sell" @click="selectStdPrc(ovhData.offerHo2)" :class="{currentPrice: nowPrice.curPr == ovhData.offerHo2 && ovhData.offerHo2 > 0, '': nowPrice.curPr != ovhData.offerHo2}">{{ ovhData.offerHo2.toFixed(tikDotSz)}}</div>
            <div class="item sellCnt">{{ Number(ovhData.offerRem2).toLocaleString() }}</div>
            <div class="item sl" :class="{stopBuyPrice: buyAvgPrice.avgPrice > 0 && Number(buyAvgPrice.avgPrice) == Number(ovhData.offerHo2) && buyAvgPrice.cssTopBottom == 'bottom'
              , stopSellPrice: sellAvgPrice.avgPrice > 0 && Number(sellAvgPrice.avgPrice) == Number(ovhData.offerHo2) && sellAvgPrice.cssTopBottom == 'top'
              , '' : buyAvgPrice.avgPrice != ovhData.offerHo2 && sellAvgPrice.avgPrice != ovhData.offerHo2}"></div>

            <div class="item sell" @click="selectStdPrc(ovhData.offerHo1)" :class="{currentPrice: nowPrice.curPr == ovhData.offerHo1 && ovhData.offerHo1 > 0, '': nowPrice.curPr != ovhData.offerHo1}">{{ ovhData.offerHo1.toFixed(tikDotSz)}}</div>
            <div class="item sellCnt">{{ Number(ovhData.offerRem1).toLocaleString() }}</div>
            <div class="item sl" :class="{stopBuyPrice: buyAvgPrice.avgPrice > 0 && Number(buyAvgPrice.avgPrice) == Number(ovhData.offerHo1) && buyAvgPrice.cssTopBottom == 'bottom'
              , stopSellPrice: sellAvgPrice.avgPrice > 0 && Number(sellAvgPrice.avgPrice) == Number(ovhData.offerHo1) && sellAvgPrice.cssTopBottom == 'top'
              , '' : buyAvgPrice.avgPrice != ovhData.offerHo1 && sellAvgPrice.avgPrice != ovhData.offerHo1}"></div>


            <div class="item buy" @click="selectStdPrc(ovhData.bidHo1)" :class="{currentPrice: nowPrice.curPr == ovhData.bidHo1 && ovhData.bidHo1 > 0, sellColor: nowPrice.curPr != ovhData.bidHo1}">{{ ovhData.bidHo1.toFixed(tikDotSz)}}</div>
            <div class="item buyCnt">{{ Number(ovhData.bidRem1).toLocaleString()}}</div>
            <div class="item sl" :class="{stopBuyPrice: buyAvgPrice.avgPrice > 0 && Number(buyAvgPrice.avgPrice) == Number(ovhData.bidHo1) && buyAvgPrice.cssTopBottom == 'top'
              , stopSellPrice: sellAvgPrice.avgPrice > 0 && Number(sellAvgPrice.avgPrice) == Number(ovhData.bidHo1) && sellAvgPrice.cssTopBottom == 'bottom'
              , '' : buyAvgPrice.avgPrice != ovhData.bidHo1 && sellAvgPrice.avgPrice != ovhData.bidHo1}"></div>

            <div class="item buy" @click="selectStdPrc(ovhData.bidHo2)" :class="{currentPrice: nowPrice.curPr == ovhData.bidHo2 && ovhData.bidHo2 > 0, sellColor: nowPrice.curPr != ovhData.bidHo2}">{{ ovhData.bidHo2.toFixed(tikDotSz)}}</div>
            <div class="item buyCnt">{{ Number(ovhData.bidRem2).toLocaleString()}}</div>
            <div class="item sl" :class="{stopBuyPrice: buyAvgPrice.avgPrice > 0 && Number(buyAvgPrice.avgPrice) == Number(ovhData.bidHo2) && buyAvgPrice.cssTopBottom == 'top'
              , stopSellPrice: sellAvgPrice.avgPrice > 0 && Number(sellAvgPrice.avgPrice) == Number(ovhData.bidHo2) && sellAvgPrice.cssTopBottom == 'bottom'
              , '' : buyAvgPrice.avgPrice != ovhData.bidHo2 && sellAvgPrice.avgPrice != ovhData.bidHo2}"></div>

            <div class="item buy" @click="selectStdPrc(ovhData.bidHo3)" :class="{currentPrice: nowPrice.curPr == ovhData.bidHo3 && ovhData.bidHo3 > 0, sellColor: nowPrice.curPr != ovhData.bidHo3}">{{ ovhData.bidHo3.toFixed(tikDotSz)}}</div>
            <div class="item buyCnt">{{ Number(ovhData.bidRem3).toLocaleString()}}</div>
            <div class="item sl" :class="{stopBuyPrice: buyAvgPrice.avgPrice > 0 && Number(buyAvgPrice.avgPrice) == Number(ovhData.bidHo3) && buyAvgPrice.cssTopBottom == 'top'
              , stopSellPrice: sellAvgPrice.avgPrice > 0 && Number(sellAvgPrice.avgPrice) == Number(ovhData.bidHo3) && sellAvgPrice.cssTopBottom == 'bottom'
              , '' : buyAvgPrice.avgPrice != ovhData.bidHo3 && sellAvgPrice.avgPrice != ovhData.bidHo3}"></div>

            <div class="item buy" @click="selectStdPrc(ovhData.bidHo4)" :class="{currentPrice: nowPrice.curPr == ovhData.bidHo4 && ovhData.bidHo4 > 0, sellColor: nowPrice.curPr != ovhData.bidHo4}">{{ ovhData.bidHo4.toFixed(tikDotSz)}}</div>
            <div class="item buyCnt">{{ Number(ovhData.bidRem4).toLocaleString()}}</div>
            <div class="item sl" :class="{stopBuyPrice: buyAvgPrice.avgPrice > 0 && Number(buyAvgPrice.avgPrice) == Number(ovhData.bidHo4) && buyAvgPrice.cssTopBottom == 'top'
              , stopSellPrice: sellAvgPrice.avgPrice > 0 && Number(sellAvgPrice.avgPrice) == Number(ovhData.bidHo4) && sellAvgPrice.cssTopBottom == 'bottom'
              , '' : buyAvgPrice.avgPrice != ovhData.bidHo4 && sellAvgPrice.avgPrice != ovhData.bidHo4}"></div>

            <div class="item buy" @click="selectStdPrc(ovhData.bidHo5)" :class="{currentPrice: nowPrice.curPr == ovhData.bidHo5 && ovhData.bidHo5 > 0, sellColor: nowPrice.curPr != ovhData.bidHo5}">{{ ovhData.bidHo5.toFixed(tikDotSz)}}</div>
            <div class="item buyCnt">{{ Number(ovhData.bidRem5).toLocaleString()}}</div>
            <div class="item sl" :class="{stopBuyPrice: buyAvgPrice.avgPrice > 0 && Number(buyAvgPrice.avgPrice) == Number(ovhData.bidHo5) && buyAvgPrice.cssTopBottom == 'top'
              , stopSellPrice: sellAvgPrice.avgPrice > 0 && Number(sellAvgPrice.avgPrice) == Number(ovhData.bidHo5) && sellAvgPrice.cssTopBottom == 'bottom'
              , '' : buyAvgPrice.avgPrice != ovhData.bidHo5 && sellAvgPrice.avgPrice != ovhData.bidHo5}"></div>

          </div>
          <div class="mobile-standard-order-ui">
            <div class="mobile-standard-order-tab">
              <button class="tab" :class="{ active: mobileSelectedStandardOrder === 'sell' }" @click="mobileSelectedStandardOrder = 'sell'">
                매도
              </button>
              <button class="tab" :class="{ active: mobileSelectedStandardOrder === 'buy' }" @click="mobileSelectedStandardOrder = 'buy'">
                매수
              </button>
              <button v-if="tap3AuthShow.stopLoss" class="tab" :class="{ active: mobileSelectedStandardOrder === 'stopLoss' }" @click="mobileSelectedStandardOrder = 'stopLoss'">
                스탑로스
              </button>
              <!-- <button class="tab" :class="{ active: mobileSelectedStandardOrder === 'modify' }" @click="mobileSelectedStandardOrder = 'modify'">
                정정
              </button> -->
            </div>

            <div class="mobile-standard-order-content" v-show="mobileSelectedStandardOrder === 'sell'">
              <div class="radio-group">
                <div class="radio-default">
                  <input type="radio" id="sellOrder" name="sellOrderType" value="1" v-model="sellOrderType" />
                  <label for="sellOrder">지정</label>
                </div>
                <div class="radio-default">
                  <input type="radio" id="sellMarket" name="sellOrderType" value="2" v-model="sellOrderType" />
                  <label for="sellMarket">시장</label>
                </div>
                <div class="radio-default" v-if="tap3AuthShow.mit">
                  <input type="radio" id="sellStopOrd" name="sellOrderType" value="stop" v-model="sellOrderType" />
                  <label for="sellStopOrd">예약</label>
                </div>
              </div>
              <div class="white-label-table-container">
                <table class="white-label-table">
                  <tbody>
                    <tr>
                      <th>주문가능수량</th>
                      <td>{{ sellAbleQty }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="mobile-row">
                <input type="number" v-model="sellOrdQty" maxlength="3" @change="chgSellOrdQty" />
                <button class="btn-line" @click="addSellQty">+</button>
                <button class="btn-line" @click="minusSellQty">-</button>
              </div>
              <div class="mobile-row">
                <input type="text" placeholder="가격" v-model="sellOrdPrc" @change="chgSellOrdPrc" />
                <button class="btn-line" @click="addSellPrc">+</button>
                <button class="btn-line" @click="minusSellPrc">-</button>
              </div>
              <div class="mobile-row">
                <button class="btn-line blue" @click="sellOrder">매도</button>
              </div>
              <div class="division-line"></div>
              <div class="white-label-table-container">
                <table class="white-label-table">
                  <tbody>
                    <tr>
                      <th>매도평균</th>
                      <td>{{ sellAvgPrc.toFixed(tikDotSz) }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="mobile-row">
                <CounterComp :maxCnt="200" :maxLength=3 @childEvent="setClickOrd" v-bind:value="ordClick" />
                <button class="btn-line" @click="setOrdClick(clickSetList[0])">{{ clickSetList[0] }}</button>
                <button class="btn-line" @click="setOrdClick(clickSetList[1])">{{ clickSetList[1] }}</button>
                <button class="btn-line" @click="setOrdClick(clickSetList[2])">{{ clickSetList[2] }}</button>
              </div>
            </div>
            <div class="mobile-standard-order-content" v-if="mobileSelectedStandardOrder === 'buy'">
              <div class="radio-group">
                <div class="radio-default">
                  <input type="radio" id="buyOrder" name="buyOrderType" value="1" v-model="buyOrderType" />
                  <label for="buyOrder">지정</label>
                </div>
                <div class="radio-default">
                  <input type="radio" id="buyMarket" name="buyOrderType" value="2" v-model="buyOrderType" />
                  <label for="buyMarket">시장</label>
                </div>
                <div class="radio-default">
                  <input type="radio" id="buyStopOrd" name="buyOrderType" value="stop" v-model="buyOrderType" />
                  <label for="buyStopOrd">예약</label>
                </div>
              </div>
              <div class="white-label-table-container">
                <table class="white-label-table">
                  <tbody>
                    <tr>
                      <th>주문가능수량</th>
                      <td>{{ buyAbleQty }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="mobile-row">
                <input type="number" placeholder="수량" v-model="buyOrdQty" maxlength="3" @change="chgBuyOrdQty" />
                <button class="btn-line" @click="addBuyQty">+</button>
                <button class="btn-line" @click="minusBuyQty">-</button>
              </div>
              <div class="mobile-row">
                <input type="number" placeholder="가격" v-model="buyOrdPrc" @change="chgBuyOrdPrc" />
                <button class="btn-line" @click="addBuyPrc">+</button>
                <button class="btn-line" @click="minusBuyPrc">-</button>
              </div>
              <div class="mobile-row">
                <button class="btn-line red" @click="buyOrder">매수</button>
              </div>
              <div class="division-line"></div>
              <div class="white-label-table-container">
                <table class="white-label-table">
                  <tbody>
                    <tr>
                      <th>매수평균</th>
                      <td>{{ buyAvgPrc.toFixed(tikDotSz) }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="mobile-row">
                <CounterComp :maxCnt="200" :maxLength=3 @childEvent="setClickOrd" v-bind:value="ordClick" />
                <button class="btn-line" @click="setOrdClick(clickSetList[0])">{{ clickSetList[0] }}</button>
                <button class="btn-line" @click="setOrdClick(clickSetList[1])">{{ clickSetList[1] }}</button>
                <button class="btn-line" @click="setOrdClick(clickSetList[2])">{{ clickSetList[2] }}</button>
              </div>
            </div>
            <div class="mobile-standard-order-content" v-show="mobileSelectedStandardOrder === 'stopLoss'">
              <!-- <div class="radio-group">
                <div class="radio-default">
                  <input type="radio" id="sl1" name="stopLossOrderType" value="1" v-model="stopLossType" />
                  <label for="sl1">손절</label>
                </div>
                <div class="radio-default">
                  <input type="radio" id="sl2" name="stopLossOrderType" value="2" v-model="stopLossType" />
                  <label for="sl2">익절</label>
                </div>
              </div> -->
              <div class="mobile-row">
                <div class="checkbox-default">
                  <input type="checkbox" id="stopProfit" name="stopProfit" v-bind:checked="slStockVal2 == true" @click="sellSlStock" />
                  <label for="stopProfit">손절</label>
                </div>
                <input type="Number" v-model="sellSlTick" maxlength="3" @change="chgSellSlTick" :disabled="slStockVal2" />
                <button class="btn-line" @click="slStockVal2 == true ? alertDisabled() : addSellSlTick()">+</button>
                <button class="btn-line" @click="slStockVal2 == true ? alertDisabled() : minusSellTick()">-</button>
              </div>
              <div class="mobile-row">
                <div class="checkbox-default">
                  <input type="checkbox" id="takeProfit" name="takeProfit" v-bind:checked="slStockVal1 == true" @click="buySlStock" />
                  <label for="takeProfit">익절</label>
                </div>
                <input type="number" v-model="buySlTick" maxlength="3" @change="chgBuySlTick" :disabled="slStockVal1" />
                <button class="btn-line" @click="slStockVal1 == true ? alertDisabled() : addBuySlTick()">+</button>
                <button class="btn-line" @click="slStockVal1 == true ? alertDisabled() : minusBuySlTick()">-</button>
              </div>
              <!-- 손절 {{ buyAvgPrice }}<br/>
              익절 {{sellAvgPrice }} -->
              
            </div>
            <div class="mobile-standard-order-content" v-if="mobileSelectedStandardOrder === 'modify'">
              <div class="white-label-table-container">
                <table class="white-label-table">
                  <tbody>
                    <tr>
                      <th>원주문</th>
                      <td><span v-if="orgOrder.orgOrdNo != 0 ">{{ orgOrder.orgOrdNo }}</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="mobile-row">
                <input type="number" placeholder="가격" v-model="orgOrder.ordPrc" @change="chgModifyOrdPrc" />
                <button class="btn-line" @click="addModifyPrc">+</button>
                <button class="btn-line" @click="minusModifyPrc">-</button>
              </div>
              <div class="mobile-row">
                <button class="btn-line variant" @click="modifyPrc">정정</button>
              </div>
            </div>
          </div>
        </div>

        <div class="mobile-balance" v-show="mobileSelectedTab === 'balance'" v-if="getLoginUserInfo != undefined && getLoginUserInfo.authId != undefined">
          <div class="white-label-table-container">
            <table class="white-label-table">
              <tbody>
                <tr>
                  <th>평가담보금</th>
                  <td><span :class="{ 'color-red' : dailyPlMn.balMn > 0, 'color-blue' : dailyPlMn.balMn < 0 }">{{ thousand(Number(dailyPlMn.balMn) + Number(dailyPlMn.totalValPl)) }}</span></td>
                  <th>로스컷</th>
                  <td>{{ thousand(dailyPlMn.totalLossCut) }}</td>
                </tr>
                <tr>
                  <th>실현손익</th>
                  <td><span :class="{ 'color-red' : dailyPlMn.totNPlMn > 0, 'color-blue' : dailyPlMn.totNPlMn < 0 }">{{ thousand(dailyPlMn.totNPlMn) }}</span></td>
                  <th>총평가손익</th>
                  <td><span :class="{ 'color-red' : dailyPlMn.totalValPl > 0, 'color-blue' : dailyPlMn.totalValPl < 0 }">{{ thousand(dailyPlMn.totalValPl) }}</span></td>
                </tr>
                <tr>
                  <th>보유담보금</th>
                  <td>{{ thousand(dailyPlMn.balMn) }}</td>
                  <th>총수수료</th>
                  <td><span :class="{ 'color-red' : dailyPlMn.fxCms > 0, 'color-blue' : dailyPlMn.fxCms < 0 }">{{ thousand(dailyPlMn.fxCms) }}</span></td>
                </tr>
                <!-- <tr>
                  <th>평가손익</th>
                  <td><span :class="{ 'color-red' : symbolPlMn.valPl > 0, 'color-blue' : symbolPlMn.valPl < 0 }">{{ thousand(symbolPlMn.valPl) }}</span></td>
                  <th>평균단가</th>
                  <td><span :class="{ 'color-red' : symbolPlMn.plMn > 0, 'color-blue' : symbolPlMn.plMn < 0 }">{{ thousand(symbolPlMn.plMn) }}</span></td>
                </tr> -->
              </tbody>
            </table>
          </div>

          <div class="mobile-ag-container balance">
            <div class="mobile-ag-header">
              잔고
              <!-- <button class="btn-primary darkgray">청산</button> -->
            </div>
            <div class="ag-container">
              <!-- <AgPositionTable></AgPositionTable> -->
              <AgPositionTable
              :account="account"
              @updateTotalValPl="updateTotalValPl"
              @updateSubscribeOnPrice="updateRtPosSymbol"
              />
            </div>
          </div>

          <!-- <div class="mobile-ag-container">
            <div class="mobile-ag-header">
              미체결
              <div class="inner">
                <button class="btn-primary darkgray">일괄취소</button>
                <button class="btn-primary darkgray">개별정정</button>
              </div>
            </div>
            <div class="ag-container">
              <AgPositionTable></AgPositionTable>
            </div>
          </div> -->
        </div>

        <div class="mobile-filled" v-if="mobileSelectedTab === 'filled'">
          <div class="ag-container">
            <AgFilledTable />
          </div>
        </div>

        <div class="mobile-chart" v-show="mobileSelectedTab === 'chart'">
          <chartBox :symbol="symbol" :symbolList="symbolList" :height="height" />
        </div>
      </div>

    <div class="mobile-sheet-container" :class="{active: showAccountSheet}">
      <div class="bg" @click="showAccountSheet = false"></div>
      <div class="mobile-sheet" :class="{active: showAccountSheet}">
        <div class="mobile-sheet-header">
          <div class="title">계좌</div>
          <button @click="showAccountSheet = false"><img src="@/assets/icons/close-sheet.svg" alt=""></button>
        </div>
        <div class="mobile-sheet-body">
          <ul>
            <li @click="showAccountSheet = false; showModal('modalTransactionTable')">거래내역</li>
            <li @click="showAccountSheet = false; showModal('modalProfitLossTable')">매매손익</li>
          </ul>
        </div>
      </div>
    </div>

    <div class="mobile-sheet-container" :class="{active: showDepositSheet}">
      <div class="bg" @click="showDepositSheet = false"></div>
      <div class="mobile-sheet" :class="{active: showDepositSheet}">
        <div class="mobile-sheet-header">
          <div class="title">입출금</div>
          <button @click="showDepositSheet = false"><img src="@/assets/icons/close-sheet.svg" alt=""></button>
        </div>
        <div class="mobile-sheet-body">
          <ul>
            <li @click="showDepositSheet = false; showModal('modalDepositWithdraw')">입출금신청</li>
            <li @click="showDepositSheet = false; showModal('modalDepositWithdrawTable')">입출금내역</li>
          </ul>
        </div>
      </div>
    </div>

    <div class="mobile-sheet-container" :class="{active: showCounterSheet}">
      <div class="bg" @click="showCounterSheet = false"></div>
      <div class="mobile-sheet" :class="{active: showCounterSheet}">
        <div class="mobile-sheet-header">
          <div class="title">주문수량</div>
          <button @click="showCounterSheet = false"><img src="@/assets/icons/close-sheet.svg" alt=""></button>
        </div>
        <div class="mobile-sheet-body">
          <div class="order-row">
            <button class="btn-line square">
            <img src="@/assets/icons/minus-black.svg" alt="">
          </button>
          <input type="number" value="2" class="text-right">
          <button class="btn-line square">
            <img src="@/assets/icons/plus-black.svg" alt="">
          </button>
          </div>
          <div class="btn-container">
            <button class="btn-primary darkgray">취소</button>
            <button class="btn-primary blue">저장</button>
          </div>
        </div>
        <div class="mobile-sheet-footer">
          
        </div>
      </div>
    </div>
    
    <ModalTransactionTable />
    <ModalProfitLossTable />
    <ModalDepositWithdraw
      v-on:depositWithdraw-complete="completeDepositWithdraw"
     />
    <ModalDepositWithdrawTable />
    <ModalSettings
     v-on:order-setting-complete="completeOrderSetting"
     :mobileSet="mobileSet"
    />
    <ModalNotice />
    <ModalNoticeDetail />
    <ModalLogout />
    <ModalInstruments
     :symbolList="symbolList"
     :kospiList="kospiList"
     :futuresList="futuresList"
    />
    <ModalConfirmOrder @event:orderConfirm="orderConfirm" />
    <ModalBasic />
  </div>
</template>

<script>
import AvatarComp from '@/components/AvatarComp.vue';
import IconChevronDown from '@/components/icons/IconChevronDown.vue';
import VueDraggableResizable from 'vue-draggable-resizable';
import 'vue-draggable-resizable/dist/VueDraggableResizable.css';
import CounterComp from '@/components/CounterComp.vue';
import AgPositionTable from '@/components/agTables/AgPositionTable.vue';
import AgFilledTable from '@/components/agTables/AgFilledTable.vue';
import ModalTransactionTable from '@/components/ModalTransactionTable.vue';
import ModalProfitLossTable from '@/components/ModalProfitLossTable.vue';
import ModalDepositWithdraw from '@/components/ModalDepositWithdraw.vue';
import ModalDepositWithdrawTable from '@/components/ModalDepositWithdrawTable.vue';
import ModalSettings from '@/components/ModalSettings.vue';
import ModalNotice from '@/components/ModalNotice.vue';
import ModalNoticeDetail from '@/components/ModalNoticeDetail.vue';
import ModalLogout from '@/components/ModalLogout.vue';
import AgOptionsTable from '@/components/agTables/AgOptionsTable.vue';
import ModalInstruments from '@/components/ModalInstruments.vue';
import { stringCount } from '@/modules/helper';
import Utils from '@/modules/utils';
import Const from '@/const';
import EventBus from '@/modules/event-bus';
import DomGridClick from '@/components/DomGridClick.vue';
import ChartBox from '@/components/ChartBox';
import ModalConfirmOrder from '@/components/ModalConfirmOrder.vue';
import ModalBasic from '@/components/ModalBasic.vue';
import CodeDropDown from '@/components/CodeDropDown.vue'
import BaseDropdown from '@/components/BaseDropdown.vue'

export default {
  name: 'WhiteLabelMobilePage',
  components: {
    AvatarComp,
    IconChevronDown,
    VueDraggableResizable,
    CounterComp,
    AgPositionTable,
    AgFilledTable,
    ModalTransactionTable,
    ModalProfitLossTable,
    ModalDepositWithdraw,
    ModalDepositWithdrawTable,
    ModalSettings,
    ModalNotice,
    ModalNoticeDetail,
    ModalLogout,
    AgOptionsTable,
    ModalInstruments,
    DomGridClick,
    ChartBox,
    ModalConfirmOrder,
    ModalBasic,
    CodeDropDown,
    BaseDropdown,
  },
  computed:{
    // 로그인 여부
    isLogin () {
      return window.$store.getters['Login/isLogin'];
    },
    //소켓시세
    changeOvcData: function(){
      let ovcData = window.$store.getters['SocketPrice/getOvcData'];
      return ovcData;
    },
    //소켓호가
    changeOvhData: function(){
      let ovhData = window.$store.getters['SocketPrice/getOvhData']
      return ovhData
    },
    //폴링시세
    changeOvcDataList: function(){
      let ovcData = window.$store.getters['SocketPrice/getOvcDataList'];
      return ovcData;
    },
    //폴링호가
    changeOvhDataList: function(){
      let ovhData = window.$store.getters['SocketPrice/getOvhDataList']
      return ovhData
    },
    changeFvSymbol: function(){
      let custom = window.$store.getters['Layout/getFavoriteGridCustom'];
      if (custom == undefined){
        return null;
      }
      return {socketSymbol: custom.socketSymbol, socketSymbolCnt: this.socketSymbolCnt+1};
    },
    getSelectSymbol: function(){
      return window.$store.getters['SocketPrice/getSelectSymbol'];
    },
    tikDotSz(){
      return stringCount(this.symbol.pricescale);
    },
    resultOms: function(){
      return window.$store.getters['SocketOms/getCompleteOrder'];
    },
    getAbleQty: function(){
      return window.$store.getters['SocketOms/getAbleQty'];
    },
    resultMsg: function(){
      return window.$store.getters['SocketMsg/getMessage'];
    },
    getDailyPlMn: function(){
      return window.$store.getters['SocketOms/getDailyPlMn'];
    },
    getPosOrdStList: function(){
      return window.$store.getters['SocketOms/getPosOrdStList'];
    },
    getLoginUserInfo: function(){
      let loginInfo = window.$store.getters['Login/getLoginUserInfo'];
      return loginInfo;
    },
  },
  watch:{
    //소켓시세
    changeOvcData(newPrice){
      if (this.symbol && newPrice.symbol == this.symbol.name) {
        this.nowPrice = newPrice;
        this.setSymbolPrice();

        //일반주문 가격 시세 첫번째 값으로 저장
        // if (this.ordStdPrc == 0){
        //   this.ordStdPrc = Number(newPrice.curPr).toFixed(this.tikDotSz);
        // }

        // if (this.sellOrdPrc == 0){
        //   this.sellOrdPrc = this.nowPrice.curPr.toFixed(this.tikDotSz);
        // }

        // if (this.buyOrdPrc == 0){
        //   this.buyOrdPrc = this.nowPrice.curPr.toFixed(this.tikDotSz);
        // }
      }
    },
    //소켓호가
    changeOvhData(newPrice){
      if (newPrice.symbol == this.symbol.name){
        //
        this.ovhData = newPrice;
      }
    },
    //폴링시세
    changeOvcDataList(newPrice){
      if (newPrice != null && newPrice.length > 0) {
        newPrice.forEach(data=>{
          if (this.symbol && data.symbol == this.symbol.name){
            // this.curPr = data.curPr;
            // this.setValProLoss(this.grid.rowData[0]); //평가손익 계산
            // this.gridApi.refreshCells();
            this.setSymbolPrice();
          }
        })
      }
    },
    //폴링호가
    changeOvhDataList(newPrice){
      if (newPrice != null && newPrice.length > 0) {
        newPrice.forEach(data=>{
          if (data.symbol == this.symbol.name){
            this.ovhData = data;
          }
        })
      }
    },
    changeFvSymbol(val){
      this.updateSubscribeOnPrice()
    },
    getSelectSymbol(newVal){
      this.rStock = Object.assign({}, null);

      //종목이 변경될경우 소켓데이터 연결 갱신
      this.symbol = Object.assign({}, newVal);

      this.nowPrice = {curPr:0, ydiffPr:0, open:0, chGrate:0, high:0, low:0, ydiffSign:0};

      this.tikSize = newVal.minmov / newVal.pricescale;

      this.ordStdPrc = 0; //일반주문 가격

      this.sellOrdPrc = 0;
      this.buyOrdPrc = 0;

      this.setStockInfo(newVal, null);

      //this.symbolDropdown(newVal);

      this.selectOrder(this.symbol.name);

      this.updateSubscribeOnPrice();
      
      //accId가 있으면
      if (this.account.accId && this.account.accId > 0){
        this.searchOrdAbleQty();
      }else{
        //accId가 로딩중이면 기다렸다 실행
        window.$store.watch(() => this.account.accId, isAccount => {
          if (isAccount) {
            this.searchOrdAbleQty();
          }
        })
      }
    },
    resultOms(res){
      if (res.result == "S"){
        this.completeOrder(res);
      }else{
        if (res.apiCode == "OMS9990"){
          console.log("OMS 로그인 정보없음", this.getLoginUserInfo);
          if (this.getLoginUserInfo != undefined && this.getLoginUserInfo.authId != undefined){
            window.$http.post('/auth/login/refresh', {params : {}});
            console.log("oms login/refresh ");
          }
          return;
        }else{
          this.procFailMessage(res);
          //실패시 리스트목록 주문 목록 재조회
          if (res.body.code != "OMSFL007" && res.body.code != "OMSFL011" && res.body.code != "OMSFL002" && res.body.code != "OMSFL012"
            && res.body.code != "OMSFL014" && res.body.code != "OMSFL016" && res.body.code != "OMSFL017"){
            this.selectOrder(this.symbol.name);
          }
        }
      }
    },
    getAbleQty:{
      handler(res){
        this.sellAbleQty = res.ordAbleSellQty;
        this.buyAbleQty = res.ordAbleBuyQty;
      },
      deep:true
    },
    resultMsg:{
      handler(res){
        console.log("resultMsg >>>> ", res)
        if (res.apiCode == Const.MSG_API_TYPE.BANK){
          //유저 데이터 재조회
          window.$store.dispatch('SocketOms/selectUsrData', {accId:this.account.accId, symCd:this.symbol.name, symNo:this.symbol.symbolno, lv:this.leverage});
        } else if ( res.apiCode == Const.MSG_API_TYPE.LOGOUT ) {

          window.$store.dispatch('Login/logout');
          this.disConnect();

          this.$alert.alert('다른 브라우저에서 로그인하여 <span style="color:red;">로그아웃</span> 되었습니다.<br/><br/>로그인을 다시 시도하시거나 사용을 종료 해 주십시오.', '안내')
          .then(() => { 
            this.$router.push('/login');
          });

          return;
        }else if (res.apiCode == Const.MSG_API_TYPE.LOGINKILL){
          //LOGINKILL("MSG0103"),		// 관리자 로그아웃처리
          window.$store.dispatch('Login/logout');

          this.disConnect();

          this.$alert.alert('관리자의 의해 차단되었지롱...<br/>관리자에게 문의주셔용', '안내')
          .then(() => {
            this.$router.push('/login');
          });
          //return;
        }else if ( res.apiCode == Const.MSG_API_TYPE.NOTLOGIN){ //로그인 정보가 없으면 브라우저 로그인 되어있는지 확인 후 로그아웃처리
          console.log("this.getLoginUserInfo ", this.getLoginUserInfo)
          if (this.getLoginUserInfo != undefined && this.getLoginUserInfo.authId != undefined){
              window.$http.post('/auth/login/refresh', {params : {}});
              console.log("login/refresh ");
          }
          return;
        }else if(res.apiCode == Const.MSG_API_TYPE.LVCHANGE){
          this.selectLeverageList();
        }

        //공지 팝업 호출
        if (res.body.post != undefined && res.body.post.popupFlg != undefined && res.body.post.popupFlg == "Y"){
          this.$modal.show("modalBasic", res.body);
           return;
        }else{
          this.$notify({
            group : 'notification',
            //text : '<span style="color: var(--main-red)">알림</span> '+ res.body.msg
            text : res.body.msg
          });
          return;
        }
      },
      deep:true
    },
    getDailyPlMn:{
      handler(res){
        this.dailyPlMn.fxPlMn = res.fxPlMn; // 해외손익금액
        this.dailyPlMn.fxCms = res.fxCms; // 해외수수료
        this.dailyPlMn.nFxPlMn = res.nFxPlMn; // 해외순손익
        this.dailyPlMn.futPlMn = res.futPlMn; // 국내손익금액
        this.dailyPlMn.futCms = res.futCms; // 국내수수료
        this.dailyPlMn.nFutPlMn = res.nFutPlMn; // 국내순손익
        this.dailyPlMn.totPlMn = res.totPlMn; // 총손익금액
        this.dailyPlMn.totNPlMn = res.totNPlMn; // 총순손익
        this.dailyPlMn.totCms = res.totCms; // 총수수료
        this.dailyPlMn.niPlMn = res.niPlMn;
        this.dailyPlMn.balMn = res.balMn;
        this.dailyPlMn.hkdExR = res.hkdExR;
        this.dailyPlMn.usdExR = res.usdExR;
        this.dailyPlMn.usdExHkd = res.usdExHkd;
      },        
      deep:true
    },
    getPosOrdStList(newList){
      if (newList.length > 0){
        this.setPosOrdStList(newList);
      }
    }
  },
  data() {
    return {
      mobileSelectedTab: 'chart',
      mobileSelectedStandardOrder: 'sell',
      tabName: [{name:"클릭", useYn:true}, {name:"MIT", useYn:false}, {name:"일반", useYn:false}, {name:"잔고", useYn:false}, {name:"체결", useYn:false}, {name:"차트", useYn:true}],
      tap1AuthShow: {ord:true, posList:false},
      tap2AuthShow: {mit:true, },
      tap3AuthShow: {mit:true, stopLoss:true},
      tap4AuthShow: {},
      tap5AuthShow: {},
      showSide: false,
      showAccountSheet: false,
      showDepositSheet: false,
      showCounterSheet: false,
      // showInstrumentSheet: false,
      // mobileSheetTab: 'intFutures',
      containerHeight: '',

      
      showPanel: true,
      showInstruments: 'global',
      setIntervalId: null,
      selectedTheme: 'black',
      symbol:{name:null, base_name:null},
      oldSymbol:null,
      symbolList:[],
      kospiList:[],
      futuresList:[],
      accId: null,
      account: {
        accNm: '',
        accPass: '',
        accCnt: 0,
        accId: 0,
      },
      accountList:[],
      chgSymbol: null,
      priceReqType: "SCKTP01", //시세 받는방식 socket(SCKTP01), polling(SCKTP02/SCKTP03)
      symbolCdList:[],
      rtPosSymbolList:[],
      sellAbleQty:0,
      buyAbleQty:0,
      orderSetting: null,
      btnSellMarketFlag : true,
      btnBuyMarketFlag : true,
      btnCancelSellStAllFlag : true,
      btnCancelBuyStAllFlag : true,
      btnCancelSellAllFlag : true,
      btnCancelBuyAllFlag : true,
      btnCancelAllFlag : true,
      sellSlTick:1,
      buySlTick:1,
      sellAvgPrice: {avgPrice:0, cssTopBottom:''},
      buyAvgPrice: {avgPrice:0, cssTopBottom:''},
      clickSetList: ['1','2','3','4','5'],
      ordClick: 1,
      slStockVal1:false,
      slStockVal2:false,
      selectOrderData:null,
      nowPrice:{curPr:0, ydiffPr:0, open:0, chGrate:0, high:0, low:0, ydiffSign:0},
      dailyPlMn:{fxPlMn:"0", fxCms:"0", nFxPlMn:"0", futPlMn:"0", futCms:"0", nFutPlMn:"0", totPlMn:"0", totNPlMn:"0", totCms:"0", niPlMn:"0", balMn:"0", hkdExR:"0", usdExR:"0", usdExHkd:"0", totalValPl:"0", totalLossCut:"0", data:null},
      symbolPlMn:{balQty:"0", execPrc:"0", plMn:"0", valPl:"0", data:null},
      totalValPl: null,
      orderType: 'click',
      tikSize: 0,
      avgPrice:null,
      popupId:null,
      width: 757,
      height: 600,
      currencyInfo:{currency:"WON"},
      ordStdType:"1",
      ordStdQty:1,
      ordStdPrc:0,
      sellOrdQty:1,
      buyOrdQty:1,
      sellOrdPrc:0,
      buyOrdPrc:0,
      buyAvgPrc: 0,
      sellAvgPrc: 0,
      leverage: '1',
      leverageList: [{cd:'1', name:'1:1'}],
      btnStdFlag:true,
      layout : {},
      mobileSet:{type:"MO", fixNm: "고정", posList:false, fix:true, ord:false, mit:false, btnBottom:false},
      highPrice : 0,
      lowPrice : 0,
      yesterdayClosingPrice : 0,
      ovhData : {
        offerHo5:0,
        offerHo4:0,
        offerHo3:0,
        offerHo2:0,
        offerHo1:0,
        offerRem5:0,
        offerRem4:0,
        offerRem3:0,
        offerRem2:0,
        offerRem1:0,
        offerNo5:0,
        offerNo4:0,
        offerNo3:0,
        offerNo2:0,
        offerNo1:0,
        bidHo5:0,
        bidHo4:0,
        bidHo3:0,
        bidHo2:0,
        bidHo1:0,
        bidRem5:0,
        bidRem4:0,
        bidRem3:0,
        bidRem2:0,
        bidRem1:0,
        bidNo5:0,
        bidNo4:0,
        bidNo3:0,
        bidNo2:0,
        bidNo1:0
      },
      orgOrder:{
        accId : null,
        symCd : null,
        trdDt : null,
        ordId : 0,
        orgOrdNo: 0,
        orgOrdPrc: 0,
        ordSdCd : null,
        ordTpCd : null,
        ordPrc : 0,
        ordQty : 0,
        repReqQty : 0,
        cxlReqQty : 0,
        orgUsrKey : null,
        modifyOrdId: 0,
        ordType: "",
      },
      sellOrderType: '1',
      buyOrderType: '1',
      stopLossType: '1',
      rStock: null,
      stopTime:null,
      orderFullSize:false,
    };
  },
  created(){
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    let cpnAuthList = window.$store.getters['Layout/getCpnAuthList'];
    console.log("cpnAuthList", cpnAuthList)
    if (cpnAuthList != undefined){
      this.setCpnAuth(cpnAuthList);
    }

    this.domainCheck();

    //설정관련 처리필요
    //this.mobileSet.ord = true;
  
    const res = this.loginChk();
    res.then(response=>{
      const data = response.data;
      if (data.code != 200){
        this.mobileSelectedTab = 'chart';
      }else{
        if (data.loginUserInfo != undefined && data.loginUserInfo.authId != undefined){
          this.mobileSelectedTab = 'clickOrder';
        }else{
          this.mobileSelectedTab = 'chart';
        }
      }

      this.loadLayout();

      this.selectAccountList();
    }).catch((e)=>{
      this.selectSymbolList();
    });

    //this.selectSymbolList();

    this.connectSokect();

    EventBus.$on('Layout:event:loadLayout', (layout) => {
      this.setLayout(layout)
    })

    //절전모드 체크
    document.addEventListener("visibilitychange", this.handleVisibilityChange, false);
  },
  mounted() {
    //화면 회전 체크...
    window.addEventListener( "orientationchange", function(event) {
      console.log("orientationchange ", window.screen, window.screen.orientation.angle)
      if (window.matchMedia('(orientation: portrait)').matches) { //가로
        console.log("portrait")
      }else{
        console.log("Landscape")
      }
    });

    const chartPanel = document.querySelector('.mobile-inner-container');

    //차트 사이즈 자동인식
    const observer = new ResizeObserver(entries => {
      for (let entry of entries) {
        const {width, height} = entry.contentRect;
        this.height = Number(height) - 80;
        //this.width = width;
      }
    });

    //자동으로 인식할 부분 추가
    observer.observe(chartPanel);

    // //공지팝업 테스트용
    // let test = {postNo:22};
    // this.$modal.show("modalBasic", test);    
  },

  methods: {
    async domainCheck(){
      const host = window.location.host;
      const formData = {hostUrl: host};

      await this.$lhttp.post('/auth/domainChk', formData).then((response) => {
        console.log("response... ", response);
        if (response.code == 200){
          if (response.data != undefined && response.data.cpnId > 0){
            //접속방식 설정
            window.$store.dispatch('SocketPrice/setPollingType', {sckTpCd:response.data.sckTpCd, sckTpDsc:response.data.sckTpDsc});
            this.priceReqType = window.$store.getters['SocketPrice/getPriceReqType'];

            console.log("response.data ", response.data)
            let cpnAuthList = window.$store.getters['Layout/getCpnAuthList']
            if (cpnAuthList == undefined || cpnAuthList == null || cpnAuthList.length){
              window.$store.dispatch('Layout/setCpnAuth', {data:response.data.cpnAuthList})
              cpnAuthList = response.data.cpnAuthList;
              this.setCpnAuth(cpnAuthList);
            }else{
              this.tabName[0].useYn = true;
              this.tabName[1].useYn = true;
              this.tabName[2].useYn = true;
              this.tabName[3].useYn = true;
              this.tabName[4].useYn = true;
              this.tabName[5].useYn = true;
              this.mobileSet.ord = true;
            }
          }else{
            this.$alert.alert('접속하신 도메인정보의 회사정보가 없습니다.\n관리자에게 문의하세요.', '알림');
            return;
          }
        }else{
          this.$alert.alert('접속하신 도메인정보의 회사정보가 없습니다.\n관리자에게 문의하세요.', '알림');
          return;
        }

      })

      //소켓연결시
      console.log("시세 연결 방식 >>> ", this.priceReqType);
      // if (this.priceReqType == "SCKTP01"){
      //   this.connectPrice();
      // }
    },
    setCpnAuth(cpnAuthList){ //커스텀 권한 설정
      if (cpnAuthList != undefined && cpnAuthList.length > 0){
        let tapChk = ["N", "N", "N", "N", "N", "N"];
        let mobileOrdChk = "N";
        let mobileMitChk = "N";
        for (let item of cpnAuthList) {
        //cpnAuthList.forEach(item=>{
          if (item.authTpCd == "MOTAP1"){ //tap1
            tapChk[0] = "Y";
            if (item.chgNmYn == "Y"){
              this.tabName[0].name = item.authTpNm;
            }
            if (item.useYn == "N"){
              this.tabName[0].useYn = false;
            }else{
              this.tabName[0].useYn = true;
            }
          }else if (item.authTpCd == "MOTAP1POS"){ //tap1 잔고리스트 표시
            if (item.useYn == "Y"){
              this.tap1AuthShow.posList = true;
              this.mobileSet.posList = true;
            }
          }else if (item.authTpCd == "MOTAP2"){ //tap2
            tapChk[1] = "Y";
            if (item.chgNmYn == "Y"){
              this.tabName[1].name = item.authTpNm;
            }
            if (item.useYn == "N"){
              this.tabName[1].useYn = false;
            }else{
              this.tabName[1].useYn = true;
            }
          }else if (item.authTpCd == "MOTAP3"){ //tap3
            tapChk[2] = "Y";
            if (item.chgNmYn == "Y"){
              this.tabName[2].name = item.authTpNm;
            }
            if (item.useYn == "N"){
              this.tabName[2].useYn = false;
            }else{
              this.tabName[2].useYn = true;
            }
          }else if (item.authTpCd == "MOTAP3MIT"){ //tap3 MIT
            mobileMitChk = "Y";
            if (item.useYn == "N"){
              this.tap3AuthShow.mit = false;
            }
          }else if (item.authTpCd == "MOTAP3SL"){ //tap3 STOPLOSS
            if (item.useYn == "N"){
              this.tap3AuthShow.stopLoss = false;
            }
          }else if (item.authTpCd == "MOTAP4"){ //tap4
            tapChk[4] = "Y";
            if (item.chgNmYn == "Y"){
              this.tabName[3].name = item.authTpNm;
            }
            if (item.useYn == "N"){
              this.tabName[3].useYn = false;
            }else{
              this.tabName[3].useYn = true;
            }
          }else if (item.authTpCd == "MOTAP5"){ //tap5
            tapChk[4] = "Y";
            if (item.chgNmYn == "Y"){
              this.tabName[4].name = item.authTpNm;
            }
            if (item.useYn == "N"){
              this.tabName[4].useYn = false;
            }else{
              this.tabName[4].useYn = true;
            }
          }else if (item.authTpCd == "MOTAP6"){ //tap6
            tapChk[6] = "Y";
            if (item.chgNmYn == "Y"){
              this.tabName[5].name = item.authTpNm;
            }
            if (item.useYn == "N"){
              this.tabName[5].useYn = false;
            }else{
              this.tabName[5].useYn = true;
            }
          }else if (item.authTpCd == "MOGRDFIX"){ //클릭주문 그리드 고정버튼
            if (item.chgNmYn == "Y"){
              this.mobileSet.fixNm = item.authTpNm;
            }
            if (item.useYn == "N"){
              this.mobileSet.fix = false;
            }
          }else if (item.authTpCd == "MOGRDORD"){ //클릭주문 그리드 매도/매수
            mobileOrdChk = "Y";
            if (item.useYn == "N"){
              this.tap1AuthShow.ord = false;
              this.mobileSet.ord = false;
            }
          }

          //탭처리가 없는것은 활성화
          for (let i=0; i<tapChk.length; i++){
            if (tapChk[i] == "N"){
              this.tabName[i].useYn = true;
            }
          }

          if (mobileOrdChk == "N"){
            this.mobileSet.ord = true;
            this.tap1AuthShow.ord = true;
          }

          if (mobileMitChk == "N"){
            this.tap1AuthShow.mit = true;
          }
        }
      }else{
        this.tabName[0].useYn = true;
        this.tabName[1].useYn = true;
        this.tabName[2].useYn = true;
        this.tabName[3].useYn = true;
        this.tabName[4].useYn = true;
        this.tabName[5].useYn = true;
        this.mobileSet.ord = true;
      }
    },
    connectSokect(){
      //소켓연결시
      if (this.priceReqType == "SCKTP01"){
        this.connectPrice();
      }

      //주문 소켓 서버 연결
      this.connectOms();

      //message 소켓 서버 연결
      this.connectMsg();
    },
    handleVisibilityChange(){
      if (document.hidden) {
        this.pauseStartPage('P');
      } else  {
        this.pauseStartPage('S');
      }
    },
    pauseStartPage(type){
      const curr = new Date();

      if (type == "P"){ //절전모드
        this.time =curr.getTime();

        this.disConnect();
        console.log("disConnect ....");
      }else if(type == "S"){ //절전모드 해제
        const res = this.loginChk();
        res.then(response=>{
          const data = response.data;
          if (data.code != 200){
            this.mobileSelectedTab = 'chart';
            window.$store.dispatch('Login/logout');
          }else{
            if (data.loginUserInfo != undefined && data.loginUserInfo.authId != undefined){
              this.connectSokect();

              setTimeout(() => {
                this.selectSymbolList();
              }, 1000)

              //10분이 넘어가면 새로고침
              const seconds = Math.floor((Number(curr.getTime()) - Number(this.time)) / 1000);
              if (seconds > 300 ){
                window.location.reload();
              }

              console.log("connect ....", seconds);
            }else{
              this.mobileSelectedTab = 'chart';
              window.$store.dispatch('Login/logout');
            }
          }
        }).catch((e)=>{
          this.mobileSelectedTab = 'chart';
          window.$store.dispatch('Login/logout');
        });
      }
    },
    async loginChk() {
      let res = window.$store.dispatch('Login/refresh');
      return res;
      
    },
    thousand(value, digits) {
      if (value == undefined){
        return 0;
      }else{
        return value.toLocaleString('en-US', { minimumFractionDigits: 0 });
      }
    },
    setPosOrdStList(newList){
      let balQty = 0;
        let execPrc = 0;
        let ordSdCd = "0";
        let plMn = 0;
        let valPl = 0;
        let plData = {};
        newList.forEach(data=>{
          if (data.ordType == "POS" && data.symCd == this.symbol.name){
            balQty = data.balQty;
            ordSdCd = data.ordSdCd;
            if (balQty > 0) execPrc = data.execPrc;
            plMn = data.plMn;
            valPl = data.valPl;
            plData = data;
            
            if (data.ordSdCd == "1" && balQty > 0){
              this.sellAvgPrc = data.execPrc;
              this.buyAvgPrc = 0; 
            }else if (data.ordSdCd == "2" && balQty > 0){
              this.buyAvgPrc = data.execPrc;
              this.sellAvgPrc = 0;
            }else{
              this.buyAvgPrc = 0; 
              this.sellAvgPrc = 0;
            }
          }
        })

        this.symbolPlMn.balQty = balQty;
        this.symbolPlMn.execPrc = execPrc;
        this.symbolPlMn.plMn = plMn;
        this.symbolPlMn.data = plData;

        this.avgPrice = Object.assign({}, {avgPrice:execPrc, ordSdCd:ordSdCd, balQty:balQty});

        this.setStdAvgPrice(execPrc, ordSdCd); //일반주문 스탑로스 가격 설정

        this.setSymbolPrice();
    },
    //--- 일반주문 스탑로스 가격 설정 ---
    setStdAvgPrice(price, balSide){
      let buyPrice = 0;
      let sellPrice = 0;
      let tikVal = this.symbol.minmov / this.symbol.pricescale;
      //소수점이 있을경우 가까운 가격쪽으로 설정
      if ((price).toString().indexOf(".") > 0){
        let avgPrice = price.toFixed(this.tikDotSz);

        if (balSide == "1"){ //매도
          price = Math.round((Number(avgPrice) / tikVal) + 0.0000000001) * tikVal
        }else if (balSide == "2"){ //매수
          price = Math.round(Number(avgPrice) / tikVal) * tikVal
        }
      }

      if (this.slStockVal1){ //익절
        if (price == 0 || balSide == 0){
          this.buyAvgPrice.avgPrice = 0;
          this.buyAvgPrice.cssTopBottom = "";
        }else{
          if (balSide == "2"){
            this.buyAvgPrice.avgPrice = price + (tikVal * this.buySlTick);
            this.buyAvgPrice.cssTopBottom = "top";
          }else{
            this.buyAvgPrice.avgPrice = price - (tikVal * this.buySlTick);
            this.buyAvgPrice.cssTopBottom = "bottom";
          }
        }
      }else if (!this.slStockVal1){
        this.buyAvgPrice.avgPrice = 0;
        this.buyAvgPrice.cssTopBottom = "";
      }

      if (this.slStockVal2){ //손절
        if (price == 0 || balSide == 0){
          this.sellAvgPrice.avgPrice = 0;
          this.sellAvgPrice.cssTopBottom = "";
        }else{
          if (balSide == "2"){
            this.sellAvgPrice.avgPrice = price - (tikVal * this.sellSlTick);
            this.sellAvgPrice.cssTopBottom = "bottom";
          }else{
            this.sellAvgPrice.avgPrice = price + (tikVal * this.sellSlTick);
            this.sellAvgPrice.cssTopBottom = "top";
          }
        }
      }else if (!this.slStockVal2){
        this.sellAvgPrice.avgPrice = 0;
        this.sellAvgPrice.cssTopBottom = "";
      }

    },
    changeSelectedTab(tabNm){
      if (tabNm == this.mobileSelectedTab) return;
      this.mobileSelectedTab = tabNm;
      if (tabNm == "clickOrder"){
        //mobileSet:{type:"MO", ord:true, mit:false, btnBottom:false},
        if (this.tap1AuthShow.ord == true){
          this.mobileSet.ord = true;
        }else{
          this.mobileSet.ord = false;
        }
        
        this.mobileSet.mit = false;

        //현재시세 설정
        this.rStock = Object.assign({}, null);
        this.nowPrice = {curPr:0, ydiffPr:0, open:0, chGrate:0, high:0, low:0, ydiffSign:0};
        this.setStockInfo(this.symbol, null);

        this.selectOrder(this.symbol.name);

        const posOrdStList = window.$store.getters['SocketOms/getPosOrdStList'];
        if (posOrdStList != undefined && posOrdStList.length > 0){
          setTimeout(() => {
            this.setPosOrdStList(posOrdStList);
          }, 300)          
        }

        this.sendOmsLogMessage("클릭메뉴 클릭");
      }else if (tabNm == "mit"){
        if (this.tap1AuthShow.mit == true){
          this.mobileSet.mit = true;
        }else{
          this.mobileSet.mit = false;
        }
        this.mobileSet.ord = false;
        

        //현재시세 설정
        this.rStock = Object.assign({}, null);
        this.nowPrice = {curPr:0, ydiffPr:0, open:0, chGrate:0, high:0, low:0, ydiffSign:0};
        this.setStockInfo(this.symbol, null);
        
        this.selectOrder(this.symbol.name);

        const posOrdStList = window.$store.getters['SocketOms/getPosOrdStList'];
        if (posOrdStList != undefined && posOrdStList.length > 0){
          setTimeout(() => {
            this.setPosOrdStList(posOrdStList);
          }, 300)
        }

        this.sendOmsLogMessage("MIT메뉴 클릭");
      }else if (tabNm == "standardOrder"){
        this.sendOmsLogMessage("일반메뉴 클릭");
      }else if (tabNm == "balance"){
        this.sendOmsLogMessage("잔고메뉴 클릭");
      }else if (tabNm == "filled"){
        this.sendOmsLogMessage("체결메뉴 클릭");
      }else if (tabNm == "chart"){
        this.sendOmsLogMessage("차트메뉴 클릭");
      }
      
    },
    showSlideMenu(name){
      this.showSide = false;
      if (name == "showAccountSheet"){
        this.sendOmsLogMessage("계좌 클릭");
        this.showAccountSheet = true;
      }else if (name == "showDepositSheet"){
        this.sendOmsLogMessage("입출금 클릭");
        this.showDepositSheet = true;
      }
      
    },
    showModal(name) {
      if (name == "modalSettings"){
        this.sendOmsLogMessage("환경설정 클릭");
      }else if (name == "modalNotice"){
        this.sendOmsLogMessage("공지사항 클릭");
      }else if (name == "modalLogout"){
        this.sendOmsLogMessage("공지사항 클릭");
      }else if (name == "modalTransactionTable"){
        this.sendOmsLogMessage("거래내역 클릭");
      }else if (name == "modalProfitLossTable"){
        this.sendOmsLogMessage("매매손익 클릭");
      }else if (name == "modalDepositWithdraw"){
        this.sendOmsLogMessage("입출금신청 클릭");
      }else if (name == "modalDepositWithdrawTable"){
        this.sendOmsLogMessage("입출금내역 클릭");
      }
      
      let params = {account:this.account, mobileYn:"Y"};
      this.$modal.show(name, params);
      console.log(name);
    },
    loadLayout() {
      window.$store
        .dispatch('Layout/load')
        .then(() => {
          this.layout = window.$store.getters['Layout/getLayout']

          EventBus.$emit('Layout:event:loadLayout', this.layout)

          //this.isCompleteLoadLayout = true
        })         
    },
    setLayout(layout) {
      //this.orderSetting = layout.orderSetting;
      this.loadCustom();
    },
    loadCustom() {
      this.orderSetting = window.$store.getters['Layout/getOrderSetting']
      //this.orderSetting = Object.assign({}, window.$store.getters['Layout/getOrderSetting']);

      if (this.orderSetting != null){
        //this.selectLoadCustom = this.selectLoadCustom +1;

        //손절
        if (this.slStockVal2 == false){
          this.setSellSlTick(this.orderSetting.slTikVal1.tikVal)
        }

        //익절
        if (this.slStockVal1 == false){
          this.setBuySlTick(this.orderSetting.slTikVal2.tikVal);
        }

        if (this.orderSetting != null){
          //주문수량 설정
          this.clickSetList[0] = this.orderSetting.clickVal1.val.toString();
          this.clickSetList[1] = this.orderSetting.clickVal2.val.toString();
          this.clickSetList[2] = this.orderSetting.clickVal3.val.toString();
          this.clickSetList[3] = this.orderSetting.clickVal4.val.toString();
          this.clickSetList[4] = this.orderSetting.clickVal5.val.toString();

          this.ordClick = Number(this.clickSetList[0]);

          //원클릭, 더블클릭 설정
          // if (this.orderSetting.oneClick == true){
          //   this.itemColumnDoubleClickTime = 0;
          // }else if (this.orderSetting.dbClick == true){
          //   this.itemColumnDoubleClickTime = 200;
          // }

          //레버리지
          if (this.orderSetting.leverage){
              this.leverage = this.orderSetting.leverage;
          }
        }
      }
    },
    async selectLeverageList(){
      const list = await window.$http.get('/api/order/lv/limitlist', { params: { accNo:this.account.accNo } } );

      let lvList = [];
      if (list.data != undefined && list.data.length > 0){
        list.data.forEach((item)=>{
          lvList.push({cd:item.cd, name:item.cdNm});
        });
        this.leverageList = lvList;

        if (Number(this.leverage) < Number(lvList[0].cd)){
          this.selectLeverage(lvList[0]);
        }
      }

    },
    //종목 조회
    async selectSymbolList() {
      await window.$store.dispatch('Symbol/getSecMt'); //월물 조회
      await window.$store.dispatch('Symbol/load');
      this.symbolList = window.$store.getters['Symbol/getSymbolList'];

      //소켓 시세
      if (this.priceReqType == "SCKTP01"){
        let symCdList = [];
        this.symbolList.forEach(item=>{
          symCdList.push(item.name);
        })
        if (!window.$store.getters['SocketPrice/getIsConnectPrice']) {
          window.$store.watch(() => window.$store.getters['SocketPrice/getIsConnectPrice'], socketPrice => {
            window.$store.dispatch('SocketPrice/setSubscribeOnPriceList', [])
            this.symbolDropdown(this.symbolList[0]);
          })
        }else{
          window.$store.dispatch('SocketPrice/setSubscribeOnPriceList', symCdList)
          this.symbolDropdown(this.symbolList[0]);
        }
      //폴링 시세
      }else if (this.priceReqType == "SCKTP02" || this.priceReqType == "SCKTP03"){
        let symbolList = [];
        this.symbolDropdown(this.symbolList[0]);
        this.connectPrice(symbolList);
      }

      //this.setStockInfo(this.symbolList, null);
    },
    // 시세 socket 연결
    connectPrice(symbolList) {
      if (this.priceReqType == "SCKTP01"){
        window.$store.dispatch('SocketPrice/setConectPrice', {sessionStorage:this.$sessionStorage.get('xDkToken')});
      }else if (this.priceReqType == "SCKTP02" || this.priceReqType == "SCKTP03"){
        window.$store.dispatch('SocketPrice/setPollingPrice', {symCd:this.symbolList[0].name, symbolList:symbolList});
      }
    },
    updateRtPosSymbol(dataList){
      this.rtPosSymbolList = [];
      dataList.forEach(data=>{
        if (data.ordType == "POS"){
          this.rtPosSymbolList.push(data);
        }
      })
      
      this.updateSubscribeOnPrice();
    },
    updateSubscribeOnPrice(){
      //소켓 구독 종목 업데이트
      this.symbolCdList = [];

      //관심종목 리스트
      // let fvCustom = window.$store.getters['Layout/getFavoriteGridCustom']
      // if (fvCustom.socketSymbol != undefined && fvCustom.socketSymbol != null && fvCustom.socketSymbol.length >0) {       

      //   fvCustom.socketSymbol.forEach(data =>{
      //     this.symbolList.forEach(symbol =>{
      //       if (symbol.symbolno == data){
      //         this.symbolCdList.push(symbol.name);
      //         return;
      //       }
      //     })
      //   });

      //   //잔고 포지션 종목
      //   this.rtPosSymbolList.forEach(data =>{
      //     let chkYn = "N";
      //     this.symbolCdList.forEach(item =>{
      //       if (item == data.symCd){
      //         chkYn = "Y";
      //         return;
      //       }
      //     })
      //     if (chkYn == "N"){
      //       this.symbolCdList.push(data.symCd);            
      //     }
      //   });
      // }else if (this.rtPosSymbolList.length > 0){
        //관심종목이 없고 잔고 목록만 있을경우
        this.rtPosSymbolList.forEach(data =>{
          this.symbolCdList.push(data.symCd);
        });
      // }

      //선택 종목
      const selSymbol = window.$store.getters['SocketPrice/getSelectSymbol'];

      if (selSymbol != undefined && selSymbol.name){
        let chkYn = "N"
        this.symbolCdList.forEach(item =>{
          if (selSymbol.name == item){
            chkYn = "Y";
            return;
          }
        })
        if (chkYn == "N"){
          this.symbolCdList.push(selSymbol.name);
        }
      }
      
      
      //console.log("소켓 구독 수정", this.symbolCdList)
      
      this.subscribeOnPriceModify(this.symbolCdList);
    },
    subscribeOnPriceModify(symbolList, popupSelSymbolList) {
      if (this.priceReqType == "SCKTP01"){
        window.$store.dispatch('SocketPrice/setUpdateSubscribeOnPriceList', {symbolList, popupSelSymbolList});
      }else if (this.priceReqType == "SCKTP02" || this.priceReqType == "SCKTP03"){
        window.$store.dispatch('SocketPrice/setPollingPrice', {symCd:this.symbol.name, symbolList:symbolList});
      }
    },
    async setStockInfo(newSymbol, oldSymbol) {

      //const rStock = await window.$http.get('chart/datafeed/stockdata', { params: {  } } )
      //console.log("rStock search >>> ", newSymbol)
      const rStock = await window.$http.get('apisise/getLastPrice', { params: { symCd: newSymbol.name} } )
      if ( rStock != null ) {
        //console.log("rStock >>>>> ", rStock)
        if (rStock.data.ovc != null){
          this.nowPrice = rStock.data.ovc;
          this.ordStdPrc = Number(this.nowPrice.curPr).toFixed(this.tikDotSz);

          this.sellOrdPrc = rStock.data.ovc.curPr.toFixed(this.tikDotSz);
          this.buyOrdPrc = rStock.data.ovc.curPr.toFixed(this.tikDotSz);
        }else{
          this.nowPrice = {curPr:0, ydiffPr:0, open:0, chGrate:0, high:0, low:0, ydiffSign:0};
        }
        
        this.rStock = Object.assign({}, rStock.data);
        
        // let ovc = this.ovcData.ovcData;

        // //시세 db값으로 초기 설정
        // rStock.data.forEach(item => {
        //   ovc.set(item.symbol, item);
        // });
        
        // let cnt = this.ovcData.cnt; //map으로 전달하면 PanelComp2 watch에서 감지를 못하여 cnt값을 계속 증가시켜 감지
        // const param = {cnt:cnt+1, ovcData:ovc};         
        // this.ovcData = param;
      }
    },
    // oms socket 연결
    async connectOms() {
      await window.$store.dispatch('SocketOms/setConectOms', {sessionStorage:this.$sessionStorage.get('xDkToken'), subscriptionUID:sessionStorage.getItem('subscriptionUID')})
    },
    async connectMsg() {
      await window.$store.dispatch('SocketMsg/setConectMsg', {sessionStorage:this.$sessionStorage.get('xDkToken'), subscriptionUID:sessionStorage.getItem('subscriptionUID')})
    },
    //종목셀렉트 선택
    symbolDropdown(value) {
      window.$store.dispatch('SocketPrice/setSelectSymbol', value)      
    },
    async selectAccountList(){
      console.log("accountList", this.accountList)
      await this.$http
      .get('/api/account/list')
      .then((result) => {
        if ( result && result.data !== null ) {
          console.log("account result ", result)
          
          this.accountList = result.data;

          if (result.data.length > 0){
            this.account = result.data[0];

            //레버리지 유저별 목록
            this.selectLeverageList();
          }
          

          //console.log("acccount >>> ", this.account)

          //console.log("accountList", this.accountList)

          // if ( this.$sessionStorage.get('acc_pass_store') ) {
          //   const accPassObj = JSON.parse(this.$sessionStorage.get('acc_pass_store'))

          //   this.accountList.forEach(item => {
          //     item.accPass = accPassObj[item.accNo]
          //   })           
          // }
        }
      }).finally(()=>{
        this.selectSymbolList();
      })
    },
    async selectOrder(symbol) {
      console.log("this.account??? ", this.account.accId)
      if (this.account.accId == 0) return;

      console.log("selectOrder ?????")

      const response = await window.$http.get('api/order/list', { params: { accId: this.account.accId, symCd: symbol } } );
      //console.log("response", response);



      //STOP LOSS 초기화
      // this.slStockVal1 = false;
      // this.slStockVal2 = false;
      // this.sellSlTick = 1; //db저장된 디폴트 값을 셋팅
      // this.buySlTick = 1; //db저장된 디폴트 값을 셋팅
      this.slStockVal2 = false;
      this.slStockVal1 = false;


      if (response.data) {
        this.selectOrderData = {selectOrderList: response.data};

        for (let order of response.data) {
          if (order.ordType == "SL" && order.symCd == this.symbol.name){
            if (order.ordSdCd == "1"){ //CHK_VAL 값 //1:익절 , 2:손절
              //ordPrc -> TICK_VAL 설정값
              if (order.ordPrc != null && order.ordPrc > 0){
                if (order.ordTpCd == 0){ // ORD_STAT_CD = 0 이면 체크
                  this.slStockVal1 = true;
                  this.setBuySlTick(order.ordPrc);
                }
              }
            }else if (order.ordSdCd == "2"){
              if (order.ordPrc != null && order.ordPrc > 0){
                if (order.ordTpCd == 0){ // ORD_STAT_CD = 0 이면 체크
                  this.slStockVal2 = true;
                  this.setSellSlTick(order.ordPrc);
                }
              }
            }
          }
        }

        //손절, 익절 포지션 설정값이 없으면 셋팅설정값으로 설정

        //손절
        if (this.slStockVal2 != undefined && this.slStockVal2 == false && this.orderSetting.slTikVal2.tikVal){
          this.setSellSlTick(this.orderSetting.slTikVal2.tikVal)
        }

        //익절
        if (this.slStockVal1 != undefined && this.slStockVal1 == false && this.orderSetting.slTikVal1.tikVal){
          this.setBuySlTick(this.orderSetting.slTikVal1.tikVal);
        }
      }else{
        this.selectOrderData = {selectOrderList: []};
      }
    },
    completeOrder(res){
      //console.log("desk completeOrder ??? >>> ", res)
      if (res != undefined && res.notify != undefined){
        this.notifyAlert(res.notify)
      }
      const apiCode = res.apiCode;
      const body = res.body;

      if ( apiCode == 'OMS0007') {
        if ( body.chkVal == '1' && body.accId == this.account.accId) {
            //this.$emit('updateSlStock', {chkType:'1', chkVal:!this.slStockVal1});
            this.updateSlStock({chkType:'1', chkVal:!this.slStockVal1});
        } else if ( body.chkVal == '2' && body.accId == this.account.accId) {
            //this.$emit('updateSlStock', {chkType:'2', chkVal:!this.slStockVal2});
            this.updateSlStock({chkType:'2', chkVal:!this.slStockVal2});
        }
      // STOP LOSS 취소 
      } else if ( apiCode == 'OMS0008') {
        if ( body.chkVal == '1' && body.accId == this.account.accId) {
          if ( body.execYn == 'Y' ) {
            this.updateSlStock({chkType:'1', chkVal:!this.slStockVal1});
          }
        } else if ( body.chkVal == '2' && body.accId == this.account.accId) {
          if ( body.execYn == 'Y' ) {
            this.updateSlStock({chkType:'2', chkVal:!this.slStockVal2});
          }
        }
       // 전/현 종목 청산
      } else if ( apiCode == 'OMS0013') {
        if (body.delList != undefined){
          body.delList.forEach(del => {
              //같은 종목만 삭제 처리
              if (del.symCd == this.symbol.name){
                if ( del.chkVal == '1' ) {
                  if ( del.execYn == 'Y' ) {
                    this.updateSlStock({chkType:'1', chkVal:false});
                  }
                } else if ( del.chkVal == '2' ) {
                  if ( del.execYn == 'Y' ) {        
                    this.updateSlStock({chkType:'2', chkVal:false});
                  }
                }
              }           
            });
        }
      //RMS 청산
      }else if (apiCode == 'OMS0017'){
        this.updateSlStock({chkType:'1', chkVal:false});
        this.updateSlStock({chkType:'2', chkVal:false});
      //배치 청산
      } else if ( apiCode == 'OMS9995'){ 
        if (body.execYn == "Y"){
          this.updateSlStock({chkType:'1', chkVal:false});
          this.updateSlStock({chkType:'2', chkVal:false});
        }
      }
      

      //포지션 조회
      if (this.account.accId && this.account.accId > 0){
        //this.search(this.symbol.name);
        this.searchOrdAbleQty();
        //this.$emit("PanelComp4Select", this.symbol.name);
      }
    },
    procFailMessage(res) {
      let body = res.body;

      //기존 알림창 닫기
      this.$notify({ group: 'notification', clean: true })

      this.$notify({
        group: 'notification',
        title: 'FAIL',
        text: body.message
      })
    },
    //주문가능수량 조회
    searchOrdAbleQty() {
      //window.$store.dispatch('SocketOms/selectAbleQty', {accId:this.account.accId, symcd:this.symbol.name, symNo:this.symbol.symbolno});
      window.$store.dispatch('SocketOms/selectUsrData', {accId:this.account.accId, symCd:this.symbol.name, symNo:this.symbol.symbolno, lv:this.leverage});
    },
    tikVal(symbol){
      return (symbol.minmov / symbol.pricescale);
    },
    notifyAlert(msgInfo){ //주문 알림 메시지
      //console.log("msgInfo", msgInfo)
      
      if (msgInfo != null){
        if (this.orderSetting != null && this.orderSetting.soundAlert){
           // 사운드 실행
           if (msgInfo.apiCode == 'OMS0001'){ //주문완료
            if (msgInfo.body.ordSdCd == '1'){
              this.soundOn('/SELL_COMP.WAV')
            }else{
              this.soundOn('/BUY_COMP.WAV')
            }
          }else if (msgInfo.apiCode == 'OMS0002'){  //취소주문
            this.soundOn('/CANCEL_COMP.WAV')
          }else if (msgInfo.apiCode == 'OMS0003'){  //정정주문  
            this.soundOn('/MODIFY_COMP.WAV')
          }else if (msgInfo.apiCode == 'OMS0005'){  //MIT취소완료
            this.soundOn('/CANCEL_COMP.WAV')
          }else if (msgInfo.apiCode == 'OMS0006'){  //MIT정정완료
            this.soundOn('/MODIFY_COMP.WAV')
          }else if (msgInfo.apiCode == 'OMS0010'){ //종목선택취소 
            this.soundOn('/CANCEL_COMP.WAV')
          }else if (msgInfo.apiCode == 'OMS0011'){ //MIT종목선택취소
            this.soundOn('/CANCEL_COMP.WAV')
          }else if (msgInfo.apiCode == 'OMS0012'){ //일괄취소
            this.soundOn('/CANCEL_COMP.WAV')
          }
        }

        if (this.orderSetting != null && this.orderSetting.messageAlert){
        
          
          this.$notify({ group: msgInfo.group, clean: true })

          this.$notify(msgInfo)
        }        
      }
    },
    soundOn(file){
      //if (this.orderSetting != null && this.orderSetting.soundAlert){
        new Audio("/wav"+file).play();
      //}
    },
    orderConfirm(orderData){
      //const orderData = {type:"sell", symCd:this.data.symCd ,qty:this.data.qty, price: this.data.price};
      if (orderData.apiCode && orderData.order){
        this.sendOmsMessage(orderData.apiCode, orderData.order);

        // if (orderData.apiCode == "OMS0001"){
        //   this.sendOmsMessage(orderData.apiCode, orderData.order);
        // }else if (orderData.apiCode == "OMS0002"){
        //   this.deleteOrder(orderData.apiCode, orderData.order);
        // }

        if (orderData.apiCode == "OMS0002" || orderData.apiCode == "OMS0003" || orderData.apiCode == "OMS0005"){
          //this.deleteOrder(orderData.apiCode, orderData.order);
        }
      }
    },
    sellMarket(ordQty, msg){
      //주문내역이 있으면 리턴 결과 오기전 재주문 불가
      if (!this.btnSellMarketFlag){
        return;
      }

      if (ordQty == undefined){
        ordQty = this.ordClick;
      }

      this.btnSellMarketFlag = false

      const order = {
        usrKey: Utils.getUuidv4(),
        accId : this.account.accId,
        symCd : this.symbol.name,
        symNo : this.symbol.symbolno,
        trdDt : '',
        ordId : 0,
        orgOrdNo: 0,
        ordSdCd : '1',
        ordTpCd : '2',
        ordPrc : 0,
        ordQty : ordQty,
        repReqQty : 0,
        cxlReqQty : 0,
        lv : this.leverage,
      }

      let message = "";
      if (msg == undefined){
        message = "시장가 매도 클릭"
      }else{
        message = msg;
      }

      this.sendOmsLogMessage(message);

      if (this.orderSetting != undefined && this.orderSetting.ordMessageAlert){
        // this.$alert.confirm(
        //   '<b style="font-weight:600;">종목명</b> : ' + this.symbol.description + '</br>' +
        //   '<b style="font-weight:600;">구분</b> : 매도(시장가)' + '</br>' +
        //   '<b style="font-weight:600;">수량</b> : ' + this.ordClick + '</br>' +
        //   '<b style="font-weight:600;">가격</b> : 시장가' + '</br>' +
        //   '<b style="font-weight:600;">매도 주문을 하시겠습니까?</b>', '주문 확인')
        // .then((response) => {
        //   if (!response) {
        //     this.btnBuyMarketFlag = true
        //     return;
        //   }else{
        //     this.sendOmsMessage('OMS0001', order)
        //   }
        // })
        const orderData = {apiCode:"OMS0001", order: order, symbol:this.symbol ,qty:this.ordClick, price: 0, message:message};
        this.$modal.show("modalConfirmOrder", orderData);
      }else{
        this.sendOmsMessage('OMS0001', order)
      }

      //1.5초후 버튼 누를 수 있게 설정
      if (!this.btnSellMarketFlag){
        setTimeout(() => {
          this.btnSellMarketFlag = true
        }, 1500)
      }
    },
    buyMarket(ordQty, msg){
      //주문내역이 있으면 리턴 결과 오기전 재주문 불가
      if (!this.btnBuyMarketFlag){
        return;
      } 

      this.btnBuyMarketFlag = false;

      if (ordQty == undefined){
        ordQty = this.ordClick;
      }

      const order = {
        usrKey: Utils.getUuidv4(),
        accId : this.account.accId,
        symCd : this.symbol.name,
        symNo : this.symbol.symbolno,
        trdDt : '',
        ordId : 0,
        orgOrdNo: 0,
        ordSdCd : '2',
        ordTpCd : '2',
        ordPrc : 0,
        ordQty : ordQty,
        repReqQty : 0,
        cxlReqQty : 0,
        lv : this.leverage,
      }

      let message = "";
      if (msg == undefined){
        message = "시장가 매수 클릭"
      }else{
        message = msg;
      }

      this.sendOmsLogMessage(message);
      

      if (this.orderSetting != undefined && this.orderSetting.ordMessageAlert){
        // this.$alert.confirm(
        //   '<b style="font-weight:600;">종목명</b> : ' + this.symbol.description + '</br>' +
        //   '<b style="font-weight:600;">구분</b> : 매수(시장가)' + '</br>' +
        //   '<b style="font-weight:600;">수량</b> : ' + this.ordClick + '</br>' +
        //   '<b style="font-weight:600;">가격</b> : 시장가' + '</br>' +
        //   '<b style="font-weight:600;">매수 주문을 하시겠습니까?</b>', '주문 확인')
        // .then((response) => {
        //   if (!response) {
        //     this.btnBuyMarketFlag = true
        //     return;
        //   }else{
        //     this.sendOmsMessage('OMS0001', order)
        //   }
        // })
        const orderData = {apiCode:"OMS0001", order: order, symbol:this.symbol ,qty:this.ordClick, price: 0, message:message};
        this.$modal.show("modalConfirmOrder", orderData);
      }else{
        this.sendOmsMessage('OMS0001', order)
      }

      //1.5초후 버튼 누를 수 있게 설정
      if (!this.btnBuyMarketFlag){
        setTimeout(() => {
          this.btnBuyMarketFlag = true;
        }, 1500)
      }
    },
    cancelSellAll(){ //SELL 전체 취소
      if (this.account == undefined || this.account.accId == undefined ||this.account.accId == 0){
        window.$alert.alert('선택된 계좌가 없습니다.', '알림')
        return;
      }

      // if (!this.getUsrAccPass){
      //   window.$alert.alert('계좌 비밀번호를 확인하세요.', '알림')
      //   return;
      // }

      //주문내역이 있으면 리턴 결과 오기전 재주문 불가
      if (!this.btnCancelSellAllFlag){
        return;
      }

      this.btnCancelSellAllFlag = false;

      const order = {
        usrKey: Utils.getUuidv4(),
        accId : this.account.accId,
        symCd : this.symbol.name,
        symNo : this.symbol.symbolno,
        trdDt : '',
        ordSdCd : '1',
        //accPass : this.getUsrAccPass
      };

      let message = "매도 취소 클릭";
      this.sendOmsLogMessage(message);

      if (this.orderSetting != undefined && this.orderSetting.clearMessageAlert){
        // this.$alert.confirm('매도주문을 취소 하시겠습니까?', '주문 확인')
        // .then((response) => {
        //   if (!response) {
        //     this.btnCancelSellAllFlag = true;
        //     return;
        //   }else{
        //     this.sendOmsMessage("OMS0010", order);
        //   }
        // });
        const orderData = {apiCode:"OMS0010", order: order, symbol:this.symbol, qty:0, price: 0, message:message};
        this.$modal.show("modalConfirmOrder", orderData);
      }else{
        this.sendOmsMessage("OMS0010", order);
      }

      //1.5초후 버튼 누를 수 있게 설정
      if (!this.btnCancelSellAllFlag){
        setTimeout(() => {
          this.btnCancelSellAllFlag = true;
        }, 1500);
      }
    },
    cancelBuyAll(){ //BUY 전체 취소
      if (this.account == undefined || this.account.accId == undefined ||this.account.accId == 0){
        window.$alert.alert('선택된 계좌가 없습니다.', '알림');
        return;
      }

      // if (!this.getUsrAccPass){
      //   window.$alert.alert('계좌 비밀번호를 확인하세요.', '알림');
      //   return;
      // }

      //주문내역이 있으면 리턴 결과 오기전 재주문 불가
      if (!this.btnCancelBuyAllFlag){
        return;
      }

      this.btnCancelBuyAllFlag = false;

      const order = {
        usrKey: Utils.getUuidv4(),
        accId : this.account.accId,
        symCd : this.symbol.name,
        symNo : this.symbol.symbolno,
        trdDt : '',
        ordSdCd : '2',
        //accPass : this.getUsrAccPass
      };

      let message = "매수 취소 클릭";
      this.sendOmsLogMessage(message);

      if (this.orderSetting != undefined && this.orderSetting.clearMessageAlert){
        // this.$alert.confirm('매수주문을 취소 하시겠습니까?', '주문 확인')
        // .then((response) => {
        //   if (!response) {
        //     this.btnCancelBuyAllFlag = true;
        //     return;
        //   }else{
        //     this.sendOmsMessage("OMS0010", order);
        //   }
        // });
        const orderData = {apiCode:"OMS0010", order: order, symbol:this.symbol, qty:0, price: 0, message:message};
        this.$modal.show("modalConfirmOrder", orderData);
      }else{
        this.sendOmsMessage("OMS0010", order);
      }

      //1.5초후 버튼 누를 수 있게 설정
      if (!this.btnCancelBuyAllFlag){
        setTimeout(() => {
          this.btnCancelBuyAllFlag = true;
        }, 1500);
      }
    },
    cancelSellStAll(){ //SELL STOP 전체 취소
      if (this.account == undefined || this.account.accId == undefined ||this.account.accId == 0){
        window.$alert.alert('선택된 계좌가 없습니다.', '알림');
        return;
      }

      // if (!this.getUsrAccPass){
      //   window.$alert.alert('계좌 비밀번호를 확인하세요.', '알림');
      //   return;
      // }

      //주문내역이 있으면 리턴 결과 오기전 재주문 불가
      if (!this.btnCancelSellStAllFlag){
        return;
      }

      this.btnCancelSellStAllFlag = false;

      const order = {
        usrKey: Utils.getUuidv4(),
        accId : this.account.accId,
        symCd : this.symbol.name,
        symNo : this.symbol.symbolno,
        trdDt : '',
        ordSdCd : '1',
        //accPass : this.getUsrAccPass
      };

      let message = "MIT 매도 취소 클릭";
      this.sendOmsLogMessage(message);

      if (this.orderSetting != undefined && this.orderSetting.clearMessageAlert){
        // this.$alert.confirm('ST매도주문을 취소 하시겠습니까?', '주문 확인')
        // .then((response) => {
        //   if (!response) {
        //     this.btnCancelSellStAllFlag = true;
        //     return;
        //   }else{
        //     this.sendOmsMessage("OMS0011", order)
        //   }
        // });
        const orderData = {apiCode:"OMS0011", order: order, symbol:this.symbol, qty:0, price: 0, message:message};
        this.$modal.show("modalConfirmOrder", orderData);
      }else{
        this.sendOmsMessage("OMS0011", order)
      }

      //1.5초후 버튼 누를 수 있게 설정
      if (!this.btnCancelSellStAllFlag){
        setTimeout(() => {
          this.btnCancelSellStAllFlag = true;
        }, 1500);
      }
    },
    cancelBuyStAll(){ //BUY STOP 전체 취소
      if (this.account == undefined || this.account.accId == undefined ||this.account.accId == 0){
        window.$alert.alert('선택된 계좌가 없습니다.', '알림');
        return;
      }

      // if (!this.getUsrAccPass){
      //   window.$alert.alert('계좌 비밀번호를 확인하세요.', '알림');
      //   return;
      // }
      
      //주문내역이 있으면 리턴 결과 오기전 재주문 불가
      if (!this.btnCancelBuyStAllFlag){
        return;
      }

      this.btnCancelBuyStAllFlag = false;

      const order = {
        usrKey: Utils.getUuidv4(),
        accId : this.account.accId,
        symCd : this.symbol.name,
        symNo : this.symbol.symbolno,
        trdDt : '',
        ordSdCd : '2',
        //accPass : this.getUsrAccPass
      };

      let message = "MIT 매수 취소 클릭";
      this.sendOmsLogMessage(message);

      if (this.orderSetting != undefined && this.orderSetting.clearMessageAlert){
        // this.$alert.confirm('ST매수주문을 취소 하시겠습니까?', '주문 확인')
        // .then((response) => {
        //   if (!response) {
        //     this.btnCancelBuyStAllFlag = true;
        //     return;
        //   }else{
        //     this.sendOmsMessage("OMS0011", order);
        //   }
        // });
        const orderData = {apiCode:"OMS0011", order: order, symbol:this.symbol, qty:0, price: 0, message:message};
        this.$modal.show("modalConfirmOrder", orderData);
      }else{
        this.sendOmsMessage("OMS0011", order);
      }
      
      //1.5초후 버튼 누를 수 있게 설정
      if (!this.btnCancelBuyStAllFlag){
        setTimeout(() => {
          this.btnCancelBuyStAllFlag = true;
        }, 1500);
      }
    },

    sendOmsMessage(apiCode, body) {
      if ( !window.$store.getters['SocketOms/getIsConnectOms'] ) {
        window.$alert.alert('주문서버 연결이 불안정합니다.\n새로고침 후 다시 시도해주세요.');
        return;
      }

      //비밀번호 추가
      //body.accPass = this.getUsrAccPass;

      window.$store.dispatch('SocketOms/sendOmsMessage', {apiCode:apiCode, body:body});
    },
    sendOmsLogMessage(message){ // 사용자 로그 저장
      message = "모바일 "+message;
      window.$store.dispatch('SocketOms/setLogMsg', {message:message});
    },
    //손절 틱설정값 저장
    setSellSlTick(tickVal){
      this.sellSlTick = Object.assign(Number(tickVal));
    },
    //익절 틱설정값 저장
    setBuySlTick(tickVal){
      this.buySlTick = Object.assign(Number(tickVal));
    },
    setClickOrd(val){
      this.ordClick = Number(val);
    },
    setOrdClick(val){
      this.ordClick = Number(val);
    },
    sellSlStock() {
      if (this.account == undefined || this.account.accId == undefined ||this.account.accId == 0){
        window.$alert.alert('계좌 정보가 없습니다.', '알림');
        return;
      }

      // if (!this.getUsrAccPass){
      //   window.$alert.alert('계좌 비밀번호를 확인하세요.', '알림')
      //   return;
      // }

      const order = {
        usrKey: Utils.getUuidv4(),
        accId : this.account.accId,
        symCd : this.symbol.name,
        symNo : this.symbol.symbolno,
        trdDt : '',
        ordId : 0,
        orgOrdNo: 0,
        ordSdCd : '1',
        ordTpCd : '1',
        ordPrc : 0,
        tickVal : this.sellSlTick,
        chkVal : '2', // '1' 익절 '2' 손절
        //accPass : this.getUsrAccPass
      };
      let apiCode = "OMS0007";
      if (this.slStockVal2){ //체크값이 true이면 삭제처리
        apiCode = "OMS0008";
      }
      this.sendOmsMessage(apiCode, order)
    },
    buySlStock() {
      if (this.account == undefined || this.account.accId == undefined ||this.account.accId == 0){
        window.$alert.alert('선택된 계좌가 없습니다.', '알림')
        return;
      }

      // if (!this.getUsrAccPass){
      //   window.$alert.alert('계좌 비밀번호를 확인하세요.', '알림')
      //   return;
      // }

      const order = {
        usrKey: Utils.getUuidv4(),
        accId : this.account.accId,
        symCd : this.symbol.name,
        symNo : this.symbol.symbolno,
        trdDt : '',
        ordId : 0,
        orgOrdNo: 0,
        ordSdCd : '1',
        ordTpCd : '1',
        ordPrc : 0,
        tickVal : this.buySlTick,
        chkVal : '1', // '1' 익절 '2' 손절 
        //accPass : this.getUsrAccPass
      };

      let apiCode = "OMS0007";
      if (this.slStockVal1){ //체크값이 true이면 삭제처리
        apiCode = "OMS0008";
      }
      this.sendOmsMessage(apiCode, order);
    },
    updateSlStock(data){
      if (data.chkType == "2"){ //손절
        this.slStockVal2 = data.chkVal;
      }else if (data.chkType == "1"){ //익절
        this.slStockVal1 = data.chkVal;
      }      
    },
    clearOrderData(type){ //전/현 종목 청산
      if (this.account == undefined || this.account.accId == undefined ||this.account.accId == 0){
        window.$alert.alert('선택된 계좌가 없습니다.', '알림')
        return;
      }

      // if (!this.getUsrAccPass){
      //   window.$alert.alert('계좌 비밀번호를 확인하세요.', '알림')
      //   return;
      // }

      //주문내역이 있으면 리턴 결과 오기전 재주문 불가
      if (!this.btnCancelAllFlag){
        return;
      }
      
      if (type == 'all' || type == 'symbol'){
        let msg = "현종목";
        let symCd = this.symbol.name;
        let symNo = this.symbol.symbolno;
        if (type == 'all'){
          msg = "전종목";
          symCd = "";
        }

        this.btnCancelAllFlag = false;

        const order = {
          usrKey: Utils.getUuidv4(),
          accId : this.account.accId,
          symCd : symCd,
          symNo : symNo,
          trdDt : '',
          clearType : type,
          accPass : this.getUsrAccPass,
        }

        let message = msg;
        message += " 청산 클릭";
        this.sendOmsLogMessage(message);

        if (this.orderSetting != undefined && this.orderSetting.clearMessageAlert){
          const orderData = {apiCode:"OMS0013", order: order, symbol:this.symbol, qty:0, price: 0, message:message};
          this.$modal.show("modalConfirmOrder", orderData);
        }else{
          this.sendOmsMessage("OMS0013", order);
        }
      }
      

      //1.5초후 버튼 누를 수 있게 설정
      if (!this.btnCancelAllFlag){
        setTimeout(() => {
          this.btnCancelAllFlag = true
        }, 1500)
      }
    },
    //잔고 내역 업데이트
    updateTotalValPl(newVal){
      this.totalValPl = Object.assign({}, newVal);
      this.dailyPlMn.totalLossCut = newVal.totalLossCut;
      this.dailyPlMn.totalValPl = newVal.totalValPl;
      //this.dailyPlMn.balMn = newVal.balMn;
    },
    //담보금 업데이트
    updateDailyPlMn(newVal){
      //console.log("updateDailyPlMn ", newVal)
    },
    popupOrder(){
      this.popupId = window.open("/orderPop", "_blank", "width=800, height=600")
    },
    //종목평가 업데이트
    setSymbolPrice(){
      const usdExr = this.dailyPlMn.usdExR;
      const usdExHkd = this.dailyPlMn.usdExHkd;
      let data = this.symbolPlMn.data;

      if (this.nowPrice != undefined && this.nowPrice.curPr){
        //평가손익 계산
        let val1 = 0;
        let val2 = 0;
        let valProLoss = 0;
        if (this.nowPrice.curPr == 0 || data == undefined || data.ordSdCd == undefined || data.ordSdCd == 0){
          valProLoss = 0;
        }else{
          if (data.ordSdCd == "1"){ //매도
            val1 = data.execPrc *  data.balQty;
            val2 = this.nowPrice.curPr * data.balQty;
          }else if (data.ordSdCd == "2"){ //매수
            val1 = this.nowPrice.curPr * data.balQty;
            val2 = data.execPrc * data.balQty;
          }

          //달러로 계산
          if (data.symNo == "2"){ //홍콩달러
            valProLoss = ((val1 - val2) * data.trdUnt * usdExHkd);

          }else{
            valProLoss = ((val1 - val2) / this.tikVal(this.symbol)) * data.trdUnt; //달러로 계산
          }
          
          if (isNaN(valProLoss)) valProLoss = 0;
          

          //원화로 표시
          if (this.currencyInfo.currency == "WON"){
            valProLoss = Math.ceil(valProLoss * usdExr);
            //data.valPl = valProLoss.toFixed(1);
          }else{ //달러
            //data.valPl = valProLoss.toFixed(2);
          }
        }
        this.symbolPlMn.valPl = valProLoss;
      }
    },
    //일반주문 수량 설정
    setStdOrdQty(stdQty){
      this.ordStdQty = Number(stdQty);
    },
    setOrdStdPrc(stdPrc){
      this.ordStdPrc = stdPrc;
    },
    stdSellBuy(type){
      if (!this.ordStdType){
        window.$alert.alert('유형을 선택하세요.', '알림');
        return;
      }else if (!this.ordStdQty || this.ordStdQty < 1){
        window.$alert.alert('수량을 입력 해주세요.', '알림');
        return;        
      }else if (!this.ordStdPrc || this.ordStdPrc <= 0){
        window.$alert.alert('가격을 입력 해주세요.', '알림');
        return;        
      }

      if (type == "buy" && this.buyAbleQty == 0){
        // this.$notify({ group: 'notification', clean: true })
        // this.$notify({
        //   group : 'notification',
        //   title : 'FAIL',
        //   text : '[주문거부] 주문가능 수량이 부족합니다.'
        // });

        window.$alert.alert('주문가능 수량이 부족합니다.', '알림');
        return;
      }

      //주문내역이 있으면 리턴 결과 오기전 재주문 불가
      if (!this.btnStdFlag){
        return;
      }

      let ordSdCd = "0";

      if (type == "sell"){
        ordSdCd = "1";
      }else if (type == "buy"){
        ordSdCd = "2";
      }

      let ordTpCd = "0";
      let apiCode = "";
      let stTpCd = "2";
      let ordPrc = 0;

      if (this.ordStdType == "1"){ //시장가
        ordTpCd = "2";
        apiCode = "OMS0001";
      }else if (this.ordStdType == "2"){ //지정가
        ordTpCd = "1";
        apiCode = "OMS0001";
        ordPrc = this.ordStdPrc;
      }else if (this.ordStdType == "3"){ //MIT
        ordTpCd = "2";
        apiCode = "OMS0004";
        ordPrc = this.ordStdPrc;
        
        //stTpCd : 스탑유형코드 // '1' 위방향 '2' 아래방향 (주문가격이 현재가보다 클경우 1 아닌경우 2)
        if (Number(this.nowPrice.curPr) == Number(this.ordStdPrc)){
          this.$notify({ group: 'notification', clean: true })
  
          this.$notify({
            group : 'notification',
            title : 'FAIL',
            text : '[주문거부] 현재가에 MIT주문이 불가능합니다.'
          });
          return;
        } else if (this.nowPrice.curPr < Number(this.ordStdPrc)){
          stTpCd = "1";
        }
      }

      this.btnStdFlag = false;

      const order = {
        usrKey: Utils.getUuidv4(),
        accId : this.account.accId,
        symCd : this.symbol.name,
        symNo : this.symbol.symbolno,
        trdDt : '',
        ordId : 0,
        orgOrdNo: 0,
        ordSdCd : ordSdCd,
        ordTpCd : ordTpCd,
        ordPrc : ordPrc,
        ordQty : this.ordStdQty,
        repReqQty : 0,
        cxlReqQty : 0,
        stTpCd : stTpCd,
        accPass : this.getUsrAccPass,
        lv : this.leverage,
      }

      if (this.orderSetting != undefined && this.orderSetting.ordMessageAlert){
        const orderData = {apiCode:apiCode, order: order, symbol:this.symbol ,qty:this.ordStdQty, price: Number(ordPrc)};
        this.$modal.show("modalConfirmOrder", orderData);
      }else{
        this.sendOmsMessage('OMS0001', order);
      }

      //0.3초후 버튼 누를 수 있게 설정
      if (!this.btnStdFlag){
        setTimeout(() => {
          this.btnStdFlag = true;
        }, 300);
      }
    },
    completeOrderSetting(){
      EventBus.$emit('Layout:event:loadLayout', this.layout)
    },
    completeDepositWithdraw(){ //입출금 완료 후
      //유저 데이터 재조회
      window.$store.dispatch('SocketOms/selectUsrData', {accId:this.account.accId, symCd:this.symbol.name, symNo:this.symbol.symbolno, lv:this.leverage});
    },
    addSellOrd(){
      if (isNaN(this.ordClick)){
        this.ordClick = 0;
      }
      if (this.ordClick < 200) {
        this.ordClick++;
      }
    },
    minusSellOrd(){
      if (isNaN(this.ordClick)){
        this.ordClick = 1;
      }
      if (this.ordClick > 1) {
        this.ordClick--;
      }
    },
    reSetData(){
      this.lowPrice = 0;
      this.highPrice = 0;
      this.lowPrice = 0;
      this.yesterdayClosingPrice = 0;
      this.buyAvgPrc = 0;
      this.sellAvgPrc = 0;

      this.buyOrdPrc = 0;
      this.buyOrdQty = 1;
      this.sellOrdPrc = 0;
      this.sellOrdQty = 1;

      this.ovhData = {
        offerHo5:0,
        offerHo4:0,
        offerHo3:0,
        offerHo2:0,
        offerHo1:0,
        offerRem5:0,
        offerRem4:0,
        offerRem3:0,
        offerRem2:0,
        offerRem1:0,
        offerNo5:0,
        offerNo4:0,
        offerNo3:0,
        offerNo2:0,
        offerNo1:0,
        bidHo5:0,
        bidHo4:0,
        bidHo3:0,
        bidHo2:0,
        bidHo1:0,
        bidRem5:0,
        bidRem4:0,
        bidRem3:0,
        bidRem2:0,
        bidRem1:0,
        bidNo5:0,
        bidNo4:0,
        bidNo3:0,
        bidNo2:0,
        bidNo1:0
      };
    },
    setSelectedInnerTab(tab) {
      this.selectedInnerTab = tab.id;
      if (this.selectedInnerTab == this.tabList[2].id){ //정정
        this.resetModify();
      }
    },
    sellOrder(){
      if (this.sellOrderType != null && this.sellOrderType != 'stop'){
        let data = {ordTpCd:this.sellOrderType, ordSdCd:'2', ordQty:this.sellOrdQty, ordPrc:this.sellOrdPrc}
        this.orderChk(data);
      }else if (this.sellOrderType != null && this.sellOrderType == 'stop'){
        let data = {ordTpCd:this.sellOrderType, ordSdCd:'2', ordQty:this.sellOrdQty, ordPrc:this.sellOrdPrc}
        this.stopOrderChk(data);
      }
    },
    buyOrder(){
      if (this.buyOrderType != null && this.buyOrderType != 'stop'){
        let data = {ordTpCd:this.buyOrderType, ordSdCd:'1', ordQty:this.buyOrdQty, ordPrc:this.buyOrdPrc}
        this.orderChk(data);
      }else if (this.buyOrderType != null && this.buyOrderType == 'stop'){
        let data = {ordTpCd:this.buyOrderType, ordSdCd:'1', ordQty:this.buyOrdQty, ordPrc:this.buyOrdPrc}
        this.stopOrderChk(data);
      }
    },
    orderChk(data){
      if ((data.ordTpCd == "1" && data.ordPrc == null || data.ordPrc == 0)){
        this.$alert.alert('지정가 주문 가격을 확인 해주세요.')
        return;
      }else if (data.ordQty < 1){
        this.$alert.alert('주문 수량을 확인 해주세요.')
        return;
      }

      let message = "일반 주문창";

      if (data.ordSdCd == "2"){ // 매도
        message += " 매도 : " + this.symbol.name;
        if (data.ordTpCd == "1"){ //지정가
          message += "/지정가"
          message += "/수량 "+ data.ordQty + "/가격 "+Number(data.ordPrc).toFixed(this.tikDotSz);
          this.sellStock(data.ordPrc, data.ordQty, message);
        }else if (data.ordTpCd == "2"){ //시장가
          message += "/시장가"
          message += "/수량 "+ data.ordQty + "/가격 0"
          this.sellMarket(data.ordQty, message);
        }
      }else if (data.ordSdCd == "1"){ //매수
        message += " 매수 : " + this.symbol.name;
        if (data.ordTpCd == "1"){ //지정가
          message += "/지정가"
          message += "/수량 "+ data.ordQty + "/가격 "+Number(data.ordPrc).toFixed(this.tikDotSz);
          this.buyStock(data.ordPrc, data.ordQty, message);
        }else if (data.ordTpCd == "2"){ //시장가
          message += "/시장가"          
          message += "/수량 "+ data.ordQty + "/가격 0";
          this.buyMarket(data.ordQty, message);
        }
      }
    },
    stopOrderChk(data){
      if ((data.ordPrc == 0)){
        this.$alert.alert('예약 주문 가격을 확인 해주세요.')
        return;
      }else if (data.ordQty < 1){
        this.$alert.alert('주문 수량을 확인 해주세요.')
        return;
      }

      let message = "일반 주문창";

      if (data.ordSdCd == "2"){ // 매도
        message += " 매도 :";
        message += " " + this.symbol.name + "/MIT/수량 "+data.ordQty + "/가격 "+Number(data.ordPrc).toFixed(this.tikDotSz);
        this.sellStStock(data.ordPrc, data.ordQty, message);
      }else if (data.ordSdCd == "1"){ //매수
        message += " 매수 :";
        message += " " + this.symbol.name + "/MIT/수량 "+data.ordQty + "/가격 "+Number(data.ordPrc).toFixed(this.tikDotSz);
        this.buyStStock(data.ordPrc, data.ordQty, message);
      }
     
      this.sendOmsLogMessage(message);
    },
    chgSellOrdQty(e){
      if (e.target.value > 200){
        this.sellOrdQty = 200;
      }else if (e.target.value < 1){
        this.sellOrdQty = 1;
      }
    },
    chgBuyOrdQty(e){
      if (e.target.value > 200){
        this.buyOrdQty = 200;
      }else if (e.target.value < 1){
        this.buyOrdQty = 1;
      }
    },
    addSellQty(){
      if (this.sellOrdQty < 200) {
        this.sellOrdQty++;
      }
    },
    minusSellQty(){
      if (this.sellOrdQty > 1) {
        this.sellOrdQty--;
      }
    },
    addBuyQty(){
      if (this.buyOrdQty < 200) {
        this.buyOrdQty++;
      }
    },
    minusBuyQty(){
      if (this.buyOrdQty > 1) {
        this.buyOrdQty--;
      }
    },
    addSellPrc(){
      this.sellOrdPrc = (Number(this.sellOrdPrc) + this.tikVal(this.symbol)).toFixed(this.tikDotSz);
    },
    minusSellPrc(){
      if (Number(this.sellOrdPrc) <= 0){
        this.sellOrdPrc = (0).toFixed(this.tikDotSz);
      }else{
        this.sellOrdPrc = (Number(this.sellOrdPrc) - this.tikVal(this.symbol)).toFixed(this.tikDotSz);
      }

    },
    addBuyPrc(){
      this.buyOrdPrc = (Number(this.buyOrdPrc) + this.tikVal(this.symbol)).toFixed(this.tikDotSz);
    },
    minusBuyPrc(){
      if (Number(this.buyOrdPrc) <= 0){
        this.buyOrdPrc = (0).toFixed(this.tikDotSz);
      }else{
        this.buyOrdPrc = (Number(this.buyOrdPrc) - this.tikVal(this.symbol)).toFixed(this.tikDotSz);
      }      
    },
    chgSellOrdPrc(e){
      console.log("chgSellOrdPrc >>> ", e.target.value)
      if (Number(e.target.value) < 0.0){
        this.sellOrdPrc = (0).toFixed(this.tikDotSz);
      }else{
        let newPrice = e.target.value.toString().replace(/[^0-9.]/g, '');
        this.sellOrdPrc = this.chkOrdPrc(newPrice);
      }
    },
    chgBuyOrdPrc(e){
      if (Number(e.target.value) < 0.0){
        this.buyOrdPrc = (0).toFixed(this.tikDotSz);
      }else{
        let newPrice = e.target.value.toString().replace(/[^0-9.]/g, '');

        this.buyOrdPrc = this.chkOrdPrc(newPrice);
      }
    },
    chkOrdPrc(newPrice){ // 일반주문 가격비교\
      //앞자리수 체크
      const nowPrc = this.nowPrice.curPr.toString().split(".");
      let strLen = 0;
      strLen = nowPrc[0].length;

      const str = newPrice.toString().split(".");

      let first = 0;

      if (str[0].length > strLen){
        first = str[0].toString().substr(0, strLen);
      }else{
        first = str[0];
      }

      //소수점이 있을경우
      if (newPrice != undefined && newPrice.toString().indexOf(".") > -1){
        let val = 0;
        //소수점 자리수가 클경우 자름
        if (str[1].length > stringCount(this.symbol.pricescale)){
          val = first + "." + str[1].substr(0, Number(stringCount(this.symbol.pricescale)));
        }else{
          //소수점 허용이 0이면 앞자리만 사용
          if (stringCount(this.symbol.pricescale) == 0){
            val = Utils.rpad(first, strLen, "0");
          }else{
            val = Utils.rpad(first, strLen, "0") + "." + str[1];
          }
        }

        if (val != undefined && val.toString().indexOf(".") > -1){
          val = Math.round(Number(val) / this.tikVal(this.symbol)) * this.tikVal(this.symbol);
        }else{
          if (first){
            val = Utils.rpad(first, strLen, "0");
          }else{
            val = "";
          }
        }
        if (val){
          val = Number(val).toFixed(this.tikDotSz);
        }
        newPrice = val;
      }else{
        first = Utils.rpad(first, strLen, "0");
        newPrice = Number(first).toFixed(this.tikDotSz);
      }
      return newPrice;
    },
    chgSellSlTick(){ //손절 값 변경시
      if (isNaN(this.sellSlTick)  || this.sellSlTick == 0){
        this.sellSlTick = 1;
      }else if (this.sellSlTick >= 200){
        this.sellSlTick = 200;
      }else{
        this.sellSlTick = Math.ceil(this.sellSlTick);
      }
    },
    addSellSlTick(){ //손절 틱값 증가
      if (this.slStockVal2){
        return;
      }
      if (this.sellSlTick == null || this.sellSlTick <=0){
        this.sellSlTick = 1;
      }else if (isNaN(this.sellSlTick)){
        this.sellSlTick = 1;
      }else if (this.sellSlTick >= 200){
        this.sellSlTick = 200;
      }else{
        this.sellSlTick++;
      }
    },
    minusSellTick(){ //손절 틱값 감소
      if (this.slStockVal2){
        return;
      }
      if (this.sellSlTick == null || this.sellSlTick <= 1){
        this.sellSlTick = 1;
      }else if (isNaN(this.sellSlTick)){
        this.sellSlTick = 1;
      }else{
        this.sellSlTick--;
      }
    },
    chgBuySlTick(){
      if (isNaN(this.buySlTick) || this.buySlTick == 0){
        this.buySlTick = 1;
      }else if (this.buySlTick >= 200){
        this.buySlTick = 200;
      }else{
        this.buySlTick = Math.ceil(this.buySlTick);
      }
    },
    addBuySlTick(){ //익절 틱값 증가
      if (this.slStockVal1){
        return;
      }
      if (this.buySlTick == null || this.buySlTick <= 0){
        this.buySlTick = 1;
      }else if (isNaN(this.buySlTick)){
        this.buySlTick = 1;
      }else if (this.buySlTick >= 200){
        this.buySlTick = 200;
      }else{
        this.buySlTick++;
      }
    },
    minusBuySlTick(){ //익절 틱값 감소
      if (this.slStockVal1){
        return;
      }
      if (this.buySlTick == null || this.buySlTick <= 1){
        this.buySlTick = 1;
      }else if (isNaN(this.buySlTick)){
        this.buySlTick = 1;
      }else{
        this.buySlTick--;
      }
    },
    alertDisabled(){
      this.$alert.alert("체크박스 상태를 해제 후 수정할 수 있습니다.", '알림');
    },
    /* 주문 */
    sellStock(price, count, msg) {
      const order = {
        usrKey: Utils.getUuidv4(),
        accId : this.account.accId,
        symCd : this.symbol.name,
        symNo : this.symbol.symbolno,
        trdDt : '',
        ordId : 0,
        orgOrdNo: 0,
        ordSdCd : '1',
        ordTpCd : '1',
        ordPrc : price,
        ordQty : count,
        repReqQty : 0,
        cxlReqQty : 0,
        lv : this.leverage,
      }

      let message = "";
      if (msg != undefined){
        message = msg;
        this.sendOmsLogMessage(message);
      }

      if (this.orderSetting != undefined && this.orderSetting.ordMessageAlert){
        const orderData = {apiCode:"OMS0001", order: order, symbol:this.symbol ,qty:count, price: price, message:message};
        this.$modal.show("modalConfirmOrder", orderData);
      }else{
        this.sendOmsMessage('OMS0001', order)
      }
    },
    buyStock(price, count, msg) {
      const order = {
        usrKey: Utils.getUuidv4(),
        accId : this.account.accId,
        symCd : this.symbol.name,
        symNo : this.symbol.symbolno,
        trdDt : '',
        ordId : 0,
        orgOrdNo: 0,
        ordSdCd : '2',
        ordTpCd : '1',
        ordPrc : price,
        ordQty : count,
        repReqQty : 0,
        cxlReqQty : 0,
        lv : this.leverage,
      }

      let message = "";
      if (msg != undefined){
        message = msg;
        this.sendOmsLogMessage(message);
      }

      if (this.orderSetting != undefined && this.orderSetting.ordMessageAlert){
        const orderData = {apiCode:"OMS0001", order: order, symbol:this.symbol ,qty:count, price: price, message:message};
        this.$modal.show("modalConfirmOrder", orderData);
      }else{
        this.sendOmsMessage('OMS0001', order)
      }
    },
    sellStStock(price, count, msg) {
      let stTpCd = "2";
      //stTpCd : 스탑유형코드 // '1' 위방향 '2' 아래방향 (주문가격이 현재가보다 클경우 1 아닌경우 2)
      if (this.nowPrice.curPr == price){
        this.$alert.alert('[주문거부] 현재가에 MIT주문이 불가능합니다.')
        return;
      } else if (this.nowPrice.curPr < price){
        stTpCd = "1"
      }
      
      const order = {
        usrKey: Utils.getUuidv4(),
        accId : this.account.accId,
        symCd : this.symbol.name,
        symNo : this.symbol.symbolno,
        trdDt : '',
        ordId : 0,
        orgOrdNo: 0,
        ordSdCd : '1',
        ordTpCd : '2',
        ordPrc : price,
        ordQty : count,
        repReqQty : 0,
        cxlReqQty : 0,
        stTpCd : stTpCd,
        lv : this.leverage,
      }

      console.log("msg", msg)

      if (this.orderSetting != undefined && this.orderSetting.ordMessageAlert){
        const orderData = {apiCode:"OMS0004", order: order, symbol:this.symbol ,qty:count, price: price, message:msg};
        this.$modal.show("modalConfirmOrder", orderData);
      }else{
        this.sendOmsMessage('OMS0004', order)
      }
    },
    buyStStock(price, count, msg) {
      let stTpCd = "2";
      //stTpCd : 스탑유형코드 // '1' 위방향 '2' 아래방향 (주문가격이 현재가보다 클경우 1 아닌경우 2)
      if (this.nowPrice.curPr == price){
        this.$alert.alert('[주문거부] 현재가에 MIT주문이 불가능합니다.')
        return
      } else if (this.nowPrice.curPr < price){
        stTpCd = "1"
      }
            
      const order = {
        usrKey: Utils.getUuidv4(),
        accId : this.account.accId,
        symCd : this.symbol.name,
        symNo : this.symbol.symbolno,
        trdDt : '',
        ordId : 0,
        orgOrdNo: 0,
        ordSdCd : '2',
        ordTpCd : '2',
        ordPrc : price,
        ordQty : count,
        repReqQty : 0,
        cxlReqQty : 0,
        stTpCd : stTpCd,
        lv : this.leverage,
      }

      if (this.orderSetting != undefined && this.orderSetting.ordMessageAlert){
        const orderData = {apiCode:"OMS0004", order: order, symbol:this.symbol ,qty:count, price: price, message:msg};
        this.$modal.show("modalConfirmOrder", orderData);
      }else{
        this.sendOmsMessage('OMS0004', order)
      }
    },
    chgModifyOrdPrc(e){
      if (Number(e.target.value) < 0.0){
        this.orgOrder.ordPrc = (0).toFixed(this.tikDotSz);
      }
    },
    modifyPrc(){
      if (this.orgOrder == null || this.orgOrder.orgOrdNo == undefined || this.orgOrder.orgOrdNo == 0){
        window.$alert.alert('원주문 정보가 없습니다.')
        return;
      }
      let orgOrders = new Array()

      orgOrders.push(this.orgOrder);

      let order = {
          usrKey : Utils.getUuidv4(),
          accId : this.account.accId,
          symCd : this.symbol.name,
          orderList : orgOrders
      }

      let omsCode = "OMS0003";
      let typeNm = "매도정정";
      if (this.orgOrder.ordType == "ORD"){
        omsCode = "OMS0003";
        typeNm = "매도정정";
      }else if (this.orgOrder.ordType == "STOP"){
        omsCode = "OMS0006";
        typeNm = "MIT매도정정";
      }

      if (this.orderSetting.ordMessageAlert){       
        this.$alert.confirm(
          '<b style="font-weight:600;">종목명</b> : ' + this.symbol.description + '</br>' +
          '<b style="font-weight:600;">구분</b> : '+ typeNm + '</br>' +
          '<b style="font-weight:600;">수량</b> : ' + this.orgOrder.ordQty + '</br>' +
          '<b style="font-weight:600;">원주문가격</b> : ' + Number(this.orgOrder.orgOrdPrc).toFixed(this.tikDotSz) + '</br>' +
          '<b style="font-weight:600;">정정가격</b> : ' + Number(this.orgOrder.ordPrc).toFixed(this.tikDotSz) + '</br></br>' +
          '<b style="font-weight:600;">'+typeNm+' 주문을 하시겠습니까?</b>', '주문 확인')
        .then((response) => {
          if (!response) {
            return;
          }else{

            this.sendOmsMessage(omsCode, order)
          }
        })
      }else{
        this.sendOmsMessage(omsCode, order)
      }
      this.resetModify();
    },
    addModifyPrc(){
      if (this.orgOrder.orgOrdNo == undefined || this.orgOrder.orgOrdNo == 0){
        return;
      }
      this.orgOrder.ordPrc = (Number(this.orgOrder.ordPrc) + this.tikVal).toFixed(this.tikDotSz);
    },
    minusModifyPrc(){
      if (this.orgOrder.orgOrdNo == undefined || this.orgOrder.orgOrdNo == 0){
        return;
      }

      if (Number(this.orgOrder.ordPrc) <= 0){
        this.orgOrder.ordPrc = (0).toFixed(this.tikDotSz);
      }else{
        this.orgOrder.ordPrc= (Number(this.orgOrder.ordPrc) - this.tikVal).toFixed(this.tikDotSz);
      }
    },
    setOrderSize(){ //클릭주문 확대/축소
      if (!this.orderFullSize){
        this.sendOmsLogMessage("주문창확대 클릭");
      }else{
        this.sendOmsLogMessage("주문창축소 클릭");
      }
      this.orderFullSize = !this.orderFullSize;
    },
    goLogin(){
      this.$router.push('/login');
    },
    async selectLeverage(leverage){
      //레버리지 선택 주문정보 확인
      
      const data = {accNo:this.account.accNo, symCd:this.symbol.name};

      await this.$lhttp.get('/api/order/rtPosOrdChk', { params: data }).then((response) => {
        if (response.code == 200){
          if (response.data == null){
            this.$alert.alert('잔고/미체결 조회중 에러가 발생했습니다.', '안내');
            return;
          }else if (response.data.ordQty != undefined && response.data.ordQty > 0){
            this.$alert.alert('잔고/미체결 내역이 있으면 레버리지 변경 불가합니다.', '안내');
            this.leverage = this.orderSetting.leverage;
            return;
          }else{
            this.leverage = leverage.cd;
            this.orderSetting.leverage = leverage.cd;

            window.$store.dispatch('SocketOms/selectAbleQty', {accId:this.account.accId, symCd:this.symbol.name, symNo:this.symbol.symbolno, lv:leverage.cd});

            window.$store.dispatch('Layout/saveCustom', { type: 'OS', custom: this.orderSetting});

            EventBus.$emit('Layout:event:loadLayout', this.layout);
          }
        }
      });
    },
    selectStdPrc(selPrc){ //일반주문 호가 클릭 가격 설정
      if (selPrc == 0) return;
      if (this.mobileSelectedStandardOrder === 'sell'){
        this.sellOrdPrc = selPrc.toFixed(this.tikDotSz);
      }else if (this.mobileSelectedStandardOrder === 'buy'){
        this.buyOrdPrc = selPrc.toFixed(this.tikDotSz);
      }
    },
    disConnect(){
      //oms
      if (window.$store.getters['SocketOms/getIsConnectOms']) {
        //
        window.$store.dispatch('SocketOms/setDisconnect');
      }

      //소켓 시세
      if (window.$store.getters['SocketPrice/getIsConnectPrice']) {
        //setDisconnect
        window.$store.dispatch('SocketPrice/setDisconnect');
      }

      //폴링 시세
      if (window.$store.getters['SocketPrice/getIsPolingPrice']) {
        //clear polling
        window.$store.dispatch('SocketPrice/setClearPolling');
      }

      //메시지
      if (window.$store.getters['SocketMsg/getIsConnectMsg']) {
        //clear polling
        console.log("msg disconnect???")
        window.$store.dispatch('SocketMsg/setDisconnect');
      }  
    }
  },
  destroyed(){
    this.disConnect();
  },
};
</script>
<style lang="scss" scoped>

.counter-btn {
  flex: 0 42px;
}
.available {
  flex: 0 42px;
}
.available2 {
  flex: 0 60px;
}
.available-label2 {
  margin-left: 10px;
}
.order-btn {
  /* flex: 0 55px; */
}
.currentPrice {
  //color : yellow !important;
  color : red !important;
  font-weight: 400;
}
.rgt_login{
  width:65px; 
  &h3{
    padding-top:3px;
  }
}

.rgt_img{
  float: left;
  padding-right: 3px;
}

.stopSellPrice{
  background-color: var(--table-blue);
}

.stopBuyPrice{
  background-color: var(--table-red);
}

</style>