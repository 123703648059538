<template>
  <div>
    <modal name="modalSettings" classes="main-modal" :adaptive="true" :width="width" :height="height"
    @before-open="beforeOpen"
    @opened="opened">
      <div class="modal-header">
        <h2 class="modal-header-title">환경설정</h2>
        <button @click="closeModal()"><img src="@/assets/icons/icon-close-modal.svg" alt="" /></button>
      </div>

      <div class="modal-body">
        <div class="tab-nav-container">
          <div class="tab" :class="{ active: selectedTab === 'ordSetting' }" @click="setSelectedTab('ordSetting')">기본설정</div>
          <div class="tab" :class="{ active: selectedTab === 'setPwd' }" @click="setSelectedTab('setPwd')">암호설정</div>
          <div class="tab" :class="{ active: selectedTab === 'overNight' }" @click="setSelectedTab('overNight')" v-if="getLoginUserInfo.lvTpCd == undefined || getLoginUserInfo.lvTpCd == 'N'">오버나잇</div>
        </div>

        <div class="tab-content" v-show="selectedTab === 'ordSetting'">
          <div class="col2-grid">
            <!-- <div class="tab-inner-row">
              <h3 class="subtitle">칼라 설정</h3>
              <ul class="row-list">
                <li>
                  <div class="theme-radio">
                    <input type="radio" id="blackTheme" name="themeRadio" value="black" v-model="selectedTheme" @change="changeTheme()" />
                    <label class="black" for="blackTheme">블랙</label>
                  </div>
                </li>
                <li>
                  <div class="theme-radio">
                    <input type="radio" id="blueTheme" name="themeRadio" value="blue" v-model="selectedTheme" @change="changeTheme()" />
                    <label class="blue" for="blueTheme">블루</label>
                  </div>
                </li>
                <li>
                  <div class="theme-radio">
                    <input type="radio" id="orangeTheme" name="themeRadio" value="orange" v-model="selectedTheme" @change="changeTheme()" />
                    <label class="orange" for="orangeTheme">오렌지</label>
                  </div>
                </li>
                <div class="theme-radio">
                  <input type="radio" id="greenTheme" name="themeRadio" value="green" v-model="selectedTheme" @change="changeTheme()" />
                  <label class="green" for="greenTheme">그린</label>
                </div>
                <li>
                  <div class="theme-radio">
                    <input type="radio" id="whiteTheme" name="themeRadio" value="white" v-model="selectedTheme" @change="changeTheme()" />
                    <label class="white" for="whiteTheme">화이트</label>
                  </div>
                </li>
              </ul>
            </div> -->
            <div class="tab-inner-row">
              <h3 class="subtitle">주문알림/확인 설정</h3>
              <ul class="row-list">
                <li>
                  <div class="checkbox-default">
                    <input type="checkbox" id="messageAlert" v-model="orderSetting.messageAlert" />
                    <label for="messageAlert">주문알림</label>
                  </div>
                </li>
                <li>
                  <div class="checkbox-default">
                    <input type="checkbox" id="ordMessageAlert" v-model="orderSetting.ordMessageAlert" />
                    <label for="ordMessageAlert">주문확인</label>
                  </div>
                </li>
                <li>
                  <div class="checkbox-default">
                    <input type="checkbox" id="clearMessageAlert" v-model="orderSetting.clearMessageAlert" />
                    <label for="clearMessageAlert">청산/취소 확인</label>
                  </div>
                </li>
                <li>
                  <div class="checkbox-default">
                    <input type="checkbox" id="soundAlert" v-model="orderSetting.soundAlert" />
                    <label for="soundAlert">음성</label>
                  </div>
                </li>
              </ul>
            </div>
            <div class="tab-inner-row" v-if="mobileSet.type == 'PC'">
              <h3 class="subtitle">클릭주문 설정</h3>
              <ul class="row-list">
                <li>
                  <div class="radio-default">
                    <input type="radio" id="oneClick" name="orderClick" value="oneClick" v-model="orderSetting.btnClick" @click="selClick('oneClick')" />
                    <label for="oneClick">원클릭</label>
                  </div>
                </li>
                <li>
                  <div class="radio-default">
                    <input type="radio" id="dbClick" name="orderClick" value="dbClick" v-model="orderSetting.btnClick" @click="selClick('dbClick')" />
                    <label for="dbClick">더블클릭</label>
                  </div>
                </li>
              </ul>
            </div>
            <div class="tab-inner-row" v-if="mobileSet.type == 'PC'">
              <h3 class="subtitle">스탑로스 틱 설정</h3>
              <ul class="row-list">
                <li>
                  손절
                  <CounterComp :maxCnt="200" :maxLength=3 @childEvent="setSlTikVal1" :value="orderSetting.slTikVal1.tikVal" />
                </li>
                <li>
                  익절
                  <CounterComp :maxCnt="200" :maxLength=3 @childEvent="setSlTikVal2" :value="orderSetting.slTikVal2.tikVal" />
                </li>
              </ul>
            </div>
            <div class="tab-inner-row full">
              <h3 class="subtitle">주문수량 설정</h3>
              <ul class="row-list">
                <li>
                  1
                  <CounterComp :maxCnt="200" :maxLength=3 @childEvent="setClickVal1" :value="orderSetting.clickVal1.val" />
                </li>
                <li>
                  2
                  <CounterComp :maxCnt="200" :maxLength=3 @childEvent="setClickVal2" :value="orderSetting.clickVal2.val" />
                </li>
                <li>
                  3
                  <CounterComp :maxCnt="200" :maxLength=3 @childEvent="setClickVal3" :value="orderSetting.clickVal3.val" />
                </li>
                <li>
                  4
                  <CounterComp :maxCnt="200" :maxLength=3 @childEvent="setClickVal4" :value="orderSetting.clickVal4.val" />
                </li>
                <li>
                  5
                  <CounterComp :maxCnt="200" :maxLength=3 @childEvent="setClickVal5" :value="orderSetting.clickVal5.val" />
                </li>
              </ul>
            </div>

            <div class="tab-inner-row full shortcut" v-if="mobileSet.type == 'PC'">
              <h3 class="subtitle">키보드 단축키 설정</h3>
              <div class="shortcut-inner-grid">
                <ul class="shortcut-list">
                  <li>
                    <div class="label">중앙정렬</div>
                    <div class="base-dropdown">
                      <BaseDropdown id="ordSetCenterKey" :options="keyOptionList" :default="defaultOption.centerKey" @ordSetCenterKey="ordSetCenterKey" />
                    </div>
                    +
                    <CounterComp :maxCnt="9" :maxLength=1 :value="orderSetting.centerKey.numberKey" @childEvent="setCenterNumberKey" />
                  </li>
                  <li>
                    <div class="label">시장가매도</div>
                    <div class="base-dropdown">
                      <BaseDropdown id="ordSetMarketSellKey" :options="keyOptionList" :default="defaultOption.marketSellKey" @ordSetMarketSellKey="ordSetMarketSellKey" />
                    </div>
                    +
                    <CounterComp :maxCnt="9" :maxLength=1 :value="orderSetting.marketSellKey.numberKey" @childEvent="setMarketSellNumberKey" />
                  </li>
                  <li>
                    <div class="label">시장가매수</div>
                    <div class="base-dropdown">
                      <BaseDropdown id="ordSetMarketBuyKey" :options="keyOptionList" :default="defaultOption.marketBuyKey" @ordSetMarketBuyKey="ordSetMarketBuyKey" />
                    </div>
                    +
                    <CounterComp :maxCnt="9" :maxLength=1 :value="orderSetting.marketBuyKey.numberKey" @childEvent="setMarketBuyNumberKey" />
                  </li>
                  <li>
                    <div class="label">매도취소</div>
                    <div class="base-dropdown">
                      <BaseDropdown id="ordSetCancelSell" :options="keyOptionList" :default="defaultOption.cancelSell" @ordSetCancelSell="ordSetCancelSell" />
                    </div>
                    +
                    <CounterComp :maxCnt="9" :maxLength=1 :value="orderSetting.cancelSell.numberKey" @childEvent="setCancelSellNumberKey" />
                  </li>
                  <li>
                    <div class="label">매수취소</div>
                    <div class="base-dropdown">
                      <BaseDropdown id="ordSetCancelBuy" :options="keyOptionList" :default="defaultOption.cancelBuy" @ordSetCancelBuy="ordSetCancelBuy" />
                    </div>
                    +
                    <CounterComp :maxCnt="9" :maxLength=1 :value="orderSetting.cancelBuy.numberKey" @childEvent="setCancelBuyNumberKey" />
                  </li>
                </ul>
                <ul class="shortcut-list">
                  <li>
                    <div class="label">MIT매도취소</div>
                    <div class="base-dropdown">
                      <BaseDropdown id="ordSetStopSellKey" :options="keyOptionList" :default="defaultOption.stopSellKey" @ordSetStopSellKey="ordSetStopSellKey" />
                    </div>
                    +
                    <CounterComp :maxCnt="9" :maxLength=1 :value="orderSetting.stopSellKey.numberKey" @childEvent="setStopSellNumberKey" />
                  </li>
                  <li>
                    <div class="label">MIT매수취소</div>
                    <div class="base-dropdown">
                      <BaseDropdown id="ordSetStopBuyKey" :options="keyOptionList" :default="defaultOption.stopBuyKey" @ordSetStopBuyKey="ordSetStopBuyKey" />
                    </div>
                    +
                    <CounterComp :maxCnt="9" :maxLength=1 :value="orderSetting.stopBuyKey.numberKey" @childEvent="setStopBuyNumberKey" />
                  </li>
                  <li>
                    <div class="label">전종목청산</div>
                    <div class="base-dropdown">
                      <BaseDropdown id="ordSetAllClear" :options="keyOptionList" :default="defaultOption.allClear" @ordSetAllClear="ordSetAllClear" />
                    </div>
                    +
                    <CounterComp :maxCnt="9" :maxLength=1 :value="orderSetting.allClear.numberKey" @childEvent="setAllClearNumberKey" />
                  </li>
                  <li>
                    <div class="label">현종목청산</div>
                    <div class="base-dropdown">
                      <BaseDropdown id="ordSetSymbolClear" :options="keyOptionList" :default="defaultOption.symbolClear" @ordSetSymbolClear="ordSetSymbolClear" />
                    </div>
                    +
                    <CounterComp :maxCnt="9" :maxLength=1 :value="orderSetting.symbolClear.numberKey" @childEvent="setSymbolClearNumberKey" />
                  </li>
                </ul>
              </div>
            </div>

            <!-- <div class="tab-inner modal-settings"></div> -->
            <!-- <div class="division-line"></div> -->
          </div>
        </div>

        <div class="tab-content" v-if="selectedTab === 'setPwd'">
          <validation-observer ref="validator">
          <div class="tab-inner">
            <h3 class="subtitle">비밀번호 입력 (8~16자리 알파벳+숫자+특수기호)</h3>
              <validation-provider :rules="{ required:true, limit: 16, }" name="현재 비밀번호" v-slot="{errors}">
                <div class="input-row">
                  <label for="passwd" class="input-label">현재 비밀번호</label>
                  <input type="password" id="passwd" v-model="form.passwd" maxlength="16"  />
                </div>
                <div class="input-row-error">
                  <label></label>
                  <div class="error" :class="[errors[0] ? 'errPadding': '']">{{errors[0]}}</div>
                </div>
              </validation-provider>
              <validation-provider :rules="{ required:true, password:true, limit: 16, }" name="변경 비밀번호" v-slot="{errors}">
                <div class="input-row">
                  <label for="changePasswd" class="input-label">변경 비밀번호</label>
                  <input type="password" id="changePasswd" v-model="form.changePasswd" maxlength="16" />
                </div>
                <div class="input-row-error">
                  <label></label>
                  <div class="error" :class="[errors[0] ? 'errPadding': '']">{{errors[0]}}</div>
                </div>
              </validation-provider>
              <validation-provider :rules="{ required:true, password:true, confirmed:'변경 비밀번호', limit: 16, }" name="변경 확인" v-slot="{errors}">
                <div class="input-row">
                  <label for="confPasswd" class="input-label">변경 확인</label>
                  <input type="password" id="confPasswd" v-model="form.confPasswd" maxlength="16" />
                </div>
                <div class="input-row-error">
                  <label></label>
                  <div class="error" :class="[errors[0] ? 'errPadding': '']">{{errors[0]}}</div>
                </div>
              </validation-provider>
          </div>
          </validation-observer>
        </div>

        <div class="tab-content medium-top" v-if="selectedTab === 'overNight'">
          <div class="flex-row">
            <button class="btn-line" @click="setOverNight">오버나잇 설정</button>
            <button class="btn-line" @click="cancelOverNight">오버나잇 취소</button>
            <button class="btn-search" @click="searchOverNight">
              <img src="@/assets/icons/icon-search.svg" alt="" />
              조회
            </button>
          </div>

          <div class="white-label-table-container">
            <table class="white-label-table variant">
              <tbody>
                <tr>
                  <th>평가담보금액</th>
                  <td><span :class="{ 'color-red' : dailyPlMn.balMn > 0, 'color-blue' : dailyPlMn.balMn < 0 }">{{ thousand(dailyPlMn.balMn + dailyPlMn.totalValPl) }}</span></td>
                  <th>평가손익</th>
                  <td><span :class="{ 'color-red' : dailyPlMn.totalValPl > 0, 'color-blue' : dailyPlMn.totalValPl < 0 }">{{ thousand(dailyPlMn.totalValPl) }}</span></td>
                  <th>로스컷금액</th>
                  <td>{{ thousand(dailyPlMn.totalLossCut) }}</td>
                  <th class="last">필요담보금</th>
                  <td class="last">{{ thousand(dailyPlMn.totalOverAmt) }}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="ag-container modal-settings">
            <AgOvernightTable
            :account="account"
            :setGridSearch="setGridSearch"
            @updateTotalValPl="updateTotalValPl"
            />
          </div>

          <div class="subinfo-container">
            <ol class="subinfo-list">
              <li>오버나잇 가능기준 종목별 1계약당 2,000,000원</li>
              <li>오버나잇 금액은 장마감 시점으로 적용됩니다.</li>
              <li>장마감시 금액이 부족하면 신청하더라도 자동 청산됩니다.</li>
              <li>오버나잇 설정 후 일부 주문 청산건 혹은 신규 진입 주문건이 발생하는 경우에 오버나잇은 취소됩니다.</li>
              <li>만기일, 기타 회사에서 공지한 날에는 오버나잇이 불가합니다. (장마감후 관리자 청산)</li>
              <li>기타 오버나잇에 대한 문의는 고객센터로 연락주시기 바랍니다.</li>
            </ol>
          </div>
        </div>
      </div>

      <div class="modal-footer" v-show="selectedTab !== 'overNight'">
        <button class="btn-primary darkgray" @click="cancel()">취소</button>
        <button class="btn-primary blue" @click="save">저장</button>
      </div>
    </modal>
  </div>
</template>

<script>
import AgOvernightTable from '@/components/agTables/AgOvernightTable.vue';
import CounterComp from '@/components/CounterComp.vue';
//import { bus } from '@/main';
import BaseDropdown from '@/components/BaseDropdown.vue';

export default {
  components: { 
    AgOvernightTable,
    CounterComp,
    BaseDropdown
  },
  props:{
    mobileSet: {
      type: Object,
      default: () => {
        return {type:"PC", ord:true, mit:true, btnBottom:true};
      }
    },
  },
  computed:{
    getDailyPlMn: function(){
      return window.$store.getters['SocketOms/getDailyPlMn'];
    },
    getLoginUserInfo: function(){
      let loginInfo = window.$store.getters['Login/getLoginUserInfo'];
      return loginInfo;
    },
  },
  watch:{
    getDailyPlMn:{
      handler(res){
        this.dailyPlMn.fxPlMn = res.fxPlMn;
        this.dailyPlMn.balMn = res.balMn;
        this.dailyPlMn.usdExR = res.usdExR;
        this.dailyPlMn.usdExHkd = res.usdExHkd;
      },        
      deep:true
    },
  },
  name: 'WhiteLabelModalSettings',
  data() {
    return {
      width: '',
      height: '',
      selectedTab: 'ordSetting',
      selectedTheme: 'blue',
      keyOptionList: [],
      orderSetting:{
        btnClick: 'oneClick',
        // oneClick: false,
        // dbClick: false,
        slTikVal1: {tikVal:1},
        slTikVal2: {tikVal:1},
        clickVal1: {val:1},
        clickVal2: {val:1},
        clickVal3: {val:1},
        clickVal4: {val:1},
        clickVal5: {val:1},
        centerKey: {primaryKey: '', numberKey: 1, keyCode:null},
        marketSellKey: {primaryKey: '', numberKey: 1, keyCode:null},
        marketBuyKey: {primaryKey: '', numberKey: 1, keyCode:null},
        cancelSell: {primaryKey: '', numberKey: 1, keyCode:null},
        cancelBuy: {primaryKey: '', numberKey: 1, keyCode:null},
        stopSellKey: {primaryKey: '', numberKey: 1, keyCode:null},
        stopBuyKey: {primaryKey: '', numberKey: 1, keyCode:null},
        allClear: {primaryKey: '', numberKey: 1, keyCode:null},
        symbolClear: {primaryKey: '', numberKey: 1, keyCode:null},
        messageAlert: false,
        ordMessageAlert: false,
        clearMessageAlert: false,
        soundAlert: false,
      },
      defaultOption: {
        centerKey: '',
        marketSellKey: '',
        marketBuyKey: '',
        cancelSell: '',
        cancelBuy: '',
        stopSellKey: '',
        stopBuyKey: '',
        allClear: '',
        symbolClear: '',
      },
      loadOption: null,
      keyCodeList: [
        {keyCode:49, Number:1},
        {keyCode:50, Number:2},
        {keyCode:51, Number:3},
        {keyCode:52, Number:4},
        {keyCode:53, Number:5},
        {keyCode:54, Number:6},
        {keyCode:55, Number:7},
        {keyCode:56, Number:8},
        {keyCode:57, Number:9}
      ],
      keyCheckList: [],
      form:{
        passwd:'',
        changePasswd:'',
        confPasswd:'',
      },
      gridSearch:{
        accId:0,
        accNo:"",
        type:"search",
      },
      setGridSearch:{
        accId:0,
      },
      account:{},
      dailyPlMn:{fxPlMn:"0", fxCms:"0", niPlMn:"0", balMn:"0", usdExR:"0", usdExHkd:"0", totalValPl:"0", totalLossCut:"0", dailyPlMn:"0", totalOverAmt:"0", data:null},
    }
  },
  created() {
    if (window.innerWidth > 1200) {
      this.width = 1000;
      this.height = 730;
    } else {
      this.width = '100%';
      this.height = '100%';
    }
  },
  mounted() {

  },
  methods: {
    selClick(type){
      let message = "";
      if (type == "oneClick"){
        message = "원클릭 체크";
      }else if (type == "dbClick"){
        message = "더블클릭 체크";
      }

      this.sendOmsLogMessage(message);

    },
    cancel(){
      let message = "";
      if (this.selectedTab == "ordSetting"){
        message = "환경설정 취소 버튼 클릭";
      }else if (this.selectedTab == "setPwd"){
        message = "암호설정 취소 버튼 클릭";
      }

      this.sendOmsLogMessage(message);
      
      this.closeModal();
    },
    closeModal() {      
      this.$modal.hide('modalSettings');
    },
    thousand(value) {
      if (value == undefined){
        return 0;
      }else{
        return value.toLocaleString('en-US', { minimumFractionDigits: 0 });
      } 
    },
    changeTheme() {
      //bus.$emit('selectedTheme', this.selectedTheme);
    },
    opened(){
      //주문설정
      this.orderSettingInit();
    },
    beforeOpen(e){
      console.log("beforeOpen e.prams", e.params)

      //오바나잇 평가정보 초기화
      this.dailyPlMn.totalValPl = "0";
      this.dailyPlMn.totalLossCut = "0";
      this.dailyPlMn.totalOverAmt = "0";
      // = {fxPlMn:"0", fxCms:"0", niPlMn:"0", balMn:"0", usdExR:"0", usdExHkd:"0", totalValPl:"0", totalLossCut:"0", dailyPlMn:"0", totalOverAmt:"0", data:null};
      
      //탭 초기화
      this.selectedTab = "ordSetting";

      //주문설정 저장 초기화
      this.loadOption = null;
      
      //주문 설정초기화
      this.orderSettingReset();

      //암호설정 초기화
      this.form.passwd = "";
      this.form.changePasswd = "";
      this.form.confPasswd = "";

      if (e.params != undefined && e.params != null){
        this.account = e.params.account;
      }

      //주문설정 저장
      this.loadOption = window.$store.getters['Layout/getOrderSetting'];
    },
    orderSettingInit(){
      //단축키 목록
      this.keyOptionList = [{name:'NONE'},{name:'ALT'}];

      console.log("this.loadOption", this.loadOption)

      //설정이 없으면 초기설정값
      if (this.loadOption == null){
        this.orderSetting = {
          btnClick: 'oneClick',
          // oneClick: true,
          // dbClick: false,
          slTikVal1: {tikVal:5},
          slTikVal2: {tikVal:5},
          clickVal1: {val:1},
          clickVal2: {val:2},
          clickVal3: {val:3},
          clickVal4: {val:4},
          clickVal5: {val:5},
          centerKey: {primaryKey: 'NONE', numberKey: 1, keyCode:null},
          marketSellKey: {primaryKey: 'NONE', numberKey: 1, keyCode:null},
          marketBuyKey: {primaryKey: 'NONE', numberKey: 1, keyCode:null},
          cancelSell: {primaryKey: 'NONE', numberKey: 1, keyCode:null},
          cancelBuy: {primaryKey: 'NONE', numberKey: 1, keyCode:null},
          stopSellKey: {primaryKey: 'NONE', numberKey: 1, keyCode:null},
          stopBuyKey: {primaryKey: 'NONE', numberKey: 1, keyCode:null},
          allClear: {primaryKey: 'NONE', numberKey: 1, keyCode:null},
          symbolClear: {primaryKey: 'NONE', numberKey: 1, keyCode:null},
          messageAlert: true,
          ordMessageAlert: true,
          clearMessageAlert: true,
          soundAlert: true,
        },
        
        this.defaultOption = {
          centerKey: 'NONE',
          marketSellKey: 'NONE',
          marketBuyKey: 'NONE',
          cancelSell: 'NONE',
          cancelBuy: 'NONE',
          stopSellKey: 'NONE',
          stopBuyKey: 'NONE',
          allClear: 'NONE',
          symbolClear: 'NONE',
        }

        console.log("디폴트", this.defaultOption.centerKey)

        // this.ordMessageAlert = true;
        // this.messageAlert = true;
        // this.clearMessageAlert = true;
        // this.soundAlert = true;
      }else{
        this.orderSetting = Object.assign({}, this.loadOption);

        // this.ordMessageAlert = this.orderSetting.ordMessageAlert;
        // this.messageAlert = this.orderSetting.messageAlert;
        // this.clearMessageAlert = this.orderSetting.clearMessageAlert;
        // this.soundAlert = this.orderSetting.soundAlert;
        
        this.defaultOption = {
          centerKey: this.orderSetting.centerKey.primaryKey,
          marketSellKey: this.orderSetting.marketSellKey.primaryKey,
          marketBuyKey: this.orderSetting.marketBuyKey.primaryKey,
          cancelSell: this.orderSetting.cancelSell.primaryKey,
          cancelBuy: this.orderSetting.cancelBuy.primaryKey,
          stopSellKey: this.orderSetting.stopSellKey.primaryKey,
          stopBuyKey: this.orderSetting.stopBuyKey.primaryKey,
          allClear: this.orderSetting.allClear.primaryKey,
          symbolClear: this.orderSetting.symbolClear.primaryKey,
        }
      }
    },
    orderSettingReset(){

      //팝업 설정 초기화
      this.orderSetting = {
        btnClick: 'oneClick',
        // oneClick: false,
        // dbClick: false,
        slTikVal1: {tikVal:1},
        slTikVal2: {tikVal:1},
        clickVal1: {val:1},
        clickVal2: {val:1},
        clickVal3: {val:1},
        clickVal4: {val:1},
        clickVal5: {val:1},
        centerKey: {primaryKey: '', numberKey: 1, keyCode:null},
        marketSellKey: {primaryKey: '', numberKey: 1, keyCode:null},
        marketBuyKey: {primaryKey: '', numberKey: 1, keyCode:null},
        cancelSell: {primaryKey: '', numberKey: 1, keyCode:null},
        cancelBuy: {primaryKey: '', numberKey: 1, keyCode:null},
        stopSellKey: {primaryKey: '', numberKey: 1, keyCode:null},
        stopBuyKey: {primaryKey: '', numberKey: 1, keyCode:null},
        allClear: {primaryKey: '', numberKey: 1, keyCode:null},
        symbolClear: {primaryKey: '', numberKey: 1, keyCode:null},
        messageAlert: false,
        ordMessageAlert: false,
        clearMessageAlert: false,
        soundAlert: false,
      },

      //체크 설정 디폴트 true, 최근설정 true 화면 체크를 못하여 모두 false
      // this.ordMessageAlert = false;
      // this.messageAlert = false;
      // this.clearMessageAlert = false;
      // this.soundAlert = false;

      this.defaultOption = {
        centerKey: '',
        marketSellKey: '',
        marketBuyKey: '',
        cancelSell: '',
        cancelBuy: '',
        stopSellKey: '',
        stopBuyKey: '',
        allClear: '',
        symbolClear: '',
      }

      this.keyOptionList = [];
    },
    //손절 틱설정값 저장
    setSlTikVal1(tickVal){
      this.orderSetting.slTikVal1.tikVal = Number(tickVal);
    },
    //익절 틱설정값 저장
    setSlTikVal2(tickVal){
      this.orderSetting.slTikVal2.tikVal = Number(tickVal);
    },
    setClickVal1(val){
      this.orderSetting.clickVal1.val = Number(val);
    },
    setClickVal2(val){
      this.orderSetting.clickVal2.val = Number(val);
    },
    setClickVal3(val){
      this.orderSetting.clickVal3.val = Number(val);
    },
    setClickVal4(val){
      this.orderSetting.clickVal4.val = Number(val);
    },
    setClickVal5(val){
      this.orderSetting.clickVal5.val = Number(val);
    },
    setOrdMsg(){
      this.orderSetting.ordMessageAlert = !this.ordMessageAlert;
      this.ordMessageAlert = !this.ordMessageAlert;
    },
    setMsg(){
      this.orderSetting.messageAlert = !this.messageAlert;
      this.messageAlert = !this.messageAlert;
    },
    setClearMsg(){
      this.orderSetting.clearMessageAlert = !this.clearMessageAlert;
      this.clearMessageAlert = !this.clearMessageAlert;
    },
    setSoundMsg(){
      this.orderSetting.soundAlert = !this.soundAlert;
      this.soundAlert = !this.soundAlert;
    },
    ordSetCenterKey(val){
      this.orderSetting.centerKey.primaryKey = val.name;
    },
    ordSetMarketSellKey(val){
      this.orderSetting.marketSellKey.primaryKey = val.name;
    },
    ordSetMarketBuyKey(val){
      this.orderSetting.marketBuyKey.primaryKey = val.name;
    },
    ordSetCancelSell(val){
      this.orderSetting.cancelSell.primaryKey = val.name;
    },
    ordSetCancelBuy(val){
      this.orderSetting.cancelBuy.primaryKey = val.name;
    },
    ordSetStopSellKey(val){
      this.orderSetting.stopSellKey.primaryKey = val.name;
    },
    ordSetStopBuyKey(val){
      this.orderSetting.stopBuyKey.primaryKey = val.name;
    },
    ordSetAllClear(val){
      this.orderSetting.allClear.primaryKey = val.name;
    },
    ordSetSymbolClear(val){
      this.orderSetting.symbolClear.primaryKey = val.name;
    },
    setCenterNumberKey(val){
      this.orderSetting.centerKey.numberKey = Number(val);
    },
    setMarketSellNumberKey(val){
      this.orderSetting.marketSellKey.numberKey = Number(val);
    },
    setMarketBuyNumberKey(val){
      this.orderSetting.marketBuyKey.numberKey = Number(val);
    },
    setCancelSellNumberKey(val){
      this.orderSetting.cancelSell.numberKey = Number(val);
    },
    setCancelBuyNumberKey(val){
      this.orderSetting.cancelBuy.numberKey = Number(val);
    },
    setStopSellNumberKey(val){
      this.orderSetting.stopSellKey.numberKey = Number(val);
    },
    setStopBuyNumberKey(val){
      this.orderSetting.stopBuyKey.numberKey = Number(val);
    },
    setAllClearNumberKey(val){
      this.orderSetting.allClear.numberKey = Number(val);
    },
    setSymbolClearNumberKey(val){
      this.orderSetting.symbolClear.numberKey = Number(val);
    },
    setCustom() {
      //중복체크용
      this.keyCheckList = [];

      //숫자키 이벤트 keyCode 입력
      this.orderSetting.centerKey.keyCode = this.getKeyCode(this.orderSetting.centerKey.numberKey, this.orderSetting.centerKey.primaryKey);
      this.orderSetting.marketSellKey.keyCode = this.getKeyCode(this.orderSetting.marketSellKey.numberKey, this.orderSetting.marketSellKey.primaryKey);
      this.orderSetting.marketBuyKey.keyCode = this.getKeyCode(this.orderSetting.marketBuyKey.numberKey, this.orderSetting.marketBuyKey.primaryKey);
      this.orderSetting.cancelSell.keyCode = this.getKeyCode(this.orderSetting.cancelSell.numberKey, this.orderSetting.cancelSell.primaryKey);
      this.orderSetting.cancelBuy.keyCode = this.getKeyCode(this.orderSetting.cancelBuy.numberKey, this.orderSetting.cancelBuy.primaryKey);
      this.orderSetting.stopSellKey.keyCode = this.getKeyCode(this.orderSetting.stopSellKey.numberKey, this.orderSetting.stopSellKey.primaryKey);
      this.orderSetting.stopBuyKey.keyCode = this.getKeyCode(this.orderSetting.stopBuyKey.numberKey, this.orderSetting.stopBuyKey.primaryKey);
      this.orderSetting.allClear.keyCode = this.getKeyCode(this.orderSetting.allClear.numberKey, this.orderSetting.allClear.primaryKey);
      this.orderSetting.symbolClear.keyCode = this.getKeyCode(this.orderSetting.symbolClear.numberKey, this.orderSetting.symbolClear.primaryKey);

      for(let i=0; i<this.keyCheckList.length; i++){
        let cnt = 0;
        for (let j=0; j<this.keyCheckList.length; j++){
          if (this.keyCheckList[i] == this.keyCheckList[j]){
            cnt++;
          }
        }
        if (cnt > 1){
          this.$alert.alert("단축키는 중복키를 설정할 수 없습니다.", '알림');
          return;
        }
      }

      window.$store.dispatch('Layout/saveCustom', { type: 'OS', custom: this.orderSetting});

      console.log("this.orderSetting", this.orderSetting);

      this.$emit('order-setting-complete');
      this.closeModal();
    },
    getKeyCode(numKey, primaryKey){
      //숫자키의 keyCode를 반환
      let keyCode = null;
      this.keyCodeList.forEach(item => {
        if (Number(numKey) === Number(item.Number)){
          keyCode = item.keyCode;
          return;
        }
      });

      //중복체크용 리스트 저장
      if (keyCode != null && primaryKey == "ALT"){
        this.keyCheckList.push(keyCode);
      }

      return keyCode;
    },
    setPassWd(){
      const { validator } = this.$refs

      validator.validate().then((result) => {
        if (result) {
          this.$alert.confirm('비밀번호를 변경 하시겠습니까?')
            .then((response) => {
              if (response) {     
                this.$lhttp
                  .post('/auth/profile/passwd/reset',
                    this.form)
                  .then((response) => {
                    if (response.data.code == 200){
                      this.$alert.alert('비밀번호가 변경 되었습니다.', '알림').then(() => {
                        this.closeModal();
                      })
                    }else{
                      this.$alert.alert(response.data.message, '알림');
                    }
                  })    
              }
            })
        }
      })
    },
    searchOverNight(){
      this.gridSearch.accId = this.account.accId;
      this.gridSearch.accNo = this.account.accNo;
      this.gridSearch.type = "search";
      this.setGridSearch = Object.assign({}, this.gridSearch);
    },
    setOverNight(){
      //평가담보금 체크
      //dailyPlMn.balMn + dailyPlMn.totalValPl dailyPlMn.totalOverAmt
      // if (Number(this.dailyPlMn.balMn + this.dailyPlMn.totalValPl) < Number(this.dailyPlMn.totalOverAmt)){
      //   this.$alert.alert("담보금이 부족합니다.", '알림');
      //   return;
      // }
      this.gridSearch.accId = this.account.accId;
      this.gridSearch.accNo = this.account.accNo;
      this.gridSearch.type = "set";
      this.setGridSearch = Object.assign({}, this.gridSearch);
    },
    cancelOverNight(){
      this.gridSearch.accId = this.account.accId;
      this.gridSearch.accNo = this.account.accNo;
      this.gridSearch.type = "cancel";
      this.setGridSearch = Object.assign({}, this.gridSearch);
    },
    setSelectedTab(selTab){
      if (this.selectedTab == selTab) return;
      this.orderSettingReset();
      this.selectedTab = selTab;

      if (this.selectedTab == "ordSetting"){
        this.orderSettingInit();
        console.log("set?? ", this.orderSetting)
      }else if (this.selectedTab == "setPwd"){
        this.sendOmsLogMessage("암호설정 클릭");
        this.form.passwd = "";
        this.form.changePasswd = "";
        this.form.confPasswd = "";
        //
      }else if (this.selectedTab == "overNight"){
        this.sendOmsLogMessage("오버나잇 클릭");
        const dailyPlMn = window.$store.getters['SocketOms/getDailyPlMn']
        this.dailyPlMn.fxPlMn = dailyPlMn.fxPlMn;
        this.dailyPlMn.balMn = dailyPlMn.balMn;
        this.dailyPlMn.usdExR = dailyPlMn.usdExR;
        this.dailyPlMn.usdExHkd = dailyPlMn.usdExHkd;
      }
    },
    save(){
      if (this.selectedTab == "ordSetting"){
        this.sendOmsLogMessage("환경설정 저장 버튼 클릭");
        this.setCustom();
      }else if (this.selectedTab == "setPwd"){
        this.sendOmsLogMessage("환경설정 취소 버튼 클릭");
        this.setPassWd();
      }
    },
    //잔고 내역 업데이트
    updateTotalValPl(newVal){
      this.totalValPl = Object.assign({}, newVal);
      this.dailyPlMn.totalLossCut = newVal.totalLossCut;
      this.dailyPlMn.totalValPl = newVal.totalValPl;
      this.dailyPlMn.totalOverAmt = newVal.totalOverAmt;
    },
    sendOmsLogMessage(message){
      if (window.location.pathname == "/mobile"){
        message = "모바일 " + message;
      }
      window.$store.dispatch('SocketOms/setLogMsg', {message:message});
    }
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/_variables.scss';
.tab-content {
  padding-left: 20px;
  padding-right: 20px;
  @media (max-width: $mobile) {
    padding-left: 0;
    padding-right: 0;
  }
  .tab-inner-row {
    &.full {
      grid-column: -1/1;
    }
    &.shortcut {
      @media (max-width: $mobile) {
        display: none;
      }
    }
    .row-list {
      flex-wrap: wrap;
      gap: 20px;
    }
  }
}
.base-dropdown {
  .dropdown {
    width: 100%;
    flex: 1;
  }
  .dropdown-list {
    width: 100%;
    flex: 1;
  }
}

.input-row-error {
  display: grid;
  grid-template-columns: 80px 200px;
  align-items: center;
  gap: 0 30px;
}

.error {
  color: var(--table-red);
  font-size: 12px;
}

.errPadding{
  padding-bottom:5px;
  width: 126%;
}
</style>
