<template>
  <div>
    <modal name="modalProfitLossTable" classes="main-modal" :adaptive="true" :width="width" :height="height"
    @before-open="beforeOpen"
    @opened="opened">
      <div class="modal-header">
        <h2 class="modal-header-title">매매손익</h2>
        <button @click="closeModal()"><img src="@/assets/icons/icon-close-modal.svg" alt="" /></button>
      </div>

      <div class="modal-body">
        <div class="filter-container">
          <div class="filter-row">
            <div class="inner">
              <label for="" class="row-label">조회일자</label>
							<div class="datepicker-container">
                <DatePicker v-model="gridSearch.fromDate" />
                ~
                <DatePicker v-model="gridSearch.toDate" />
              </div>
            </div>
            <button class="btn-search" @click="search()">
              <img src="@/assets/icons/icon-search.svg" alt="" />
              조회
            </button>
          </div>
        </div>


				<div class="ag-container" style="height: 424px">
					<AgProfitLossTable
           :account="account"
           :setGridSearch="setGridSearch"
          />
				</div>
      </div>

      <!-- <div class="modal-footer">hi</div> -->
    </modal>
  </div>
</template>

<script>

import DatePicker from '@/components/DatePicker.vue';
import AgProfitLossTable from '@/components/agTables/AgProfitLossTable.vue';
import Utils from '@/modules/utils'

export default {
	name: 'WhiteLabelModalProfitLossTable',
  components: { DatePicker, AgProfitLossTable },

  data() {
    return {
      width: '',
      height: '',
      account: {
        accNo: '',
        accId: 0,
      },
      gridSearch: {
        accId: 0,
        //accPass: '',
        fromDate: '', 
        toDate: '', 
      },
      setGridSearch:{
        accId: 0,
        //accPass: '',
        fromDate: '', 
        toDate: '',
      },
    };
  },

  created() {
    if (window.innerWidth > 1200) {
      this.width = 1000;
      this.height = 600;
    } else {
      this.width = '100%';
      this.height = '100%';
    }
  },

  mounted() {},

  methods: {
    closeModal() {
      this.$modal.hide('modalProfitLossTable');
    },
    opened(){
    },
    beforeOpen(e){
      //그리드 초기화
      this.selectTrdDt();
      
      this.account = Object.assign({}, e.params.account);
    },
    search(){
      this.gridSearch.accId = this.account.accId;
      this.setGridSearch = Object.assign({}, this.gridSearch);
    },
    async selectTrdDt(){
      const res = await window.$http.get('api/order/trdDt', { params: {} } );
      if (res != undefined && res.data != undefined){
        this.gridSearch.fromDate = res.data.replace(/(\d{4})(\d{2})(\d{2})/, "$1-$2-$3");
        this.gridSearch.toDate = res.data.replace(/(\d{4})(\d{2})(\d{2})/, "$1-$2-$3");
      }else{
        this.gridSearch.fromDate = Utils.getToday();
        this.gridSearch.toDate = Utils.getToday();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_variables.scss';
.filter-container {
	.filer-row {
		/* flex-wrap: nowrap; */
	}
}
.datepicker-container {
	margin-left: 0;
}

</style>