<template>
  <div style="width: 100%; height: 100%">
    <ag-grid-vue
      style="width: 100%; height: 100%"
      class="ag-theme-wts"
      :columnDefs="grid.columnDefs"
      :rowData="grid.rowData"
      :headerHeight="rowHeight"
      :rowHeight="rowHeight"
    />
  </div>
</template>

<script>
// import { ref } from 'vue';
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
// import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme
import { AgGridVue } from "ag-grid-vue"; // Vue Grid Logic
// eslint-disable-next-line no-unused-vars
import PaginationComp from '@/components/agTables/PaginationComp.vue';
import Utils from '@/modules/utils'
export default {
  name: "agLogTable",
  components: {
    AgGridVue,
    PaginationComp,
  },
  props:{
    account:{
      type: Object
    },
    setGridSearch:{
      type: Object
    }
  },
  watch:{
    setGridSearch:{
      handler(newVal){
        console.log("setGridSearch ", newVal)
        this.gridSearch = newVal;
        this.search();
      },
      deep:true
    }
  },
  data() {
    return {
      grid:{
        columnDefs: [
          { 
            headerName: "일시", 
            field: "msgTm",
            minWidth: 130,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
          },
          { 
            headerName: "종목코드",
            field: "symCd",
            minWidth: 80,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
          },
          { 
            headerName: "내용",
            field: "msg",
            minWidth: 250,
            flex: 5,
            cellStyle: {
              // justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
          },
          // { 
          // 	headerName: "비고",
          // 	field: "비고",
          // 	minWidth: 80,
          // 	flex: 1,
          // 	cellStyle: {
          // 		justifyContent: 'center'
          // 	},
          // 	headerClass: "center-align-header",
          // 	suppressMovable: true,
          // },
        ],
        rowData: [],
        autoSizeStrategy: null,
        paginationPageSize: null,
        paging: {
          page: 1,
          size: 20,
          total: 0,
          pageSize : 10
        } 
      },
      currentPage: '',
      totalPages: '',
			rowHeight: '',
      gridApi: null,
    };
  },
	created() {
    this.grid.rowData = [];
		this.isMobile();
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
    },
		isMobile() {
      if (window.innerWidth <= 1200) {
        return this.rowHeight = 40;
      } else {
        return this.rowHeight = 30;
      }
    },
    async search(){
      // 조회 조건
      this.gridSearch.accId   = this.account.accId
      //this.gridSearch.accPass = this.account.accPass
      this.gridSearch.page    = this.grid.paging.page
      this.gridSearch.size    = this.grid.paging.size

      const response = await this.$lhttp.get('/api/msg/list/page', { params: this.gridSearch })

      if ( response && response.data ) {
        //this.grid.paging.total = response.data.total
        this.grid.rowData = response.data.content

        if ( response.data.content.length <= 0 ) {
          this.$alert.alert('조회된 로그내역이 없습니다.', '알림')
        }
      }   
    },
  },
};


</script>

<style lang="scss" scoped>
@import '@/assets/scss/agGridStyles.scss';
</style>