<template>
  <div class="checkbox-default">
    <input type="checkbox" :id="id" :value="value" />
    <label for="all">{{ label }}</label>
  </div>
</template>

<script>
export default {
  name: 'WhiteLabelCheckbox',
	props: {
		id: String,
		value: String,
		label: String,
	},
  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>
