<template>
  <div class="container" ref="dropdown" :value="value">
    <input type="text" v-model="modelPrice" @click="handleClick" :class="{ active: active }" step="0.25" ref="inputNum" v-on:blur="completeUpdate" />
    <div class="counter-input">
      <button class="up" @click="countUp">
        <img src="@/assets/icons/triangle-black.svg" alt="" />
      </button>
      <button class="down" @click="countDown">
        <img src="@/assets/icons/triangle-black.svg" alt="" />
      </button>
    </div>
    <!-- <div class="price-dropdown" v-if="isDropdownVisible" :class="{modal:modal}">
      <ul>
                <li class="price-item" 
                    v-for="(price, index) in priceList"
                    :key="index"
          :class="{'current-price': Number(currentPrice) === Number(price)}"
                    @click="modelPrice = price, isDropdownVisible = false"
                >
                    <h3>{{ price.toFixed(tikDotSz) }}</h3>
                </li>
      </ul>
    </div> -->
  </div>
</template>

<script>
import { stringCount } from '@/modules/helper';
import Utils from '@/modules/utils';

export default {
  name: 'OrderInput',
  components: {
  },
  props: {
    symbol: {
      type: Object,
      default: () => {
        return { name: '', minmov : 0.1, pricescale : 100 }
      },
    },
    nowPrice : Number,
    modal: Boolean,
    value: {type: Number, default: 0},
  },
  watch: {
    symbol: {
      handler(newSymbol) {
        this.symbolTik = newSymbol.minmov/newSymbol.pricescale,
        this.currentPrice = 0;
        this.modelPrice = 0;
        this.priceList = [];
        this.priceList.push(this.currentPrice);
        //input 증가값 변경
        this.$refs.inputNum.step= newSymbol.minmov/newSymbol.pricescale;
      }
    },
    nowPrice: {
      handler(newPrice) {
        this.currentPrice = newPrice;

        //if (newPrice > 0){
          //if (this.modelPrice < 1){
            //this.modelPrice = newPrice;
          //}
        //}
      }
    },
		value: {
      handler(newValue) {
        this.modelPrice = newValue;
      },
      deep: true,
		},
    modelPrice : {
      handler(newPrice) {
        //this.modelPrice = Number(newPrice).toFixed(this.tikDotSz);
        //this.modelPrice = newPrice;
        newPrice = newPrice.toString().replace(/[^0-9.]/g, '');

        //앞자리수 체크
        const nowPrc = this.currentPrice.toString().split(".");
        let strLen = 0;
        strLen = nowPrc[0].length;

        const str = newPrice.toString().split(".");
        
        let first = 0;

        

        if (str[0].length > strLen){
          first = str[0].toString().substr(0, strLen);
        }else{
          first = str[0];
          // if (str[0].length > strLen){
          //   first = str[0].toString().substr(0, strLen);
          // }else{
          //   first = str[0];
          // }
        }

        //소수점이 있을경우
        if (newPrice != undefined && newPrice.toString().indexOf(".") > -1){
          
          let val = 0;

          


          //소수점 자리수가 클경우 자름
          if (str[1].length > stringCount(this.symbol.pricescale)){
            //this.modelPrice = Number(newPrice).toFixed(this.tikDotSz);
            val = first + "." + str[1].substr(0, Number(stringCount(this.symbol.pricescale)));
          }else{
            //소수점 허용이 0이면 앞자리만 사용
            if (stringCount(this.symbol.pricescale) == 0){
              val = first;
            }else{
              val = first + "." + str[1];
            }
          }

          

          //this.modelPrice = Math.round(Number(val) / this.tikVal) * this.tikVal;          

          this.modelPrice = val;

          //소수점이 있을경우 가까운 가격쪽으로 설정
          // if ((price).toString().indexOf(".") > 0){
          //   let avgPrice = price.toFixed(this.stockOrderBook.pricescale);

          //   if (balSide == "1"){ //매도
          //     price = Math.round((Number(avgPrice) / this.tikVal) + 0.0000000001) * this.tikVal
          //   }else if (balSide == "2"){ //매수
          //     price = Math.round(Number(avgPrice) / this.tikVal) * this.tikVal
          //   }
          // }
        }else{
          //this.modelPrice = Number(newPrice).toFixed(this.tikDotSz);
          this.modelPrice = first;
        }

        ///if (Number.isInteger(newPrice))

        this.$emit("updateOrdPrc", this.modelPrice);
        
      }
    }
  },
  data() {
    return {
      priceMakeCnt: 10, //시세 위아래 그려질 개수
      modelPrice: 0,
      isDropdownVisible: false,
      active: false,
      currentPrice: 0,
      priceList: [],
      setStep : 0.25,
    };
  },
    computed: {
    tikVal(){
      return (this.symbol.minmov / this.symbol.pricescale);
    },
    tikDotSz(){
      return stringCount(this.symbol.pricescale)
    }
    },
  mounted() {
    window.addEventListener('click', this.closeDropdown);
  },
  beforeDestroy() {
    window.removeEventListener('click', this.closeDropdown);
  },

  methods: {
    inputNumber(){
      
    },
    handleClick() {
      this.isDropdownVisible = !this.isDropdownVisible;
      this.active = !this.active;

      if (this.active){
        let priceList = [];

        if (this.currentPrice > 0){
          if (this.modelPrice < 1){
            this.modelPrice = this.currentPrice;
          }


          //현재가 위
          for (let i=this.priceMakeCnt; i>0; i--){
            let price = (this.currentPrice + (this.tikVal * i) );
            priceList.push(price);
          }

          //현재가
          priceList.push(this.currentPrice);

          //현재가 아래
          for (let i=1; i<=10; i++){
            let price = (this.currentPrice - (this.tikVal * i) );
            priceList.push(price);
          }
        }

        this.priceList = priceList;
      }
    },
    closeDropdown(element) {
      if (!this.$refs.dropdown.contains(element.target)) {
        this.isDropdownVisible = false;
        this.active = false;
      }
    },
    countUp(){
      this.modelPrice = (Number(this.modelPrice) + Number(this.tikVal)).toFixed(stringCount(this.symbol.pricescale));
    },
    countDown(){
      if (this.modelPrice > 0){
        this.modelPrice = (Number(this.modelPrice) - Number(this.tikVal)).toFixed(stringCount(this.symbol.pricescale));
      }
    },
    completeUpdate(){
      const str = this.modelPrice.toString().split(".");
      //앞자리수 체크
      const nowPrc = this.currentPrice.toString().split(".");
      let strLen = 0;
      strLen = nowPrc[0].length;

      //this.modelPrice = "";
      if (this.modelPrice != undefined && this.modelPrice.toString().indexOf(".") > -1){
        this.modelPrice = Math.round(Number(this.modelPrice) / this.tikVal) * this.tikVal;
      }else{
        if (str[0]){
          this.modelPrice = Utils.rpad(str[0], strLen, "0");
        }else{
          this.modelPrice = "";
        }
      }
      if (this.modelPrice){
        this.modelPrice = Number(this.modelPrice).toFixed(this.tikDotSz);
      }
      

      this.$emit("updateOrdPrc", this.modelPrice);
    }
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_variables.scss';
.container {
  display: flex;
  flex: 1;
  width: 100%;
  height: 30px;
  position: relative;

  input[type='number'] {
    flex: 1;
    width: 100%;
    height: 100%;
    padding: 0 20px 2px 10px;
        background-color: transparent;
    &:hover {
      border-color: var(--main-orange);
    }
    &:focus {
      border-color: var(--main-orange);
    }
    &.active {
      border-color: var(--main-orange);
    }
  }

  .counter-input {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    display: inline-flex;
    flex-direction: column;
    gap: 4px;
    .down {
      transform: rotate(180deg);
    }
  }

  .price-dropdown {
    position: absolute;
    bottom: 100%;
    left: 0;
    right: 0;
    background-color: var(--main-black);
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-bottom: none;
    padding: 5px 0;
    &.modal {
      height: 140px;
      overflow-y: auto;
    }
    .price-item {
      height: 30px;
      display: flex;
      align-items: center;
      padding-left: 10px;
      cursor: pointer;
      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }
      &.current-price {
        background-color: var(--main-orange);
        h3 {
          font-weight: bold;
        }
      }
    }
    
  }
}
</style>
