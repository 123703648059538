export function generateSymbol(exchange, fromSymbol, toSymbol) {
	const short = `${fromSymbol}/${toSymbol}`;
	return {
		short,
		full: `${exchange}:${short}`,
	};
}

export function parseFullSymbol(fullSymbol) {
	const match = fullSymbol.match(/^(\w+):(\w+)$/);
	
	if (!match) {
		return null;
	}

	return {
		exchange: match[1],
		fromSymbol: match[2],
		
	};
}

export function stringCount(value){
	if(value){
		let newValue = new String(value)
		const result = newValue.match(/0/g)
		if(result != null){
			return result.length;
		}
	}
	return 0
}

