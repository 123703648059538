<template>
  <div style="width: 100%; height: 100%">
    <ag-grid-vue
      style="width: 100%; height: 100%"
      class="ag-theme-wts"
      :columnDefs="grid.columnDefs"
      :rowData="grid.rowData"
      :headerHeight="rowHeight"
			:rowHeight="rowHeight"
      @grid-ready="onGridReady"
    />
  </div>
</template>

<script>
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import { AgGridVue } from "ag-grid-vue"; // Vue Grid Logic
import PaginationComp from '@/components/agTables/PaginationComp.vue';

export default {
  name: "agCurrentPositionTable",
  components: {
    AgGridVue,
    PaginationComp,
  },
  props:{
    setGridSearch:{
      type: Object
    },
    symbolList:{
      type: Array
    },
  },
  watch:{
    setGridSearch:{
      handler(newVal){
        this.gridSearch = newVal;
        this.search();
      },
      deep:true
    }
  },
  data() {
    return {
      grid: {
        columnDefs: [
          // { 
          //   headerName: "구분", 
          //   field: "ordSdNm",
          //   minWidth: 50,
          //   flex: 1,
          //   cellStyle: {
          //     justifyContent: 'center'
          //   },
          //   headerClass: "center-align-header",
          //   suppressMovable: true,
          // },
          { 
            headerName: "국내선물(매도/매수)",
            field: "futCnt",
            minWidth: 50,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
          },
          // { 
          //   headerName: "국내옵션(매도/매수)",
          //   field: "국내옵션",
          //   minWidth: 50,
          //   flex: 1,
          //   cellStyle: {
          //     justifyContent: 'center'
          //   },
          //   headerClass: "center-align-header",
          //   suppressMovable: true,
          // },
          { 
            headerName: "해외선물(매도/매수)",
            field: "fxCnt",
            minWidth: 50,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
          },
        ],
        rowData: [],        
        autoSizeStrategy: null,
        paginationPageSize: null,
        rowHeight: ''	,
      },
      gridApi: null,
      gridSearch: null,
    }
  },
	created() {
		this.isMobile();
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
    },
		isMobile() {
      if (window.innerWidth <= 1200) {
        return this.rowHeight = 40;
      } else {
        return this.rowHeight = 30;
      }
    },
    async search() {
      this.grid.rowData = [];
      if (this.gridSearch.usrId == ""){
        
        return;
      }
      const response = await this.$lhttp.post('/api/partner/usliveexeclist', this.gridSearch)

      if ( response && response.data ) {
        if (response.data.length > 0){
          this.grid.rowData = [];

          let fxCnt = 0;
          let futCnt = 0;
          let fxS = 0;
          let fxB = 0;
          let futS = 0;
          let futB = 0;
          console.log(response.data)
          response.data.forEach(data=>{
            fxCnt += data.symNo < 10 ? data.balQty : 0;
            futCnt += data.symNo == 10 ? data.balQty : 0;

            if (data.symNo == 10){
              if (data.ordSdCd == "매수"){
                futB+= data.balQty
              }else{
                futS+= data.balQty  
              }
            }else{
              if (data.ordSdCd == "매수"){
                fxB+= data.balQty
              }else{
                fxS+= data.balQty  
              }  
            }
          })
          
          let rowData = {fxCnt:fxCnt, futCnt:futCnt, futB : futB, futS:futS, fxB:fxB, fxS:fxS};
          this.$emit("updateLivePosition", rowData);
          this.grid.rowData.push(rowData);
        }else{
          this.grid.rowData = [];
        }
      }
    },
  },
};

</script>

<style lang="scss" scoped>
@import '@/assets/scss/agGridStyles.scss';
</style>