<template>
  <div class="counter-container" :class="{ full: full, alignRight: alignRight }" :style="{ width: width + 'px', height: height + 'px' }" :value="value">
    <input type="number" v-model="counter" :disabled="disabled" :maxCnt="maxCnt" :maxlength="maxlength" @input="inputNumber($event)" :step="step" />
    <div class="counter-input">
      <button class="up" @click="!disabled ? (counter >= maxCnt ? maxCnt : counter++) : alertDisabled()"><!-- :disabled="disabled == true" -->
        <img src="@/assets/icons/triangle-black.svg" alt="" />
      </button>
      <button class="down" @click="!disabled ? (counter > 1 ? counter-- : '') : alertDisabled()"><!-- :disabled="disabled == true" -->
        <img src="@/assets/icons/triangle-black.svg" alt="" />
      </button>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CounterComp',
  props: {
    full: Boolean,
    width: [String, Number],
    height: [String, Number],
    alignRight: Boolean,
    disabled:Boolean,
    maxCnt:{type: Number, default: 999}, //최대입력 가능 수, 디폴트999
    maxlength: {type: Number, default: 3}, //최대입력 자리수, 디폴트 3자리
		value: {type: Number, default: 1},
    step: {type: Number, default: 1}
  },
  watch : {
    counter(newValue){
      this.$emit("childEvent", newValue); //부모페이지 호출 <CounterComp .... @childEvent='메소드명' />
    },
		value: {
      handler(newValue) {
        this.counter = Number(newValue);
      },
      deep: true,
		},
  },
  data() {
    return {
      counter: 1,
    };
  },
  mounted() {
    document.addEventListener('wheel', function () {
      if (document.activeElement.type === 'number') {
        document.activeElement.blur();
      }
    });
  },
  beforeDestroy() {
    document.removeEventListener('wheel', function () {
      if (document.activeElement.type === 'number') {
        document.activeElement.blur();
      }
    });
  },
  methods: {
    alertDisabled(){
      this.$alert.alert("체크박스 상태를 해제 후 수정할 수 있습니다.", '알림');
    },
    inputNumber(val){
      console.log("inputNumber ", val.keyCode)
      if (val.target.value <= 0){
        this.counter = 0;
      }
      if (this.counter > this.maxCnt){
        this.counter =  this.maxCnt;
      }
    },
  },
  components: {

  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_variables.scss';
.counter-container {
  display: flex;
  align-items: center;
  position: relative;
  /* width: 50px; */
  width: 55px;
  height: 30px;
  @media (max-width: $mobile) {
    height: 40px;
  }
  &.full {
    flex: 1;
    width: 100%;
    height: 30px;
  }
  &.alignRight {
    input {
      text-align: right;
    }
  }

  input {
    /* flex: 1; */
    width: 100%;
    height: 100%;
    font-size: 12px;
    /* padding: 0; */
    padding: 2px 20px 0 10px;
    /* background-color: transparent; */
    /* border-radius: 0; */
    /* color: red; */
  }
  .counter-input {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    display: inline-flex;
    flex-direction: column;
    gap: 4px;
    .down {
      transform: rotate(180deg);
    }
  }
}
</style>