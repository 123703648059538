<template>
  <div class="login2">
    <div class="login2-bg">
      <h1>Welcome!</h1>

      <h2>
        to keep connected with us please
        <br />
        login with your personal info
      </h2>
    </div>
    <div class="login2-form">
      <div class="login-form">
        <div class="login-form-header">
          <img class="company-logo" src="@/assets/images/toss-logo.png" alt="" />
          <h1 class="login-title">로그인</h1>
          <button class="close-btn"><img src="@/assets/icons/close-black.svg" alt="" /></button>
        </div>
        <div class="login-form-body">
          <input type="text" placeholder="ID" />
          <input type="password" placeholder="PW" class="error" />
          <div class="error">아이디 또는 패스워드가 틀렸습니다.</div>
          <div class="sub-options">
            <div class="checkbox-default">
              <input type="checkbox" id="all" value="" />
              <label for="all">아이디 저장</label>
            </div>
            <span class="division">|</span>
            <a href="">회원가입</a>
          </div>
        </div>
        <div class="login-form-footer">
          <button class="btn-primary darkgray">종료</button>
          <button class="btn-primary blue">로그인</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WhiteLabelLogin2Page',

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>
<style lang="scss" scoped>
.btn-primary {
  width: unset;
  flex: 1;
  height: 50px;
}
</style>
