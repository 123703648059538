<template>
  <div class="checkbox-default">
    <input type="checkbox" :id="params.node.data.checkBoxId" :checked="params.node.data.checked == true ? true : false" @click="cellClicked($event, params)" />
    <!-- :value="params.node.data.checked == true ? true : false" -->
    <label :for="params.node.data.checkBoxId"></label>
  </div>
</template>

<script>
export default {
  name: 'WhiteLabelTableCheckbox',
  props: {
  },
  components: {
  },
  data() {
    return {};
  },

  mounted() {

  },

  methods: {
    cellClicked(val, params){
      //console.log("cellClicked >>> ", val.target.checked, params)
      params.node.data.checked = val.target.checked;
      params.clicked(val.target.checked, params);
    },
  },
};
</script>
