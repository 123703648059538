<template>
	<div class="avatar-container" :style="{ width: width + 'px', height: height + 'px', backgroundColor: avatarColor[Math.floor(Math.random() * 11)] }">
		<img class="profile-image" src="@/assets/images/profile-sample.jpg" alt="" v-if="profile">
		<div class="avatar-name" :style="{fontSize: fontSize + 'px', fontWeight: fontWeight}" v-if="!profile">S</div>
	</div>
</template>

<script>
export default {
	name: 'AvatarComp',
	props: {
		width: [String, Number],
	height: [String, Number],
		fontSize: [String, Number],
		fontWeight: String,
		profile: Boolean
	},
	data() {
		return {
			avatarColor: ['#459DFF', '#9757FF', '#6FA751', '#6464FF', '#A27548', '#67B1B4', '#CE63A1', '#527ED1', '#C77A62', '#8B9D00']
		};
	},

	mounted() {
		
	},

	methods: {
		
	},
};
</script>

<style lang="scss" scoped>
.avatar-container {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 30px;
	height: 30px;
	border-radius: 50vh;
	background-color: #459DFF;
	color: white;
	overflow: hidden;
	cursor: default;
	.profile-image {
		width: 100%;
		object-position: center;
	}
	.avatar-name {
		font-size: 16px;
		/* padding-bottom: 2px; */
	}
}
</style>