<template>
  <div>
		<modal name="modalNotice" classes="main-modal" :adaptive="true" :width="width" :height="height"
    @before-open="beforeOpen"
    @opened="opened">
      <div class="modal-header">
        <h2 class="modal-header-title">공지사항</h2>
        <button @click="closeModal()"><img src="@/assets/icons/icon-close-modal.svg" alt="" /></button>
      </div>

      <div class="modal-body notice">
				<table class="notice-table">
					<colgroup>
						<col style="width:15%">
						<col style="width:70%">
						<col style="width:15%">
					</colgroup>
					<thead>
						<tr>
							<th>구분</th>
							<th>제목</th>
							<th>날짜</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="notice in noticeList" v-bind:key="notice.postNo" @click="showDetail('modalNoticeDetail', notice)">
							<td>{{ notice.postDivNm }}</td>
							<td>{{ notice.postSj }}</td>
							<td>{{ notice.regDt }}</td>
						</tr>
            <tr v-if="noticeList.length == 0">
              <td colspan="3">등록된 공지사항이 없습니다.</td>
            </tr>
					</tbody>
				</table>


				<!-- <ul class="notice-list">
					<li v-for="notice in noticeList" v-bind:key="notice.postNo" class="notice" @click="showDetail('modalNoticeDetail', notice)">
            <div class="notice-title">{{ notice.postDivNm }}</div>
						<div class="notice-title">{{ notice.postSj }} <div class="flag">N</div></div>
						<div class="notice-date">{{ notice.regDt }}</div>
					</li>
          <li v-if="noticeList.length == 0" class="notice">
						<div class="notice-title">등록된 공지사항이 없습니다.</div>
						<div class="notice-date"></div>
          </li>
				</ul> -->
        
			</div>
    </modal>
  </div>
</template>

<script>
export default {
	name: 'WhiteLabelModalNotice',
	data() {
		return {
			noticeList: [],
		};
	},
	created() {
    if (window.innerWidth > 1200) {
      this.width = 1000;
      this.height = 600;
    } else {
      this.width = '100%';
      this.height = '100%';
    }
  },
  mounted() {
    this.search();
	},
  methods: {
    closeModal() {
      this.$modal.hide('modalNotice');
    },
		showDetail(name, notice) {
			this.$modal.show(name, notice);
		},
    opened(){
    },
    beforeOpen(e){
      //리스트 초기화
      this.noticeList = [];
      this.search();
    },
    async search() {
      // 조회 조건
      const params = {
        brdCd : 'NOTICE',
      }

      const response = await this.$lhttp.get('/api/board/list', { params: params });

      if ( response && response.data ) {
        this.noticeList = response.data.content;
      }         
    }
  },
};
</script>