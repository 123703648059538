<template>
	<div style="width: 100%; height: 100%">
		<ag-grid-vue
			style="width: 100%; height: 100%"
			class="ag-theme-wts"
			:columnDefs="grid.columnDefs"
            :rowData="grid.rowData"
            :headerHeight="rowHeight"
            :rowHeight="rowHeight"
			:suppressScrollOnNewData="true"
            :columnHoverHighlight="true"
            @grid-ready="onGridReady"
		>
    </ag-grid-vue>
	</div>
</template>

<script>
// import { ref } from 'vue';
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
// import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme
import { AgGridVue } from "ag-grid-vue"; // Vue Grid Logic
import Utils from '@/modules/utils'
import gridUtils from '@/modules/grid-utils';
// eslint-disable-next-line no-unused-vars
import {buySellType, cellClassRulesMinus} from '@/modules/grid-utils.js';

export default {
  name: "AgSettlementTable",
  components: {
    AgGridVue,
  },
  props:{
    account:{
      type: Object
    },
    setGridSearch:{
      type: Object
    }
  },
  watch:{
    setGridSearch:{
      handler(newVal){
        this.gridSearch = newVal;
        this.search();
      },
      deep:true
    }
  },
  data() {
    return {
      gridSearch: {
        accId: 0,
        //accPass: '',
        symCd: '',
        fromDate: Utils.getToday(), 
        toDate: Utils.getToday(), 
        //crc: 'USD',
      },
      grid:{
        columnDefs: [
        { 
					headerName: "아이디",
					field: "usrId",
					minWidth: 80,
					flex: 1,
					cellStyle: {
						justifyContent: 'center'
					},
					headerClass: "center-align-header",
					suppressMovable: true,
					sortable : true ,
				},
				{ 
					headerName: "이름",
					field: "usrNm",
					minWidth: 80,
					flex: 1,
					cellStyle: {
						justifyContent: 'center'
					},
					headerClass: "center-align-header",
					suppressMovable: true,
					sortable : true ,
				},
        { 
					headerName: "파트너",
					field: "rcmdId",
					minWidth: 80,
					flex: 1,
					cellStyle: {
						justifyContent: 'center'
					},
					headerClass: "center-align-header",
					suppressMovable: true,
					sortable : true ,
				},
				{ 
					headerName: "권한",
					field: "authId",
					minWidth: 80,
					flex: 1,
					cellStyle: {
						justifyContent: 'center'
					},
					headerClass: "center-align-header",
					suppressMovable: true,
					sortable : true ,
				},
				{ 
					headerName: "입금",
					field: "dps",
					minWidth: 110,
					flex: 1,
					cellStyle: {
						justifyContent: 'center'
					},
					headerClass: "center-align-header",
					suppressMovable: true,
					sortable : true ,
                    cellClassRules: cellClassRulesMinus,
                    valueFormatter: gridUtils.numberComma,
				},
				{ 
					headerName: "이벤트",
					field: "evtdps",
					minWidth: 110,
					flex: 1,
					cellStyle: {
						justifyContent: 'center'
					},
					headerClass: "center-align-header",
					suppressMovable: true,
					sortable : true ,
					cellClassRules: cellClassRulesMinus,
                    valueFormatter: gridUtils.numberComma,
				},
				{ 
					headerName: "보상",
					field: "evtdps2",
					minWidth: 110,
					flex: 1,
					cellStyle: {
						justifyContent: 'center'
					},
					headerClass: "center-align-header",
					suppressMovable: true,
					sortable : true ,
					cellClassRules: cellClassRulesMinus,
                    valueFormatter: gridUtils.numberComma,
				},
				{ 
					headerName: "출금",
					field: "wd",
					minWidth: 110,
					flex: 1,
					cellStyle: {
						justifyContent: 'center'
					},
					headerClass: "center-align-header",
					suppressMovable: true,
					sortable : true ,
					cellClassRules: cellClassRulesMinus,
                    valueFormatter: gridUtils.numberComma,
				},
				{ 
					headerName: "국내수수료",
					field: "futCms",
					minWidth: 110,
					flex: 1,
					cellStyle: {
						justifyContent: 'center'
					},
					headerClass: "center-align-header",
					suppressMovable: true,
					sortable : true ,
					cellClassRules: cellClassRulesMinus,
                    valueFormatter: gridUtils.numberComma,
				},
				{ 
					headerName: "해외수수료",
					field: "fxCms",
					minWidth: 110,
					flex: 1,
					cellStyle: {
						justifyContent: 'center'
					},
					headerClass: "center-align-header",
					suppressMovable: true,
					sortable : true ,
					cellClassRules: cellClassRulesMinus,
                    valueFormatter: gridUtils.numberComma,
				},
				{ 
					headerName: "수수료합계",
					field: "totCms",
					minWidth: 110,
					flex: 1,
					cellStyle: {
						justifyContent: 'center'
					},
					headerClass: "center-align-header",
					suppressMovable: true,
					sortable : true ,
					cellClassRules: cellClassRulesMinus,
                    valueFormatter: gridUtils.numberComma,
				},
				{ 
					headerName: "국내손익",
					field: "futPlMn",
					minWidth: 110,
					flex: 1,
					cellStyle: {
						justifyContent: 'center'
					},
					headerClass: "center-align-header",
					suppressMovable: true,
					sortable : true ,
					cellClassRules: cellClassRulesMinus,
                    valueFormatter: gridUtils.numberComma,
				},
				{ 
					headerName: "해외손익",
					field: "fxPlMn",
					minWidth: 110,
					flex: 1,
					cellStyle: {
						justifyContent: 'center'
					},
					headerClass: "center-align-header",
					suppressMovable: true,
					sortable : true ,
					cellClassRules: cellClassRulesMinus,
                    valueFormatter: gridUtils.numberComma,
				},
				{ 
					headerName: "손익합계",
					field: "totPlMn",
					minWidth: 110,
					flex: 1,
					cellStyle: {
						justifyContent: 'center'
					},
					headerClass: "center-align-header",
					suppressMovable: true,
					sortable : true ,
					cellClassRules: cellClassRulesMinus,
                    valueFormatter: gridUtils.numberComma,
				},
        ],
        rowData: [],
		bottomRowData: [],
        gridApi: null,
        autoSizeStrategy: null,
      },
			rowHeight: '',
			mobileMinWidth: ''
    };
  },
	created() {
		this.grid.rowData = [];
		this.isMobile();
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
    },
	async search() {
		this.grid.rowData = [];
		this.grid.bottomRowData = [];
		if (this.gridSearch.usrId == ""){return}
      const response = await this.$http.post('/api/partner/usrallprofitlist', { usrId: this.gridSearch.usrId, stdt : this.gridSearch.fromDate, eddt : this.gridSearch.toDate, tp : '0', cpnId : this.gridSearch.cpnId, authId : this.gridSearch.authId})
          let dps = 0;
          let evtdps = 0;
          let evtdps2 = 0;
          let wd = 0;
          let futCms = 0;
          let fxCms = 0;
          let totCms = 0;
          let futPlMn = 0;
          let fxPlMn = 0;
          let totPlMn = 0;
      if ( response && response.data ) {
         this.grid.rowData = response.data.content
          let sum = [{
            usrId: '합계', 
            dps: dps, //총손익
            evtdps: evtdps, //매매손익합
            evtdps2: evtdps2, //totCms
            wd: wd, //fxPlMn
            futCms: futCms, //해외수수료
            fxCms: fxCms, //국내선물손익
            totCms: totCms, //국내선물수수료
            futPlMn: futPlMn, //옵션손익
            fxPlMn: fxPlMn, //옵션수수료
			totPlMn: totPlMn, //옵션수수료
          }];
          if (response.data.sum != undefined && response.data.sum.length > 0){
            sum = response.data.sum;
          }

          this.gridApi.setPinnedBottomRowData(sum);
      }     
    },
		isMobile() {
      if (window.innerWidth <= 1200) {
        return this.rowHeight = 40;
      } else {
        return this.rowHeight = 30;
      }
    },
  },
};



</script>

<style lang="scss" scoped>
@import '@/assets/scss/agGridStyles.scss';
</style>