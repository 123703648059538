<template>
  <div style="width: 100%; height: 100%">
    <ag-grid-vue
      style="width: 100%; height: 100%"
      class="ag-theme-wts"
      :columnDefs="grid.columnDefs"
      :rowData="grid.rowData"
      :headerHeight="grid.rowHeight"
      :rowHeight="grid.rowHeight"
      rowSelection="single"
      @row-clicked="selectSymbol"
      @grid-ready="onGridReady" />
  </div>
</template>

  
<script>
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import { AgGridVue } from "ag-grid-vue"; // Vue Grid Logic

export default {
  name: "agFuturesTable",
  components: {
    AgGridVue,
  },
  props:{
    //
  },
  computed:{
    getCoinSymbolList: function(){
      return window.$store.getters['Symbol/getCoinSymbolList']
    },    
    getSelectCoinSymbol: function(){
      return window.$store.getters['SocketCoinPrice/getSelectCoinSymbol'];
    },
  },
  watch:{
    getCoinSymbolList(newVal){
      console.log("coin list >>> ", newVal)
      this.selCoinSymbol = newVal[0];
      //종목 리스트 created에서 읽지 못하는경우가 있어서 감지로 처리
      this.selectCoinSymbolList();
    },
    getSelectCoinSymbol(newVal){
      if (newVal != null){
        this.selCoinSymbol = newVal;
      }
    }
  },
  data() {
    return {
      grid:{
        columnDefs: [
          {
            headerName: "종목명", 
            field: "description",
            minWidth: 80,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
          },
          { 
            headerName: "코드",
            field: "name",
            minWidth: 80,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
          },
        ],
        rowData: null,
        autoSizeStrategy: null,
        paginationPageSize: null,
        currentPage: '',
        totalPages: '',
        rowHeight: '',
      },
      gridApi: null,
      gridColumnApi:null,
      isGridReady:null,
      selCoinSymbol:null,
    }
  },
  created() {
    this.isMobile();
    this.selectCoinSymbolList();
  },
  mounted(){
    this.selCoinSymbol = window.$store.getters['SocketCoinPrice/getSelectCoinSymbol'];
    console.log("mounted >>> ", this.selCoinSymbol)
  },
  methods: {
    isMobile() {
      if (window.name == undefined || window.name == null || window.name == ""){
        if (window.innerWidth <= 1200) {
          return this.grid.rowHeight = 40;
        } else {
          return this.grid.rowHeight = 30;
        }
      }else{
        return this.grid.rowHeight = 30;
      }
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.isGridReady = true;

      this.selectCoinSymbolList();
    },
    selectSymbol(val){
      console.log("val??? ", val.data.name, this.selCoinSymbol)
      if (val.data.name == this.selCoinSymbol.name){
        this.$emit("selectSymbol"); //모바일 닫기
        return;
      }
      this.selCoinSymbol = val.data;
      window.$store.dispatch('SocketCoinPrice/setSelectCoinSymbol', val.data);
      this.sendOmsLogMessage("종목 선택 "+val.data.name);
      this.$emit("selectSymbol"); //모바일 닫기
    },
    selectCoinSymbolList(){
      let coinSymbolList = window.$store.getters['Symbol/getCoinSymbolList'];
      this.grid.rowData = [];
      //console.log("coin symbolList", coinSymbolList)
      
      for (let i=0; i<coinSymbolList.length; i++){
        this.grid.rowData.push(coinSymbolList[i]);

        if (this.selCoinSymbol && this.selCoinSymbol.name == coinSymbolList[i].name){
          if (this.grid.rowData.length > 0){
            // console.log("???")
            // this.gridApi.forEachNode((node) => {
            //     //node.setSelected(true, false, true);
            //     console.log("node??? ", node)
            // });
          }
        }          
      }

      
      // symbolList.forEach(item =>{
      //   this.grid.rowData.push(item);
      //   if (this.selCoinSymbol && this.selCoinSymbol.name == item.name){
      //     //row 첫번째 셀렉트를 줘야하는데..
      //     //console.log("item.name ", item.name, this.gridApi);
      //     if (this.grid.rowData.length > 0){

      //       this.gridApi.forEachNode((node) => {
      //           //node.setSelected(true, false, true);
      //           console.log("node??? ", node)
      //       });



      //       // const index = 0; // 선택할 노드의 인덱스
      //       // const node = this.gridApi.getRowNode(index);
      //       // this.gridApi.selectNode(node);
      //     }
      //   }
      // });
    },
    sendOmsLogMessage(message){
      if (window.name == "orderPop1" || window.name == "orderPop2"){
        message = "팝업("+window.name + ") " + message;
        window.opener.$store.dispatch('SocketOms/setLogMsg', {message:message});
      }else{
        window.$store.dispatch('SocketOms/setLogMsg', {message:message});
      }
    }
  },
}

</script>

<style lang="scss" scoped>
@import '@/assets/scss/agGridStyles.scss';
</style>

