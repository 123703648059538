<template>
  <div>
    <modal name="modalOptions" classes="main-modal" :adaptive="true" :width="width" :height="height">
      <div class="modal-header">
        <h2 class="modal-header-title">종목선택</h2>
        <button @click="closeModal()"><img src="@/assets/icons/icon-close-modal.svg" alt="" /></button>
      </div>

      <div class="modal-body">
        <div class="tab-nav-container">
          <div class="tab" :class="{ active: selectedTab === 'tab1' }" @click="selectedTab = 'tab1'">해외선물</div>
          <div class="tab" :class="{ active: selectedTab === 'tab2' }" @click="selectedTab = 'tab2'">국내선물옵션</div>
        </div>

        <div class="tab-content medium-top" v-if="selectedTab === 'tab1'">
          <div class="options-grid">
            <ul class="options-list">
							<li>
								<button class="btn-line options" :class="{active: selectedOption === 'all'}" @click="selectedOption = 'all'">전체</button>
							</li>
							<li>
								<button class="btn-line options" :class="{active: selectedOption === 'index'}" @click="selectedOption = 'index'">지수</button>
							</li>
							<li>
								<button class="btn-line options" :class="{active: selectedOption === 'energy'}" @click="selectedOption = 'energy'">에너지</button>
							</li>
							<li>
								<button class="btn-line options" :class="{active: selectedOption === 'currency'}" @click="selectedOption = 'currency'">통화</button>
							</li>
							<li>
								<button class="btn-line options" :class="{active: selectedOption === 'metals'}" @click="selectedOption = 'metals'">귀금속</button>
							</li>
							<li>
								<button class="btn-line options" :class="{active: selectedOption === 'nonMetals'}" @click="selectedOption = 'nonMetals'">비철금속</button>
							</li>
							<li>
								<button class="btn-line options" :class="{active: selectedOption === 'rates'}" @click="selectedOption = 'rates'">금리</button>
							</li>
						</ul>
            <div class="options-content">
							<div class="ag-container" v-if="selectedOption === 'all'">all</div>
							<div class="ag-container" v-if="selectedOption === 'index'">지수</div>
							<div class="ag-container" v-if="selectedOption === 'energy'">
								에너지 <br>
							</div>
							<div class="ag-container" v-if="selectedOption === 'currency'">통화</div>
							<div class="ag-container" v-if="selectedOption === 'metals'">귀금속</div>
							<div class="ag-container" v-if="selectedOption === 'nonMetals'">비철금속</div>
							<div class="ag-container" v-if="selectedOption === 'rates'">금리</div>
						</div>
          </div>
        </div>

        <div class="tab-content medium-top" v-if="selectedTab === 'tab2'">
					<div class="options-grid">
            <ul class="options-list">
							<li>
								<button class="btn-line options" :class="{active: selectedOptionKospi === 'kospi200'}" @click="selectedOptionKospi = 'kospi200'">코스피200</button>
							</li>
							<li>
								<button class="btn-line options" :class="{active: selectedOptionKospi === 'kospi200Weekly'}" @click="selectedOptionKospi = 'kospi200Weekly'">코스피200 위클리</button>
							</li>
						</ul>
            <div class="options-content">
							<div class="ag-container" v-if="selectedOptionKospi === 'kospi200'">kospi200</div>
							<div class="ag-container" v-if="selectedOptionKospi === 'kospi200Weekly'">kospi200Weekly</div>
						</div>
          </div>
				</div>
      </div>

      <div class="modal-footer">
        <button class="btn-primary darkgray" @click="closeModal()">취소</button>
        <button class="btn-primary blue">확인</button>
      </div>
    </modal>
  </div>
</template>

<script>
export default {
  name: 'WhiteLabelModalOptions',

  data() {
    return {
      width: '',
      height: '',
      selectedTab: 'tab1',
			selectedOption: 'all',
			selectedOptionKospi: 'kospi200'
    };
  },

  created() {
    if (window.innerWidth > 1200) {
      this.width = 1000;
      this.height = 600;
    } else {
      this.width = '100%';
      this.height = '100%';
    }
  },

  mounted() {},

  methods: {
    closeModal() {
      this.$modal.hide('modalOptions');
    },
  },
};
</script>
