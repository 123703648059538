<template>
	<div style="width: 100%; height: 100%">
		<ag-grid-vue
			style="width: 100%; height: 100%"
			class="ag-theme-wts"
			:columnDefs="columnDefs"
			:rowData="rowData"
			:headerHeight="rowHeight"
			:rowHeight="rowHeight"
			:paginationPageSize="paginationPageSize"
		>
    </ag-grid-vue>
		<!-- <PaginationComp :currentPage="currentPage" :totalPages="totalPages" @toFirstPage="toFirstPage" @toPrevPage="toPrevPage" @toNextPage="toNextPage" @toLastPage="toLastPage" /> -->
	</div>
</template>

<script>
// import { ref } from 'vue';
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
// import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme
import { AgGridVue } from "ag-grid-vue"; // Vue Grid Logic
// eslint-disable-next-line no-unused-vars
import {currencyFormatter} from '@/agGridUtils.js';
import PaginationComp from '@/components/agTables/PaginationComp.vue';

export default {
  name: "agPartnerTab1Table",
  components: {
    AgGridVue,
    PaginationComp,
  },
  props:{
    setGridSearch:{
      type: Object
    },
    userInfoList:{
      type: Array
    },
  },
  watch:{
    setGridSearch:{
      handler(newVal){
        this.gridSearch = newVal;
        this.search();
      },
      deep:true
    }
  },
  data() {
    return {
      ovcMap: new Map(),
        columnDefs: [
        { 
					headerName: "no", 
					field: "no",
					minWidth: 20,
					flex: 0.3,
					cellStyle: {
						justifyContent: 'center'
					},
					headerClass: "center-align-header",
					suppressMovable: true,
					sortable : true ,
				},
        { 
					headerName: "아이디",
					field: "usrId",
					minWidth: 50,
					flex: 0.6,
					cellStyle: {
						justifyContent: 'center'
					},
					headerClass: "center-align-header",
					suppressMovable: true,
					sortable : true ,
				},
				{ 
					headerName: "이름",
					field: "usrNm",
					minWidth: 50,
					flex: 0.6,
					cellStyle: {
						justifyContent: 'center'
					},
					headerClass: "center-align-header",
					suppressMovable: true,
					sortable : true ,
				},
        { 
					headerName: "파트너",
					field: "rcmdId",
					minWidth: 50,
					flex: 0.6,
					cellStyle: {
						justifyContent: 'center'
					},
					headerClass: "center-align-header",
					suppressMovable: true,
					sortable : true ,
				},
				{ 
					headerName: "권한",
					field: "authId",
					minWidth: 50,
					flex: 0.6,
					cellStyle: {
						justifyContent: 'center'
					},
					headerClass: "center-align-header",
					suppressMovable: true,
					sortable : true ,
				},
				{ 
					headerName: "이메일",
					field: "mail",
					minWidth: 80,
					flex: 1.3,
					cellStyle: {
						justifyContent: 'center'
					},
					headerClass: "center-align-header",
					suppressMovable: true,
					sortable : true ,
				},
				{ 
					headerName: "전화번호",
					field: "moNum",
					minWidth: 80,
					flex: 0.6,
					cellStyle: {
						justifyContent: 'center'
					},
					headerClass: "center-align-header",
					suppressMovable: true,
					sortable : true ,
				},
				{ 
					headerName: "IP",
					field: "connIp",
					minWidth: 80,
					flex: 0.6,
					cellStyle: {
						justifyContent: 'center'
					},
					headerClass: "center-align-header",
					suppressMovable: true,
					sortable : true ,
				},
				{ 
					headerName: "최근접속일",
					field: "lstLgnTm",
					minWidth: 80,
					flex: 0.8,
					cellStyle: {
						justifyContent: 'center'
					},
					headerClass: "center-align-header",
					suppressMovable: true,
					sortable : true ,
				},
        ],
        rowData: [],
        autoSizeStrategy: null,
        paginationPageSize: null,
        rowHeight: '',
      gridApi: null,
      gridSearch: null,
      
    };
  },
	created() {
    this.paginationPageSize = 12;
		this.isMobile();
  },
  methods: {
    onPaginationChanged() {
      console.log('onPaginationPageLoaded');
      if (this.gridApi) {
        this.currentPage = this.gridApi.paginationGetCurrentPage() + 1;
        this.totalPages = this.gridApi.paginationGetTotalPages();
      }
    },
		toFirstPage() {
			console.log('current page: ' + this.currentPage)
			this.gridApi.paginationGoToFirstPage();
		},
		toPrevPage() {
      this.gridApi.paginationGoToPreviousPage();
    },
    toNextPage() {
      this.gridApi.paginationGoToNextPage();
    },
		toLastPage() {
			this.gridApi.paginationGoToLastPage();
		},
    onGridReady(params) {
      this.gridApi = params.api;
    },
		isMobile() {
      if (window.innerWidth <= 1200) {
        return this.rowHeight = 40;
      } else {
        return this.rowHeight = 30;
      }
    },
    async search() {
      this.rowData = [];
      //url 변경필요
      if (this.gridSearch.usrId == ""){return}
      const response = await this.$lhttp.post('/api/partner/usrlist', { usrId: this.gridSearch.usrId, authId : this.gridSearch.authId, cpnId : this.gridSearch.cpnId })

      if ( response && response.data ) {

		console.log(response.data)
        if (response.data.length > 0){
          response.data.forEach(data=>{
            //실시간
			if (this.gridSearch.usrNm === undefined){
				this.rowData.push(data);
			}else if (this.gridSearch.usrNm === null){
				this.rowData.push(data);		
			}else if (this.gridSearch.usrNm === ""){
				this.rowData.push(data);		
			}else{
					if (this.gridSearch.usrType === "0"){
						if (data.usrId.toLowerCase().includes(this.gridSearch.usrNm)){
						this.rowData.push(data);		
						}
					}else{
						if (data.usrNm.toLowerCase().includes(this.gridSearch.usrNm)){
						this.rowData.push(data);		
						}
					}
			}
          })
        }
      }
    },
  },
};

</script>

<style lang="scss" scoped>
@import '@/assets/scss/agGridStyles.scss';
</style>