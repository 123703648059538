<template>
  <div>
    <modal name="modalLogout" classes="main-modal" :adaptive="true" :width="width" :height="height">
      <div class="modal-header">
        <h2 class="modal-header-title">종료</h2>
        <button @click="closeModal()"><img src="@/assets/icons/icon-close-modal.svg" alt="" /></button>
      </div>

      <div class="modal-body">
        <div class="logout-container">
          <div class="text-container">
            <h1>이용해주셔서 감사합니다.</h1>
            <h1>WTS를 종료하시겠습니까?</h1>
          </div>
          <div class="white-label-table-container">
            <table class="white-label-table">
              <tbody>
                <tr>
                  <th>보유잔고</th>
                  <td>
                    <div class="inner">
                      <span class="label">매도</span>
                    {{ sellBalQty }}
                    </div>
                  </td>
                  <td>
                    <div class="inner">
                      <span class="label">매수</span>
                      {{ buyBalQty }}
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>MIT 주문</th>
                  <td>
                    <div class="inner">
                      <span class="label">매도</span>
                      {{ sellMitQty }}
                    </div>
                  </td>
                  <td>
                    <div class="inner">
                      <span class="label">매수</span>
                      {{ buyMitQty }}
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>미체결</th>
                  <td class="last" colspan="2">{{ ordQty }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="notice">프로그램 종료시 상기 주문은 취소되지 않습니다. 잔고를 꼭 확인해 주세요.</div>
          <!-- <div class="logout-table">
						<div class="label">보유잔고</div>
						<div class="value">
							<div class="inner-label">매수</div>
							<div class="inner-value">0</div>
						</div>
						<div class="value">
							<div class="inner-label">매도</div>
							<div class="inner-value">0</div>
						</div>
						<div class="label">MIT 주문</div>
						<div class="value">
							<div class="inner-label">매수</div>
							<div class="inner-value">0</div>
						</div>
						<div class="value">
							<div class="inner-label">매도</div>
							<div class="inner-value">0</div>
						</div>
						<div class="label">미체결</div>
						<div class="value last">0</div>
					</div> -->
        </div>
      </div>

      <div class="modal-footer">
        <button class="btn-primary darkgray" @click="closeModal()">아니오</button>
        <button class="btn-primary blue" @click="logout">예</button>
      </div>
    </modal>
  </div>
</template>

<script>
export default {
  name: 'WhiteLabelModalLogout',
  computed: {
    getPosOrdStList: function(){
      return window.$store.getters['SocketOms/getPosOrdStList'];
    },
  },
  watch: {
    getPosOrdStList(newList){
      this.sellBalQty = 0;
      this.buyBalQty = 0;
      this.ordQty = 0;
      this.sellMitQty = 0;
      this.buyMitQty = 0;
      if (newList.length > 0){
        newList.forEach(data=>{
          if (data.ordType == "POS"){
            if (data.ordSdCd == "1"){
              this.sellBalQty += data.balQty;
            }else if (data.ordSdCd == "2"){
              this.buyBalQty += data.balQty;
            }            
          }else if (data.ordType == "ORD"){
            this.ordQty += data.balQty;
          }else if (data.ordType == "ST"){
            if (data.ordSdCd == "1"){
              this.sellMitQty += data.balQty;
            }else if (data.ordSdCd == "2"){
              this.buyMitQty += data.balQty;
            } 
          }
        });
      }
    },
  },
  data() {
    return {
      sellBalQty: 0,
      buyBalQty: 0,
      ordQty: 0,
      sellMitQty: 0,
      buyMitQty: 0,
    }
  },

  created() {
    if (window.innerWidth > 1200) {
      this.width = 500;
      this.height = 400;
    } else {
      this.width = '100%';
      this.height = '100%';
    }
    this.search();
  },

  mounted() {
  },
  methods: {
    closeModal() {
      this.$modal.hide('modalLogout');
    },
    show(name) {
      this.$modal.show(name);
    },
    logout(){
      this.sendOmsLogMessage("로그아웃 '예' 버튼 클릭");
      this.$modal.hide('modalLogout');
      this.$router.push('/login');
    },
    search(){
      //보유잔고	매수, 매도, ,MIT 주문	,매수, 매도, 미체결	
      
    },
    sendOmsLogMessage(message){
      if (window.location.pathname == "/mobile"){
        message = "모바일 " + message;
      }
      window.$store.dispatch('SocketOms/setLogMsg', {message:message});
    }
  },
};
</script>
