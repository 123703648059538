<template>
  <vueDatePicker v-model="date" valueType="format" placeholder="기간입력" :clearable="false" :style="{ width: width + 'px' }" @input="onInput"></vueDatePicker>
</template>

<script>
import vueDatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  name: 'DatePicker',
  components: {
    vueDatePicker
  },
  props: {
    width: [String, Number],
    value: {
      type: String,
      default: '',
    },
  },
  watch:{
    value:{
      handler(newValue){
        this.date = newValue;
      }
    }
  },
  data() {
    return {
      date: '',
    };
  },
  mounted() {
    this.date = this.value
  },
  methods: {
    onInput () {
      this.$emit('input', this.date)
    }	
  },
};

</script>

<style lang="scss">
@import '@/assets/scss/datepicker.scss';

</style>