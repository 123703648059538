<template>
  <!-- Stock Order Book -->
  <div ref="refSection" tabindex="0" class="sob_section" @focus.capture="onSectionFocus" @blur.capture="onSectionBlur">
    <!-- BODY -->
    <div class="sob_body" @wheel.prevent="onWheel">
      <div
        :class="{
          sob_scroll_hold: innerScrollHold,
        }"
        ref="bodyContent"
        class="sob_body_content"
        @mouseleave="onMouseleave"
        @touchmove="onColumnTouchMove"
      >
        <!-- ROW HEADER -->
        <div class="sob_row sob_row_header" @mouseover="onMouseleave">
          <slot name="itemRowHeader" :bind="{ checked: innerScrollHold }" :on="{ change: slotUpdateScrollHold }">
            <!-- LEFT -->
            <div class="sob_col sob_col_header sob_col_left sob_col_st">
              <div class="sob_col_value">ST</div>
              <div class="sob_col_border"></div>
            </div>
            <div class="sob_col sob_col_header sob_col_left sob_col_sell">
              <div class="sob_col_value">매도</div>
              <div class="sob_col_border"></div>
            </div>
            <div class="sob_col sob_col_header sob_col_left sob_col_number">
              <div class="sob_col_value">건수</div>
              <div class="sob_col_border"></div>
            </div>
            <div class="sob_col sob_col_header sob_col_left sob_col_remain">
              <div class="sob_col_value">잔량</div>
              <div class="sob_col_border"></div>
            </div>
            <!-- CENTER -->
            <div class="sob_col sob_col_header sob_col_center sob_col_price">
              <div class="sob_col_value">
                <input
                  v-model="innerScrollHold"
                  :id="`sob_col_header_checkbox-${componentId}`"
                  type="checkbox"
                  class="sob_col_header_checkbox"
                />
                <label :for="`sob_col_header_checkbox-${componentId}`" class="sob_col_header_checkbox">고정</label>
              </div>
              <div class="sob_col_border"></div>
            </div>
            <!-- RIGHT -->
            <div class="sob_col sob_col_header sob_col_right sob_col_remain">
              <div class="sob_col_value">잔량</div>
              <div class="sob_col_border"></div>
            </div>
            <div class="sob_col sob_col_header sob_col_right sob_col_number">
              <div class="sob_col_value">건수</div>
              <div class="sob_col_border"></div>
            </div>
            <div class="sob_col sob_col_header sob_col_right sob_col_buy">
              <div class="sob_col_value">매수</div>
              <div class="sob_col_border"></div>
            </div>
            <div class="sob_col sob_col_header sob_col_right sob_col_st">
              <div class="sob_col_value">ST</div>
              <div class="sob_col_border"></div>
            </div>
          </slot>
        </div>
        <!-- ROW ITEMS -->
        <div
          v-for="(item, itemIndex) in rowItemList"
          :key="itemIndex"
          :class="{
            sob_row_upper: removeGarbageValue($props.nowPrice) < item.price && item.price <= removeGarbageValue($props.highPrice),
            sob_row_nowPrice: item.price === removeGarbageValue($props.nowPrice),
            sob_row_center: item.price === removeGarbageValue(centerPrice),
            sob_row_lower: removeGarbageValue($props.lowPrice) <= item.price && item.price < removeGarbageValue($props.nowPrice),
            sob_row_high: item.price === removeGarbageValue($props.highPrice),
            sob_row_low: item.price === removeGarbageValue($props.lowPrice),
            sob_row_yesterday: item.price === removeGarbageValue($props.yesterdayClosingPrice),
            [itemHoverIndex[1]]: itemIndex === itemHoverIndex[0],
          }"
          class="sob_row"
          @mouseover="onMouseOver($event, itemIndex)"
          @mousedown="onMousedown"
          @touchstart="onColumnTouchStart"
          @touchend="onColumnTouchEnd"
        >
          <slot name="itemRowItem" :item="item" :itemStyle="getColumnStyle(item.price)">
            <!-- LEFT -->
            <div :style="getColumnStyle(item.price).SELL_STOP_LOSS.COLUMN" class="sob_col sob_col_item sob_col_left sob_col_st">
              <div
                v-html="item.orderCountSellST ? item.orderCountSellST.toLocaleString() : ''"
                :style="getColumnStyle(item.price).SELL_STOP_LOSS.PRICE"
                class="sob_col_value"
              ></div>
              <div :style="getColumnStyle(item.price).SELL_STOP_LOSS.BORDER" class="sob_col_border"></div>
            </div>
            <div :style="getColumnStyle(item.price).SELL.COLUMN" class="sob_col sob_col_item sob_col_left sob_col_sell">
              <div
                v-html="item.orderCountSell ? item.orderCountSell.toLocaleString() : ''"
                :style="getColumnStyle(item.price).SELL.PRICE"
                class="sob_col_value"
              ></div>
              <div :style="getColumnStyle(item.price).SELL.BORDER" class="sob_col_border"></div>
            </div>
            <div :style="getColumnStyle(item.price).SELL_NUMBER.COLUMN" class="sob_col sob_col_item sob_col_left sob_col_number">
              <div
                v-html="item.countSellNumber ? item.countSellNumber.toLocaleString() : ''"
                :style="getColumnStyle(item.price).SELL_NUMBER.PRICE"
                class="sob_col_value"
              ></div>
              <div :style="getColumnStyle(item.price).SELL_NUMBER.BORDER" class="sob_col_border"></div>
            </div>
            <div :style="getColumnStyle(item.price).SELL_REMAIN.COLUMN" class="sob_col sob_col_item sob_col_left sob_col_remain">
              <div
                v-html="item.countSellRemain ? item.countSellRemain.toLocaleString() : ''"
                :style="getColumnStyle(item.price).SELL_REMAIN.PRICE"
                class="sob_col_value"
              ></div>
              <div :style="getColumnStyle(item.price).SELL_REMAIN.BORDER" class="sob_col_border"></div>
            </div>
            <!-- CENTER -->
            <slot name="itemRowItemPrice" :item="item" :itemPriceSubStyle="getColumnStyle(item.price).PRICE">
              <div :style="getColumnStyle(item.price).PRICE.COLUMN" class="sob_col sob_col_item sob_col_center sob_col_price">
                <div :style="getColumnStyle(item.price).PRICE.PRICE" class="sob_col_value">
                  {{ item.price >= 1 ? item.price.toFixed(2) : "" }}
                </div>
                <div :style="getColumnStyle(item.price).PRICE.BORDER" class="sob_col_border"></div>
              </div>
            </slot>
            <!-- RIGHT -->
            <div :style="getColumnStyle(item.price).BUY_REMAIN.COLUMN" class="sob_col sob_col_item sob_col_right sob_col_remain">
              <div
                v-html="item.countBuyRemain ? item.countBuyRemain.toLocaleString() : ''"
                :style="getColumnStyle(item.price).BUY_REMAIN.PRICE"
                class="sob_col_value"
              ></div>
              <div :style="getColumnStyle(item.price).BUY_REMAIN.BORDER" class="sob_col_border"></div>
            </div>
            <div :style="getColumnStyle(item.price).BUY_NUMBER.COLUMN" class="sob_col sob_col_item sob_col_right sob_col_number">
              <div
                v-html="item.countBuyNumber ? item.countBuyNumber.toLocaleString() : ''"
                :style="getColumnStyle(item.price).BUY_NUMBER.PRICE"
                class="sob_col_value"
              ></div>
              <div :style="getColumnStyle(item.price).BUY_NUMBER.BORDER" class="sob_col_border"></div>
            </div>
            <div :style="getColumnStyle(item.price).BUY.COLUMN" class="sob_col sob_col_item sob_col_right sob_col_buy">
              <div
                v-html="item.orderCountBuy ? item.orderCountBuy.toLocaleString() : ''"
                :style="getColumnStyle(item.price).BUY.PRICE"
                class="sob_col_value"
              ></div>
              <div :style="getColumnStyle(item.price).BUY.BORDER" class="sob_col_border"></div>
            </div>
            <div :style="getColumnStyle(item.price).BUY_STOP_LOSS.COLUMN" class="sob_col sob_col_item sob_col_right sob_col_st">
              <div
                v-html="item.orderCountBuyST ? item.orderCountBuyST.toLocaleString() : ''"
                :style="getColumnStyle(item.price).BUY_STOP_LOSS.PRICE"
                class="sob_col_value"
              ></div>
              <div :style="getColumnStyle(item.price).BUY_STOP_LOSS.BORDER" class="sob_col_border"></div>
            </div>
          </slot>
        </div>
        <!-- ROW FOOTER -->
        <div class="sob_row sob_row_footer" @mouseover="onMouseleave">
          <slot name="itemRowFooter" :totalSum="totalSum">
            <!-- LEFT -->
            <div class="sob_col sob_col_footer sob_col_left sob_col_st">
              <div class="sob_col_value">{{ totalSum.orderCountSellST ? totalSum.orderCountSellST.toLocaleString() : "" }}</div>
              <div class="sob_col_border"></div>
            </div>
            <div class="sob_col sob_col_footer sob_col_left sob_col_sell">
              <div class="sob_col_value">{{ totalSum.orderCountSell ? totalSum.orderCountSell.toLocaleString() : "" }}</div>
              <div class="sob_col_border"></div>
            </div>
            <div class="sob_col sob_col_footer sob_col_left sob_col_number">
              <div class="sob_col_value">{{ totalSum.countSellNumber ? totalSum.countSellNumber.toLocaleString() : "" }}</div>
              <div class="sob_col_border"></div>
            </div>
            <div class="sob_col sob_col_footer sob_col_left sob_col_remain">
              <div class="sob_col_value">{{ totalSum.countSellRemain ? totalSum.countSellRemain.toLocaleString() : "" }}</div>
              <div class="sob_col_border"></div>
            </div>
            <!-- CENTER -->
            <div class="sob_col sob_col_footer sob_col_center sob_col_price">
              <div class="sob_col_value">0</div>
              <div class="sob_col_border"></div>
            </div>
            <!-- RIGHT -->
            <div class="sob_col sob_col_footer sob_col_right sob_col_remain">
              <div class="sob_col_value">{{ totalSum.countBuyRemain ? totalSum.countBuyRemain.toLocaleString() : "" }}</div>
              <div class="sob_col_border"></div>
            </div>
            <div class="sob_col sob_col_footer sob_col_right sob_col_number">
              <div class="sob_col_value">{{ totalSum.countBuyNumber ? totalSum.countBuyNumber.toLocaleString() : "" }}</div>
              <div class="sob_col_border"></div>
            </div>
            <div class="sob_col sob_col_footer sob_col_right sob_col_buy">
              <div class="sob_col_value">{{ totalSum.orderCountBuy ? totalSum.orderCountBuy.toLocaleString() : "" }}</div>
              <div class="sob_col_border"></div>
            </div>
            <div class="sob_col sob_col_footer sob_col_right sob_col_st">
              <div class="sob_col_value">{{ totalSum.orderCountBuyST ? totalSum.orderCountBuyST.toLocaleString() : "" }}</div>
              <div class="sob_col_border"></div>
            </div>
          </slot>
        </div>
      </div>
      <div class="sob_body_scroll">
        <div class="sob_scroll_button sob_scroll_top" @click="moveScrollBarPosition(-1)">▲</div>
        <div ref="scrollBarWrap" class="sob_scroll_bar_wrap" @mousedown="onScrollBarWrapMousedown" @touchstart="onScrollBarTouchStart">
          <div
            :style="{ top: tableScrollTop + '%', height: tableScrollAreaHeight + '%' }"
            class="sob_scroll_bar"
            @mousedown="onScrollBarMousedown"
            @touchstart="onScrollBarTouchStart"
          ></div>
        </div>
        <div class="sob_scroll_button sob_scroll_bottom" @click="moveScrollBarPosition(1)">▼</div>
      </div>
      <div
        :class="{
          sob_body_higher_price_than_yesterday: 0 <= nowPricePercentageOfYesterdayClosingPrice,
          sob_body_lower_price_than_yesterday: nowPricePercentageOfYesterdayClosingPrice < 0,
        }"
        class="sob_body_daily_stock_price"
      >
        <div
          :style="{ height: (50 / dailyCandleMaxPercent) * Math.abs(percentageOfYesterdayClosingPrice(lowPrice)) + '%' }"
          class="sob_low_price_line"
        ></div>
        <div
          :style="{ height: (50 / dailyCandleMaxPercent) * Math.abs(percentageOfYesterdayClosingPrice(highPrice)) + '%' }"
          class="sob_high_price_line"
        ></div>
        <div
          :style="{
            height: (50 / dailyCandleMaxPercent) * Math.abs(nowPricePercentageOfYesterdayClosingPrice) + '%',
            transform: 0 < nowPricePercentageOfYesterdayClosingPrice ? 'translateY(-100%)' : 'none',
          }"
          class="sob_now_price_line"
        ></div>
        <div class="sob_yesterday_price_line"></div>
      </div>
    </div>

    <!-- DEFAULT POPUP -->
    <div v-if="popupDefaultInfo.show" class="sob_popup_section">
      <div class="sob_popup_background" @click="popupDefaultInfo.onBackground"></div>
      <div class="sob_popup_item">
        <div class="sob_popup_content_wrap">
          <div v-html="popupDefaultInfo.content" class="sob_popup_content"></div>
          <div class="sob_popup_button_section">
            <button class="sob_popup_button sob_popup_button_cancel" @click="popupDefaultInfo.onCancel">
              {{ popupDefaultInfo.cancelText }}
            </button>
            <button class="sob_popup_button sob_popup_button_done" @click="popupDefaultInfo.onOkay">{{ popupDefaultInfo.okayText }}</button>
          </div>
        </div>
      </div>
    </div>

    <!-- SLOT POPUP -->
    <template v-for="(slotId, slotIdIndex) in popupSlotIdList">
      <div :key="slotIdIndex" class="sob_popup_section">
        <div
          class="sob_popup_background"
          @click="
            typeof popupSlotBackgroundClickEventInfo[slotId] === 'function' ? popupSlotBackgroundClickEventInfo[slotId]() : function () {}
          "
        ></div>
        <div class="sob_popup_item">
          <slot :name="slotId"></slot>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import isMobile from "ismobilejs";

/** 소수점 끝자리 쓰레기 값 정리를 위한 임의의 값 */
const adjustNumber = 1000000;

// --------------------------------------------------------------- [ TYPE DEFINE ]
/**
 * @typedef { object } StockOrderBookData
 * @property { number } orderCountSellST 매도 신청 ST
 * @property { number } orderCountSell 매도 신청 건수
 * @property { number } countSellNumber 매도 건수
 * @property { number } countSellRemain 매도 잔량
 * @property { number } price 해당 가격
 * @property { number } countBuyRemain 매수 잔량
 * @property { number } countBuyNumber 매수 건수
 * @property { number } orderCountBuy 매수 신청 건수
 * @property { number } orderCountBuyST 매수 신청 ST
 */

/**
 * @typedef { 'SELL_STOP_LOSS' | 'SELL' | 'SELL_NUMBER' | 'SELL_REMAIN' | 'PRICE' | 'BUY_REMAIN' | 'BUY_NUMBER' | 'BUY' | 'BUY_STOP_LOSS' } ColumnType
 */

/**
 * @typedef { 'COLUMN' | 'PRICE' | 'BORDER' } ColumnSubType
 */

export default {
  name: "StockOrderBook",
  props: {
    /**
     * 표에 표시 될 한 줄의 값들
     * @type { Vue.PropOptions<StockOrderBookData[]> }
     */
    itemList: {
      type: Array,
      default: () => [],
    },
    /**
     * 매수 1호가
     * @type { Vue.PropOptions<Number> }
     */
    buy1stPrice: {
      type: Number,
      default: 0,
    },
    /**
     * 현재 가격
     * @type { Vue.PropOptions<Number> }
     */
    nowPrice: {
      type: Number,
      default: 0,
    },
    /**
     * 당일 고가
     * @type { Vue.PropOptions<Number> }
     */
    highPrice: {
      type: Number,
      default: 0,
    },
    /**
     * 당일 저가
     * @type { Vue.PropOptions<Number> }
     */
    lowPrice: {
      type: Number,
      default: 0,
    },
    /**
     * 전일 종가
     * @type { Vue.PropOptions<Number> }
     */
    yesterdayClosingPrice: {
      type: Number,
      default: 0,
    },
    /**
     * 줄 당 가격 차이
     * @type { Vue.PropOptions<Number> }
     */
    stepPrice: {
      type: Number,
      default: 0.25,
    },
    /**
     * 아이템 표시 포커스 금액
     * @type { Vue.PropOptions<Number> }
     */
    focusPrice: {
      type: Number,
      default: undefined,
    },
    /**
     * 보여질 테이블 줄 갯수
     * @type { Vue.PropOptions<Number> }
     */
    visibleRowCount: {
      type: Number,
      default: 20,
    },
    /**
     * 전체 스크롤 가능한 줄 갯수
     * @type { Vue.PropOptions<Number> }
     */
    availScrollRowCount: {
      type: Number,
      default: 40,
    },
    /**
     * 한 번 스크롤 당 이동할 줄 갯수
     * @type { Vue.PropOptions<Number> }
     */
    scrollMoveRowCount: {
      type: Number,
      default: 2,
    },
    /**
     * 한 번 터치 드래그 당 이동할 줄 갯수
     * @type { Vue.PropOptions<Number> }
     */
    touchMoveRowCount: {
      type: Number,
      default: 1,
    },
    /**
     * 터치 드래그로 인식할 이동 거리
     * @type { Vue.PropOptions<Number> }
     */
    touchMoveDistance: {
      type: Number,
      default: 1,
    },
    /**
     * 터치 드래그로 가속 이동
     * @type { Vue.PropOptions<Boolean> }
     */
    touchMoveAcceleration: {
      type: Boolean,
      default: true,
    },
    /**
     * 테이블 스크롤 고정 여부
     * @type { Vue.PropOptions<Boolean> }
     */
    scrollHold: {
      type: Boolean,
      default: true,
    },
    /**
     * 아이템 컬럼 더블클릭 인정 시간
     * @type { Vue.PropOptions<Number> }
     */
    itemColumnDoubleClickTime: {
      type: Number,
      default: 120,
    },
    /**
     * 아이템 컬럼 롱터치 인정 시간
     * @type { Vue.PropOptions<Number> }
     */
    itemColumnLongTouchTime: {
      type: Number,
      default: 750,
    },
    /**
     * 스크롤 일봉 한 칸당 퍼센티지
     * @type { Vue.PropOptions<Number> }
     */
    dailyCandlePerPercent: {
      type: Number,
      default: 1,
    },
    /**
     * 스크롤 일봉 전체 칸 수
     * @type { Vue.PropOptions<Number> }
     */
    dailyCandleMaxPercent: {
      type: Number,
      default: 8,
    },
  },
  emits: {
    // ----------------------------------------------- [ 변수 업데이트 ]
    "update:focusPrice":
      /**
       * 내부 focusPrice 값 변동시 업데이트 알림
       * @param { number } focusPrice
       * @returns { any }
       */
      // eslint-disable-next-line no-unused-vars
      (focusPrice) => undefined,
    "update:scrollHold":
      /**
       * 내부 scrollHold 값 변동시 업데이트 알림
       * @param { boolean } scrollHold
       * @returns { any }
       */
      // eslint-disable-next-line no-unused-vars
      (scrollHold) => undefined,
    "update:scrollPosition":
      /**
       * 내부 scrollPosition 값 변동시 업데이트 알림
       * @param { boolean } scrollPosition
       * @returns { any }
       */
      // eslint-disable-next-line no-unused-vars
      (scrollPosition) => undefined,
    // ----------------------------------------------- [ 이벤트 ]
    "event:focus":
      /**
       * 섹션 포커스 알림
       * @returns { any }
       */
      // eslint-disable-next-line no-unused-vars
      () => undefined,
    "event:blur":
      /**
       * 섹션 포커스 블러 알림
       * @returns { any }
       */
      // eslint-disable-next-line no-unused-vars
      () => undefined,
    "event:keyup":
      /**
       * 섹션 포커스 중 키보드 업 이벤트 발생 알림
       * @param { string } key
       * @returns { any }
       */
      // eslint-disable-next-line no-unused-vars
      (key) => undefined,
    "event:keydown":
      /**
       * 섹션 포커스 중 키보드 다운 이벤트 발생 알림
       * @param { string } key
       * @returns { any }
       */
      // eslint-disable-next-line no-unused-vars
      (key) => undefined,
    "event:columnHover":
      /**
       * 컬럼 호버 알림
       * @param { number } price
       * @param { ColumnType } columnType
       * @returns { any }
       */
      // eslint-disable-next-line no-unused-vars
      (price, columnType) => undefined,
    "event:columnClick":
      /**
       * 컬럼 클릭 알림
       * @param { number } price
       * @param { ColumnType } columnType
       * @returns { any }
       */
      // eslint-disable-next-line no-unused-vars
      (price, columnType) => undefined,
    "event:columnDoubleClick":
      /**
       * 컬럼 더블 클릭 알림
       * @param { number } price
       * @param { ColumnType } columnType
       * @returns { any }
       */
      // eslint-disable-next-line no-unused-vars
      (price, columnType) => undefined,
    "event:columnDrag":
      /**
       * 컬럼 클릭 알림
       *
       * 컴포넌트 밖으로 드래그시 targetPrice -1, targetColumnType undefined 반환
       *
       * @param { number } originPrice
       * @param { ColumnType } originColumnType
       * @param { number } targetPrice
       * @param { ColumnType } targetColumnType
       * @returns { any }
       */
      // eslint-disable-next-line no-unused-vars
      (originPrice, originColumnType, targetPrice, targetColumnType) => undefined,
    "event:columnTouch":
      /**
       * 컬럼 터치 알림
       * @param { number } price
       * @param { ColumnType } columnType
       * @returns { any }
       */
      // eslint-disable-next-line no-unused-vars
      (price, columnType) => undefined,
    "event:columnLongTouch":
      /**
       * 컬럼 터치 알림
       * @param { number } price
       * @param { ColumnType } columnType
       * @returns { any }
       */
      // eslint-disable-next-line no-unused-vars
      (price, columnType) => undefined,
  },
  data() {
    /**
     * 외부 변수와 연동되는 내부 변수는 이름 앞에 inner가 붙습니다.
     */
    return {
      /** HTML 동작 관련 고유 ID */
      componentId: Array.from({ length: 4 }, () => Math.floor(Math.random() * 36).toString(36)).join(""),
      /** 아이템 표시 포커스 금액 */
      innerFocusPrice: 0,
      /** 테이블 스크롤 고정 여부 */
      innerScrollHold: true,
      /** 테이블 스크롤 위치 */
      tableScrollTop: 0,
      /** 테이블 리사이징시 가운데 정렬을 위한 ResizeObserver */
      tableResizeObserver: new ResizeObserver(function () {}),
      /** 아이템 호버 인덱스, 클래스 */
      itemHoverIndex: [-1, ""],
      /** 아이템 마우스 다운 인덱스, 컬럼 타입 */
      itemMousedownIndex: [-1, ""],
      /** 아이템 마우스 다운 포커스 금액 */
      itemMousedownFocusPrice: -1,
      /** 아이템 마우스 다운 이벤트 시점 */
      itemMousedownTime: 0,
      /** 아이템 마우스 다운 이벤트 타임아웃 아이디 */
      itemMousedownTimeoutId: 0,
      /** 아이템 마우스 더블 다운 여부 */
      itemMousedownDouble: false,
      /** 섹션 포커스 여부 */
      isSectionFocused: false,
      /** 섹션 포커스 emit 이벤트 중복 호출 방지용 */
      lastSectionFocused: false,
      /** 커스텀 CSS */
      customRowItemCss: {},
      /** 기본 팝업 정보 */
      popupDefaultInfo: {
        show: false,
        content: "",
        okayText: "확인",
        cancelText: "취소",
        onOkay: function () {},
        onCancel: function () {},
        onBackground: function () {},
      },
      /** 표시할 커스텀 팝업 Slot ID */
      popupSlotIdList: [],
      /** 커스텀 팝업 백그라운드 클릭 이벤트 */
      popupSlotBackgroundClickEventInfo: {},
      /** 스크롤 이벤트 전달 쓰로틀링 타이머 */
      emitScrollEventTimer: null,
      /** 모바일 혹은 태블릿인지 여부 */
      isMobileDevice: false,
      /** 컬럼 터치 정보 */
      columnTouchInfo: {},
      /** 컬럼 터치 가속력 보관 */
      columnTouchAccelerationPower: 0,
      /** 컬럼 터치 가속력 감소 인터벌 */
      columnTouchAccelerationInterval: null,
      /** 셀 터치 이벤트 최초 아이디 저장 */
      touchEventCellId: null,
      /** 스크롤 터치 이벤트 최초 아이디 저장 */
      touchEventScrollId: null,
    };
  },
  computed: {
    /**
     * 현재 기준이 되는 금액
     *
     *
     * * 고정시: 매수 1호가
     * * 해제시: 현재가
     */
    centerPrice() {
      if (this.innerScrollHold) return this.buy1stPrice;
      return this.nowPrice;
    },

    /**
     * 금액: 아이템 으로 정렬한 오브젝트
     */
    itemObject() {
      const itemObject = {};

      this.itemList.forEach((item) => {
        itemObject[item.price] = item;
      });

      return itemObject;
    },

    /**
     * 줄 별 아이템
     */
    rowItemList() {
      return Array.from({ length: this.visibleRowCount }, (_, rowIndex) => this.getItemOfTargetRow(rowIndex));
    },

    /** 테이블 스크롤 길이 */
    tableScrollAreaHeight() {
      const scrollHeight = Math.ceil((this.visibleRowCount / this.availScrollRowCount) * 100);
      return Math.max(5, Math.min(100, scrollHeight));
    },

    /** 총 합계 */
    totalSum() {
      const sum = {
        orderCountSellST: 0,
        orderCountSell: 0,
        countSellNumber: 0,
        countSellRemain: 0,
        countBuyRemain: 0,
        countBuyNumber: 0,
        orderCountBuy: 0,
        orderCountBuyST: 0,
      };

      this.itemList.forEach((item) => {
        sum.orderCountSellST += item.orderCountSellST;
        sum.orderCountSell += item.orderCountSell;
        sum.countSellNumber += item.countSellNumber;
        sum.countSellRemain += item.countSellRemain;
        sum.countBuyRemain += item.countBuyRemain;
        sum.countBuyNumber += item.countBuyNumber;
        sum.orderCountBuy += item.orderCountBuy;
        sum.orderCountBuyST += item.orderCountBuyST;
      });

      return sum;
    },

    /**
     * 전일 종가 대비 현재가 %
     * @param { number } targetPrice
     */
    nowPricePercentageOfYesterdayClosingPrice() {
      return this.percentageOfYesterdayClosingPrice(this.nowPrice);
    },
  },
  watch: {
    // 기준가 변동시 (현재가 혹은 매수 1호가) - 고정이면 현재가로 포커스 고정, 스크롤바 위치 이동
    centerPrice: {
      immediate: true,
      handler(centerPrice) {
        if (this.innerFocusPrice === 0 || this.innerScrollHold) {
          this.innerFocusPrice = centerPrice;
        }

        // 매수 1호가 가격에 맞춰서 현재 표시 가격 최대 범위 조정
        this.updateInnerFocusPrice();

        // 아이템 표시 포커스 위치 변동에 따른 스크롤 위치 업데이트
        this.updateScrollByFocusPrice();
      },
    },

    // Focus Price 외부 - 내부 연동 및 스크롤 위치 초기화
    focusPrice: {
      immediate: true,
      handler(focusPrice) {
        if (this.innerScrollHold) return;

        if (typeof focusPrice === "number") {
          this.innerFocusPrice = focusPrice;
        }

        // 현재 가격에 맞춰서 현재 표시 가격 최대 범위 조정
        this.updateInnerFocusPrice();
      },
    },
    innerFocusPrice(innerFocusPrice) {
      if (this.$listeners["update:focusPrice"] && innerFocusPrice !== this.focusPrice) {
        this.$emit("update:focusPrice", this.removeGarbageValue(innerFocusPrice));
      }

      // 아이템 표시 포커스 위치 변동에 따른 스크롤 위치 업데이트
      this.updateScrollByFocusPrice();

      // 아이템 컬럼 호버중이면, 금액 변경시 변경된 금액에 맞춰 이벤트 전달
      const [hoverIndex, hoverClass] = this.itemHoverIndex;
      if (hoverIndex !== -1) {
        const hoverPrice = this.convertItemIndexToItemPrice(hoverIndex);
        const columnType = this.convertHoverClassToColumnType(hoverClass);
        this.$emit("event:columnHover", hoverPrice, columnType);
      }
    },

    // Scroll Hold 외부 - 내부 연동
    scrollHold: {
      immediate: true,
      handler(scrollHold) {
        if (typeof scrollHold === "boolean") {
          this.innerScrollHold = scrollHold;
        }
      },
    },
    innerScrollHold(innerScrollHold) {
      if (this.$listeners["update:scrollHold"] && innerScrollHold !== this.scrollHold) {
        this.$emit("update:scrollHold", innerScrollHold);
      }

      if (innerScrollHold) {
        this.innerFocusPrice = this.buy1stPrice;
        this.alignBodyContentScrollCenter();
      }

      // Scroll DOM Show가 반영된 이후 스크롤 위치 조정
      setTimeout(this.updateScrollByFocusPrice);
    },

    // 섹션 포커스 변동
    isSectionFocused() {
      this.debounceFocusEmit(
        function () {
          if (this.lastSectionFocused === this.isSectionFocused) return;

          this.lastSectionFocused = this.isSectionFocused;
          if (this.isSectionFocused) {
            this.$emit("event:focus");
          } else {
            this.$emit("event:blur");
          }
        }.bind(this)
      );
    },

    // 스크롤 위치 변경
    tableScrollTop() {
      // 쓰로틀링
      if (this.emitScrollEventTimer === null) {
        this.emitScrollEventTimer = setTimeout(
          function () {
            this.emitScrollEventTimer = null;
            const scrollPosition = this.tableScrollTop / (100 - this.tableScrollAreaHeight);
            this.$emit("update:scrollPosition", scrollPosition * 100);
          }.bind(this),
          100
        );
      }
    },

    // 터치 가속력 감소 인터벌 토글
    touchMoveAcceleration: {
      immediate: true,
      handler(touchMoveAcceleration) {
        clearInterval(this.columnTouchAccelerationInterval);

        if (touchMoveAcceleration) {
          this.columnTouchAccelerationInterval = setInterval(
            function () {
              // 이동이 거의 없을 땐, 이동 멈춤
              if (-1 <= this.columnTouchAccelerationPower && this.columnTouchAccelerationPower <= 1) {
                this.columnTouchAccelerationPower = 0;
                return;
              }

              // 미비한 이동일 땐, 가속력 없이 이동
              if (
                (-4 <= this.columnTouchAccelerationPower && this.columnTouchAccelerationPower < -1) ||
                (1 < this.columnTouchAccelerationPower && this.columnTouchAccelerationPower <= 4)
              ) {
                this.moveScrollBarPosition(this.columnTouchAccelerationPower);
                this.columnTouchAccelerationPower = 0;
                return;
              }

              // 가속 이동
              const moveDistance = Math.floor(this.columnTouchAccelerationPower / 4);
              this.columnTouchAccelerationPower -= moveDistance;
              this.moveScrollBarPosition(moveDistance);
            }.bind(this),
            50
          );
        }
      },
    },
  },
  mounted() {
    /** 키보드 이벤트 등록 */
    window.addEventListener("keyup", this.onKeyup);
    window.addEventListener("keydown", this.onKeydown);

    /** 초기 스크롤 중앙으로 정렬 */
    this.updateScrollByFocusPrice();

    /** 테이블 리사이징시 가운데 정렬을 위한 ResizeObserver 등록 */
    this.tableResizeObserver = new ResizeObserver(
      function () {
        if (this.innerScrollHold === false) return;
        this.alignBodyContentScrollCenter();
      }.bind(this)
    );
    this.tableResizeObserver.observe(this.$refs.bodyContent);

    /** 모바일 여부 확인 */
    this.checkMobileDevice();
    window.addEventListener("resize", this.checkMobileDevice.bind(this));
  },
  beforeDestroy() {
    /** 키보드 이벤트 해제 */
    window.removeEventListener("keyup", this.onKeyup);
    window.removeEventListener("keydown", this.onKeydown);

    /** ResizeObserver 해제 */
    if (this.tableResizeObserver) {
      this.tableResizeObserver.disconnect();
      this.tableResizeObserver = null;
    }
  },
  methods: {
    // ---------------------------------------------------------------------------- [ EXTERNAL SERVICE FEATURES ]

    /**
     * 외부로 제공되는 함수들은 함수 이름 앞에 do가 붙습니다.
     */

    /**
     * 버튼 동작: 센터정렬
     */
    doCenterAlign() {
      if (this.innerScrollHold) return;

      this.innerFocusPrice = this.nowPrice;
      this.updateScrollByFocusPrice();
      this.alignBodyContentScrollCenter();
    },

    /**
     * 컬럼 CSS 변경
     * @param { number } price
     * @param { ColumnType } columnType
     * @param { ColumnSubType } columnSubType
     * @param { string } style
     */
    doChangeColumnStyle(price, columnType, columnSubType, style) {
      price = Number(price);
      if (typeof price !== "number") {
        throw new Error("[doChangeColumnStyle] price가 숫자가 아닙니다.");
      }

      if (Boolean(this.customRowItemCss[price]) === false) {
        this.customRowItemCss[price] = {
          SELL_STOP_LOSS: { COLUMN: "", PRICE: "", BORDER: "" },
          SELL: { COLUMN: "", PRICE: "", BORDER: "" },
          SELL_NUMBER: { COLUMN: "", PRICE: "", BORDER: "" },
          SELL_REMAIN: { COLUMN: "", PRICE: "", BORDER: "" },
          PRICE: { COLUMN: "", PRICE: "", BORDER: "" },
          BUY_REMAIN: { COLUMN: "", PRICE: "", BORDER: "" },
          BUY_NUMBER: { COLUMN: "", PRICE: "", BORDER: "" },
          BUY: { COLUMN: "", PRICE: "", BORDER: "" },
          BUY_STOP_LOSS: { COLUMN: "", PRICE: "", BORDER: "" },
        };
      }

      this.$set(this.customRowItemCss[price][columnType], columnSubType, style);
    },

    /**
     * 컬럼 CSS 추가
     * @param { number } price
     * @param { ColumnType } columnType
     * @param { ColumnSubType } columnSubType
     * @param { string } style
     */
    doAddColumnStyle(price, columnType, columnSubType, style) {
      price = Number(price);
      if (typeof price !== "number") {
        throw new Error("[doChangeColumnStyle] price가 숫자가 아닙니다.");
      }

      const columnStyle = this.getColumnStyle(price);
      const beforeColumnSubTypeStyle = columnStyle[columnType][columnSubType] ? columnStyle[columnType][columnSubType] + ";" : "";
      this.doChangeColumnStyle(price, columnType, columnSubType, beforeColumnSubTypeStyle + style);
    },

    /**
     * 컬럼 CSS 조회
     * @param { number } price
     * @param { ColumnType } columnType
     * @param { ColumnSubType } columnSubType
     */
    doGetColumnStyle(price, columnType, columnSubType) {
      price = Number(price);
      if (typeof price !== "number") {
        throw new Error("[doChangeColumnStyle] price가 숫자가 아닙니다.");
      }

      return this.getColumnStyle(price)[columnType][columnSubType];
    },

    /**
     * 기본 팝업 표시
     * @param { object } popupInfo
     * @param { string } popupInfo.content
     * @param { string } popupInfo.okayText
     * @param { string } popupInfo.cancelText
     * @param { function } popupInfo.onOkay
     * @param { function } popupInfo.onCancel
     * @param { function } popupInfo.onBackground
     */
    doShowDefaultPopup(popupInfo) {
      this.popupDefaultInfo.show = true;
      this.popupDefaultInfo.content = popupInfo.content || "";
      this.popupDefaultInfo.okayText = popupInfo.okayText || "확인";
      this.popupDefaultInfo.cancelText = popupInfo.cancelText || "취소";
      this.popupDefaultInfo.onOkay = popupInfo.onOkay || function () {};
      this.popupDefaultInfo.onCancel = popupInfo.onCancel || function () {};
      this.popupDefaultInfo.onBackground = popupInfo.onBackground || function () {};
    },

    /**
     * 기본 팝업 숨김
     */
    doHideDefaultPopup() {
      this.popupDefaultInfo.show = false;
      this.popupDefaultInfo.content = "";
      this.popupDefaultInfo.okayText = "";
      this.popupDefaultInfo.cancelText = "";
      this.popupDefaultInfo.onOkay = function () {};
      this.popupDefaultInfo.onCancel = function () {};
      this.popupDefaultInfo.onBackground = function () {};

      // 팝업 닫을 때, 컴포넌트에 재포커싱
      this.$refs.refSection.focus();
    },

    /**
     * 커스텀 팝업 표시
     * @param { string } popupId
     */
    doShowCustomPopup(popupId) {
      const findedCustomPopupIdIndex = this.popupSlotIdList.findIndex(function (slotId) {
        return slotId === popupId;
      });

      if (findedCustomPopupIdIndex !== -1) {
        this.popupSlotIdList.push(this.popupSlotIdList.splice(findedCustomPopupIdIndex, 1)[0]);
      } else {
        this.popupSlotIdList.push(popupId);
      }
    },

    /**
     * 커스텀 팝업 숨김
     * @param { string } popupId
     */
    doHideCustomPopup(popupId) {
      const findedCustomPopupIdIndex = this.popupSlotIdList.findIndex(function (slotId) {
        return slotId === popupId;
      });

      if (findedCustomPopupIdIndex !== -1) {
        this.popupSlotIdList.splice(findedCustomPopupIdIndex, 1);
      }

      // 팝업 닫을 때, 컴포넌트에 재포커싱
      this.$refs.refSection.focus();
    },

    /**
     * 커스텀 팝업 배경 클릭 이벤트 설정
     * @param { string } popupId
     * @param { Function } callback
     */
    doSetCustomPopupBackgroundClickEvent(popupId, callback) {
      this.popupSlotBackgroundClickEventInfo[popupId] = callback;
    },

    // ---------------------------------------------------------------------------- [ SLOT ]

    /**
     * Slot으로 제공되는 함수들은 함수 이름 앞에 slot이 붙습니다.
     * @param { InputEvent } event
     */
    slotUpdateScrollHold(event) {
      this.innerScrollHold = event.target.checked;
    },

    // ---------------------------------------------------------------------------- [ ITEM ]

    /**
     * 금액으로 아이템 가져오기
     * @param { number } price
     */
    getItemWithPrice(price) {
      return (
        this.itemObject[price] || {
          orderCountSellST: 0,
          orderCountSell: 0,
          countSellNumber: 0,
          countSellRemain: 0,
          price: price,
          countBuyRemain: 0,
          countBuyNumber: 0,
          orderCountBuy: 0,
          orderCountBuyST: 0,
        }
      );
    },

    /**
     * 해당 줄의 아이템 가져오기
     * @param { number } rowIndex
     */
    getItemOfTargetRow(rowIndex) {
      const centerIndex = Math.ceil(this.visibleRowCount / 2);
      const centerAlignmentRowIndex = -rowIndex + centerIndex;
      // 소수점으로 계산시 끝에 쓰레기 값이 붙은 경우가 있어서, 정수로 변환 후 계산하고, 다시 소수로 바꿔줌
      const adjustPrice =
        Math.round(adjustNumber * centerAlignmentRowIndex * this.stepPrice + adjustNumber * this.innerFocusPrice) / adjustNumber;
      return this.getItemWithPrice(adjustPrice);
    },

    /**
     * 컬럼 CSS 조회 (모든 컬럼 타입)
     * @param { number } price
     */
    getColumnStyle(price) {
      price = Number(price);
      if (typeof price !== "number") {
        throw new Error("[doChangeColumnStyle] price가 숫자가 아닙니다.");
      }

      return (
        this.customRowItemCss?.[price] || {
          SELL_STOP_LOSS: { COLUMN: "", PRICE: "", BORDER: "" },
          SELL: { COLUMN: "", PRICE: "", BORDER: "" },
          SELL_NUMBER: { COLUMN: "", PRICE: "", BORDER: "" },
          SELL_REMAIN: { COLUMN: "", PRICE: "", BORDER: "" },
          PRICE: { COLUMN: "", PRICE: "", BORDER: "" },
          BUY_REMAIN: { COLUMN: "", PRICE: "", BORDER: "" },
          BUY_NUMBER: { COLUMN: "", PRICE: "", BORDER: "" },
          BUY: { COLUMN: "", PRICE: "", BORDER: "" },
          BUY_STOP_LOSS: { COLUMN: "", PRICE: "", BORDER: "" },
        }
      );
    },

    /**
     * 현재 가격에 맞춰서 현재 표시 가격 최대 범위 조정
     */
    updateInnerFocusPrice() {
      const maxIndex = this.availScrollRowCount - this.visibleRowCount;
      const halfRange = this.stepPrice * Math.floor(maxIndex / 2);
      this.innerFocusPrice = Math.max(this.centerPrice - halfRange, Math.min(this.centerPrice + halfRange, this.innerFocusPrice));
    },

    // ---------------------------------------------------------------------------- [ VIRTUAL SCROLL ]

    /**
     * 아이템 목록의 가상 스크롤이 아닌 브라우저 스크롤 가운데로 정렬
     */
    alignBodyContentScrollCenter() {
      this.$refs.bodyContent.scrollTop = (this.$refs.bodyContent.scrollHeight - this.$refs.bodyContent.offsetHeight) / 2;
    },

    /**
     * 아이템 표시 포커스 위치 변동에 따른 스크롤 위치 업데이트
     */
    updateScrollByFocusPrice() {
      if (this.$refs.scrollBarWrap === undefined) return;

      const maxPercent = 100 - this.tableScrollAreaHeight;
      const maxIndex = this.availScrollRowCount - this.visibleRowCount || 1;
      const focusIndex = (this.innerFocusPrice - this.centerPrice) / this.stepPrice;
      const scrollPercent = Math.max(0, Math.min(1, (maxIndex / 2 - focusIndex) / maxIndex));
      this.tableScrollTop = scrollPercent * maxPercent;
    },

    /** 아이템 목록 휠 이벤트 리스너 */
    onWheel(event) {
      const deltaY = event.deltaY;
      const moveDistance = this.scrollMoveRowCount * (deltaY >= 0 ? 1 : -1);
      this.moveScrollBarPosition(moveDistance);
    },

    /** 아이템 목록 스크롤 위치 위/아래로 이동 (음수: 위로, 양수: 아래로) */
    moveScrollBarPosition(moveDistance) {
      if (this.innerScrollHold) return;

      const maxIndex = this.availScrollRowCount - this.visibleRowCount;
      const halfRange = this.stepPrice * Math.floor(maxIndex / 2);
      const calculatedPrice = this.innerFocusPrice - moveDistance * this.stepPrice;
      this.innerFocusPrice = Math.max(this.centerPrice - halfRange, Math.min(this.centerPrice + halfRange, calculatedPrice));
    },

    /** 퍼센트로 아이템 목록 스크롤 이동 (0 맨 위, 1 맨 아래) */
    movePricePercent(percent) {
      const maxIndex = this.availScrollRowCount - this.visibleRowCount;
      this.innerFocusPrice = this.centerPrice + Math.floor(maxIndex * (0.5 - percent)) * this.stepPrice;
    },

    /** 아이템 목록 스크롤 마우스 다운 이벤트 리스너 */
    onScrollBarMousedown(event) {
      // 모바일 & 태블릿에서 해당 기능 동작 차단
      if (this.isMobileDevice) return;

      if (this.innerScrollHold) return;

      const maxPercent = 100 - this.tableScrollAreaHeight;
      const startPercent = -(this.tableScrollTop / maxPercent - 0.5);
      const startMouseY = event.clientY;

      // 마우스 이동 이벤트
      function onMousemove(event) {
        if (this.innerScrollHold) return;

        const mouseY = event.clientY;
        const moveDistnace = mouseY - startMouseY;

        const movePercent = moveDistnace / (this.$refs.scrollBarWrap.offsetHeight - this.tableScrollAreaHeight);
        const movedPercent = Math.max(-0.5, Math.min(0.5, startPercent - movePercent));
        this.movePricePercent(-movedPercent + 0.5);
      }
      const bindedOnMousemove = onMousemove.bind(this);
      window.addEventListener("mousemove", bindedOnMousemove);

      // 마우스를 떼면 마우스 이동 이벤트 해제
      function onMouseup() {
        window.removeEventListener("mousemove", bindedOnMousemove);
      }
      window.addEventListener("mouseup", onMouseup, { once: true });
    },

    /** 아이템 목록 스크롤랩 마우스 다운 이벤트 리스너 */
    onScrollBarWrapMousedown(event) {
      // 모바일 & 태블릿에서 해당 기능 동작 차단
      if (this.isMobileDevice) return;

      if (this.innerScrollHold) return;
      if (event.target !== event.currentTarget) return;

      let intervalId; // 반복 함수 등록 아이디
      let mouseY = event.offsetY; // 마지막 마우스 위치

      // 마우스 위치로 반복 이동
      function intervalScrollMove() {
        if (this.innerScrollHold) return;

        const maxPercent = 100 - this.tableScrollAreaHeight;
        const startPercent = -(this.tableScrollTop / maxPercent - 0.5);
        const startMouseY = (this.tableScrollTop / maxPercent) * this.$refs.scrollBarWrap.offsetHeight;

        const moveDistnace = mouseY - startMouseY;

        const movePercent = moveDistnace / (this.$refs.scrollBarWrap.offsetHeight - this.tableScrollAreaHeight);
        const movedPercent = Math.max(-0.5, Math.min(0.5, startPercent - movePercent / 1.5));
        this.movePricePercent(-movedPercent + 0.5);
      }
      const bindedIntervalScrollMove = intervalScrollMove.bind(this);

      // 현재 마우스 위치로 스크롤 이동
      function onMousemove(event) {
        if (event.target !== this.$refs.scrollBarWrap) {
          offEvent();
          return;
        }

        mouseY = event.offsetY;
      }
      const bindedOnMousemove = onMousemove.bind(this);
      window.addEventListener("mousemove", bindedOnMousemove);

      // 마우스를 떼면 마우스 이동 이벤트 해제
      function onMouseup() {
        window.removeEventListener("mousemove", bindedOnMousemove);
        clearInterval(intervalId);
      }
      window.addEventListener("mouseup", onMouseup, { once: true });

      intervalId = setInterval(bindedIntervalScrollMove, 25);
      bindedIntervalScrollMove();

      // 관련 모든 이벤트 해제
      function offEvent() {
        window.removeEventListener("mousemove", bindedOnMousemove);
        window.removeEventListener("mouseup", onMouseup);
        clearInterval(intervalId);
      }
    },

    // ---------------------------------------------------------------------------- [ MOUSE EVENT ]

    /** 아이템에 마우스 접근시 해당 아이템 컬럼 이름으로 클래스 추가 */
    onMouseOver(event, itemIndex) {
      // 모바일 & 태블릿에서 해당 기능 동작 차단
      if (this.isMobileDevice) return;

      let target = event.target;
      let className = target.className;

      while (/\ssob_col|sob_col\s/.test(className) === false) {
        target = target.parentElement;

        className = target.className;

        if (className.includes("sob_row") || className.includes("sob_body_content") || className.includes("sob_body")) {
          this.itemHoverIndex = [-1, ""];
          break;
        }
      }

      // 왼쪽 ST
      if (className.includes("sob_col_left") && className.includes("sob_col_st")) {
        className = "sob_hover_left_st";
      }

      // 왼쪽 매도
      if (className.includes("sob_col_left") && className.includes("sob_col_sell")) {
        className = "sob_hover_left_sell";
      }

      // 왼쪽 건수
      if (className.includes("sob_col_left") && className.includes("sob_col_number")) {
        className = "sob_hover_left_number";
      }

      // 왼쪽 잔량
      if (className.includes("sob_col_left") && className.includes("sob_col_remain")) {
        className = "sob_hover_left_remain";
      }

      // 현재가
      if (className.includes("sob_col_center") && className.includes("sob_col_price")) {
        className = "sob_hover_price";
      }

      // 오른쪽 ST
      if (className.includes("sob_col_right") && className.includes("sob_col_st")) {
        className = "sob_hover_right_st";
      }

      // 오른쪽 매도
      if (className.includes("sob_col_right") && className.includes("sob_col_buy")) {
        className = "sob_hover_right_buy";
      }

      // 오른쪽 건수
      if (className.includes("sob_col_right") && className.includes("sob_col_number")) {
        className = "sob_hover_right_number";
      }

      // 오른쪽 잔량
      if (className.includes("sob_col_right") && className.includes("sob_col_remain")) {
        className = "sob_hover_right_remain";
      }

      const [hoverIndex, hoverClass] = this.itemHoverIndex;
      if (hoverIndex === itemIndex && hoverClass === className) return;

      this.itemHoverIndex = [itemIndex, className];

      const hoverPrice = this.convertItemIndexToItemPrice(itemIndex);
      const columnType = this.convertHoverClassToColumnType(className);
      this.$emit("event:columnHover", hoverPrice, columnType);
    },

    /** 아이템에서 마우스가 떠날시 포커스된 아이템 초기화 */
    onMouseleave() {
      // 모바일 & 태블릿에서 해당 기능 동작 차단
      if (this.isMobileDevice) return;

      this.itemHoverIndex = [-1, ""];
    },

    /** 아이템 마우스 다운 (클릭, 더블클릭, 드래그 시작) */
    onMousedown() {
      // 모바일 & 태블릿에서 해당 기능 동작 차단
      if (this.isMobileDevice) return;

      // 마우스를 떼면 마우스 이동 이벤트 해제
      window.addEventListener("mouseup", this.onMouseup.bind(this), { once: true });

      const [hoverIndex, hoverClass] = this.itemHoverIndex;
      const hoverColumnType = this.convertHoverClassToColumnType(hoverClass);

      // 더블 클릭 확인
      const [clickIndex, columnType] = this.itemMousedownIndex;
      const now = new Date().getTime();
      if (hoverIndex === clickIndex && hoverColumnType === columnType && now - this.itemMousedownTime < this.itemColumnDoubleClickTime) {
        clearTimeout(this.itemMousedownTimeoutId);
        this.itemMousedownDouble = true;
        return;
      }

      // 클릭 인덱스 저장
      this.itemMousedownIndex = [hoverIndex, hoverColumnType];
      this.itemMousedownFocusPrice = this.innerFocusPrice;
    },

    /** 아이템 마우스 업 (드래그 종료) */
    onMouseup() {
      // 모바일 & 태블릿에서 해당 기능 동작 차단
      if (this.isMobileDevice) return;

      const [hoverIndex, hoverClass] = this.itemHoverIndex;
      const hoverColumnType = this.convertHoverClassToColumnType(hoverClass);
      const [clickIndex, columnType] = this.itemMousedownIndex;

      // 스크롤 고정인 상태에서는 같은 자리 클릭시 드래그 발생 X
      if (
        hoverIndex !== clickIndex ||
        (hoverIndex === clickIndex && hoverColumnType !== columnType) ||
        (this.innerScrollHold === false && this.itemMousedownFocusPrice !== this.innerFocusPrice)
      ) {
        // 드래그
        const focusPriceDifference = this.removeGarbageValue(this.itemMousedownFocusPrice - this.innerFocusPrice);
        const clickPrice = this.convertItemIndexToItemPrice(clickIndex);
        const hoverPrice = hoverIndex === -1 ? -1 : this.convertItemIndexToItemPrice(hoverIndex);
        this.$emit("event:columnDrag", clickPrice + focusPriceDifference, columnType, hoverPrice, hoverColumnType);
        this.itemMousedownIndex = [-1, ""];
        this.itemMousedownFocusPrice = -1;
        this.itemMousedownTime = -1;
        this.itemMousedownDouble = false;
        return;
      }

      if (hoverIndex === -1 || clickIndex === -1) return;

      if (hoverColumnType !== columnType) return;

      // 더블 클릭 이벤트 발생
      if (this.itemMousedownDouble === true) {
        const clickPrice = this.convertItemIndexToItemPrice(clickIndex);
        this.$emit("event:columnDoubleClick", clickPrice, columnType);
        this.itemMousedownIndex = [-1, ""];
        this.itemMousedownFocusPrice = -1;
        this.itemMousedownTime = -1;
        this.itemMousedownDouble = false;
        return;
      }

      // 클릭 이벤트 발생
      clearTimeout(this.itemMousedownTimeoutId);
      this.itemMousedownTime = new Date().getTime();
      this.itemMousedownTimeoutId = setTimeout(
        function () {
          const clickPrice = this.convertItemIndexToItemPrice(clickIndex);
          this.$emit("event:columnClick", clickPrice, columnType);
          this.itemMousedownTime = -1;
          this.itemMousedownIndex = [-1, ""];
          this.itemMousedownDouble = false;
        }.bind(this),
        this.itemColumnDoubleClickTime
      );
    },

    /**
     * 호버 클래스 -> 컬럼 타입 전환
     * @param { string } hoverClass
     * @returns { ColumnType }
     */
    convertHoverClassToColumnType(hoverClass) {
      /** @type { [key: string]: ColumnType } */
      const convertMap = {
        sob_hover_left_st: "SELL_STOP_LOSS",
        sob_hover_left_sell: "SELL",
        sob_hover_left_number: "SELL_NUMBER",
        sob_hover_left_remain: "SELL_REMAIN",
        sob_hover_price: "PRICE",
        sob_hover_right_remain: "BUY_REMAIN",
        sob_hover_right_number: "BUY_NUMBER",
        sob_hover_right_buy: "BUY",
        sob_hover_right_st: "BUY_STOP_LOSS",
      };
      return convertMap[hoverClass];
    },

    // ---------------------------------------------------------------------------- [ KEY EVENT ]

    onSectionFocus() {
      this.isSectionFocused = true;
    },

    onSectionBlur() {
      this.isSectionFocused = false;
    },

    onKeyup(event) {
      this.$emit("event:keyup", event);
    },

    onKeydown(event) {
      this.$emit("event:keydown", event);
    },

    // ---------------------------------------------------------------------------- [ COLUMN TOUCH EVENT ]

    /** 컬럼 터치 이벤트 */
    onColumnTouchStart(event) {
      if (this.touchEventCellId === null) {
        this.touchEventCellId = event.targetTouches?.[0]?.identifier || 0;
      }

      const touchList = [...event.changedTouches];
      // 일단, 단일 터치만 지원
      touchList
        .filter(
          function (touch) {
            const isItemColumn =
              touch.target.className.includes("sob_col_item") || touch.target.parentElement.className.includes("sob_col_item");
            return touch.identifier === this.touchEventCellId && isItemColumn;
          }.bind(this)
        )
        .forEach(
          function (touch) {
            // 터치한 객체 조회
            let touchColumn = touch.target;
            while (touchColumn.className.includes("sob_col_item") === false && touchColumn.className.includes("sob_body") === false) {
              touchColumn = touchColumn.parentElement;
            }
            if (touchColumn.className.includes("sob_col_item") === false) return;

            // 몇 번째 컬럼인지 확인
            const touchRow = touchColumn.parentElement;
            const bodyContent = touchRow.parentElement;
            const rowCount = [...bodyContent.children].findIndex(function (child) {
              return child === touchRow;
            });
            if (rowCount === -1) return;

            // 컬럼 가격 조회
            const rowInfo = this.getItemOfTargetRow(rowCount - 1);
            const rowPrice = rowInfo.price;

            // 컬럼 타입 조회
            const columnTypeRaw = touchColumn.className
              .replace(/sob_col/g, "")
              .replace(/_item|_center| |/g, "")
              .replace(/^_/, "");
            const columnTypeMap = {
              left_st: "SELL_STOP_LOSS",
              left_sell: "SELL",
              left_number: "SELL_NUMBER",
              left_remain: "SELL_REMAIN",
              price: "PRICE",
              right_remain: "BUY_REMAIN",
              right_number: "BUY_NUMBER",
              right_buy: "BUY",
              right_st: "BUY_STOP_LOSS",
            };
            const columnType = columnTypeMap[columnTypeRaw];

            // 컬럼 터치 정보 생성
            if (this.columnTouchInfo[touch.identifier] === undefined) {
              this.columnTouchInfo[touch.identifier] = {
                type: "NONE",
                startPrice: rowPrice,
                startColumnType: columnType,
                longTouchTimeout: setTimeout(
                  function () {
                    this.columnTouchInfo[touch.identifier].type = "LONG_TOUCH";

                    this.$emit(
                      "event:columnLongTouch",
                      this.columnTouchInfo[touch.identifier].startPrice,
                      this.columnTouchInfo[touch.identifier].startColumnType
                    );

                    delete this.columnTouchInfo[touch.identifier];
                  }.bind(this),
                  this.itemColumnLongTouchTime
                ),
                startX: touch.pageX,
                startY: touch.pageY,
                moveX: touch.pageX,
                moveY: touch.pageY,
              };
            }
          }.bind(this)
        );
    },

    /** 컬럼 터치 드래그 이벤트 */
    onColumnTouchMove(event) {
      const touchList = [...event.changedTouches];

      // 일단, 단일 터치만 지원
      touchList
        .filter(
          function (touch) {
            return touch.identifier === this.touchEventCellId;
          }.bind(this)
        )
        .forEach(
          function (touch) {
            // 컬럼 터치 이동 정보 업데이트
            if (this.columnTouchInfo[touch.identifier]) {
              this.columnTouchInfo[touch.identifier].moveX = touch.pageX;
              this.columnTouchInfo[touch.identifier].moveY = touch.pageY;

              const { startX, startY, moveX, moveY } = this.columnTouchInfo[touch.identifier];
              const dist = Math.sqrt(Math.pow(moveX - startX, 2) + Math.pow(moveY - startY, 2));
              if (dist >= 10) {
                if (this.columnTouchInfo[touch.identifier].longTouchTimeout !== null) {
                  clearTimeout(this.columnTouchInfo[touch.identifier].longTouchTimeout);
                  this.columnTouchInfo[touch.identifier].longTouchTimeout = null;
                  this.columnTouchInfo[touch.identifier].type = "DRAG";
                }

                if (this.innerScrollHold === false && this.columnTouchInfo[touch.identifier].type === "DRAG") {
                  const distY = startY - moveY;

                  if (Math.abs(distY) >= this.touchMoveDistance) {
                    this.columnTouchInfo[touch.identifier].startX = touch.pageX;
                    this.columnTouchInfo[touch.identifier].startY = touch.pageY;

                    const moveDistance = Math.floor((this.touchMoveRowCount * Math.floor(distY / this.touchMoveDistance)) / 10);
                    if (this.touchMoveAcceleration) {
                      this.columnTouchAccelerationPower += moveDistance * 2;
                    } else {
                      this.moveScrollBarPosition(moveDistance);
                    }
                  }
                }
              }
            }
          }.bind(this)
        );
    },

    /** 컬럼 터치 종료 이벤트 */
    onColumnTouchEnd(event) {
      const touchList = [...event.changedTouches];

      touchList.forEach(
        function (touch) {
          // 컬럼 터치 정보 삭제
          if (this.columnTouchInfo[touch.identifier]) {
            if (this.columnTouchInfo[touch.identifier].longTouchTimeout) {
              clearTimeout(this.columnTouchInfo[touch.identifier].longTouchTimeout);
              this.columnTouchInfo[touch.identifier].longTouchTimeout = null;
            }

            if (this.columnTouchInfo[touch.identifier].type === "NONE") {
              this.$emit(
                "event:columnTouch",
                this.columnTouchInfo[touch.identifier].startPrice,
                this.columnTouchInfo[touch.identifier].startColumnType
              );
            }

            delete this.columnTouchInfo[touch.identifier];
          }

          if (touch.identifier === this.touchEventCellId) {
            this.touchEventCellId = null;
          }
        }.bind(this)
      );
    },

    // ---------------------------------------------------------------------------- [ SCROLL TOUCH EVENT ]

    /** 스크롤 영역 터치 이벤트 */
    onScrollBarTouchStart(event) {
      if (this.innerScrollHold) return;

      if (event.target.className.includes("sob_scroll_bar") === false) return;

      if (this.touchEventScrollId === null) {
        this.touchEventScrollId = event.targetTouches?.[0]?.identifier || 0;
      }

      const touchList = [...event.changedTouches];
      const touchInfo = touchList.find(
        function (touch) {
          return touch.identifier === this.touchEventScrollId;
        }.bind(this)
      );
      if (touchInfo === undefined) return;

      const offset = this.$refs.scrollBarWrap.getBoundingClientRect();
      const offsetWidthCorrection = this.$refs.scrollBarWrap.offsetWidth / 2;
      const maxPercent = 100 - this.tableScrollAreaHeight;
      const startPercent = -(this.tableScrollTop / maxPercent - 0.5);

      function onScrollBarTouchMove(moveEvent) {
        if (this.innerScrollHold) return;
        if (moveEvent.target.className.includes("sob_scroll_bar") === false) {
          offEvent.call(this);
          return;
        }

        const moveTouchList = [...moveEvent.changedTouches];
        const moveTouchInfo = moveTouchList.find(
          function (touch) {
            return touch.identifier === this.touchEventScrollId;
          }.bind(this)
        );
        if (moveTouchInfo === undefined) return;

        if (
          moveTouchInfo.clientX < offset.x - offsetWidthCorrection ||
          moveTouchInfo.clientX > offset.x + offset.width + offsetWidthCorrection ||
          moveTouchInfo.clientY < offset.y ||
          moveTouchInfo.clientY > offset.y + offset.height
        ) {
          offEvent.call(this);
          return;
        }

        const moveDistnace = moveTouchInfo.clientY - touchInfo.clientY;

        const movePercent = moveDistnace / (this.$refs.scrollBarWrap.offsetHeight - this.tableScrollAreaHeight);
        const movedPercent = Math.max(-0.5, Math.min(0.5, startPercent - movePercent));
        this.movePricePercent(-movedPercent + 0.5);
      }
      const bindedOnScrollBarTouchMove = onScrollBarTouchMove.bind(this);
      window.addEventListener("touchmove", bindedOnScrollBarTouchMove);

      function onScrollBarTouchEnd() {
        offEvent.call(this);
      }
      const bindedOnScrollBarTouchEnd = onScrollBarTouchEnd.bind(this);
      window.addEventListener("touchend", bindedOnScrollBarTouchEnd, { once: true });

      function offEvent() {
        window.removeEventListener("touchmove", bindedOnScrollBarTouchMove);
        window.removeEventListener("touchend", bindedOnScrollBarTouchEnd);
        this.touchEventScrollId = null;
      }
    },

    // ---------------------------------------------------------------------------- [ UTIL ]

    /**
     * 모바일 여부 확인
     */
    checkMobileDevice() {
      const isMobileType = isMobile(navigator.userAgent);
      this.isMobileDevice = isMobileType.phone || isMobileType.tablet;
    },

    /**
     * 아이템 인덱스를 해당 아이템 금액으로 변경
     * @param { Number } itemIndex
     */
    convertItemIndexToItemPrice(itemIndex) {
      const itemPrice = this.removeGarbageValue(this.innerFocusPrice + this.stepPrice * Math.floor(this.visibleRowCount / 2 - itemIndex));
      return itemPrice;
    },

    /**
     * 섹션 포커스 emit 알림 디바운스
     */
    debounceFocusEmit: (function () {
      let timeoutId = 0;
      return function (emitFunction) {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(emitFunction, 100);
      };
    })(),

    /**
     * 전일 종가 대비 타겟 금액 %
     * @param { number } targetPrice
     */
    percentageOfYesterdayClosingPrice(targetPrice) {
      const percentageOfYesterdayClosingPrice = ((targetPrice - this.yesterdayClosingPrice) / this.yesterdayClosingPrice) * 100;
      const isMinus = percentageOfYesterdayClosingPrice < 0;
      return Math.abs(percentageOfYesterdayClosingPrice / this.dailyCandlePerPercent) * (isMinus ? -1 : 1);
    },

    /**
     * 소수점 끝자리 쓰레기 값 제거
     */
    removeGarbageValue(value) {
      const adjustValue = Math.round(adjustNumber * value) / adjustNumber;
      return adjustValue;
    },

    /**
     * 빈 값 숨기기
     * @param { any } value
     */
    hideEmpty(value) {
      if (typeof value !== "boolean" && Boolean(value) === false) return "";
      return value;
    },
  },
};
</script>

<style scoped>
/* SECTION */
.sob_section {
  position: relative;
  width: 530px;
  height: 100%;
  display: flex;
  flex-direction: column;
  outline: none;
}

/* BODY */
.sob_section .sob_body {
  position: relative;
  display: flex;
  flex-direction: row;
  min-height: 120px;
}

.sob_section .sob_body .sob_body_content {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

/* BODY - SCROLL BAR */

.sob_section .sob_body .sob_body_scroll {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 25px;
  margin-left: 10px;
  background-color: #f1f1f1;
}

.sob_section .sob_body .sob_body_scroll .sob_scroll_button {
  user-select: none;
  flex: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 4px 0;
  line-height: 100%;
  font-size: 12px;
  color: #9e9e9e;
  background-color: #f1f1f1;
}

.sob_section .sob_body .sob_body_scroll .sob_scroll_bar_wrap {
  user-select: none;
  position: relative;
  flex: 1;
  display: flex;
  height: 100%;
  margin: 4px 0;
  overflow: hidden;
}

.sob_section .sob_body .sob_body_scroll .sob_scroll_bar_wrap .sob_scroll_bar {
  user-select: none;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  background-color: #d6d6d6;
}

.sob_section .sob_body:not(:has(.sob_scroll_hold)) :where(.sob_scroll_button, .sob_scroll_bar) {
  cursor: pointer;
}

.sob_section .sob_body:has(.sob_scroll_hold) :where(.sob_scroll_button, .sob_scroll_bar) {
  cursor: default;
}

/* BODY - DAILY STOCK PRICE */
.sob_section .sob_body .sob_body_daily_stock_price {
  pointer-events: none;
  user-select: none;
  position: absolute;
  top: 24px;
  right: 0px;
  width: 25px;
  height: calc(100% - 48px);
  overflow: hidden;
}

.sob_section .sob_body .sob_body_daily_stock_price .sob_now_price_line {
  position: absolute;
  top: 50%;
  right: 7.5px;
  min-width: 10px;
  transform: translateY(-100%);
  z-index: 1;
}

.sob_section .sob_body .sob_body_daily_stock_price .sob_high_price_line {
  position: absolute;
  top: 50%;
  right: 10px;
  min-width: 5px;
  transform: translateY(-100%);
  z-index: 1;
}

.sob_section .sob_body .sob_body_daily_stock_price .sob_low_price_line {
  position: absolute;
  top: 50%;
  right: 10px;
  min-width: 5px;
  z-index: 1;
}

.sob_section
  .sob_body
  .sob_body_daily_stock_price.sob_body_higher_price_than_yesterday
  :where(.sob_now_price_line, .sob_high_price_line, .sob_low_price_line) {
  background-color: #dd9d96;
}

.sob_section
  .sob_body
  .sob_body_daily_stock_price.sob_body_lower_price_than_yesterday
  :where(.sob_now_price_line, .sob_high_price_line, .sob_low_price_line) {
  background-color: #94c5ea;
}

.sob_section .sob_body .sob_body_daily_stock_price .sob_yesterday_price_line {
  position: absolute;
  top: 50%;
  right: 0px;
  min-width: 25px;
  height: 1px;
  background-color: #000000;
  transform: translateY(-50%);
  z-index: 1;
}

/* BODY - ROW */
.sob_section .sob_body .sob_row {
  position: relative;
  display: flex;
  flex-direction: row;
}

.sob_section .sob_body {
  touch-action: none;
}

/* BODY - ROW - HEADER */

.sob_section .sob_body .sob_row.sob_row_header {
  position: sticky;
  top: 0px;
  width: 100%;
  z-index: 1;
}

/* BODY - ROW - FOOTER */

.sob_section .sob_body .sob_row.sob_row_footer {
  position: sticky;
  bottom: 0px;
  width: 100%;
}

/* BODY - ROW - COL */

.sob_section .sob_body .sob_row .sob_col {
  user-select: none;
  position: relative;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 24px;
  box-sizing: border-box;
  font-size: 14px;
  background-color: #ffffff;
}

.sob_section .sob_body .sob_row .sob_col:not(.sob_col_item) {
  overflow: hidden;
}

/* BODY - ROW - COL - HEADER */

.sob_section .sob_body .sob_row .sob_col.sob_col_header {
  font-size: 13px;
  background-color: #e6e6e6;
}

/* BODY - ROW - COL - ITEM */

.sob_section .sob_body .sob_row .sob_col.sob_col_item {
  cursor: pointer;
  font-size: 13px;
  color: #303030;
}

.sob_section .sob_body .sob_row.sob_row_upper .sob_col.sob_col_item.sob_col_price {
  color: #ff0000;
}

.sob_section .sob_body .sob_row.sob_row_lower .sob_col.sob_col_item.sob_col_price {
  color: #00b7ff;
}

.sob_section .sob_body .sob_row .sob_col.sob_col_item:not(.sob_col_center) {
  color: #000000;
}

.sob_section .sob_body .sob_row .sob_col.sob_col_item.sob_col_left.sob_col_sell {
  background-color: #94c5ea;
}

.sob_section .sob_body .sob_row .sob_col.sob_col_item.sob_col_right.sob_col_buy {
  background-color: #dd9d96;
}

.sob_section .sob_body .sob_row .sob_col.sob_col_item.sob_col_left:where(.sob_col_number, .sob_col_remain):has(.sob_col_value:not(:empty)) {
  background-color: #d3e6f6;
}

.sob_section .sob_body .sob_row .sob_col.sob_col_item.sob_col_left.sob_col_number:has(.sob_col_value:not(:empty)) + .sob_col_remain {
  background-color: #d3e6f6;
}

.sob_section
  .sob_body
  .sob_row
  .sob_col.sob_col_item.sob_col_left.sob_col_number:has(~ .sob_col_left.sob_col_remain > .sob_col_value:not(:empty)) {
  background-color: #d3e6f6;
}

.sob_section
  .sob_body
  .sob_row
  .sob_col.sob_col_item.sob_col_right:where(.sob_col_number, .sob_col_remain):has(.sob_col_value:not(:empty)) {
  background-color: #edcbc6;
}

.sob_section .sob_body .sob_row .sob_col.sob_col_item.sob_col_right.sob_col_remain:has(.sob_col_value:not(:empty)) + .sob_col_number {
  background-color: #edcbc6;
}

.sob_section
  .sob_body
  .sob_row
  .sob_col.sob_col_item.sob_col_right.sob_col_remain:has(~ .sob_col_right.sob_col_number > .sob_col_value:not(:empty)) {
  background-color: #edcbc6;
}

.sob_section .sob_body .sob_row .sob_col.sob_col_center {
  flex: 1.4;
}

.sob_section .sob_body .sob_row .sob_col .sob_col_border {
  pointer-events: none;
  user-select: none;
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 24px;
  border: 1px solid #d1d1d1;
  box-sizing: border-box;
}

.sob_section .sob_body .sob_row .sob_col.sob_col_item.sob_col_left.sob_col_sell .sob_col_border {
  border-color: #7daed3;
}

.sob_section .sob_body .sob_row .sob_col.sob_col_item.sob_col_right.sob_col_buy .sob_col_border {
  border-color: #c4837c;
}

.sob_section
  .sob_body
  .sob_row:not(.sob_row_center):not(:where(.sob_hover_left_st, .sob_hover_left_sell, .sob_hover_left_number, .sob_hover_left_remain))
  .sob_col.sob_col_item.sob_col_left:where(.sob_col_number, .sob_col_remain):has(.sob_col_value:not(:empty))
  .sob_col_border {
  border-color: #acbfcf;
}

.sob_section
  .sob_body
  .sob_row:not(.sob_row_center):not(:where(.sob_hover_left_st, .sob_hover_left_sell, .sob_hover_left_number, .sob_hover_left_remain))
  .sob_col.sob_col_item.sob_col_left.sob_col_number:has(.sob_col_value:not(:empty))
  + .sob_col_remain
  .sob_col_border {
  border-color: #acbfcf;
}

.sob_section
  .sob_body
  .sob_row:not(.sob_row_center):not(:where(.sob_hover_left_st, .sob_hover_left_sell, .sob_hover_left_number, .sob_hover_left_remain))
  .sob_col.sob_col_item.sob_col_left.sob_col_number:has(~ .sob_col_left.sob_col_remain > .sob_col_value:not(:empty))
  .sob_col_border {
  border-color: #acbfcf;
}

.sob_section
  .sob_body
  .sob_row:not(.sob_row_center):not(:where(.sob_hover_right_remain, .sob_hover_right_number, .sob_hover_right_buy, .sob_hover_right_st))
  .sob_col.sob_col_item.sob_col_right:where(.sob_col_number, .sob_col_remain):has(.sob_col_value:not(:empty))
  .sob_col_border {
  border-color: #c7a4a1;
}

.sob_section
  .sob_body
  .sob_row:not(.sob_row_center):not(:where(.sob_hover_right_remain, .sob_hover_right_number, .sob_hover_right_buy, .sob_hover_right_st))
  .sob_col.sob_col_item.sob_col_right.sob_col_remain:has(.sob_col_value:not(:empty))
  + .sob_col_number
  .sob_col_border {
  border-color: #c7a4a1;
}

.sob_section
  .sob_body
  .sob_row:not(.sob_row_center):not(:where(.sob_hover_right_remain, .sob_hover_right_number, .sob_hover_right_buy, .sob_hover_right_st))
  .sob_col.sob_col_item.sob_col_right.sob_col_remain:has(~ .sob_col_right.sob_col_number > .sob_col_value:not(:empty))
  .sob_col_border {
  border-color: #c7a4a1;
}

.sob_section
  .sob_body
  .sob_row.sob_row_center:not(:where(.sob_hover_left_st, .sob_hover_left_sell, .sob_hover_left_number, .sob_hover_left_remain))
  .sob_col.sob_col_item.sob_col_left:where(.sob_col_number, .sob_col_remain):has(.sob_col_value:not(:empty))
  .sob_col_border {
  border-bottom-color: #acbfcf;
  border-left-color: #acbfcf;
  border-right-color: #acbfcf;
}

.sob_section
  .sob_body
  .sob_row.sob_row_center:not(:where(.sob_hover_left_st, .sob_hover_left_sell, .sob_hover_left_number, .sob_hover_left_remain))
  .sob_col.sob_col_item.sob_col_left.sob_col_number:has(.sob_col_value:not(:empty))
  + .sob_col_remain
  .sob_col_border {
  border-bottom-color: #acbfcf;
  border-left-color: #acbfcf;
  border-right-color: #acbfcf;
}

.sob_section
  .sob_body
  .sob_row.sob_row_center:not(:where(.sob_hover_left_st, .sob_hover_left_sell, .sob_hover_left_number, .sob_hover_left_remain))
  .sob_col.sob_col_item.sob_col_left.sob_col_number:has(~ .sob_col_left.sob_col_remain > .sob_col_value:not(:empty))
  .sob_col_border {
  border-bottom-color: #acbfcf;
  border-left-color: #acbfcf;
  border-right-color: #acbfcf;
}

.sob_section
  .sob_body
  .sob_row.sob_row_center:not(:where(.sob_hover_right_remain, .sob_hover_right_number, .sob_hover_right_buy, .sob_hover_right_st))
  .sob_col.sob_col_item.sob_col_right:where(.sob_col_number, .sob_col_remain):has(.sob_col_value:not(:empty))
  .sob_col_border {
  border-bottom-color: #c7a4a1;
  border-left-color: #c7a4a1;
  border-right-color: #c7a4a1;
}

.sob_section
  .sob_body
  .sob_row.sob_row_center:not(:where(.sob_hover_right_remain, .sob_hover_right_number, .sob_hover_right_buy, .sob_hover_right_st))
  .sob_col.sob_col_item.sob_col_right.sob_col_remain:has(.sob_col_value:not(:empty))
  + .sob_col_number
  .sob_col_border {
  border-bottom-color: #c7a4a1;
  border-left-color: #c7a4a1;
  border-right-color: #c7a4a1;
}

.sob_section
  .sob_body
  .sob_row.sob_row_center:not(:where(.sob_hover_right_remain, .sob_hover_right_number, .sob_hover_right_buy, .sob_hover_right_st))
  .sob_col.sob_col_item.sob_col_right.sob_col_remain:has(~ .sob_col_right.sob_col_number > .sob_col_value:not(:empty))
  .sob_col_border {
  border-bottom-color: #c7a4a1;
  border-left-color: #c7a4a1;
  border-right-color: #c7a4a1;
}

.sob_section
  .sob_body
  .sob_body_content:not(.sob_scroll_hold)
  .sob_row.sob_row_center:not(:where(.sob_hover_left_st, .sob_hover_left_sell, .sob_hover_left_number, .sob_hover_left_remain))
  .sob_col.sob_col_item.sob_col_left:where(.sob_col_number, .sob_col_remain):has(.sob_col_value:not(:empty))
  .sob_col_border {
  border-top-color: #acbfcf;
}

.sob_section
  .sob_body
  .sob_body_content:not(.sob_scroll_hold)
  .sob_row.sob_row_center:not(:where(.sob_hover_left_st, .sob_hover_left_sell, .sob_hover_left_number, .sob_hover_left_remain))
  .sob_col.sob_col_item.sob_col_left.sob_col_number:has(.sob_col_value:not(:empty))
  + .sob_col_remain
  .sob_col_border {
  border-top-color: #acbfcf;
}

.sob_section
  .sob_body
  .sob_body_content:not(.sob_scroll_hold)
  .sob_row.sob_row_center:not(:where(.sob_hover_left_st, .sob_hover_left_sell, .sob_hover_left_number, .sob_hover_left_remain))
  .sob_col.sob_col_item.sob_col_left.sob_col_number:has(~ .sob_col_left.sob_col_remain > .sob_col_value:not(:empty))
  .sob_col_border {
  border-top-color: #acbfcf;
}

.sob_section
  .sob_body
  .sob_body_content:not(.sob_scroll_hold)
  .sob_row.sob_row_center:not(:where(.sob_hover_right_remain, .sob_hover_right_number, .sob_hover_right_buy, .sob_hover_right_st))
  .sob_col.sob_col_item.sob_col_right:where(.sob_col_number, .sob_col_remain):has(.sob_col_value:not(:empty))
  .sob_col_border {
  border-top-color: #c7a4a1;
}

.sob_section
  .sob_body
  .sob_body_content:not(.sob_scroll_hold)
  .sob_row.sob_row_center:not(:where(.sob_hover_right_remain, .sob_hover_right_number, .sob_hover_right_buy, .sob_hover_right_st))
  .sob_col.sob_col_item.sob_col_right.sob_col_remain:has(.sob_col_value:not(:empty))
  + .sob_col_number
  .sob_col_border {
  border-top-color: #c7a4a1;
}

.sob_section
  .sob_body
  .sob_body_content:not(.sob_scroll_hold)
  .sob_row.sob_row_center:not(:where(.sob_hover_right_remain, .sob_hover_right_number, .sob_hover_right_buy, .sob_hover_right_st))
  .sob_col.sob_col_item.sob_col_right.sob_col_remain:has(~ .sob_col_right.sob_col_number > .sob_col_value:not(:empty))
  .sob_col_border {
  border-top-color: #c7a4a1;
}

.sob_section .sob_body .sob_scroll_hold .sob_row.sob_row_center .sob_col.sob_col_item .sob_col_border {
  border-top-color: black;
}

.sob_section .sob_body .sob_row .sob_col:not(:first-child) .sob_col_border {
  border-left-width: 0.5px;
}

.sob_section .sob_body .sob_row .sob_col:not(:last-child) .sob_col_border {
  border-right-width: 0.5px;
}

.sob_section .sob_body .sob_row:not(:first-child) .sob_col .sob_col_border {
  border-top-width: 0.5px;
}

.sob_section .sob_body .sob_row:not(:last-child) .sob_col .sob_col_border {
  border-bottom-width: 0.5px;
}

/* BODY - ROW - COL - ITEM - HOVER */

/* BODY - ROW - COL - ITEM - HOVER - CENTER */
.sob_section .sob_body .sob_row.sob_row_upper.sob_hover_price .sob_col.sob_col_item.sob_col_center.sob_col_price .sob_col_border {
  border-width: 2px;
  border-color: #ff0000;
}
.sob_section .sob_body .sob_row.sob_row_nowPrice.sob_hover_price .sob_col.sob_col_item.sob_col_center.sob_col_price .sob_col_border {
  border-width: 2px;
  border-color: #ff0000;
}
.sob_section .sob_body .sob_row.sob_row_lower.sob_hover_price .sob_col.sob_col_item.sob_col_center.sob_col_price .sob_col_border {
  border-width: 2px;
  border-color: #00b7ff;
}
.sob_section
  .sob_body
  .sob_row.sob_hover_price:not(:where(.sob_row_upper, .sob_row_nowPrice, .sob_row_lower))
  .sob_col.sob_col_item.sob_col_center.sob_col_price
  .sob_col_border {
  border-width: 2px;
  border-color: #000000;
}

/* BODY - ROW - COL - ITEM - HOVER - LEFT */
.sob_section .sob_body .sob_row.sob_hover_left_st .sob_col.sob_col_item.sob_col_left.sob_col_st .sob_col_border {
  border-width: 2px;
  border-color: #00b7ff;
}
.sob_section .sob_body .sob_row.sob_hover_left_sell .sob_col.sob_col_item.sob_col_left.sob_col_sell .sob_col_border {
  border-width: 2px;
  border-color: #00b7ff;
}
.sob_section
  .sob_body
  .sob_row:where(.sob_hover_left_st, .sob_hover_left_sell, .sob_hover_left_number, .sob_hover_left_remain)
  .sob_col.sob_col_item.sob_col_left.sob_col_number
  .sob_col_border {
  border-top-width: 2px;
  border-bottom-width: 2px;
  border-left-width: 2px;
  border-top-color: #00b7ff;
  border-bottom-color: #00b7ff;
  border-left-color: #00b7ff;
}
.sob_section
  .sob_body
  .sob_row:where(.sob_hover_left_st, .sob_hover_left_sell, .sob_hover_left_number, .sob_hover_left_remain)
  .sob_col.sob_col_item.sob_col_left.sob_col_remain
  .sob_col_border {
  border-top-width: 2px;
  border-bottom-width: 2px;
  border-top-color: #00b7ff;
  border-bottom-color: #00b7ff;
}
.sob_section
  .sob_body
  .sob_row:where(.sob_hover_left_st, .sob_hover_left_sell, .sob_hover_left_number, .sob_hover_left_remain)
  .sob_col.sob_col_item.sob_col_center.sob_col_price
  .sob_col_border {
  border-top-width: 2px;
  border-bottom-width: 2px;
  border-right-width: 2px;
  border-top-color: #00b7ff;
  border-bottom-color: #00b7ff;
  border-right-color: #00b7ff;
}

/* BODY - ROW - COL - ITEM - HOVER - RIGHT */
.sob_section .sob_body .sob_row.sob_hover_right_buy .sob_col.sob_col_item.sob_col_right.sob_col_buy .sob_col_border {
  border-width: 2px;
  border-color: #ff0000;
}
.sob_section .sob_body .sob_row.sob_hover_right_st .sob_col.sob_col_item.sob_col_right.sob_col_st .sob_col_border {
  border-width: 2px;
  border-color: #ff0000;
}
.sob_section
  .sob_body
  .sob_row:where(.sob_hover_right_remain, .sob_hover_right_number, .sob_hover_right_buy, .sob_hover_right_st)
  .sob_col.sob_col_item.sob_col_center.sob_col_price
  .sob_col_border {
  border-top-width: 2px;
  border-bottom-width: 2px;
  border-left-width: 2px;
  border-top-color: #ff0000;
  border-bottom-color: #ff0000;
  border-left-color: #ff0000;
}
.sob_section
  .sob_body
  .sob_row:where(.sob_hover_right_remain, .sob_hover_right_number, .sob_hover_right_buy, .sob_hover_right_st)
  .sob_col.sob_col_item.sob_col_right.sob_col_remain
  .sob_col_border {
  border-top-width: 2px;
  border-bottom-width: 2px;
  border-top-color: #ff0000;
  border-bottom-color: #ff0000;
}
.sob_section
  .sob_body
  .sob_row:where(.sob_hover_right_remain, .sob_hover_right_number, .sob_hover_right_buy, .sob_hover_right_st)
  .sob_col.sob_col_item.sob_col_right.sob_col_number
  .sob_col_border {
  border-top-width: 2px;
  border-bottom-width: 2px;
  border-right-width: 2px;
  border-top-color: #ff0000;
  border-bottom-color: #ff0000;
  border-right-color: #ff0000;
}

/* POPUP */
.sob_popup_section {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.sob_popup_background {
  position: absolute;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(1px);
  background-color: rgba(0, 0, 0, 0.25);
}

.sob_popup_item {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.sob_popup_content_wrap {
  min-width: 270px;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  border-radius: 4px;
  background-color: #ededed;
}

.sob_popup_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;
}

.sob_popup_button_section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.sob_popup_button {
  cursor: pointer;
  width: 120px;
  height: 36px;
  border: none;
  border-radius: 4px;
  color: white;
}

.sob_popup_button:hover {
  filter: brightness(0.8) contrast(150%);
}

.sob_popup_button_cancel {
  background-color: #bebebe;
}

.sob_popup_button_done {
  background-color: #4595da;
}
</style>
