<template>
  <div>
    <modal name="modalTransactionTable" classes="main-modal" :adaptive="true" :width="width" :height="height"
    @before-open="beforeOpen"
    @opened="opened">
      <div class="modal-header">
        <h2 class="modal-header-title">거래내역</h2>
        <button @click="closeModal()"><img src="@/assets/icons/icon-close-modal.svg" alt="" /></button>
      </div>
      <div class="modal-body">
        <div class="filter-container">
          <div class="filter-row">
            <div class="inner">
              <label for="" class="row-label">영업일</label>
              <div class="datepicker-container desktop">
                <DatePicker v-model="gridSearch.fromDate" />
              </div>
              <div class="datepicker-container mobile">
                <DatePicker v-model="gridSearch.fromDate" />
                ~
                <DatePicker v-model="gridSearch.toDate" />
              </div>
            </div>
            <div class="checkbox-default">
              <input type="checkbox" id="execYn" v-model="gridSearch.execYn" />
              <label for="execYn">체결</label>
            </div>
            <div class="inner">
              <!-- <button class="btn-primary darkgray">로그내역</button> -->
              <button class="btn-search" @click="search()">
                <img src="@/assets/icons/icon-search.svg" alt="" />
                조회
              </button>
            </div>
          </div>
        </div>

        <div class="ag-container" style="height: 210px">
          <AgTransactionTable 
           :account="account"
           :setGridSearch="setGridSearch"
          />
        </div>

        <div class="filter-container logtable">
          <div class="filter-row">
            <div class="inner">
              <label for="" class="row-label">영업일</label>
              <div class="datepicker-container desktop">
                <DatePicker v-model="gridSearch2.fromDate" />
              </div>
              <div class="datepicker-container mobile">
                <DatePicker v-model="gridSearch2.fromDate" />
                ~
                <DatePicker v-model="gridSearch2.toDate" />
              </div>
            </div>
            <button class="btn-search" @click="logSearch()">
              <img src="@/assets/icons/icon-search.svg" alt="" />
              조회
            </button>
          </div>
        </div>
        <div class="ag-container" style="height: 180px">
          <AgLogTable 
           :account="account"
           :setGridSearch="setGridSearch2"
          />
        </div>
      </div>

      <!-- <div class="modal-footer">hi</div> -->
    </modal>
  </div>
</template>

<script>
import DatePicker from '@/components/DatePicker.vue';
import Checkbox from './Checkbox.vue';
import AgTransactionTable from '@/components/agTables/AgTransactionTable.vue';
import AgLogTable from '@/components/agTables/AgLogTable.vue';
import Utils from '@/modules/utils'
export default {
  name: 'WhiteLabelModalTransactionTable',
  components: { 
    DatePicker, 
    Checkbox, 
    AgTransactionTable, 
    AgLogTable 
  },
  data() {
    return {
      width: '',
      height: '',
      account: {
        accNo: '',
        accId: 0,
      },
      gridSearch: {
        accId: 0,
        //accPass: '',
        fromDate: '', 
        toDate: '',
        execYn: false,
      },
      gridSearch2: {
        accId: 0,
        //accPass: '',
        fromDate: '', 
        toDate: '', 
      },
      setGridSearch:{
        accId: 0,
        //accPass: '',
        fromDate: '', 
        toDate: '',
      },
      setGridSearch2:{
        accId: 0,
        //accPass: '',
        fromDate: '', 
        toDate: '',
      },
    };
  },

  created() {
    if (window.innerWidth > 1200) {
      this.width = 1000;
      this.height = 650;
    } else {
      this.width = '100%';
      this.height = '100%';
    }
  },

  mounted() {},

  methods: {
    closeModal() {
      this.$modal.hide('modalTransactionTable');
    },
    opened(){

    },
    beforeOpen(e){
      //그리드 초기화
      this.selectTrdDt();

      this.gridSearch.execYn = false;

      this.account = Object.assign({}, e.params.account);
    },
    search(){
      this.gridSearch.accId = this.account.accId;
      this.gridSearch.toDate = this.gridSearch.fromDate;
      this.setGridSearch = Object.assign({}, this.gridSearch);
      if (this.gridSearch.execYn == true){
        this.setGridSearch.execYn = "Y";
      }
    },
    logSearch(){
      this.gridSearch2.accId = this.account.accId;
      this.gridSearch2.toDate = this.gridSearch2.fromDate;
      this.setGridSearch2 = Object.assign({}, this.gridSearch2);
    },
    async selectTrdDt(){
      const res = await window.$http.get('api/order/trdDt', { params: {} } );
      if (res != undefined && res.data != undefined){
        this.gridSearch.fromDate = res.data.replace(/(\d{4})(\d{2})(\d{2})/, "$1-$2-$3");
        this.gridSearch.toDate = res.data.replace(/(\d{4})(\d{2})(\d{2})/, "$1-$2-$3");
        this.gridSearch2.fromDate = res.data.replace(/(\d{4})(\d{2})(\d{2})/, "$1-$2-$3");
        this.gridSearch2.toDate = res.data.replace(/(\d{4})(\d{2})(\d{2})/, "$1-$2-$3");
      }else{
        this.gridSearch.fromDate = Utils.getToday();
        this.gridSearch.toDate = Utils.getToday();
        this.gridSearch2.fromDate = Utils.getToday();
        this.gridSearch2.toDate = Utils.getToday();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_variables.scss';

.filter-container {
  &.logtable {
    margin-top: 76px;
  }
}

.datepicker-container {
  margin-left: 0;
  &.desktop {
    @media (max-width: $mobile) {
      display: none;
    }
  }
  &.mobile {
    display: none;
    @media (max-width: $mobile) {
      display: flex;
      .mx-datepicker {
        min-width: unset;
        width: unset;
        flex: 1;
      }
    }
  }
}
.checkbox-default {
  @media (max-width: $mobile) {
    display: none;
  }
}
.btn-primary {
  &.darkgray {
    /* margin-left: 20px; */
    @media (max-width: $mobile) {
      display: none;
    }
  }
}
</style>
