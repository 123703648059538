import Utils from '@/modules/utils'

const state = {
  channelToCoinPriceCoc: new Map(), //구독중 OVC(체결) 종목
  channelToCoinPriceCoh: new Map(), //구독중 OVH(호가) 종목
  socketCoinPrice : null,
  isConnectCoinPrice: false,
  stockCoinPrice: null,
  sessionStorage: null,
  cocData: null,
  cohData: null,
  cotData: null,
  comData: null,
  coinPriceInterval: null,
  connectCoinPriceTimeout: null,
  selectCoinSymbol: null, //선택한 종목
  //---폴링시세 관련---
  isPolingCoinPrice: false,
  coinPollingInterVal: null,
  cocDataList: null,
  cohDataList: null,
  cotDataList: null,
  comDataList: null,
  pollingCoinSymCd: null,
  pollingCoinSymList: null,
  pollingCoinDelayTime: 300, //폴링간격 0.1초
  //---폴링시세 관련---
  coinPriceReqType: "SCKTP02", //시세 받는방식 socket(SCKTP01), polling(SCKTP02/SCKTP03)
}
  
const getters = {
  getIsConnectCoinPrice(state){
      return state.isConnectCoinPrice;
  },
  //코인 시세
  getCocData(state){
    return state.cocData;
  },
  //코인 호가
  getCohData(state){
    return state.cohData;
  },
  //코인 센터가
  getCotData(state){
    return state.cotData;
  },
  //코인 청산가
  getComData(state){
    return state.comData;
  },
  getStockCoinPrice(state){
    return state.stockCoinPrice;
  },
  getSelectCoinSymbol(state){
    return state.selectCoinSymbol;
  },
  //---폴링시세 관련---
  getIsPolingCoinPrice(state){
    return state.isPolingCoinPrice;
  },
  //코인 시세
  getCocDataList(state){
    return state.cocDataList;
  },
  //코인 호가
  getCohDataList(state){
    return state.cohDataList;
  },
  //코인 센터가
  getCotDataList(state){
    return state.cotDataList;
  },
  //코인 청산가
  getComDataList(state){
    return state.comDataList;
  },
  //---폴링시세 관련---
  getcoinPriceReqType(state){
    return state.coinPriceReqType;
  }
}
  
const mutations = {
  setSessionStorage (state, {sessionStorage}){
    state.sessionStorage = sessionStorage
  },
  setConectCoinPrice (state, {socketCoinPrice}){
    state.socketCoinPrice = socketCoinPrice
  },
  setIsConnectCoinPrice(state, {isConnect}){
    // console.log("setIsConnectCoinPrice >>> ", isConnect)
    state.isConnectCoinPrice = isConnect
  },
  setStockCoinPrice(state, {stockCoinPrice}){
      state.stockCoinPrice = stockCoinPrice
  },
  sendCoinPriceMessage(state, {apiCode, apiType, symbolList, symbol}){
    //console.log("sendCoinPriceMessage >>>>> ", apiCode, apiType, symbolList, symbol)
    
    if (!symbolList && !symbol) return;

    if (symbolList != undefined && symbolList.length > 0){
      //
    }

    let cocReqData = {
      header: {
        apiCode : apiCode,
        token : state.sessionStorage
      },
      body : {
        apiType : 'COC',
        apiTypeData: ['COC'],
        symbolData : symbolList
      }
    }

    let cotReqData = {
      header: {
        apiCode : apiCode,
        token : state.sessionStorage
      },
      body : {
        apiType : 'COT',
        apiTypeData: ['COT'],
        symbolData : []
      }
    }

    let comReqData = {
      header: {
        apiCode : apiCode,
        token : state.sessionStorage
      },
      body : {
        apiType : 'COM',
        apiTypeData: ['COM'],
        symbolData : []
      }
    }

    if (apiType == 'COT'){
      cotReqData.body.symbolData = [symbol];
    }

    if (apiType == 'COM'){
      comReqData.body.symbolData = [symbol];
    }

    if (apiCode == "DSA0004"){
      cocReqData.body = {
        apiType : 'COC',
        symbol : symbol,
        apiTypeData: ['COC'],
        symbolData : [symbol] 
      }

      cotReqData.body = {
        apiType : 'COT',
        symbol : symbol,
        apiTypeData: ['COT'],
        symbolData : [symbol] 
      }

      comReqData.body = {
        apiType : 'COM',
        symbol : symbol,
        apiTypeData: ['COM'],
        symbolData : [symbol] 
      }
    }

    //호가는 선택한 종목 단건만 요청
    let cohReqData = {
      header: {
        apiCode : apiCode,
        token : state.sessionStorage
      },
      body : {
        apiType : 'COH',
        apiTypeData: ['COH'],
        symbol : symbol,
        symbolData : symbolList
      }
    }
    
    try{
      if (state.socketCoinPrice.readyState == 1){
        if (apiType == "COC"){          
          if (cocReqData.body.symbolData[0]){
            //console.log("sned coc??? ", symbolList)  
            state.socketCoinPrice.send(JSON.stringify(cocReqData))            
          }
        }

        if (apiType == 'COT'){
          //console.log("send COT data >>> ", cotReqData.body.symbolData[0])
          state.socketCoinPrice.send(JSON.stringify(cotReqData))
        }

        if (apiType == 'COM'){
          //console.log("send COM data >>> ", comReqData.body.symbolData[0])
          state.socketCoinPrice.send(JSON.stringify(comReqData))
        }

        if (apiType == "COH"){
          //console.log("sned coh??? ", symbolList)  
          state.socketCoinPrice.send(JSON.stringify(cohReqData))
        }
      }
    }catch(e){
      console.log("e >>> ", e, cocReqData, cohReqData)
    }  
  },
  setchannelToCoinPriceCoc(state,  {subscribeItem}){
    state.channelToCoinPriceCoc.set(subscribeItem.symbol, subscribeItem)
  },
  delchannelToCoinPriceCoc(state,  {symbol}){
    state.channelToCoinPriceCoc.delete(symbol)
  },
  setchannelToCoinPriceCoh(state,  {subscribeItem}){
    state.channelToCoinPriceCoh.set(subscribeItem.symbol, subscribeItem)
  },
  delchannelToCoinPriceCoh(state,  {symbol}){
    state.channelToCoinPriceCoh.delete(symbol)
  },
  sendPriceHeartBeat(state) {
    // console.log("sendPriceHeartBeat ", state.sessionStorage)
      const reqData = {
        header: {
        apiCode : 'DSA0000',
        token : state.sessionStorage
        },
        body : {
        }
      }
      if (state.socketCoinPrice.readyState == 1){
        state.socketCoinPrice.send(JSON.stringify(reqData))
      }
  },
  setOvcData(state,  {ovcData}){
    state.ovcData = ovcData
  },
  setOvhData(state,  {ovhData}){
    state.ovhData = ovhData
  },
  setCocData(state,  {cocData}){
    state.cocData = cocData
  },
  setCohData(state,  {cohData}){
    state.cohData = cohData
  },
  setCotData(state,  {cotData}){
    state.cotData = cotData
  },
  setComData(state,  {comData}){
    state.comData = comData
  },
  setSelectCoinSymbol(state, symbol){
    state.selectCoinSymbol = symbol
  },
  setCoinDisconnect(state){
    if (state.isConnectCoinPrice){
      state.socketCoinPrice.onclose = function () {}
      state.socketCoinPrice.close();
      state.isConnectCoinPrice = false
    }
    clearInterval(state.coinPriceInterval)
  },
  //---폴링시세 관련---
  setisPolingCoinPrice(state, {symCd:symCd, symbolList:symbolList}){
    state.isPolingCoinPrice = true;
    //시세,호가 받을 목록 저장
    state.pollingCoinSymCd = symCd;
    state.pollingCoinSymList = symbolList;
  },
  setClearCoinPolling(state){
    if (state.isPolingCoinPrice){
      state.isPolingCoinPrice = false
    }
    clearInterval(state.coinPollingInterVal)
  },
  setPollingData(state, {data:data}){
    //시세, 호가 데이터 초기화
    state.ovcDataList = [];
    state.ovhDataList = [];
    if (data != undefined && data.ovh != null){
      const ovhData = {
        bidHo1: data.ovh.bidHo1,
        bidHo2: data.ovh.bidHo2,
        bidHo3: data.ovh.bidHo3,
        bidHo4: data.ovh.bidHo4,
        bidHo5: data.ovh.bidHo5,
        bidNo1: data.ovh.bidNo1,
        bidNo2: data.ovh.bidNo2,
        bidNo3: data.ovh.bidNo3,
        bidNo4: data.ovh.bidNo4,
        bidNo5: data.ovh.bidNo5,
        bidRem1: data.ovh.bidRem1,
        bidRem2: data.ovh.bidRem2,
        bidRem3: data.ovh.bidRem3,
        bidRem4: data.ovh.bidRem4,
        bidRem5: data.ovh.bidRem5,
        offerHo1: data.ovh.offerHo1,
        offerHo2: data.ovh.offerHo2,
        offerHo3: data.ovh.offerHo3,
        offerHo4: data.ovh.offerHo4,
        offerHo5: data.ovh.offerHo5,
        offerNo1: data.ovh.offerNo1,
        offerNo2: data.ovh.offerNo2,
        offerNo3: data.ovh.offerNo3,
        offerNo4: data.ovh.offerNo4,
        offerNo5: data.ovh.offerNo5,
        offerRem1: data.ovh.offerRem1,
        offerRem2: data.ovh.offerRem2,
        offerRem3: data.ovh.offerRem3,
        offerRem4: data.ovh.offerRem4,
        offerRem5: data.ovh.offerRem5,
        symbol: data.ovh.symbol,
        totBidCnt: data.ovh.totBidCnt,
        totBidRem: data.ovh.totBidRem,
        totOfferCnt: data.ovh.totOfferCnt,
        totOfferRem: data.ovh.totOfferRem
      };

      state.ovhDataList.push(ovhData);
    }

    if (data != undefined && data.ovcDataList != undefined) {
      for (const key in data.ovcDataList){
        const item = data.ovcDataList[key];
        if (item.symbol == null) return;
        const ovcData = {          
          symbol: item.symbol,
          ovsDate: item.ovsDate,
          korDate: item.korDate,
          trdTm: item.trdTm,
          korTm: item.korTm,
          curPr: item.curPr,
          ydiffPr: item.ydiffPr,
          ydiffSign: item.ydiffSign,
          open: item.open,
          high: item.high,
          low: item.low,
          chGrate: item.chGrate,
          trDq: item.trDq,
          totQ: item.totQ,
          cgubun: item.cgubun,
          mdVolume: item.mdVolume,
          msVolume: item.msVolume,
          ovsMkEnd: item.ovsMkEnd,
        };
        state.ovcDataList.push(ovcData);
      }
    }
  },
  setDelayTime(state, {time:time}){
    if (time == null && isNaN(time)){
      return;
    }else{
      state.pollingCoinDelayTime = time;
      //console.log("setDelayTime ", state.pollingCoinDelayTime)
    }
  },
  //---폴링시세 관련---
  //소켓 폴링 설정
  setCoinPriceReqType(state, {sckTpCd:sckTpCd}){
    //console.log("setCoinPriceReqType >>>> ", sckTpCd)
    state.coinPriceReqType = sckTpCd;
  },
  resetSelectSymbol(state){
    state.selectCoinSymbol = null;
  }
}
  
const actions = {
  //소켓 커넥션 생성
  setConectCoinPrice({commit, state, dispatch}, sessionStorage){
    commit('setSessionStorage', {sessionStorage:sessionStorage.sessionStorage})
    const websocketPath = window.$store.VUE_APP_QUOTE_COIN_SOCKET_BASE_URL

    if(state.socketCoinPrice && state.socketCoinPrice.readyState == WebSocket.OPEN){     
        state.socketCoinPrice.close()
    }
    let socketCoinPrice = new WebSocket(websocketPath);

    commit('setConectCoinPrice', {socketCoinPrice: socketCoinPrice})   

    state.socketCoinPrice.onopen = () => {
      commit('setIsConnectCoinPrice', {isConnect:true})
      //state.isConnectCoinPrice = true
      //commit('sendPriceHeartBeat', {})
      clearInterval(state.coinPriceInterval);
      state.coinPriceInterval = setInterval(() => {
        commit('sendPriceHeartBeat', {})
      }, 50000)
    }

    state.socketCoinPrice.onmessage = (e) => {
      //console.log("onMessage?", e.data)
      const returnData = JSON.parse(e.data)
      const data = returnData.body

      //state.stockCoinPrice = data;
      commit('setStockCoinPrice', {stockCoinPrice:data})
      if (data.apiType == 'COC'){
        data.trDq = data.qty;
        commit('setCocData', {cocData:data})
      }else if (data.apiType == 'COH'){
        commit('setCohData', {cohData:data})
      }else if (data.apiType == 'COT'){
        commit('setCotData', {cotData:data})
      }else if (data.apiType == 'COM'){
        commit('setComData', {comData:data})
      }
    }

    state.socketCoinPrice.onerror = (e) => {
      commit('setIsConnectCoinPrice', {isConnect:false})
      console.error("[Error] socket Error")
    }

    //socket.onclose = () => {
    state.socketCoinPrice.onclose = (e) => {
      commit('setIsConnectCoinPrice', {isConnect:false})

      if (e.wasClean) {
        console.log('[price] WS Closed')
        state.connectMsgTimeout = setTimeout(() => {
          dispatch('setConectPrice', {sessionStorage:state.sessionStorage});
        }, 5000);
      } else {
        console.log('[price] WS Connecting...')
        dispatch('setConectPrice', {sessionStorage:state.sessionStorage})
      }

      // console.error("[OnClose] socket close", e.code, e.wasClean);
      // state.connectCoinPriceTimeout = setTimeout(() => {
      //   dispatch('setConectPrice', {sessionStorage:state.sessionStorage})
      // }, 1000)
    }
  },
  setSubscribeOnPriceList({commit, state}, symbolList) {
    if (!symbolList || symbolList.length == 0) return

    let subscriptionUID = sessionStorage.getItem('subscriptionUID')

    if ( subscriptionUID == null ) {
      subscriptionUID = Utils.getUuidv4()
      sessionStorage.setItem('subscriptionUID', subscriptionUID)
    }

    if (symbolList && symbolList.length > 0){
      symbolList.forEach(symbol=>{
        // 소켓 구독
        const subscribeItem = {
          symbol: symbol,
          subscriptionUID: sessionStorage.getItem('subscriptionUID'),
          data: new Array()
        }

        commit('setchannelToCoinPriceCoc', {subscribeItem:subscribeItem})
      })
    }



    //this.channelToPrice.set(symbol, subscribeItem)
    //commit('setChannelToPrice', {subscribeItem:subscribeItem})
    commit('sendCoinPriceMessage', {apiCode:'DSA0003', apiType:'OVC', symbolList})
    //commit('setConnectSymbol', {symbol:symbol})
  },
  setUpdateSubscribeOnPriceList({commit, state}, {symbolList, popupSelSymbolList}) {
    // 소켓 구독 수정
    
    let connectList = [];
    if (symbolList && symbolList.length > 0){
      
      
      for (let symbol of state.channelToCoinPriceCoc.keys()) {
        if (symbolList.indexOf(symbol) == -1){ //symbolList 접속중인 symbol 없으면 구독 해지
          //구독 해지
          //console.log("구독 해지 종목? ", symbol)
          commit('delchannelToCoinPriceCoc', {symbol:symbol});
          commit('sendCoinPriceMessage', {apiCode:'DSA0004', apiType:'COC', symbolList:null, symbol:symbol})
          commit('sendCoinPriceMessage', {apiCode:'DSA0004', apiType:'COH', symbolList:null, symbol:symbol})
          commit('sendCoinPriceMessage', {apiCode:'DSA0004', apiType:'COT', symbolList:null, symbol:symbol})
          commit('sendCoinPriceMessage', {apiCode:'DSA0004', apiType:'COM', symbolList:null, symbol:symbol})
        }
      }
      
      symbolList.forEach(symbol=>{
        if (state.channelToCoinPriceCoc.get(symbol) == undefined){ //접속중인 목록이 없는 목록만 추가
          // 소켓 구독
          const subscribeItem = {
            symbol: symbol,
            subscriptionUID: sessionStorage.getItem('subscriptionUID'),
            data: new Array()
          }

          commit('setchannelToCoinPriceCoc', {subscribeItem:subscribeItem})
          connectList.push(symbol);
        }else{
          connectList.push(symbol);
        }
      })
    }  


    //팝업창 호가 업데이트
    let ovhConnectList = [];

    //선택중인 심볼 추가
    if (state.selectCoinSymbol != undefined){
      const selectCoinSymbol = state.selectCoinSymbol.name;
      ovhConnectList = [selectCoinSymbol];

      //COH구독해지
      for (let symbol of state.channelToCoinPriceCoh.keys()) {
        if (state.channelToCoinPriceCoh.get(selectCoinSymbol) != undefined){ //현재 종목과 다르면
          commit('sendCoinPriceMessage', {apiCode:'DSA0004', apiType:'COH', symbolList:null, symbol:symbol})
        }
      }

      if (connectList.indexOf(selectCoinSymbol) == -1){
          // 소켓 구독
          const subscribeItem = {
            symbol: selectCoinSymbol,
            subscriptionUID: sessionStorage.getItem('subscriptionUID'),
            data: new Array()
          }

          commit('setchannelToCoinPriceCoc', {subscribeItem:subscribeItem})

          connectList.push(selectCoinSymbol);
      }

      // 소켓 구독
      const subscribeItem = {
        symbol: selectCoinSymbol,
        subscriptionUID: sessionStorage.getItem('subscriptionUID'),
        data: new Array()
      }

      commit('setchannelToCoinPriceCoh', {subscribeItem:subscribeItem})
      ovhConnectList = [selectCoinSymbol];

      commit('sendCoinPriceMessage', {apiCode:'DSA0003', apiType:'COT', symbolList:null, symbol:selectCoinSymbol});
      commit('sendCoinPriceMessage', {apiCode:'DSA0003', apiType:'COM', symbolList:null, symbol:selectCoinSymbol});

    }   
    
    if (popupSelSymbolList && popupSelSymbolList.length > 0){
      for (let symbol of state.channelToCoinPriceCoh.keys()) {
        if (popupSelSymbolList.indexOf(symbol) == -1){ //popupSelSymbolList 접속중인 symbol 없으면 구독 해지
          //구독 해지
          commit('delchannelToCoinPriceCoh', {symbol:symbol});
          let apiType = 'COH';

          //console.log("팝업 구독해지", symbol)

          commit('sendCoinPriceMessage', {apiCode:'DSA0004', apiType:apiType, symbolList:null, symbol:symbol})
        }
      }
      
      //OVC 추가
      symbolList.forEach(symbol=>{
        if (state.channelToCoinPriceCoc.get(symbol) == undefined){ //접속중인 목록이 없는 목록만 추가
          // 소켓 구독
          const subscribeItem = {
            symbol: symbol,
            subscriptionUID: sessionStorage.getItem('subscriptionUID'),
            data: new Array()
          }

          commit('setchannelToCoinPriceCoc', {subscribeItem:subscribeItem})
          connectList.push(symbol);
        }
      })

      //OVH 추가
      popupSelSymbolList.forEach(symbol=>{
        if (state.channelToCoinPriceCoh.get(symbol) == undefined){ //접속중인 목록이 없는 목록만 추가
          // 소켓 구독
          const subscribeItem = {
            symbol: symbol,
            subscriptionUID: sessionStorage.getItem('subscriptionUID'),
            data: new Array()
          }

          commit('setchannelToCoinPriceCoh', {subscribeItem:subscribeItem})
          ovhConnectList.push(symbol);
        }else{ //호가 시세를 받기위해 목록에 추가
          ovhConnectList.push(symbol);
        }
      })
    }

    commit('sendCoinPriceMessage', {apiCode:'DSA0003', apiType:'COC', symbolList:connectList});
    commit('sendCoinPriceMessage', {apiCode:'DSA0003', apiType:'COH', symbolList:ovhConnectList});    
  },
  setSubscribeOnPrice({commit, state}, symbol) {
    let subscriptionUID = sessionStorage.getItem('subscriptionUID')

    if ( subscriptionUID == null ) {
      subscriptionUID = Utils.getUuidv4()
      sessionStorage.setItem('subscriptionUID', subscriptionUID)
    }

    // 소켓 구독
    const subscribeItem = {
      symbol,
      subscriptionUID: sessionStorage.getItem('subscriptionUID'),
      data: new Array()
    }

    commit('setchannelToCoinPriceCoc', {subscribeItem:subscribeItem})

    //this.channelToPrice.set(symbol, subscribeItem)
    //commit('setChannelToPrice', {subscribeItem:subscribeItem})
    //commit('sendCoinPriceMessage', {apiCode:'DSA0003', apiType:'', symbol:symbol})
    commit('setConnectSymbol', {symbol:symbol})
    
  },
  setUpdateSubscribeOnPrice({commit, state}, symbol) {
    // 소켓 구독 수정
    const subscribeItem = {
      //symbol,
      subscriptionUID: sessionStorage.getItem('subscriptionUID'),
      data: new Array()
    }

    commit('setchannelToCoinPriceCoc', {subscribeItem:subscribeItem})

    //commit('sendCoinPriceMessage', {apiCode:'DSA0003', apiType:'', symbol:symbol})
    
  },
  setUnSubscribeOnPrice({commit, state}, symbol){
    // 소켓 구독 종료
    const data = state.channelToCoinPriceCoc.get(symbol)
    if(data != null){
      if(state.isConnectCoinPrice){
        commit('sendCoinPriceMessage', {apiCode:'DSA0004', apiType:'COC', symbol:symbol})
        commit('delchannelToCoinPriceCoc', {symbol:symbol})
      }
    }    
  },
  setSelectCoinSymbol({commit, state}, symbol){
    //시세 변경시 호가 구독 종료 호출
    if (window.name == undefined || window.name == null || window.name == ""){
      // let apiType = 'OVH';
      // if (state.isConnectCoinPrice && state.selectCoinSymbol){
      //   apiType = 'OVH';
      //   if (state.selectCoinSymbol.symbolno == 10){ //국내선물
      //     apiType = 'FH0';
      //   }
      //   //ovh 접속 목록 삭제
      //   commit('delchannelToCoinPriceCoh', {symbol:state.selectCoinSymbol.name});
      //   commit('sendCoinPriceMessage', {apiCode:'DSA0004', apiType:apiType, symbolList:null, symbol:state.selectCoinSymbol.name});
      // }       
      
      // if (symbol.symbolno == 10){ //국내선물
      //   apiType = 'FH0';
      // }else{
      //   apiType = 'OVH';
      // }
      // //ovh 접속 목록 추가
      // const subscribeItem = {
      //   symbol: symbol,
      //   subscriptionUID: sessionStorage.getItem('subscriptionUID'),
      //   data: new Array()
      // }
      // commit('setchannelToCoinPriceCoh', {subscribeItem:subscribeItem});
      // commit('sendCoinPriceMessage', {apiCode:'DSA0003', apiType:apiType, symbolList:null, symbol:symbol.name});
    }else{
      //팝업창일때
      if (window.name == "orderPop1"){
        window.opener.postMessage({ type: 'addSymbol', popupId:window.name, symbol:symbol}, window.location.origin);
      }else if (window.name == "orderPop2"){
        window.opener.postMessage({ type: 'addSymbol', popupId:window.name, symbol:symbol }, window.location.origin);
      }
    }

    //console.log("setSelectCoinSymbol111 >>> ", symbol)

    commit('setSelectCoinSymbol', symbol);
  },
  //구독중인 종목 모두 삭제
  resetSymbol({commit, state}){
    for (let symbol of state.channelToCoinPriceCoc.keys()) {
        //구독 해지
        //console.log("COC 구독 해지 종목? ", symbol);
        commit('delchannelToCoinPriceCoc', {symbol:symbol});

        commit('sendCoinPriceMessage', {apiCode:'DSA0004', apiType:'COC', symbolList:null, symbol:symbol})
        commit('sendCoinPriceMessage', {apiCode:'DSA0004', apiType:'COT', symbolList:null, symbol:symbol})
        commit('sendCoinPriceMessage', {apiCode:'DSA0004', apiType:'COM', symbolList:null, symbol:symbol})
    }

    for (let symbol of state.channelToCoinPriceCoh.keys()) {
        //구독 해지
        //console.log("COH 구독 해지 종목? ", symbol);
        commit('delchannelToCoinPriceCoh', {symbol:symbol});
        commit('sendCoinPriceMessage', {apiCode:'DSA0004', apiType:'COH', symbolList:null, symbol:symbol})
    }

    //선택종목 초기화
    commit("resetSelectSymbol");
  },
  //---폴링시세 관련---
  setPollingPrice({commit, state, dispatch}, {symCd:symCd, symbolList:symbolList}){
    commit('setisPolingCoinPrice', {symCd:symCd, symbolList:symbolList});
    if (state.coinPriceInterval == null){
      dispatch('getPollingData');
    }
  },
  getPollingData({commit, state, dispatch}){
    state.coinPriceInterval = setInterval(() => {
      return new Promise((resolve, reject) => {
        window.$http
          .get('apisise/getCurPrice', { params: { symCd:state.pollingCoinSymCd, symbolList: state.pollingCoinSymList } })
          .then(response => {
            if ( response ) {
              if ( response.data !== null ) {
                commit('setPollingData', {data:response.data});
              }
            }
            resolve()
          })
          .catch(e => reject(e))
      }) 
    }, state.pollingCoinDelayTime)
  },
  setPollingType({commit, state, dispatch}, {sckTpCd:sckTpCd, sckTpDsc:sckTpDsc}){
    let type = sckTpCd;
    let time = 300;

    if (type == undefined || type == null || type.trim() == ""){
      type = "SCKTP02"; //디폴트 폴링
    }
    commit('setCoinPriceReqType', {sckTpCd:type});

    if (type == "SCKTP02"){
      time = 100;
    }else if (type == "SCKTP03"){
      time = 300;
    }

    commit('setDelayTime', {time:time});
  },
  //---폴링시세 관련---
  //---팝업시세 관련---
  setChannelData({ commit, dispatch }, {type, data}) {
    if (type == 'COC') {
      commit('setOvcData', {ovcData:data})
    }else if (type == 'COH'){
      commit('setovhData', {ovhData:data})
    }
  },
  //---팝업시세 관련---

  // getOVCReqData({commit, state}, {apiCode:apiCode, symbol:symbol}) {
  //   let apiTypeData = ['OVC'];
  //   let apiType = 'OVC';

  //   const reqData = {
  //     header: {
  //       apiCode : apiCode,
  //       token : state.sessionStorage,
  //     },
  //     body : {
  //       apiType : apiType,
  //       symbol : symbol,
  //       apiTypeData: apiTypeData,
  //       symbolData : [symbol]
  //     }
  //   }
  //   return reqData

  // },    
  // getOVHReqData({commit, state}, {apiCode:apiCode, symbol:symbol}) {
  //     let apiTypeData = ['OVH'];
  //     let apiType = 'OVH';
      
  //     const reqData = {
  //         header: {
  //         apiCode : apiCode,
  //         token : state.sessionStorage,
  //         },
  //         body : {
  //         apiType : apiType,
  //         symbol : symbol,
  //         apiTypeData: apiTypeData,
  //         symbolData : [symbol]
          
  //         }
  //     }
  //     return reqData
  // },
  //커넥션 종료
  setCoinDisconnect({commit, state}){
    commit('setCoinDisconnect', {})
  },
  //폴링 종료
  setClearCoinPolling({commit, state}){
    commit("setCoinClearCoinPolling")
  }
}
  
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}