<template>
	<div style="width: 100%; height: 100%">
    <ag-grid-vue
      style="width: 100%; height: 100%"
      class="ag-theme-wts"
      :columnDefs="grid.columnDefs"
      :rowData="grid.rowData"
      :headerHeight="grid.rowHeight"
			:rowHeight="grid.rowHeight"
      :overlayNoRowsTemplate="grid.overlayNoRowsTemplate"
      @grid-ready="onGridReady"/>
  </div>
</template>

<script>
import 'ag-grid-community/styles/ag-grid.css'; // Core CSS
import { AgGridVue } from 'ag-grid-vue'; // Vue Grid Logic
//import TableCheckbox from '@/components/agTables/TableCheckbox.vue';
//import CustomHeader from '@/components/agTables/CustomHeader.vue';
import {buySellType, cellClassRulesMinus} from '@/modules/grid-utils.js';
import { stringCount } from '@/modules/helper';
import gridUtils from '@/modules/grid-utils';
import AgBtn from '@/components/agTables/AgBtn.vue';
import Utils from '@/modules/utils'
import EventBus from '@/modules/event-bus'

export default {
	name: 'WhiteLabelAgPositionTable',

	components: {
		AgGridVue,
    AgBtn
		// TableCheckbox,
		// agColumnHeader: CustomHeader,
	},
  props:{
    account: {
      type: Object
    },
  },
  computed:{
    getSymbolList: function(){
      let symbolList = window.$store.getters['Symbol/getSymbolList']
      return symbolList;
    },
    //소켓시세
    changeOvcData: function(){
      let ovcData = window.$store.getters['SocketPrice/getOvcData']
      return ovcData;
    },
    //폴링시세
    changeOvcDataList: function(){
      let ovcData = window.$store.getters['SocketPrice/getOvcDataList']
      return ovcData;
    },
    resultOms: function(){
      return window.$store.getters['SocketOms/getCompleteOrder']
    },
    getSelectSymbol: function(){
      return window.$store.getters['SocketPrice/getSelectSymbol'];
    },
    getPosOrdStList: function(){
      return window.$store.getters['SocketOms/getPosOrdStList'];
    },
  },
  watch:{
    getSymbolList(symbolList){
      this.symbolList = symbolList;
      //this.searchPosOrdStList();
    },
    //소켓시세
    changeOvcData(newPrice){
      this.ovcMap.set(newPrice.symbol, newPrice);
      this.setGridPrice(); //현재가, 평가손익 새로고침
    },
    //폴링시세
    changeOvcDataList(newPrice){
      if (newPrice != null && newPrice.length > 0) {
        newPrice.forEach(data=>{
          this.ovcMap.set(data.symbol, data);
          this.setGridPrice(); //현재가, 평가손익 새로고침
        })
      }
    },
    resultOms(){ 
      this.searchPosOrdStList();
    },
    getSelectSymbol(newVal){
      if (newVal == undefined) return;
      //종목이 변경될경우 소켓데이터 연결 갱신
      this.symbol = Object.assign({}, newVal);

      this.nowPrice = {curPr:0, ydiffPr:0, open:0, chGrate:0, high:0, low:0, ydiffSign:0};

      this.tikSize = newVal.minmov / newVal.pricescale
      this.searchPosOrdStList();
    },
    getPosOrdStList(newList){
      this.posOrdStList = newList;
      this.searchPosOrdStList();
    }
  },
	data() {
    return {
			grid:{
        columnDefs: [
          {
            headerName: '타입',
            field: 'ordType',
            resizable: false,
            suppressMovable: true,
            flex: 0.45,
            minWidth: 50,
            cellStyle: {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            },
            headerClass: 'center-align-header',
            valueFormatter: (d) =>{
              if (d.value == "POS"){
                return '체결';
              }else if (d.value == "ORD"){
                return '미체결';
              }else if (d.value == "ST"){
                return 'MIT';
              }
            },
          },
          {
            headerName: '종목코드',
              field: 'symCd',
            resizable: false,
            suppressMovable: true,
            flex: 0.6,
            minWidth: 60,
            cellStyle: {
              justifyContent: 'center',
            },
            headerClass: 'center-align-header',
          },
          {
            headerName: '구분',
              field: 'ordSdNm',
            resizable: false,
            suppressMovable: true,
            flex: 0.6,
            minWidth: 60,
            headerClass: 'center-align-header',
            cellStyle: {
              justifyContent: 'center',
            },
            cellClassRules: buySellType,
          },
          {
            headerName: '잔고',
              field: 'balQty',
            cellStyle: {
              justifyContent: 'center',
            },
            headerClass: 'center-align-header',
            resizable: false,
            suppressMovable: true,
            flex: 1,
            minWidth: 100,
            valueFormatter: gridUtils.numberComma
          },        
          {
            headerName: '체결가',
              field: 'execPrc',
            resizable: false,
            suppressMovable: true,
            flex: 0.8,
            minWidth: 80,
            headerClass: 'center-align-header',
            cellStyle: {
              justifyContent: 'center',
            },
            valueFormatter: (d) =>{
              let val = d.value;
              if (d.data.symNo == 2){ //항셍
                if (!Number.isInteger(val)){
                  val = val.toFixed(1);
                }
                return val;
              }else{
                let symbol = null;
                this.symbolList.forEach(item => {
                  if (item.symbolno == d.data.symNo){
                    symbol = item;
                    return;
                  }
                });
                return val.toFixed(this.tikDotSz(symbol));
              }
            },
          },
          {
            headerName: '현재가',
            field: 'nowPrice',
            resizable: false,
            suppressMovable: true,
            flex: 0.8,
            minWidth: 80,
            headerClass: 'center-align-header',
            cellStyle: {
              justifyContent: 'center',
            },
            valueFormatter: (d) =>{
              if (d.value == undefined || d.value == null){
                return 0;
              }else if (d.data.ordType == "ORD" || d.data.ordType == "ST"){
                return '';
              }else{
                let symbol = null;
                this.symbolList.forEach(item => {
                  if (item.name == d.data.symCd){
                    symbol = item;
                    return;
                  }
                });
                return Number(d.value).toFixed(this.tikDotSz(symbol));
              }
            }
          },
          {
            headerName: '평가손익',
            field: 'valPl',
            resizable: false,
            suppressMovable: true,
            flex: 1,
            minWidth: 100,
            headerClass: 'center-align-header',
            cellStyle: {
              justifyContent: 'center',
            },
            cellClassRules: cellClassRulesMinus,
            valueFormatter: gridUtils.numberComma,
          },
          {
            headerName: '',
            field: 'actionName',
            resizable: false,
            suppressMovable: true,
            flex: 0.6,
            minWidth: 80,
            cellStyle: {
              justifyContent: 'center',
            },
            headerClass: 'center-align-header',
            cellRenderer: 'AgBtn',
            cellRendererParams: {
              actionName: '',
              enableMenuTouch: true,
              touched:(d)=>{
              }
            },
            onCellClicked: (d) =>{
              if (d.data.ordType == "POS"){
                //청산
                this.orderClear(d.data);
              }else if (d.data.ordType == "ORD"){
                //취소
                this.cancelStock(d.data);
              }else if (d.data.ordType == "ST"){
                //ST 취소
                this.cancelStStock(d.data);
              }
            },

            
            
          },
          // {
          //     headerName: '만기일',
          //     field: 'expDt',
          //     resizable: false,
          //     suppressMovable: true,
          //     flex: 0.6,
          //     minWidth: 80,
          //     headerClass: 'center-align-header',
          //     cellStyle: {
          //       justifyContent: 'center',
          //     },
          //     valueFormatter: (d) =>{
          //       if (d.value){
          //         return d.value.replace(/(\d{4})(\d{2})(\d{2})/, "$1-$2-$3");
          //       }else{
          //         return '';
          //       }
          //     },
          //   },
        ],
        rowData: [],
        rowHeight:30,
        overlayNoRowsTemplate: '<span style="margin-top: 30px;">조회된 실시간 보유가 없습니다.</span>',
        autoSizeStrategy: null,
      },
      gridApi: null,
      gridColumnApi:null,
      isGridReady:false,
      symbolList:[],
      selectList:[],
      ovcMap: new Map(),
      currencyInfo:{currency:"WON"},
      symbol: null,
      orderFlag:false,
      orderSetting: null,
      posOrdStList:[],
		};
  },
	created() {
		this.isMobile();
    EventBus.$on('Layout:event:loadLayout', (layout) => {
      this.loadCustom();
    })
	},
	mounted() {
    this.loadCustom();
    //this.searchPosOrdStList();

    if (this.symbolList == undefined || this.symbolList.length == 0){
      this.symbolList = window.$store.getters['Symbol/getSymbolList'];
    }
	},

	methods: {
		isMobile() {
      if (window.name == undefined || window.name == null || window.name == ""){
        if (window.innerWidth <= 1200) {
          return this.grid.rowHeight = 40;
        } else {
          return this.grid.rowHeight = 30;
        }
      }else{
        return this.grid.rowHeight = 30;
      }
    },
    loadCustom(){
      this.orderSetting = window.$store.getters['Layout/getOrderSetting'];
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.isGridReady = true;
      this.searchPosOrdStList();
    },
    async searchPosOrdStList(){
      if (!this.account.accId) return;

      //this.grid.rowData = [];
      this.selectList = [];

      const ovcData = await window.$http.get('apisise/getOvcData', { params: {} } );

      if (this.posOrdStList.length == 0){
        this.posOrdStList = window.$store.getters['SocketOms/getPosOrdStList']
      }

      if ( this.posOrdStList) {
        let rowData = [];
        this.posOrdStList.forEach((data) => {
          for (let ovc in ovcData.data){
            if (ovc == data.symCd){
              data.nowPrice = ovcData.data[ovc].curPr;
            }
            if (this.ovcMap.get(ovc) == undefined || this.ovcMap.get(ovc).curPr == undefined){
              this.ovcMap.set(ovc, ovcData.data[ovc]);
            }
          }

          data.valPl = '';
          data.nowPrice = 0;
          if (data.balQty > 0 || data.ordQty > 0){
            //data.checkBoxId = "AgPosition_"+ data.symCd;
            rowData.push(data);
          }
          if (data.ordType == "POS"){
            data.actionName = "청산";
          }else if (data.ordType == "ORD"){
            data.actionName = "취소";
          }else if (data.ordType == "ST"){
            data.actionName = "취소";
          }
          
        });

        this.grid.rowData = rowData;

        if (this.gridApi != null)
          this.gridApi.refreshCells(); //현재가 보이도록 셀 새로고침

        this.setGridPrice();
        
        //잔고 시세 업데이트
        this.$emit("updateSubscribeOnPrice", this.grid.rowData);
      }else{
        //잔고 목록이 없으면 평가손익 초기화
        this.setGridPrice();
        this.selectList = [];
      }
    },
    tikVal(symbol){
      if (symbol == null){
        return 0;
      }
      return (symbol.minmov / symbol.pricescale);
    },
    tikDotSz(symbol){
      if (symbol == null){
        return 0;
      }
      return stringCount(symbol.pricescale);
    },
    setGridPrice(){
      let totData = {totalValPl:0, totalQty:0, totalLossCut:0};
      if (this.grid.rowData != null && this.grid.rowData.length > 0){
        let totalValPl = 0;
        let totalQty = 0;
        let totalLossCut = 0;

        let usdExr = 0;
        let usdExHkd = 0;
        let hkdExR = 0;
        const dailyPlMn = window.$store.getters['SocketOms/getDailyPlMn'];
        if (dailyPlMn && dailyPlMn.usdExR) usdExr = dailyPlMn.usdExR;
        if (dailyPlMn && dailyPlMn.usdExHkd) usdExHkd = dailyPlMn.usdExHkd;
        if (dailyPlMn && dailyPlMn.hkdExR) hkdExR = dailyPlMn.hkdExR;
        this.grid.rowData.forEach(data => {
          if (data.balQty > 0 && data.ordType == "POS"){
            totalQty += Number(data.balQty);
            totalLossCut += (Number(data.rmsSetAmt) * Number(data.balQty)); //RMS_SET_AMT

            //let res = this.symbolCalPrc(data, this.ovcMap.get(data.symCd), this.symbolList, usdExr, usdExHkd, hkdExR, data.lvTpCd, this.currencyInfo.currency);
            // 계산 공통사용변경
            // data - 그리드 1개 row data
            // ovcMap
            // symbolList
            // usdExr 환율
            // usdExHkd 홍콩달러환율
            // hkdExR 홍콩환율
            // lvTpCd 레버리지여부
            // currency 원/달러 구분 WON
            // return {data, }
            let res = Utils.symbolCalPrc(data, this.ovcMap.get(data.symCd), this.symbolList, usdExr, usdExHkd, hkdExR, data.lvTpCd, this.currencyInfo.currency);
            //data = res.data; //데이터가 갱신 안되면 주석해제
            totalValPl += Number(res.valProLoss);
          }
        });
        this.gridApi.refreshCells(); //현재가 보이도록 셀 새로고침

        totData.totalQty = totalQty;
        totData.totalValPl = totalValPl;
        totData.totalLossCut = totalLossCut;

        //this.$emit("updateTotalValPl", totalValPl);
      }else{
        //this.$emit("updateTotalValPl", 0);
      }

      this.$emit("updateTotalValPl", totData);
    },
    orderClear(selData) {
      if (this.account == undefined || this.account.accId == undefined ||this.account.accId == 0){
        window.$alert.alert('계좌 정보가 없습니다.', '알림')
        return;
      }

      // if (!this.getUsrAccPass){
      //   window.$alert.alert('계좌 비밀번호를 확인하세요.', '알림')
      //   return;
      // }

      if (!selData){
        this.$alert.alert("선택한 주문이 없습니다.", '알림');
        return;
      }

      // if (this.orderFlag){
      //   return;
      // }
      let symbolData = null;
      this.symbolList.forEach(item => {
        if (item.name == selData.symCd){
          symbolData = item;
          return;
        }
      });

      let message = "체결리스트 청산 클릭 "+ selData.symCd;
      this.sendOmsLogMessage(message);

      let selectList = [{symCd:selData.symCd}];

      const order = {
          accId : this.account.accId,
          trdDt : '',
          //accPass : this.getUsrAccPass,
          ordSdCd: selData.ordSdCd,
          symbolList : selectList,
        }

      if (this.orderSetting != undefined && this.orderSetting.clearMessageAlert){
        const orderData = {apiCode:"OMS0014", order: order, symbol:symbolData, qty:selData.balQty, price: selData.execPrc, message:message};
        this.$modal.show("modalConfirmOrder", orderData);
      }else{
        this.sendOmsMessage("OMS0014", order);
      }

      // this.$alert.confirm('선택한 보유잔고를 청산하시겠습니까?', '청산확인')
      //   .then((response) => {
      //     if (response) { 
      //       //this.orderFlag = true;

      //       const order = {
      //         accId : this.account.accId,
      //         trdDt : '',
      //         //accPass : this.getUsrAccPass,
      //         symbolList : selectList,
      //       }
      //       this.sendOmsMessage('OMS0014', order)
      //     }
      //   })

      //1.5초후 버튼 누를 수 있게 설정
      // if (this.orderFlag){
      //   setTimeout(() => {
      //     this.orderFlag = false
      //   }, 1500)
      // }
    },
    cancelStock(data) {
      if ( data.symCd === undefined
        || data.execPrc === undefined ) {
        return;
      } 
  
      let orgOrders = new Array();
      let totCnt = 0;
      let totOrdQty = 0;

      totCnt++;
      totOrdQty += data.ordQty;

      const orgOrder = {
        accId : this.account.accId,
        symCd : data.symCd,
        symNo : data.symNo,
        trdDt : data.trdDt,
        ordId : 0,
        orgOrdNo: data.ordId,
        ordSdCd : data.ordSdCd,
        ordTpCd : data.ordTpCd,
        ordPrc : data.execPrc,
        ordQty : data.ordQty,
        repReqQty : data.repReqQty,
        cxlReqQty : data.ordQty,
        orgUsrKey : data.usrKey,
      }

      orgOrders.push(orgOrder);
        
      let order = {
          usrKey : Utils.getUuidv4(),
          accId : this.account.accId,
          symCd : data.symCd,
          symNo : data.symNo,
          //accPass : this.getUsrAccPass,
          orderList : orgOrders,
          ordSdCd : data.ordSdCd
      }

      let symbolData;

      this.symbolList.forEach(symbol=>{
        if (symbol.name == data.symCd){
          symbolData = symbol;
        }
      })

      let message = "체결리스트 지정가 취소 클릭 "+ data.symCd;
      this.sendOmsLogMessage(message);
  
      if (this.orderSetting != undefined && this.orderSetting.ordMessageAlert){
        const orderData = {apiCode:"OMS0002", order: order, symbol:symbolData ,qty:totOrdQty, price: data.execPrc, message:message};
        this.$modal.show("modalConfirmOrder", orderData);
      }else{
        this.sendOmsMessage('OMS0002', order)

      }
    },
    cancelStStock(data) {
      if ( data.symCd === undefined
        || data.execPrc === undefined ) {
        return;
      } 

      let orgStOrders = new Array()
      let totCnt = 0;
      let totOrdQty = 0;
      totCnt++;
      totOrdQty += data.ordQty

      const orgOrder = {
        accId : this.account.accId,
        symCd : data.symCd,
        symNo : data.symNo,
        trdDt : data.trdDt,
        ordId : 0,
        orgOrdNo: data.ordId,
        ordSdCd : data.ordSdCd,
        ordTpCd : data.ordTpCd,
        ordPrc : data.execPrc,
        ordQty : data.ordQty,
        repReqQty : data.repReqQty,
        cxlReqQty : data.ordQty,
        orgUsrKey : data.usrKey
      }

      orgStOrders.push(orgOrder);
      
      let order = {
          usrKey : Utils.getUuidv4(),
          accId : this.account.accId,
          symCd : this.symbol.name,
          symNo : this.symbol.symbolno,
          ordSdCd : '1',
          //accPass : this.getUsrAccPass,
          orderList : orgStOrders
      }

      let message = "체결리스트 지정가 취소 클릭 "+ data.symCd;
      this.sendOmsLogMessage(message);

      if (this.orderSetting != undefined && this.orderSetting.ordMessageAlert){
        let symbolData;

        this.symbolList.forEach(symbol=>{
          if (symbol.name == data.symCd){
            symbolData = symbol;
          }
        })

        const orderData = {apiCode:"OMS0005", order: order, symbol:symbolData ,qty:totOrdQty, price: data.execPrc, message:message};
        this.$modal.show("modalConfirmOrder", orderData);
      }else{
        this.sendOmsMessage('OMS0005', order);
      }
    },
    sendOmsMessage(apiCode, body) {
      //팝업주문창여부 확인
      if (window.name == undefined || window.name == null || window.name == ""){
        if ( !window.$store.getters['SocketOms/getIsConnectOms'] ) {
          window.$alert.alert('주문서버 연결이 불안정합니다.\n새로고침 후 다시 시도해주세요.');
          return
        }
        //비밀번호 추가
        // body.accPass = this.getUsrAccPass; 
        window.$store.dispatch('SocketOms/sendOmsMessage', {apiCode:apiCode, body:body})
      }else{
        if ( !window.opener.$store.getters['SocketOms/getIsConnectOms'] ) {
          window.$alert.alert('주문서버 연결이 불안정합니다.\n새로고침 후 다시 시도해주세요.');
          return
        }

        window.opener.$store.dispatch('SocketOms/sendOmsMessage', {apiCode:apiCode, body:body})
      }
    },
    sendOmsLogMessage(message){
      if (window.name == "orderPop1" || window.name == "orderPop2"){
        message = "팝업("+window.name + ") " + message;
        window.opener.$store.dispatch('SocketOms/setLogMsg', {message:message});
      }else{
        if (window.location.pathname == "/mobile"){
          message = "모바일 " + message;
        }
        window.$store.dispatch('SocketOms/setLogMsg', {message:message});
      }
    }
	},
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/agGridStyles.scss';
</style>