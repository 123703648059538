import axios from 'axios'
import Const from '@/const'
import qs from "qs"

const HttpModule = {
  install (Vue) {
    const http = axios.create({
      baseURL: window.$store.VUE_APP_SERVER_BASE_URL,
      crossDomain: true,
      withCredentials: true,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }      
    })
        
    // request
    http.interceptors.request.use((config) => {
      
      window.$loading.show()
      
      if ( Vue.$sessionStorage.get('xDkToken') ) {
        config.headers['x-dk-token'] = Vue.$sessionStorage.get('xDkToken')
      }

      return config
    }, (error) => {
      window.$loading.hide()
      //window.$alert.alert(error, 'Error')
      if (error != undefined && error != null && error.indexOf("Axios Error") == 0){
        console.log("http interceptors.request error >>> ", error);
        window.$alert.alert(error, 'Error')
      }else{
        window.$alert.alert("네트워크 접속이 불안정합니다.<br/>네트워크 상태를 확인 해주세요.", 'Error')
        .then(() => {
          window.location.reload();
        });
      }
    })
        
    // response
    http.interceptors.response.use((response) => {
      window.$loading.hide()

      // 에러 처리
      if (response.data.code !== Const.RESPONSE_TYPE.SUCCESS) {
        //throw new Error(response)
        //window.$alert.alert(response.data.message, 'Error')
        if (response.data.message != undefined && response.data.message != null && response.data.message.indexOf("Axios Error") == 0){
          console.log("http interceptors.response error >>> ", response.data.message);
          window.$alert.alert(response.data.message, 'Error')
        }else{
          window.$alert.alert("네트워크 접속이 불안정합니다.<br/>네트워크 상태를 확인 해주세요.", 'Error')
          .then(() => {
            window.location.reload();
          });
        }
        return new Promise(() => {})        
      }

      const xDkToken = response.headers['x-dk-token']

      if ( xDkToken !== undefined && xDkToken !== null ) {
        response.data.xDkToken = xDkToken
      }

      return response.data
    }, (error) => {
      window.$loading.hide()
      
      // 
      if (error.response) {
        if ( error.response.data.code === Const.RESPONSE_TYPE.NOT_LOGIN) {
          //window.$alert.alert('로그인 인증이 만료되었습니다.<br/>다시 로그인하여 주십시오.', '안내')
          //.then(() => {
              window.$store.dispatch('Login/logout')
          //})
        } else {
          if (error.response.data.message != undefined && error.response.data.message != null && error.response.data.message.indexOf("Axios Error") == 0){
            console.log("http logout error >>> ", error.response.data.message);
            window.$alert.alert(error.response.data.message, 'Error')
          }else{
            window.$alert.alert("네트워크 접속이 불안정합니다.<br/>네트워크 상태를 확인 해주세요.", 'Error')
            .then(() => {
              window.location.reload();
            });
          }          
        }	  
      } else {
        window.$alert.alert("네트워크 접속이 불안정합니다.<br/>네트워크 상태를 확인 해주세요.", 'Error')
        .then(() => {
          window.location.reload();
        });
      }
        
      //return Promise.reject(error)
      return new Promise(() => {})
    })

    window.$lhttp = http
    Vue.httpLClient = http
    Vue.prototype.$lhttp = http          
  }
}

export default HttpModule