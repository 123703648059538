<template>
  <div class="menu-btn"  ref="HeaderMenu">
    <img src="@/assets/icons/menu.svg" alt="" @click="showMenuVie()" />
    <ul class="menu" v-if="showMenu">
      <li v-if="loginUserInfo">
        <div class="member-level standard"><span>{{ loginUserInfo.authNm }}</span></div>
        <span>{{ loginUserInfo.usrId }}</span>
      </li>
      <!-- <router-link to="/">
        <li>
          <img class="icon" src="@/assets/icons/home.svg" alt="" />
          홈으로
        </li>
      </router-link> -->
      <!-- <li>
        <img class="icon" src="@/assets/icons/help-circle.svg" alt="" />
        헬프 센터
      </li> -->
      <li @click="showModal('modalNotice')" v-if="getLoginUserInfo != undefined && getLoginUserInfo.authId">
        <img class="icon" src="@/assets/icons/notice.svg" alt="" />
        공지사항
      </li>
      <li @click="showModal('modalLogout')" v-if="getLoginUserInfo != undefined && getLoginUserInfo.authId">
        <img class="icon" src="@/assets/icons/logout.svg" alt="" />
        종료
      </li>
      <router-link to="/" v-if="getLoginUserInfo == undefined || getLoginUserInfo.authId == undefined">
      <li>
        <img class="icon" src="@/assets/icons/logout.svg" alt="" />
        로그인
      </li>
    </router-link>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'WhiteLabelHeaderMenu',
  computed:{
    getLoginUserInfo: function(){
      let loginInfo = window.$store.getters['Login/getLoginUserInfo'];
      return loginInfo;
    },
  },
  watch:{
    getLoginUserInfo:{
      handler(userInfo){
        this.loginUserInfo = userInfo;
      }
    }
  },
  data() {
    return {
      showMenu: false,
      loginUserInfo: {usrId:null, planNm:null, authNm:null}
    };
  },
  mounted() {
    window.addEventListener('click', this.closeMenu);
    this.loginUserInfo = window.$store.getters['Login/getLoginUserInfo'];
  },
  beforeDestroy() {
    window.removeEventListener('click', this.closeMenu);
  },

  methods: {
    closeMenu(element) {
      if (!this.$refs.HeaderMenu.contains(element.target)) {
        this.showMenu = false;
      }
    },
    showMenuVie(){
      if (!this.showMenu){
        this.showMenu = true;
        this.sendOmsLogMessage("메뉴버튼 클릭");
      }      
    },
    showModal(name) {
      let message = "";

      if (name == "modalNotice"){
        message = "공지사항 클릭";
      }else if (name == "modalLogout"){
        message = "종료 클릭";
      }

      this.sendOmsLogMessage(message);

      this.$modal.show(name);
      console.log(name);
      this.showMenu = false;
    },
    sendOmsLogMessage(message){
      window.$store.dispatch('SocketOms/setLogMsg', {message:message});
    }
  },
};
</script>
