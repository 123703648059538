<template>
	<div></div>
</template>
<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Init',
  methods: {
    isMobile() {
			console.log('calculating :' + window.innerWidth  )
      if (window.innerWidth <= 1200) {
        return true;
      } else {
        return false;
      }
    },
  },
  created() {
    // if (this.isMobile()) {
		// 	console.log('mobile')
		// 	this.$router.push('/mobile');
		// 	} else {
		// 		console.log('desktop')
		// 		this.$router.push('/desktop');
    // }
  },
};
</script>
<style scoped></style>
