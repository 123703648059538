<template>
  <div v-if="isShow" class="modal-overlay">
    <div class="modal-container">
      <div class="modal-content">
        <div class="modal-header">
          <h1>{{title}}</h1>
          <div class="header-ui">
            <button class="close-btn" @click="close"><img src="@/assets/icons/close-black.svg" alt=""></button>
          </div>
        </div>
        <div class="modal-body">
          <h2 v-html="message"></h2>
        </div>
        <div class="modal-footer">
          <button class="btn-primary darkgray"  v-if="isConfirm" @click="cancel">아니오</button>
          <button class="btn-primary blue"  v-if="isConfirm" @click="confirm">예</button>
          <button class="btn-primary blue"  v-if="!isConfirm" @click="close">확인</button>
        </div>
      </div>
    </div>
  </div>      
</template>
  
<script>
export default {
  components: {
  },  
  data () {
    return {
      isShow: false,
      isConfirm: false,
      title: '알림',
      message: '',
      width: 500,
      height: 400
    }
  },
  methods: {
    confirm: function () {
      this.isShow = false

      if (typeof this.callback === 'function') {
        this.callback(true)
      }
    },
    cancel: function () {
      this.isShow = false

      if (typeof this.callback === 'function') {
        this.callback(false)
      }
    },
    close: function () {
      this.isShow = false

      if (typeof this.callback === 'function') {
        this.callback('close')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.6);
	z-index: 999;
	display: flex;
	align-items: center;
	justify-content: center;
}
.modal-container {
  width: 400px;
  height: 216px;
  //background-color: #212736;
  background-color: white;
  padding: 0 20px;
  border-radius: 3px;
  box-shadow: 0 20px 60px -2px rgba(27, 33, 58, 0.4);
  @media (max-width: $mobile) {
      font-size: 13px;
    }
}
.modal-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.modal-header {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 55px;
  .header-ui {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

.modal-body {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  h2 {
    line-height: 1.3;
  }  
}

.modal-footer {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  height: 70px;
  button {
    width: 80px;
  }
}
</style>