<template>
  <div class="dom-grid-container">
    <div class="dom-grid">
      <!-- header -->
      <div class="row-content">
        <div class="item header">Price(USDT)</div>
        <div class="item header">Size(BTC)</div>
        <div class="item header">Sum(BTC)</div>
      </div>
      <!-- row -->
      <div class="row-content"
        v-for="(item, index) in asksObject"
        :key="'ask'+index"
        @click="selectRowItem(item)"
      >
        <div class="item color-red"> {{ item.price }} </div>
        <div class="item"> {{ item.size }} </div>
        <div class="item"> {{ item.sum }} </div>
      </div>

      <!-- 센터가격 -->
      <div class="row-content">
        <div class="item centerPrice" :class="{ 'color-red' : cot.buyerMaker === true && cot.curPr > 0, 'color-blue' : coc.buyerMaker === false && cot.curPr > 0, '' : cot.curPr == 0 }">{{ cot.curPr }}</div>
        <div class="item centerMarkPrice">{{ com.markPr.toFixed(1) }}</div>
        <div class="item"></div>
      </div>

      <div class="row-content"
        v-for="(item, index) in bidsObject"
        :key="'bid'+index"
        @click="selectRowItem(item)"
      >
        <div class="item color-blue"> {{ item.price }} </div>
        <div class="item"> {{ item.size }} </div>
        <div class="item"> {{ item.sum }} </div>
      </div>

    </div>


    
  </div>
</template>
<!--
<template>
  <div>
    <div clss="bn-tooltips-wrap ml-0 draggableCancel flex items-center ml-[8px]">
      <div class="bn-tooltips-ele"></div>
      <div class="bn-bubble bn-bubble__unset shadow data-font-14 bn-tooltips" style="transform: translate(0px, 0px); top: 100%; right: 0px;">
        <div class="bn-bubble-content" style="margin-top: 4px;">
        </div>
      </div>
    </div>
    <div class="orderbook-header-tips">
      <div class="bn-tooltips-wrap bn-tooltips-web">
        <div class="bn-tooltips-ele">
          <div class="ob-type-button" data-testid="defaultModeButton" style="opacity: 1; width:16px; height:16px">
            <button class="order-book"></button>
          </div>
        </div>
      </div>
    </div>
    <div class="bn-tooltips-wrap bn-tooltips-web">
      <div class="bn-tooltips-ele">
        <div class="ob-type-button" data-testid="buyModeButton" style="opacity: 0.5;">
          <button class="order-buy"></button>
        </div>
      </div>
    </div>
    <div class="bn-tooltips-wrap bn-tooltips-web">
      <div class="bn-tooltips-ele">
        <div class="ob-type-button" data-testid="sellModeButton" style="opacity: 0.5;">
          <button class="order-sell"></button>
        </div>
      </div>
    </div>
    <div>
      코인 종목 : {{ coc.symbol }}<br/>
      코인 체결가 : {{ coc.curPr  }}<br/>
      코인 수량 : {{ coc.qty  }}<br/>
    </div>
  </div>
</template>
-->

<script>
import { stringCount } from '@/modules/helper';
import Utils from '@/modules/utils';

export default {
  name: 'WhiteLabelDesktopPage',
  components: {
  },
  props:{
    coinAccount: {
      type: Object
    },
  },
  computed: {
    //ask 호가 표시개수만큼 자르기
    asksObject(){
      return this.viewCnt ? this.asksList.slice(this.viewCnt+1, this.asksList.length) : this.asksList
    },
    //bid 호가 표시개수만큼 자르기
    bidsObject(){
      return this.viewCnt ? this.bidsList.slice(0, this.viewCnt+1) : this.bidsList
    },
    // 로그인 여부
    isLogin () {
      return window.$store.getters['Login/isLogin'];
    },
    //소켓 코인 시세
    changeCocData: function(){
      let cocData = window.$store.getters['SocketCoinPrice/getCocData'];
      return cocData;
    },
    //소켓 코인 호가
    changeCohData: function(){
      let cohData = window.$store.getters['SocketCoinPrice/getCohData']
      return cohData
    },
    //소켓 코인 센터가
    changeCotData: function(){
      let cotData = window.$store.getters['SocketCoinPrice/getCotData']
      return cotData
    },
    //소켓 코인 청산가
    changeComData: function(){
      let comData = window.$store.getters['SocketCoinPrice/getComData']
      return comData
    },
    //폴링시세
    changeCocDataList: function(){
      let cocData = window.$store.getters['SocketCoinPrice/getCocDataList'];
      return cocData;
    },
    //폴링호가
    changeCohDataList: function(){
      let cohData = window.$store.getters['SocketCoinPrice/getCohDataList']
      return cohData
    },
    changeFvSymbol: function(){
      let custom = window.$store.getters['Layout/getFavoriteGridCustom'];
      if (custom == undefined){
        return null;
      }
      return {socketSymbol: custom.socketSymbol, socketSymbolCnt: this.socketSymbolCnt+1};
    },
    getSelectCoinSymbol: function(){
      return window.$store.getters['SocketCoinPrice/getSelectCoinSymbol'];
    },
    tikDotSz(){
      return stringCount(this.symbol.pricescale);
    },
    resultOms: function(){
      return window.$store.getters['SocketCoinOms/getCompleteOrder'];
    },
    getAbleQty: function(){
      return window.$store.getters['SocketCoinOms/getAbleQty'];
    },
    resultMsg: function(){
      return window.$store.getters['SocketMsg/getMessage'];
    },
    getDailyPlMn: function(){
      return window.$store.getters['SocketCoinOms/getDailyPlMn'];
    },
    getPosOrdStList: function(){
      return window.$store.getters['SocketCoinOms/getPosOrdStList'];
    },
    getLoginUserInfo: function(){
      let loginInfo = window.$store.getters['Login/getLoginUserInfo'];
      return loginInfo;
    },
  },
  watch: {
    //소켓시세
    changeCocData(newPrice){
      //console.log("changeOvcData >>>> ", newPrice)
      if (newPrice.symbol == this.symbol.name){
        this.coc = newPrice;
      }
      //
    },
    //소켓호가
    changeCohData(newPrice){
      // apiType
      // asks
      // bids
      // hotTime
      // symbol
      if (newPrice.symbol == this.symbol.name){
        this.updateCoh(newPrice);
      }
    },
    //소켓 코인 센터가
    changeCotData(newPrice){
      if (newPrice.symbol == this.symbol.name){

        // apiType: "COT"
        // buyerMaker: false
        // curPr: 61094.7
        // korDate: "20241011"
        // korTm: "191003"
        // qty: 0.017
        // symbol: "BTCUSDT"
        // trdTm: 1728641403276

        
        this.cot = newPrice
      }
    },
    //소켓 코인 청산가
    changeComData(newPrice){
      // apiType
      // korDate
      // korTm
      // markPr
      // rate
      // symbol
      // trdTm
      if (newPrice.symbol == this.symbol.name){
        this.com = newPrice
      }
    },
    //폴링시세
    changeOvcDataList(newPrice){
      //
    },
    //폴링호가
    changeOvhDataList(newPrice){
      //
    },
    changeFvSymbol(val){
      // this.updateSubscribeOnPrice()
    },
    getSelectCoinSymbol(newVal){
      if (newVal == undefined) return;
      // this.rStock = Object.assign({}, null);

      //종목이 변경될경우 소켓데이터 연결 갱신
      this.symbol = Object.assign({}, newVal);

      this.nowPrice = {curPr:0, ydiffPr:0, open:0, chGrate:0, high:0, low:0, ydiffSign:0};

      this.tikSize = newVal.minmov / newVal.pricescale;

      this.ordStdPrc = 0; //일반주문 가격

      this.bidsList = [];
      this.asksList = [];
      this.coc.curPr = 0;
      this.coc.qty = 0;
      this.cot.curPr = 0;
      this.com.markPr = 0;

      this.setStockInfo(newVal);

      // //this.symbolDropdown(newVal);

      this.updateSubscribeOnCoinPrice();

      // this.selectSymbolTikPrice();

      // this.selectOrder(this.symbol.name);
      
      //accId가 있으면
      if (this.coinAccount.accId && this.coinAccount.accId > 0){
        this.searchOrdAbleQty();
      }else{
        //accId가 로딩중이면 기다렸다 실행
        window.$store.watch(() => this.coinAccount.accId, isAccount => {
          if (isAccount) {
            this.searchOrdAbleQty();
          }
        })
      }
    },
    resultOms(res){
      //
      
    },
    getAbleQty:{
      handler(res){
        //
      },
      deep:true
    },
    getDailyPlMn:{
      handler(res){
        // this.dailyPlMn.fxPlMn = res.fxPlMn; // 해외손익금액
        // this.dailyPlMn.fxCms = res.fxCms; // 해외수수료
        // this.dailyPlMn.nFxPlMn = res.nFxPlMn; // 해외순손익
        // this.dailyPlMn.futPlMn = res.futPlMn; // 국내손익금액
        // this.dailyPlMn.futCms = res.futCms; // 국내수수료
        // this.dailyPlMn.nFutPlMn = res.nFutPlMn; // 국내순손익
        // this.dailyPlMn.totPlMn = res.totPlMn; // 총손익금액
        // this.dailyPlMn.totNPlMn = res.totNPlMn; // 총순손익
        // this.dailyPlMn.totCms = res.totCms; // 총수수료
        // this.dailyPlMn.niPlMn = res.niPlMn;
        // this.dailyPlMn.balMn = res.balMn;
        // this.dailyPlMn.hkdExR = res.hkdExR;
        // this.dailyPlMn.usdExR = res.usdExR;
        // this.dailyPlMn.usdExHkd = res.usdExHkd;

        // this.sendPopupData('DAILY', res); //팝업 전송
      },        
      deep:true
    },
    getPosOrdStList(newList){
      // this.sendPopupData('POS_ORD', newList); //팝업 잔고리스트 전송
      // if (newList.length > 0){
      //   let balQty = 0;
      //   let execPrc = 0;
      //   let ordSdCd = "0";
      //   let plMn = 0;
      //   let valPl = 0;
      //   let plData = {};
      //   newList.forEach(data=>{
      //     if (data.ordType == "POS" && data.symCd == this.symbol.name){
      //       balQty = data.balQty;
      //       ordSdCd = data.ordSdCd;
      //       if (balQty > 0) execPrc = data.execPrc;
      //       plMn = data.plMn;
      //       valPl = data.valPl;
      //       plData = data;
      //     }
      //   })

      //   this.symbolPlMn.balQty = balQty;
      //   this.symbolPlMn.execPrc = execPrc;
      //   this.symbolPlMn.plMn = plMn;
      //   this.symbolPlMn.data = plData;

      //   this.avgPrice = Object.assign({}, {avgPrice:execPrc, ordSdCd:ordSdCd, balQty:balQty})
        

      //   this.setSymbolPrice();
        
      //   //symbolPlMn:{balQty:0, execPrc:0, fxPlMn:0, valPl:0},
      // }
    },
    getLoginUserInfo:{
      handler(userInfo){
        // this.loginUserInfo = userInfo;
      }
    },

  },
  data() {
    return {
      coc:{
        curPr: 0,
        qty: 0,
        buyerMaker: false,
      },
      cot:{
        curPr: 0,
        qty: 0,
        buyerMaker: false,
      },
      com:{
        markPr: 0,
      },
      bidsList:[],
      asksList:[],
      viewCnt:8,
      symbol:null,
      nowPrice: {curPr:0, ydiffPr:0, open:0, chGrate:0, high:0, low:0, ydiffSign:0},
      tikSize: 0,
      ordStdPrc: 0,
      symbolCdList:[],
      priceReqType: "SCKTP01", //시세 받는방식 socket(SCKTP01), polling(SCKTP02/SCKTP03)
      rtPosCoinSymbolList:[],
    }
  },
  created() {
    
  },
  mounted(){

  },
  methods: {
    selectRowItem(item){
      console.log("selectRowItem ", item);
      this.$emit("updateCoinPrice", item);
    },
    updateCoh(newPrice){
      let asksList = [];
        let askSum = 0;
        let revsAskList = [];

        //ask
        if (newPrice.asks != undefined){
          //sum값 계산, 배열 순번이 뒤로 갈수록 합산
          newPrice.asks.forEach(item=>{
            askSum += Number(item[1]);
            item[2] = askSum.toFixed(3);
          })

          //리스트 리버스
          for (let i=newPrice.asks.length-1; i>=0;i--){
            let item = newPrice.asks[i];
            asksList.push({price:item[0], size:item[1], sum:item[2]});
          }

          this.asksList = asksList;
        }

        //bid
        if (newPrice.bids != undefined){
          let bidsList = [];
          let bidSum = 0;
          newPrice.bids.forEach(item=>{
            bidSum += Number(item[1]);
            bidsList.push({price:item[0], size:item[1], sum:bidSum.toFixed(3)});
          });

          this.bidsList = bidsList;
        }
    },
    updateSubscribeOnCoinPrice(){
      //소켓 구독 종목 업데이트
      this.symbolCdList = [];

      //잔고목록
      this.rtPosCoinSymbolList.forEach(data =>{
        this.symbolCdList.push(data.symCd);
      });

      //선택 종목
      const selSymbol = window.$store.getters['SocketCoinPrice/getSelectCoinSymbol'];

      //팝업 선택 종목
      let popupSelSymbolList = [];
      //stock_trader_popup1SelSymbol

      if (JSON.parse(this.$sessionStorage.get('popup1SelSymbol')) != null){
        const popupSymbol = JSON.parse(this.$sessionStorage.get('popup1SelSymbol'));
        popupSelSymbolList.push(popupSymbol.name);
      }

      if (this.$sessionStorage.get('popup2SelSymbol') != null){
        const popupSymbol = JSON.parse(this.$sessionStorage.get('popup2SelSymbol'));
        popupSelSymbolList.push(popupSymbol.name);
      }

      //팝업 종목 추가
      popupSelSymbolList.forEach(item =>{
        let chkYn = "N";
        this.rtPosCoinSymbolList.forEach(data =>{
          if (data == item){
            chkYn = "Y";
            return;
          }
        });

        if (chkYn == "N"){
          this.symbolCdList.push(item);
        }
      });

      if (selSymbol != undefined && selSymbol.name){
        let chkYn = "N";
        this.symbolCdList.forEach(item =>{
          if (selSymbol.name == item){
            chkYn = "Y";
            return;
          }
        })
        if (chkYn == "N"){
          this.symbolCdList.push(selSymbol.name);
        }
      }

      //코인 테스트 목록
      //this.symbolCdList.push('BTCUSDT');
      

      console.log("소켓 구독 수정", this.symbolCdList)
      
      this.subscribeOnCoinPriceModify(this.symbolCdList, popupSelSymbolList);
    },
    subscribeOnCoinPriceModify(symbolList, popupSelSymbolList) {
      if (this.priceReqType == "SCKTP01"){
        window.$store.dispatch('SocketCoinPrice/setUpdateSubscribeOnPriceList', {symbolList, popupSelSymbolList});
      }else if (this.priceReqType == "SCKTP02" || this.priceReqType == "SCKTP03"){
        window.$store.dispatch('SocketCoinPrice/setPollingPrice', {symCd:this.symbol.name, symbolList:symbolList, popupSelSymbolList:popupSelSymbolList});
      }
    },
    async setStockInfo(newSymbol) {

      //const rStock = await window.$http.get('chart/datafeed/stockdata', { params: {  } } )
      //console.log("rStock search >>> ", newSymbol)
      const rStock = await window.$http.get('apisise/getCoinLastPrice', { params: { symCd: newSymbol.name} } )

      if ( rStock != null ) {
        //console.log("rStock >>>>> ", rStock)
        if (rStock.data.coc != null){
          this.coc = rStock.data.coc
        }
        if (rStock.data.cot != null){
          this.cot = rStock.data.cot
        }
        if (rStock.data.com != null){
          this.com = rStock.data.com
        }

        if (rStock.data.coh != null){
          this.updateCoh(rStock.data.coh);
        }
          
        //   this.nowPrice = rStock.data.ovc;
        //   this.ordStdPrc = Number(this.nowPrice.curPr).toFixed(this.tikDotSz);
        // }else{
        //   this.nowPrice = {curPr:0, ydiffPr:0, open:0, chGrate:0, high:0, low:0, ydiffSign:0};
        // }
        
        // this.rStock = Object.assign({}, rStock.data);
        
        // let ovc = this.ovcData.ovcData;

        // //시세 db값으로 초기 설정
        // rStock.data.forEach(item => {
        //   ovc.set(item.symbol, item);
        // });
        
        // let cnt = this.ovcData.cnt; //map으로 전달하면 PanelComp2 watch에서 감지를 못하여 cnt값을 계속 증가시켜 감지
        // const param = {cnt:cnt+1, ovcData:ovc};         
        // this.ovcData = param;
      }
    },
    searchOrdAbleQty(){
      window.$store.dispatch('SocketCoinOms/selectUsrData', {accId:this.coinAccount.accId, symCd:this.symbol.name, symNo:this.symbol.symbolno, lv:this.leverage, ordType:'coin'});
    },
  }
}
</script>

<style scoped>

.order-book{
  width:16px;
  height:16px;
  background-image: url('@/assets/icons/order-book.svg');
}

.order-buy{
  width:16px;
  height:16px;
  background-image: url('@/assets/icons/order-buy.svg');
}

.order-sell{
  width:16px;
  height:16px;
  background-image: url('@/assets/icons/order-sell.svg');
}
</style>