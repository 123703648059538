<template>
	<div>
		<button class="btn-primary blue" @click="show('modalSettings')">환경설정</button>
		<ModalSettings />

		<br>
		<button class="btn-primary blue" @click="show('modalDepositWithdraw')">입출금신청</button>
		<ModalDepositWithdraw />

		<br>
		<button class="btn-primary blue" @click="show('modalDepositWithdrawTable')">입출금내역</button>
		<ModalDepositWithdrawTable />

		<br>
		<button class="btn-primary blue" @click="show('modalTransactionTable')">거래내역</button>
		<ModalTransactionTable />

		<br>
		<button class="btn-primary blue" @click="show('modalProfitLossTable')">매매손익</button>
		<ModalProfitLossTable />

		<br>
		<button class="btn-primary blue" @click="show('modalOptions')">종목선택</button>
		<ModalOptions />

		<br>
		<button class="btn-primary blue" @click="show('modalNotice')">공지사항</button>
		<ModalNotice />

		<br>
		<button class="btn-primary blue" @click="show('modalNoticeDetail')">공지사항 상세</button>
		<ModalNoticeDetail />

		<br>
		<button class="btn-primary blue" @click="show('modalLogout')">종료</button>
		<ModalLogout />

		<br>
		<button class="btn-primary blue" @click="show('modalBasic')">공지 팝업</button>
		<ModalBasic />

		<br>
		<button class="btn-primary blue" @click="showNotification('orderFilledNotification', type='buy')">알림</button>
		<Notification />
		
		<br><br>
		<button class="btn-primary blue" @click="show('modalConfirmOrder')">주문확인</button>
		<ModalConfirmOrder />
	</div>
</template>

<script>
import ModalSettings from '@/components/ModalSettings.vue';
import ModalDepositWithdraw from '@/components/ModalDepositWithdraw.vue';
import ModalDepositWithdrawTable from '@/components/ModalDepositWithdrawTable.vue';
import ModalTransactionTable from '@/components/ModalTransactionTable.vue';
import ModalProfitLossTable from '@/components/ModalProfitLossTable.vue';
import ModalOptions from '@/components/ModalOptions.vue';
import ModalNotice from '@/components/ModalNotice.vue';
import ModalNoticeDetail from '@/components/ModalNoticeDetail.vue';
import ModalLogout from '@/components/ModalLogout.vue';
import ModalBasic from '@/components/ModalBasic.vue';
import Notification from '@/components/Notification.vue';
import ModalConfirmOrder from '@/components/ModalConfirmOrder.vue';

export default {
	name: 'WhiteLabelTestPage',
  components: { 
		ModalSettings,
		ModalDepositWithdraw,
		ModalDepositWithdrawTable,
    ModalTransactionTable,
    ModalProfitLossTable,
    ModalOptions,
    ModalNotice,
    ModalNoticeDetail,
    ModalLogout,
    ModalBasic,
    Notification,
    ModalConfirmOrder
	},

	data() {
		return {
			
		};
	},

	mounted() {
		this.$modal.show('modalConfirmOrder')
	},

	methods: {
		show(name) {
			this.$modal.show(name)
		},
		showNotification (group, type='') {
			console.log('hi');
			let text = '';
			if (type==='buy') {
				text = `
        <span style="color: var(--main-red)">매수</span>주문 체결 <span style="opacity: 0.4">|</span> 체결량 2
        <br>
       	NQ 10:25:23
      `	
			}
			if (type==='sell') {
				text = `
        <span style="color: var(--main-blue)">매도</span>주문 체결 <span style="opacity: 0.4">|</span> 체결량 2
        <br>
       	NQ 10:25:23
      `	
			}

      this.$notify({
        group,
        text,
				type
      })
    },
		

    clean (group) {
      this.$notify({ group, clean: true })
    }
	},
};
</script>

<style lang="scss" scoped>

</style>