<template>
  <div style="width: 100%; height: 100%">
    <ag-grid-vue
      style="width: 100%; height: 100%"
      class="ag-theme-wts"
      :columnDefs="grid.columnDefs"
      :rowData="grid.rowData"
      :headerHeight="grid.rowHeight"
      :rowHeight="grid.rowHeight"
      rowSelection="single"
      @row-clicked="selectSymbol"
      @grid-ready="onGridReady" />
  </div>
</template>
<script>
// import { ref } from 'vue';
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
// import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme
import { AgGridVue } from "ag-grid-vue"; // Vue Grid Logic
// eslint-disable-next-line no-unused-vars
// import {currencyFormatter, cellClassRulesMinus} from '@/agGridUtils.js';

export default {
  name: "agOptionsTable",
  components: {
    AgGridVue,
  },
  data() {
    return {
      grid:{
        columnDefs: [
          { 
            headerName: "콜", 
            field: "콜",
            minWidth: 80,
            flex: 1,
            cellStyle: {
              justifyContent: 'center',
              backgroundColor: '#f84957',
              color: 'red',
            },
            headerClass: "center-align-header",
            suppressMovable: true,
          },
          { 
            headerName: "행사가",
            field: "행사가",
            minWidth: 80,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header price",
            suppressMovable: true,
          },
          { 
            headerName: "풋",
            field: "풋",
            minWidth: 80,
            flex: 1,
            cellStyle: {
              justifyContent: 'center',
              backgroundColor: '#459dff',
              color: 'blue'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
          },
          
        ],
        rowData: [
          { 
            콜: '',
            행사가: 37111.50,
            풋: '',
          },
          { 
            콜: '',
            행사가: 377.50,
            풋: '',
          },
          { 
            콜: '',
            행사가: 377.50,
            풋: '',
          },
          { 
            콜: '',
            행사가: 377.50,
            풋: '',
          },
          { 
            콜: '',
            행사가: 377.50,
            풋: '',
          },
          { 
            콜: '',
            행사가: 377.50,
            풋: '',
          },
          { 
            콜: '',
            행사가: 377.50,
            풋: '',
          },
          { 
            콜: '',
            행사가: 377.50,
            풋: '',
          },
          { 
            콜: '',
            행사가: 377.50,
            풋: '',
          },
          { 
            콜: '',
            행사가: 377.50,
            풋: '',
          },
          { 
            콜: '',
            행사가: 377.50,
            풋: '',
          },
          { 
            콜: '',
            행사가: 377.50,
            풋: '',
          },
          { 
            콜: '',
            행사가: 377.50,
            풋: '',
          },
          { 
            콜: '',
            행사가: 377.50,
            풋: '',
          },
          { 
            콜: '',
            행사가: 377.50,
            풋: '',
          },
          { 
            콜: '',
            행사가: 377.50,
            풋: '',
          },
          { 
            콜: '',
            행사가: 377.50,
            풋: '',
          },
          { 
            콜: '',
            행사가: 377.50,
            풋: '',
          },
          { 
            콜: '',
            행사가: 377.50,
            풋: '',
          },
          { 
            콜: '',
            행사가: 377.50,
            풋: '',
          },
        ],
        autoSizeStrategy: null,
        paginationPageSize: null,
        currentPage: '',
        totalPages: '',
        rowHeight: '',
      },
      gridApi: null,
      gridColumnApi:null,
      isGridReady:null,
      selSymbol:null,
    };
  },
	created() {
    // this.paginationPageSize = 12;
		this.isMobile();
  },
  methods: {
		isMobile() {
      if (window.innerWidth <= 1200) {
        return this.rowHeight = 40;
      } else {
        return this.rowHeight = 30;
      }
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.isGridReady = true;

      //this.selectSymbolList();
    },
    selectSymbol(){

    },
  },
};

</script>

<style lang="scss" scoped>
@import '@/assets/scss/agGridStyles.scss';
</style>