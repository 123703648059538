<template>
	<div style="width: 100%; height: 100%">
    <ag-grid-vue
      style="width: 100%; height: 100%"
      class="ag-theme-wts"
      :columnDefs="grid.columnDefs"
      :rowData="grid.rowData"
      :headerHeight="rowHeight"
			:rowHeight="rowHeight"
      @grid-ready="onGridReady"
    />
  </div>
</template>

<script>
import 'ag-grid-community/styles/ag-grid.css'; // Core CSS
import { AgGridVue } from 'ag-grid-vue'; // Vue Grid Logic
import TableCheckbox from '@/components/agTables/TableCheckbox.vue';
import CustomHeader from '@/components/agTables/CustomHeader.vue';
import {buySellType, cellClassRulesMinus} from '@/modules/grid-utils.js';
import { stringCount } from '@/modules/helper';
import gridUtils from '@/modules/grid-utils';
import Utils from '@/modules/utils';

export default {
	name: 'WhiteLabelAgOvernightTable',
  props:{
    account:{
      type: Object
    },
    setGridSearch:{
      type: Object
    }
  },
  computed:{
    //소켓시세
    changeOvcData: function(){
      let ovcData = window.$store.getters['SocketPrice/getOvcData']
      return ovcData;
    },
    //폴링시세
    changeOvcDataList: function(){
      let ovcData = window.$store.getters['SocketPrice/getOvcDataList']
      return ovcData;
    },
    resultOms: function(){
      return window.$store.getters['SocketOms/getCompleteOrder']
    },
  },
  watch:{
    //소켓시세
    changeOvcData(newPrice){
      this.ovcMap.set(newPrice.symbol, newPrice);
      this.setGridPrice(); //현재가, 평가손익 새로고침
    },
    //폴링시세
    changeOvcDataList(newPrice){
      if (newPrice != null && newPrice.length > 0) {
        newPrice.forEach(data=>{
          this.ovcMap.set(data.symbol, data);
          this.setGridPrice(); //현재가, 평가손익 새로고침
        })
      }
    },
    resultOms(){ 
      //주문서버 리턴이 있을경우 목록 재조회
      this.search();
    },
    setGridSearch:{
      handler(newVal){
        this.gridSearch = newVal;
        if (newVal.type == "search"){
          //조회
          this.search();
        }else if (newVal.type == "set"){
          //담보금 체크
          const dailyPlMn = window.$store.getters['SocketOms/getDailyPlMn'];

          let totalOverAmt = 0;

          //설정된 오버나잇 금액
          this.grid.rowData.forEach(data => {
            console.log("data.overStat", data.overStat)
            if (data.balQty > 0 && data.overStat == "Y"){
                totalOverAmt += (Number(data.ovnAbleAmt) * Number(data.balQty)); //오버나잇 담보금액
            }
          })

          this.selectList.forEach(item =>{
            this.grid.rowData.forEach(data => {
              if (data.balQty > 0 && data.symCd == item.symCd && data.overStat == "N"){
                if (data.symCd == item.symCd){ //선택한 종목
                  totalOverAmt += (Number(data.ovnAbleAmt) * Number(data.balQty)); //오버나잇 담보금액
                }

              }
            })
          })

          if (Number(dailyPlMn.balMn + this.totData.totalValPl) < Number(totalOverAmt)){
            this.$alert.alert("담보금이 부족합니다.", '알림');
            this.sendOmsLogMessage("오버나잇 설정 : 담보금이 부족 취소");
            return;
          }

          //오버나잇 설정
          this.setOverNight();
        }else if (newVal.type == "cancel"){
          //오버나잇 취소
          this.cancelOverNight();
        }
        
      },
      deep:true
    }
  },
	components: {
		AgGridVue,
		TableCheckbox,
		agColumnHeader: CustomHeader,
	},

	data() {
    return {
      symbolList:[],
      gridSearch:{
        accId:0,
      },
      grid:{
        columnDefs: [
          {
            headerName: '',
            headerComponentParams: {
              enableMenu: true,
              checkBoxId:'AgOverNightHeader', // checkbox 이름이 안겹치도록 다르게 설정
              clicked: (chkVal) => { // customHeader.vue 에서 전달하는 리턴 클릭, 리턴값 (true, false)
                if (chkVal){
                  this.grid.rowData.forEach(data => {
                    data.checked = true;
                    this.selectList.push(data);
                  });
                }else{
                  this.selectList = [];
                  this.grid.rowData.forEach(data => {
                    data.checked = false;
                  });
                }
                this.gridApi.redrawRows();

                //오버나잇 금액 다시 계산
                let totalOverAmt = 0;
                //설정된 오버나잇 금액
                this.grid.rowData.forEach(data => {
                  if (data.balQty > 0 && data.overStat == "Y"){
                      totalOverAmt += (Number(data.ovnAbleAmt) * Number(data.balQty)); //오버나잇 담보금액
                  }
                })

                this.selectList.forEach(item =>{
                  this.grid.rowData.forEach(data => {
                    if (data.balQty > 0 && data.symCd == item.symCd && data.overStat == "N"){
                      if (data.symCd == item.symCd){ //선택한 종목
                        totalOverAmt += (Number(data.ovnAbleAmt) * Number(data.balQty)); //오버나잇 담보금액
                      }
                    }
                  })
                })

                this.totData.totalOverAmt = totalOverAmt;

                this.$emit("updateTotalValPl", this.totData);
              },
            },
            field: 'overNightCheckbox',
            resizable: false,
            suppressMovable: true,
            flex: 0.45,
            minWidth: 50,
            // checkboxSelection: true,
            cellRenderer: 'TableCheckbox',
            cellRendererParams: {
              clicked: (chkVal, params) => {
                document.getElementById("AgOverNightHeader").checked = false; //그리드 헤더 전체 체크 해제
                if (chkVal){
                  this.selectList.push(params.node.data);
                }else{
                  this.selectList.forEach((node, index) => {
                    if (node.symCd == params.node.data.symCd){
                      this.selectList.splice(index, 1);
                      return;
                    }
                  })
                }

                //오버나잇 금액 다시 계산
                let totalOverAmt = 0;
                //설정된 오버나잇 금액
                this.grid.rowData.forEach(data => {
                  if (data.balQty > 0 && data.overStat == "Y"){
                      totalOverAmt += (Number(data.ovnAbleAmt) * Number(data.balQty)); //오버나잇 담보금액
                  }
                })

                this.selectList.forEach(item =>{
                  this.grid.rowData.forEach(data => {
                    if (data.balQty > 0 && data.symCd == item.symCd && data.overStat == "N"){
                      if (data.symCd == item.symCd){ //선택한 종목
                        totalOverAmt += (Number(data.ovnAbleAmt) * Number(data.balQty)); //오버나잇 담보금액
                      }
                    }
                  })
                })

                this.totData.totalOverAmt = totalOverAmt;

                this.$emit("updateTotalValPl", this.totData);
              },
            },
            cellStyle: {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            },
            headerClass: 'center-align-header',
          },
          {
            headerName: '종목명',
            field: 'symKorNm',
            resizable: false,
            suppressMovable: true,
            flex: 1,
            minWidth: 70,
            cellStyle: {
              justifyContent: 'center',
            },
            headerClass: 'center-align-header',
          },
          {
            headerName: '잔고',
            field: 'balQty',
            cellStyle: {
              justifyContent: 'center',
            },
            headerClass: 'center-align-header',
            resizable: false,
            suppressMovable: true,
            flex: 1,
            minWidth: 100,
            valueFormatter: gridUtils.numberComma
          },
          {
            headerName: '구분',
            field: 'ordSdNm',
            resizable: false,
            suppressMovable: true,
            flex: 1,
            minWidth: 80,
            headerClass: 'center-align-header',
            cellStyle: {
              justifyContent: 'center',
            },
            cellClassRules: buySellType,
          },
          {
            headerName: '평균단가',
            field: 'execPrc',
            resizable: false,
            suppressMovable: true,
            flex: 1,
            minWidth: 90,
            headerClass: 'center-align-header',
            cellStyle: {
              justifyContent: 'center',
            },
            valueFormatter: (d) =>{
              let val = d.value;
              if (d.data.symNo == 2){ //항셍
                if (!Number.isInteger(val)){
                  val = val.toFixed(1);
                }
                return val;
              }else{
                let symbol = null;
                this.symbolList.forEach(item => {
                  if (item.symbolno == d.data.symNo){
                    symbol = item;
                    return;
                  }
                });
                return val.toFixed(this.tikDotSz(symbol));
              }
            },
          },
          {
            headerName: '평가손익',
            field: 'valPl',
            resizable: false,
            suppressMovable: true,
            flex: 1,
            minWidth: 100,
            headerClass: 'center-align-header',
            cellStyle: {
              justifyContent: 'center',
            },
            cellClassRules: cellClassRulesMinus,
            valueFormatter: gridUtils.numberComma
          },
          {
            headerName: '신청상태',
            field: 'overStatNm',
            resizable: false,
            suppressMovable: true,
            flex: 1,
            minWidth: 80,
            headerClass: 'center-align-header',
            cellStyle: {
              justifyContent: 'center',
            },
            valueFormatter: (d) =>{
              if (d.data.overStat == "N"){
                return "미설정";
              }else if (d.data.overStat == "Y"){
                return "설정";
              }
            },
          },
        ],
        rowData:[],
        autoSizeStrategy: null,
      },
      gridApi: null,
      gridColumnApi:null,
      isGridReady:false,
      selectList:[],
      ovcMap: new Map(),
      currencyInfo:{currency:"WON"},
      totData: {totalValPl:0, totalQty:0, totalLossCut:0, totalOverAmt:0},
    }
  },
	created() {
		this.isMobile();
	},
	mounted() {
    this.symbolList = window.$store.getters['Symbol/getSymbolList'];
	},
	methods: {
		isMobile() {
      if (window.innerWidth <= 1200) {
        return this.rowHeight = 40;
      } else {
        return this.rowHeight = 30;
      }
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.isGridReady = true;
    },
    tikVal(symbol){
      if (symbol == null){
        return 0;
      }
      return (symbol.minmov / symbol.pricescale);
    },
    tikDotSz(symbol){
      if (symbol == null){
        return 0;
      }
      return stringCount(symbol.pricescale);
    },
    async search(){
      if (this.gridSearch.accId == undefined || this.gridSearch.accId == 0){
        return;
      }

      document.getElementById("AgOverNightHeader").checked = false; //그리드 헤더 전체 체크 해제

      // 조회 조건
      const response = await this.$lhttp.get('/api/order/overNight/list', { params: this.gridSearch })
      this.grid.rowData = [];
      this.selectList = [];

      if ( response && response.data ) {
        response.data.forEach((data) => {
          data.valPl = 0;
          data.nowPrice = 0;
          if (data.balQty > 0 || data.ordQty > 0){
            data.checkBoxId = "AgOvernight_"+ data.symNo;
            this.grid.rowData.push(data);
          }          
        });
        this.gridApi.refreshCells(); //현재가 보이도록 셀 새로고침
      }

      this.setGridPrice();

    },
    setGridPrice(){
      let totData = {totalValPl:0, totalQty:0, totalLossCut:0, totalOverAmt:0,};
      if (this.grid.rowData != null && this.grid.rowData.length > 0){
        let totalValPl = 0;
        let totalQty = 0;
        let totalLossCut = 0;
        let totalOverAmt = 0;

        let usdExr = 0;
        let usdExHkd = 0;
        let hkdExR = 0;
        const dailyPlMn = window.$store.getters['SocketOms/getDailyPlMn'];
        if (dailyPlMn && dailyPlMn.usdExR) usdExr = dailyPlMn.usdExR;
        if (dailyPlMn && dailyPlMn.usdExHkd) usdExHkd = dailyPlMn.usdExHkd;
        if (dailyPlMn && dailyPlMn.hkdExR) hkdExR = dailyPlMn.hkdExR;
        this.grid.rowData.forEach(data => {
          if (data.balQty > 0){
            totalQty += Number(data.balQty);
            totalLossCut += (Number(data.rmsSetAmt) * Number(data.balQty)); //RMS_SET_AMT
            //totalOverAmt += (Number(data.ovnAbleAmt) * Number(data.balQty)); //오버나잇 담보금액

            const ovcData = this.ovcMap.get(data.symCd);

            // 계산 공통사용변경
            // data - 그리드 1개 row data
            // nowPrice OVC 데이터로 전달
            // symbolList
            // usdExr 환율
            // usdExHkd 홍콩달러환율
            // hkdExR 홍콩환율
            // lvTpCd 레버리지여부
            // currency 원/달러 구분 WON
            // return {data, }
            if (data != null){
              //console.log("data", data, ovcData, usdExr, usdExHkd, hkdExR, data.lvTpCd, this.currencyInfo.currency)
              let res = Utils.symbolCalPrc(data, ovcData, this.symbolList, usdExr, usdExHkd, hkdExR, data.lvTpCd, this.currencyInfo.currency);
              //data = res.data; //데이터가 갱신 안되면 주석해제
              console.log("res >>> ", res)
              totalValPl = res.valProLoss;
            }

            // let symbolData = null;
            // let pricescale = 1;
            //   this.symbolList.forEach(item => {
            //     if (item.name == data.symCd){
            //       symbolData = item;
            //       if (item.pricescale > 0){
            //         pricescale = item.pricescale;
            //       }
                  
            //       return;
            //     }
            //   });


            // if (ovcData != undefined){
            //   //현재가
            //   data.nowPrice = Number(ovcData.curPr).toFixed(this.tikDotSz(symbolData));
              


            //   //평가손익 계산
            //   let val1 = 0;
            //   let val2 = 0;
            //   let valProLoss = 0;
            //   if (ovcData.curPr == 0 || data.ordSdCd == 0){
            //     valProLoss = 0;
            //   }else{
            //     if (data.ordSdCd == "1"){ //매도
            //       val1 = ((data.execPrc.toFixed(this.tikDotSz(symbolData)) * pricescale) *  data.balQty);
            //       val2 = ((ovcData.curPr * pricescale) * data.balQty);
            //     }else if (data.ordSdCd == "2"){ //매수
            //       val1 = ((ovcData.curPr * pricescale) * data.balQty) ;
            //       val2 = (((data.execPrc.toFixed(this.tikDotSz(symbolData)) * pricescale) * data.balQty));
            //     }

            //     //소수점 계산에서 오차가 발생 ex> 0.0001 + 0.0009 = 0.000999 같은경우
            //     //1. execPrc의 평균가를 toFixed로 종목별 자릿수 만큼 반올림
            //     //2. pricescale 곱하여 소수점없이 계산
            //     //3. val1,val2 계산된값에 trunc을 하여 소수점을 버림
            //     //4. 최종 가격에서 pricescale로 나눠서 정상 가격을 표시

            //     //달러로 계산
            //     if (data.symNo == "2"){ //홍콩달러
            //       valProLoss = ((val1 - val2) * data.trdUnt); //홍콩달러계산
            //     }else{
            //       valProLoss = ((val1 - val2) / this.tikVal(symbolData)) * data.trdUnt; //달러로 계산
            //     }
                
            //     if (isNaN(valProLoss)) valProLoss = 0;

            //     //원화로 표시
            //     if (this.currencyInfo.currency == "WON"){
            //       if (data.symNo == "2"){
            //         valProLoss = Math.round((valProLoss * hkdExR) / pricescale);
            //         data.valPl = valProLoss;
            //       }else if (data.symNo == "10"){
            //         //국내선물
            //         //console.log("valProLoss ", valProLoss)
            //         valProLoss = Math.round(valProLoss) / pricescale;
            //         data.valPl = valProLoss;
            //       }else{
            //         //원화는 통화를 원화로 변경
            //         valProLoss = Math.round((valProLoss * usdExr) / pricescale);
            //         data.valPl = valProLoss;
            //       }  
            //     }else{ //달러
            //       if (data.symNo == "2"){
            //         valProLoss = (valProLoss * usdExHkd);
            //         data.valPl = valProLoss.toFixed(2);
            //       }else if (data.symNo == "10"){
            //         valProLoss = (valProLoss / usdExr);
            //         data.valPl = valProLoss.toFixed(2);
            //       }else{
            //         data.valPl = valProLoss.toFixed(2);
            //       }
            //     }

            //     totalValPl += valProLoss;
            //   }
            //   return;
            // }
          }
        });
        this.gridApi.refreshCells(); //현재가 보이도록 셀 새로고침

        //오버나잇 금액 다시 계산
        totalOverAmt = 0;
        //설정된 오버나잇 금액
        this.grid.rowData.forEach(data => {
          if (data.balQty > 0 && data.overStat == "Y"){
              totalOverAmt += (Number(data.ovnAbleAmt) * Number(data.balQty)); //오버나잇 담보금액
          }
        })

        this.selectList.forEach(item =>{
          this.grid.rowData.forEach(data => {
            if (data.balQty > 0 && data.symCd == item.symCd && data.overStat == "N"){
              if (data.symCd == item.symCd){ //선택한 종목
                totalOverAmt += (Number(data.ovnAbleAmt) * Number(data.balQty)); //오버나잇 담보금액
              }
            }
          })
        })

        totData.totalQty = totalQty;
        totData.totalValPl = totalValPl;
        totData.totalLossCut = totalLossCut;
        totData.totalOverAmt = totalOverAmt;
        this.totData = totData;
      }

      this.$emit("updateTotalValPl", totData);
    },
    //오버나잇 설정
    async setOverNight(){
      //선택 목록 확인
      if (this.selectList.length == 0){
        this.$alert.alert("선택한 종목이 없습니다.", '알림');
        return;
      }

      let selCd = "";
      for(let i=0; i<this.selectList.length; i++){
        if (i != 0){
          selCd += ", ";
        }
        selCd += this.selectList[i].symCd;
      }

      this.sendOmsLogMessage("오버나잇 설정 : " + selCd);

      //오버나잇 가능여부 확인
      for(let i=0; i<this.selectList.length; i++){
        if (this.selectList[i].ovnAbleFlg == "N"){
          this.$alert.alert(this.selectList[i].symKorNm+" 종목은 오버나잇 설정이 제한 되었습니다.", '알림');
          this.sendOmsLogMessage("오버나잇 설정 : " + this.selectList[i].symCd + " 설정이 제한");
          return;
        }
      }

      //신청한 오버나잇이 있는지 확인
      for(let i=0; i<this.selectList.length; i++){
        if (this.selectList[i].overStat == "Y"){
          this.$alert.alert(this.selectList[i].symKorNm+" 종목은 오버나잇 설정중입니다.", '알림');
          this.sendOmsLogMessage("오버나잇 설정 : " + this.selectList[i].symCd + " 오버나잇 설정중 상태");
          return;
        }
      }

      this.$alert.confirm('선택한 종목을 오버나잇 신청 하시겠습니까?').then((response) => {
        if (response) {      
          this.sendOmsLogMessage("오버나잇 설정 : '예' 버튼 클릭");  
          this.$lhttp
            .post('/api/order/overNight/save',
            this.selectList)
            .then((response) => {
              if (response.code == 200){
                if (Number(response.data) > 0){
                  this.$alert.alert('오버나잇 설정 되었습니다.', '알림').then(() => {
                    this.search();
                  })
                }else{
                  this.$alert.alert('오버나잇 설정 저장에 실패했습니다.', '알림').then(() => {
                    this.search();
                  })
                }
              }else{
                this.$alert.alert('오버나잇 설정 저장에 실패했습니다.', '알림').then(() => {
                    this.search();
                  })
              }
            })
        }else{
          this.sendOmsLogMessage("오버나잇 설정 : '아니오' 버튼 클릭");
        }
      })
    },
    //오버나잇 취소
    async cancelOverNight(){
      //선택 목록 확인
      if (this.selectList.length == 0){
        this.$alert.alert("선택한 종목이 없습니다.", '알림');
        return;
      }

      let selCd = "";
      for(let i=0; i<this.selectList.length; i++){
        if (i != 0){
          selCd += ", ";
        }
        selCd += this.selectList[i].symCd;
      }

      this.sendOmsLogMessage("오버나잇 취소 : " + selCd);

      //취소된 목록이 있는지 확인
      for(let i=0; i<this.selectList.length; i++){
        if (this.selectList[i].overStat == "N"){
          this.$alert.alert(this.selectList[i].symKorNm+" 종목은 오버나잇 신청내역이 없습니다.", '알림');
          this.sendOmsLogMessage("오버나잇 취소 : " + this.selectList[i].symCd + " 오버나잇 신청내역 없음");
          return;
        }
      }     

      this.$alert.confirm('선택한 종목을 오버나잇 취소 하시겠습니까?').then((response) => {
        if (response) {
          this.sendOmsLogMessage("오버나잇 취소 : '예' 버튼 클릭");
          this.$lhttp
            .post('/api/order/overNight/delete',
            this.selectList)
            .then((response) => {
              if (response.code == 200){
                if (Number(response.data) > 0){
                  this.$alert.alert('오버나잇 취소 되었습니다.', '알림').then(() => {
                    this.search();
                  })
                }else{
                  this.$alert.alert('오버나잇 취소 저장에 실패했습니다.', '알림').then(() => {
                    this.search();
                  })
                }
              }else{
                this.$alert.alert('오버나잇 취소 저장에 실패했습니다.', '알림').then(() => {
                    this.search();
                  })
              }
            })
        }else{
          this.sendOmsLogMessage("오버나잇 취소 : '아니오' 버튼 클릭");
        }
      })

      //const response = await this.$lhttp.post('/api/order/overNight/delete', { params: this.gridSearch })
    },
    sendOmsLogMessage(message){
      window.$store.dispatch('SocketOms/setLogMsg', {message:message});
    }
	},
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/agGridStyles.scss';
</style>